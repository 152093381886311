import React, { useState, useEffect, useRef } from "react";

import { useTranslation } from "react-i18next";

import { useForm, FormProvider, useFieldArray } from "react-hook-form";

import useAuth from "../../../DataProviders/auth/useAuth";
import useErrors from "../../../utils/hooks/useErrors";

import useProfiles from "../../../DataProviders/profiles/useProfiles";
import useLabeled from "../../../DataProviders/labeled/useLabeled";
import useMediumTypes from "../../../DataProviders/mediumTypes/useMediumTypes";

import { PageWrapper } from "ui-components";
import { PageHeader } from "ui-components";
import { PageBody } from "ui-components";

import { CardRowWrapper } from "ui-components";
import { Card } from "ui-components";
import { CardColumnIcon } from "ui-components";

import { CardColumnForm } from "ui-components";

import CloseIcon from "@material-ui/icons/Close";

import { Button, Chip, Input, Select as FormSelect, SwitchChip } from "ui-components";
import DateInput from "../../../components/DateInput";
import { RadioGroupRow } from "ui-components";
import { NumberInput } from "ui-components";

import { validateStringAmount } from "ui-components";

import { OverlayScrollbarsComponent } from "overlayscrollbars-react";

import { toastNotify } from "../../../components/ToastNotify";

import _ from "lodash";
import produce from "immer";

import amount from "../../../helpers/amount";
import amountNumber from "../../../helpers/amountNumber";

import "overlayscrollbars/css/OverlayScrollbars.css";
import "react-datepicker/dist/react-datepicker.css";
import "./styles.scss";

const newEmptyRate = {
  validFrom: new Date(),
  mediumTypes: [
    {
      id: null,
      nexusFee: 0,
      urbanFee: 0,
      fee: 0,
      scheduledFee: 0,
      km: 0,
      minute: 0,
    },
  ],
};

const newEmptyMediumType = {
  id: null,
  nexusFee: 0,
  urbanFee: 0,
  fee: 0,
  scheduledFee: 0,
  km: 0,
  minute: 0,
};

const Edit = ({ _id, onClose }) => {
  const { t } = useTranslation();

  const {
    data: { user },
  } = useAuth();

  const currency = user.company.localization.currency;

  const decimalSeparator = (100.01).toLocaleString(currency.locale).replace(/\d/g, "")[0];

  //const regExpFloat = decimalSeparator === "," ? /^([1-9][\d]*|0)(,[0-9]{0,2})?$/ : /^([1-9][\d]*|0)(\.[0-9]{0,2})?$/;
  const regExpFloat =
    decimalSeparator === "," ? /^[0-9]{1,3}([0-9]{3})*\,[0-9]{0,2}$/ : /^[0-9]{1,3}([0-9]{3})*\.[0-9]{0,2}$/;

  const regExpFloatCharts = decimalSeparator === "," ? /[0-9,]{1}/ : /[0-9]|(\.){1}/;

  //const regExpInteger = /^0{1}|[1-9]{1}[0-9]{0,1}$/;
  const regExpInteger = /^(?:0{1}|[1-9]{1}[0-9]{0,1})$/;

  const regExpIntegerCharts = /[0-9]{1}/;

  const eventKeyFloat = ["Tab", "Enter", "Backspace", "ArrowLeft", "ArrowRight"];

  const [isFetching, setIsFetching] = useState(false);

  const custodyDaysRef = useRef();
  const labelSelectionGroupRef = useRef();

  const [profileList, setProfileList] = useState(null);
  const [labelList, setLabelList] = useState(null);
  const [mediumTypeList, setMediumTypeList] = useState(null);

  const [initialDetail, setInitialDetail] = useState();
  const [formModified, setFormModified] = useState(false);

  const [hasAccumulateSurcharge, setHasAccumulateSurcharge] = useState(null)

  const [selectedLabels, setSelectedLabels] = useState([]);
  const [showLabels, setShowLabels] = useState(false);

  const [ratesHidden, setRatesHidden] = useState([]);

  const methods = useForm({ mode: "onChange" });
  const { register, handleSubmit, errors, formState, watch, trigger, control, setValue, getValues, setError, reset } =
    methods;
  const { getError } = useErrors();
  const { isValid } = formState;

  const { fields, append, prepend, remove, swap, move, insert, update } = useFieldArray({
    control,
    name: "rates",
  });

  const cancelRate = watch("cancelRate");
  const cancelAmount = watch("cancelAmount");
  const watchAllFields = watch();

  const {
    data: { detail },
    methods: { fetchAllProfiles, fetchProfileDetail, updateProfile },
  } = useProfiles();

  const {
    methods: { fetchAllLabeled },
  } = useLabeled();

  const {
    methods: { fetchAllMediumTypes },
  } = useMediumTypes();

  const handleSaveProfile = async () => {
    setIsFetching(true);
    try {
      let nextState = produce(getValues(), draft => {
        draft.labeledType = showLabels ? "brand" : "all";
        draft.labels = showLabels ? selectedLabels : [];
        draft.custodyDays = draft.custodyDays || 0;
        draft.custodyRate = amountNumber(draft.custodyRate) || 0;
        draft.surchargeRate = amountNumber(draft.surchargeRate) || 0;
        draft.cancelRate = amountNumber(draft.cancelRate) || 0;
        draft.cancelAmount = amountNumber(draft.cancelAmount) || 0;
        if (draft.rates?.length > 0) {
          draft.rates = draft.rates.map(rate => {
            if (rate.mediumTypes.length > 0) {
              return {
                ...rate,
                mediumTypes: rate.mediumTypes.map((mt, index) => {
                  return {
                    id: mt.id,
                    nexusFee: amountNumber(mt.nexusFee) || 0,
                    urbanFee: amountNumber(mt.urbanFee) || 0,
                    fee: amountNumber(mt.fee) || 0,
                    scheduledFee: amountNumber(mt.scheduledFee) || 0,
                    km: amountNumber(mt.km) || 0,
                    minute: amountNumber(mt.minute) || 0,
                  };
                }),
              };
            } else return [];
          });
        }
      });
      nextState = { ...nextState, nightlyAccumulates: hasAccumulateSurcharge }
      if (!detail) return;
      await updateProfile(_id, nextState);
      toastNotify({ t, type: "success" });
    } catch (error) {
      // console.log(error);
      toastNotify({ t, type: "error" });
    }
    setIsFetching(false);
    onClose();
  };

  const handleRemoveRow = index => {
    if (fields.length > 0) {
      remove(index);
    }
  };

  const handleAddMediumType = rateIndex => {
    const nextState = produce(getValues(), draft => {
      if (!draft.rates[rateIndex].mediumTypes) {
        draft.rates[rateIndex] = { ...draft.rates[rateIndex], mediumTypes: [newEmptyMediumType] };
      } else {
        draft.rates[rateIndex].mediumTypes = [...draft.rates[rateIndex].mediumTypes, [newEmptyMediumType]];
      }
    });
    setValue("rates", [...nextState.rates]);
    trigger("rates");
  };

  const handleRemoveMediumType = (rateIndex, mediumTypeIndex) => {
    let mediums = getValues().rates[rateIndex].mediumTypes.filter((mt, index) => index !== mediumTypeIndex);
    if (!mediums) mediums = [];
    const nextState = produce(getValues(), draft => {
      draft.rates[rateIndex].mediumTypes = mediums;
    });
    setValue("rates", [...nextState.rates]);
    trigger("rates");
  };

  const parseFloatAmount = (amountToConvert, currency, digits = 2) => {
    let amount = amountToConvert;
    if (typeof amount === "string") {
      amount = Number(amount.replace(",", "."));
    }
    const { locale, code } = currency;
    const options = {
      currency: code,
      minimumFractionDigits: digits,
      maximumFractionDigits: digits,
      useGrouping: false,
    };
    const formattedAmount = amount.toLocaleString(locale, options);
    return `${formattedAmount}`;
  };

  const fetchFormData = async id => {
    setIsFetching(true);
    try {
      await fetchProfileDetail(id);
      setProfileList(await fetchAllProfiles());
      setLabelList(await fetchAllLabeled());
      setMediumTypeList(await fetchAllMediumTypes());
      setIsFetching(false);
    } catch (error) {
      setIsFetching(false);
      toastNotify({ t, type: "error" });
      onClose();
    }
  };

  useEffect(() => {
    fetchFormData(_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!detail) return;
    let nextState = produce(detail, draft => {
      draft.labels = [...draft.labels].sort();
      draft.custodyRate = draft.custodyRate !== 0 ? amount(draft.custodyRate) : "";
      draft.surchargeRate = draft.surchargeRate !== 0 ? amount(draft.surchargeRate) : "";
      if (draft.rates?.length > 0) {
        draft.rates = draft.rates.map(rate => {
          if (rate.mediumTypes.length > 0) {
            return {
              ...rate,
              mediumTypes: rate.mediumTypes.map((mt, index) => {
                return {
                  id: mt.id,
                  nexusFee: mt.nexusFee !== 0 ? amount(mt.nexusFee) : "",
                  urbanFee: mt.urbanFee && mt.urbanFee !== 0 ? amount(mt.urbanFee) : "",
                  fee: mt.fee !== 0 ? amount(mt.fee) : "",
                  scheduledFee: mt.scheduledFee && mt.scheduledFee !== 0 ? amount(mt.scheduledFee) : "",
                  km: mt.km !== 0 ? amount(mt.km) : "",
                  minute: mt.minute !== 0 ? amount(mt.minute) : "",
                };
              }),
            };
          } else return [];
        });
      }
    });
    setInitialDetail(_.omit(nextState, ["_id", "active", "createdAt", "region", "updatedAt"]));
    if (detail.rates?.length > 0) {
      setValue("rates", [...detail.rates]);
    } else append(newEmptyRate);
    if (detail.labeledType === "all") {
      setSelectedLabels([]);
      setShowLabels(false);
    } else {
      setSelectedLabels(detail.labels);
      setShowLabels(true);
    }
    setHasAccumulateSurcharge(detail.nightlyAccumulates || false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detail]);

  useEffect(() => {
    const currentFormState = {
      ...watchAllFields,
      labeledType: showLabels ? "brand" : "all",
      labels: showLabels ? [...selectedLabels].sort() : [],
      rates: watchAllFields.rates ? [...watchAllFields.rates] : [],
    };
    setFormModified(!_.isEqual(initialDetail, currentFormState));
  }, [watchAllFields]);

  return (
    detail &&
    profileList &&
    labelList &&
    mediumTypeList && (
      <PageWrapper id="EditProfile">
        <PageHeader
          goBack={onClose}
          title={detail.name}
          info={t("fieldsRequired")}
          actions={
            <div className="PageHeader__actions flex end v-center">
              <Button onClick={handleSaveProfile} disabled={!isValid || !formModified}>
                {t("save")}
              </Button>
            </div>
          }
        />

        <OverlayScrollbarsComponent style={{ height: "100%", width: "100%" }}>
          <PageBody>
            <FormProvider {...methods}>
              <form className="Form">
                {/* PROFILE */}

                <CardRowWrapper>
                  <Card>
                    <CardColumnIcon title={t("profile")} icon="assets/icons/profiles.svg" />
                    <CardColumnForm className="profile-card">
                      {/* name & profileCode */}

                      <div className="Row flex row">
                        <div className="Column">
                          <Input
                            label={t("name")}
                            name="name"
                            defaultValue={detail.name || ""}
                            required
                            rules={{ required: t("validation.fieldRequired") }}
                            errorobj={errors}
                          />
                        </div>
                        <div className="Column">
                          <Input
                            label={t("code")}
                            name="profileCode"
                            defaultValue={detail.profileCode || ""}
                            required
                            rules={{ required: t("validation.fieldRequired") }}
                            errorobj={errors}
                          />
                        </div>
                      </div>

                      {/* billingType, profileNames, custodyDays & custodyRate */}

                      <div className="Row flex row">
                        <div className="Column half">
                          <FormSelect
                            label={t("billing")}
                            name="billingType"
                            options={[
                              { value: "nexus", label: "Nexus" },
                              { value: "customer", label: t("customer") },
                            ]}
                            defaultValue={detail.billingType || ""}
                          />
                          <FormSelect
                            label={t("childProfile")}
                            name="childProfile"
                            options={profileList
                              .filter(p => p._id !== detail._id)
                              .map(p => ({ value: p._id, label: p.name }))}
                            defaultValue={detail.childProfile || ""}
                            required={false}
                            rules={{ required: false }}
                          />
                        </div>

                        <div className="Column half">
                          <NumberInput
                            ref={custodyDaysRef}
                            className="NumberInput"
                            label={t("custodyDays")}
                            name="custodyDays"
                            defaultValue={detail.custodyDays || 0}
                            min={0}
                            onChange={() => {}}
                          />
                          <Input
                            label={t("custodyRate")}
                            name="custodyRate"
                            defaultValue={detail.custodyRate ? amount(detail.custodyRate) : ""}
                            rules={{
                              pattern: {
                                value: /^[0-9]+(\,[0-9]{1,2})?$/,
                                message: t("validation.invalidCurrencyAmount"),
                              },
                            }}
                            errorobj={errors}
                          />
                        </div>
                      </div>

                      {/* nightlyStart, nightlyEnd, surchargeRate */}

                      <div className="Row flex row">
                        <div className="Column half">
                          <Input
                            label={t("nightlyStart")}
                            name="nightlyStart"
                            defaultValue={detail.nightlyStart || ""}
                            rules={{
                              pattern: {
                                value: /^(?:2[0-3]|[01][0-9]):[0-5][0-9]$/,
                                message: t("validation.invalidTimeFormat"),
                              },
                            }}
                            errorobj={errors}
                          />
                          <Input
                            label={t("nightlyEnd")}
                            name="nightlyEnd"
                            defaultValue={detail.nightlyEnd || ""}
                            rules={{
                              pattern: {
                                value: /^(?:2[0-3]|[01][0-9]):[0-5][0-9]$/,
                                message: t("validation.invalidTimeFormat"),
                              },
                            }}
                            errorobj={errors}
                          />
                        </div>

                        <div className="Column half">
                          <Input
                            label={t("surchargeRate")}
                            name="surchargeRate"
                            defaultValue={detail.surchargeRate ? detail.surchargeRate.toFixed(0) : "0"}
                            rules={{
                              pattern: {
                                value: /^(?:0{1}|[1-9]{1}[0-9]{0,1})$/,
                                message: t("validation.invalidCurrencyAmount"),
                              },
                            }}
                            errorobj={errors}
                          />

                          <Input
                            label={t("cancelRatePercentage")}
                            name="cancelRate"
                            defaultValue={detail.cancelRate ? detail.cancelRate.toFixed(0) : "0"}
                            rules={{
                              pattern: {
                                value: /^(?:0{1}|[1-9]{1}[0-9]{0,1})$/,
                                message: t("validation.invalidCurrencyAmount"),
                              },
                            }}
                            errorobj={errors}
                          />

                          <Input
                            label={t("cancelRateAmount")}
                            name="cancelAmount"
                            defaultValue={detail.cancelAmount ? detail.cancelAmount.toFixed(0) : "0"}
                            rules={{
                              pattern: {
                                value: /^(?:0{1}|[1-9]{1}[0-9]{0,1})$/,
                                message: t("validation.invalidCurrencyAmount"),
                              },
                            }}
                            errorobj={errors}
                          />
                        </div>
                      </div>

                      {/* nightlyWeekend checkbox */}

                      <div className="Row flex row accumulate">
                        <div>
                          <div className="AccumulateSurchargeLabel">{t("accumulateNightAndHolidaySurcharge")}</div>
                          <SwitchChip value={hasAccumulateSurcharge} onChange={v => setHasAccumulateSurcharge(v)} />
                        </div>
                      </div>

                      {/* Labels */}

                      <div className="Row row-labels">
                        <div className="Column label-selection-group">
                          <RadioGroupRow
                            ref={labelSelectionGroupRef}
                            className="RadioGroupRow"
                            legend={t("label")}
                            name="profileId"
                            items={[
                              { id: "all", name: t("all", { context: "female" }) },
                              { id: "byBrand", name: t("byBrand") },
                            ]}
                            value={detail.labeledType === "all" ? "all" : "byBrand"}
                            onChange={() => {
                              setSelectedLabels([]);
                              setShowLabels(!showLabels);
                            }}
                            errorobj={errors}
                          />
                        </div>

                        {(showLabels && (
                          <div className="Column labels">
                            {labelList.map((item, index) => {
                              return (
                                <Chip
                                  className="Chip"
                                  key={index}
                                  color="236, 117, 74"
                                  label={item.name}
                                  isActive={selectedLabels.includes(item._id)}
                                  onChange={() => setSelectedLabels(_.xor(selectedLabels, [item._id]))}
                                />
                              );
                            })}
                          </div>
                        )) || <div />}
                      </div>

                    </CardColumnForm>
                  </Card>
                </CardRowWrapper>

                {/* RATE GROUPS */}

                {fields.map((rate, rateIndex) => (
                  <CardRowWrapper key={rate.id}>
                    <Card>
                      <CloseIcon
                        className="close-icon-rates"
                        onClick={event => {
                          event.preventDefault();
                          handleRemoveRow(rateIndex);
                        }}
                      />
                      <CardColumnIcon title={t("rates")} icon="assets/icons/rates.svg" />
                      <CardColumnForm>
                        {/* MediumTypesCount & validFrom */}

                        <div className="Row flex row rate-mediumtype header start v-center first-row">
                          <div className="Column half rate-medium-type-column">
                            <div className="dropdown">
                              <img
                                className="dropdown"
                                src={`assets/icons/dropdown-${_.includes(ratesHidden, rate.id) ? "open" : "close"}.svg`}
                                alt=""
                                onClick={event => {
                                  event.preventDefault();
                                  setRatesHidden(_.xor([...ratesHidden], [rate.id]));
                                }}
                              />
                            </div>

                            <div className="medium-counter">{`${t("MEDIUMS")} (${rate.mediumTypes.length})`}</div>

                            <div className="date">
                              <DateInput
                                label={t("fromDate")}
                                name={`rates.${rateIndex}.validFrom`}
                                defaultValue={rate.validFrom}
                              />
                            </div>
                          </div>

                          <div className="Column half" />
                        </div>

                        {/* RATES (map) */}

                        <div className={`rates-mediumtypes-wrapper ${_.includes(ratesHidden, rate.id) ? "hide" : ""}`}>
                          {rate.mediumTypes.map((mediumType, index) => (
                            <div key={index} className="Row flex row rate-mediumtype">
                              <div className="Column half">
                                <FormSelect
                                  label={t("mediumType")}
                                  name={`rates.${rateIndex}.mediumTypes.${index}.id`}
                                  options={mediumTypeList.map(mt => ({ value: mt._id, label: mt.name }))}
                                  defaultValue={mediumType.id || null}
                                />
                              </div>

                              <div className="Column half">
                                <Input
                                  label={t("nexusFee")}
                                  name={`rates.${rateIndex}.mediumTypes.${index}.nexusFee`}
                                  defaultValue={mediumType.nexusFee ? amount(mediumType.nexusFee) : "0"}
                                  required
                                  rules={{
                                    required: t("validation.invalidCurrencyAmount"),
                                    pattern: {
                                      value: /^[0-9]+(\,[0-9]{1,2})?$/,
                                      message: t("validation.invalidCurrencyAmount"),
                                    },
                                  }}
                                  errorobj={errors}
                                />
                                <Input
                                  label={t("urban")}
                                  name={`rates.${rateIndex}.mediumTypes.${index}.urbanFee`}
                                  defaultValue={mediumType.urbanFee ? amount(mediumType.urbanFee) : "0"}
                                  required
                                  rules={{
                                    required: t("validation.invalidCurrencyAmount"),
                                    pattern: {
                                      value: /^[0-9]+(\,[0-9]{1,2})?$/,
                                      message: t("validation.invalidCurrencyAmount"),
                                    },
                                  }}
                                  errorobj={errors}
                                />
                                <Input
                                  label={t("fee")}
                                  name={`rates.${rateIndex}.mediumTypes.${index}.fee`}
                                  defaultValue={mediumType.fee ? amount(mediumType.fee) : "0"}
                                  required
                                  rules={{
                                    required: t("validation.invalidCurrencyAmount"),
                                    pattern: {
                                      value: /^[0-9]+(\,[0-9]{1,2})?$/,
                                      message: t("validation.invalidCurrencyAmount"),
                                    },
                                  }}
                                  errorobj={errors}
                                />
                                <Input
                                  label={t("scheduledAbbrev")}
                                  name={`rates.${rateIndex}.mediumTypes.${index}.scheduledFee`}
                                  defaultValue={mediumType.scheduledFee ? amount(mediumType.scheduledFee) : "0"}
                                  required
                                  rules={{
                                    required: t("validation.invalidCurrencyAmount"),
                                    pattern: {
                                      value: /^[0-9]+(\,[0-9]{1,2})?$/,
                                      message: t("validation.invalidCurrencyAmount"),
                                    },
                                  }}
                                  errorobj={errors}
                                />
                                <Input
                                  label={t("kilometer")}
                                  name={`rates.${rateIndex}.mediumTypes.${index}.km`}
                                  defaultValue={mediumType.km ? amount(mediumType.km) : "0"}
                                  required
                                  rules={{
                                    required: t("validation.invalidCurrencyAmount"),
                                    pattern: {
                                      value: /^[0-9]+(\,[0-9]{1,2})?$/,
                                      message: t("validation.invalidCurrencyAmount"),
                                    },
                                  }}
                                  errorobj={errors}
                                />
                                <Input
                                  label={t("minute")}
                                  name={`rates.${rateIndex}.mediumTypes.${index}.minute`}
                                  defaultValue={mediumType.minute ? amount(mediumType.minute) : "0"}
                                  required
                                  rules={{
                                    required: t("validation.invalidCurrencyAmount"),
                                    pattern: {
                                      value: /^[0-9]+(\,[0-9]{1,2})?$/,
                                      message: t("validation.invalidCurrencyAmount"),
                                    },
                                  }}
                                  errorobj={errors}
                                />
                                <CloseIcon
                                  className="close-mediumtype"
                                  onClick={event => {
                                    event.preventDefault();
                                    handleRemoveMediumType(rateIndex, index);
                                  }}
                                />
                              </div>
                            </div>
                          ))}

                          {/* Add mediumType */}

                          <div className="Row flex row rate-mediumtype last-row">
                            <div className="Column half add-btn">
                              <Button
                                variant="secondary"
                                onClick={event => {
                                  event.preventDefault();
                                  handleAddMediumType(rateIndex);
                                }}
                              >
                                <img src="/assets/icons/add.svg" />
                              </Button>
                            </div>
                          </div>
                        </div>
                      </CardColumnForm>
                    </Card>
                  </CardRowWrapper>
                ))}

                {/* ADD NEW RATES GROUP BUTTON */}

                <CardRowWrapper>
                  <Button
                    variant="secondary"
                    prefixIcon="add"
                    onClick={event => {
                      event.preventDefault();
                      append(newEmptyRate);
                    }}
                  >
                    {t("newRates")}
                  </Button>
                </CardRowWrapper>
              </form>
            </FormProvider>
          </PageBody>
        </OverlayScrollbarsComponent>
      </PageWrapper>
    )
  );
};

export default Edit;
