import { useDispatch, useSelector } from 'react-redux'

import { setDocTypeDetail, setDocTypesList } from './actions'
import useFetch from '../../utils/hooks/useFetch'

const useDocTypes = () => {
  const endpoint = 'documentTypes'
  const instance = useSelector(state => state.docTypes)
  const dispatch = useDispatch()
  const { get, post, put, patch } = useFetch()

  //LIST
  const fetchList = async params => {
    const data = await get({ endpoint, params })
    dispatch(setDocTypesList({ ...data, search: params?.search }))
  }

  const handleChangePage = page => {
    fetchList({ ...instance.params, page })
  }

  const handleChangePageSize = limit => {
    fetchList({ ...instance.params, page: 1, limit })
  }

  const handleChangeSearch = search => {
    fetchList({ ...instance.params, page: 1, search })
  }

  //DETAIL
  const updateDocType = async (id, body) => {
    const response = await put({ endpoint, id, body })
    const listIndex = instance.list.findIndex(item => item._id === response._id)
    if (listIndex !== -1) {
      const newList = [...instance.list]
      newList[listIndex] = response
      dispatch(setDocTypesList({ docs: newList, metadata: { totalDocs: instance.totalDocs } }))
    }
    return response
  }

  const toggleActiveDocType = async (id, active) => {
    const response = await patch({ endpoint, id, body: { active } })
    const listIndex = instance.list.findIndex(item => item._id === response._id)
    if (listIndex !== -1) {
      const newList = [...instance.list]
      newList[listIndex] = { ...newList[listIndex], active: response.active }
      dispatch(setDocTypesList({ docs: newList }))
    }
    return response
  }

  const fetchAllDocTypes = async () => {
    return await get({ endpoint: 'documenttypes/all' })
  }

  const addDocType = async body => {
    return await post({ endpoint, body })
  }

  const fetchDocTypeDetail = async id => {
    const response = await get({ endpoint: `${endpoint}/${id}` })
    dispatch(setDocTypeDetail(response))
  }

  const unsetDocTypeDetail = () => {
    dispatch(setDocTypeDetail(null))
  }

  const getDocTypeCategories = async () => {
    return await get({ endpoint: `${endpoint}/attachmentTypes` })
  }

  return {
    methods: {
      fetchList,
      handleChangePage,
      handleChangePageSize,
      handleChangeSearch,
      toggleActiveDocType,
      fetchAllDocTypes,
      updateDocType,
      addDocType,
      fetchDocTypeDetail,
      setDocTypeDetail: data => dispatch(setDocTypeDetail(data)),
      unsetDocTypeDetail,
      getDocTypeCategories
    },
    data: instance
  }
}

export default useDocTypes
