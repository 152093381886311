import { useDispatch, useSelector } from 'react-redux'

import { setRejectionDetail, setRejectionsList } from './actions'
import useFetch from '../../utils/hooks/useFetch'

const useRejections = () => {
  const instance = useSelector(state => state.rejections)
  const endpoint = 'rejections'
  const dispatch = useDispatch()
  const { get, post, put, patch } = useFetch()

  //LIST
  const fetchList = async params => {
    const data = await get({ endpoint, params })
    dispatch(setRejectionsList({ ...data, search: params?.search }))
  }

  const handleChangePage = page => {
    fetchList({ ...instance.params, page })
  }

  const handleChangePageSize = limit => {
    fetchList({ ...instance.params, page: 1, limit })
  }

  const handleChangeSearch = search => {
    fetchList({ ...instance.params, page: 1, search })
  }

  //DETAIL
  const updateRejection = async (id, body) => {
    const response = await put({ endpoint, id, body })
    const listIndex = instance.list.findIndex(item => item._id === response._id)
    if (listIndex !== -1) {
      const newList = [...instance.list]
      newList[listIndex] = response
      dispatch(setRejectionsList({ docs: newList, metadata: { totalDocs: instance.totalDocs } }))
    }
    return response
  }

  const toggleActiveRejection = async (id, active) => {
    const response = await patch({ endpoint, id, body: { active } })
    const listIndex = instance.list.findIndex(item => item._id === response._id)
    if (listIndex !== -1) {
      const newList = [...instance.list]
      newList[listIndex] = { ...newList[listIndex], active: response.active }
      dispatch(setRejectionsList({ docs: newList }))
    }
    return response
  }

  const fetchAllRejections = async () => {
    return await get({ endpoint: `${endpoint}/all` })
  }

  const addRejection = async body => {
    return await post({ endpoint, body })
  }

  const fetchRejectionDetail = async id => {
    const response = await get({ endpoint: `${endpoint}/${id}` })
    dispatch(setRejectionDetail(response))
  }

  const unsetRejectionDetail = () => {
    dispatch(setRejectionDetail(null))
  }

  return {
    methods: {
      fetchList,
      handleChangePage,
      handleChangePageSize,
      handleChangeSearch,
      toggleActiveRejection,
      fetchAllRejections,
      updateRejection,
      addRejection,
      fetchRejectionDetail,
      setRejectionDetail: data => dispatch(setRejectionDetail(data)),
      unsetRejectionDetail
    },
    data: instance
  }
}

export default useRejections
