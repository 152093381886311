export const RESET_PROFILES_LIST = "RESET_PROFILES_LIST";
export const resetProfilesList = payload => ({
  type: RESET_PROFILES_LIST,
  payload,
});

export const SET_PROFILES_LIST = "SET_PROFILES_LIST";
export const setProfilesList = payload => ({
  type: SET_PROFILES_LIST,
  payload,
});

export const SET_PROFILE_DETAIL = "SET_PROFILE_DETAIL";
export const setProfileDetail = payload => ({
  type: SET_PROFILE_DETAIL,
  payload,
});
