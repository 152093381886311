import { useState, useEffect } from 'react'
import { lightFormat } from 'date-fns'
import { toast } from 'react-toastify'

import useFetch from '../../utils/hooks/useFetch'

const NATIONAL_DISTRICT = '00'

const useCalendar = () => {
  const [districts, setDistricts] = useState([])
  const [dates, setDates] = useState([])
  const { get, post, put, patch, del } = useFetch()
  const endpoint = 'calendar'

  const fetchDistricts = async () => {
    const data = await get({ endpoint: `${endpoint}/districts` })
    setDistricts(data.districts)
  }

  const fetchDates = async year => {
    const data = await get({ endpoint, params: { year } })
    setDates(data.districts || [])
  }

  const addHoliday = async (date, district) => {
    try {
      const data = await post({
        endpoint,
        body: {
          district: district,
          festive: lightFormat(date, 'yyyy-MM-dd')
        }
      })
      setDates(data.districts)
    } catch (e) {
      toast.error(
        <>
          <div className='Toastify__toast__title'>Error al añadir festivo.</div>
          <div className='Toastify__toast__subtitle'>Has superado el máximo de festivos posibles.</div>
        </>
      )
    }
  }

  const removeHoliday = async (date, district) => {
    const data = await del({
      endpoint,
      body: {
        district: district,
        festive: lightFormat(date, 'yyyy-MM-dd')
      }
    })
    setDates(data.districts)
  }

  return {
    methods: {
      fetchDistricts,
      fetchDates,
      addHoliday,
      removeHoliday
    },
    data: {
      districts,
      dates
    }
  }
}

export default useCalendar
