import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setUser, userLogout } from "./actions";
import useFetch from "../../utils/hooks/useFetch";
import useRouter from "../../utils/hooks/useRouter";
import MqttService from "../../services/MqttService";
import useUi from "../../DataProviders/ui/useUi";

const useAuth = () => {

  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const { get, post, patch } = useFetch();
  const { history } = useRouter();

  const {
    methods: { showLoader, hideLoader },
  } = useUi();

  const login = async data => {
    showLoader();
    try {
      const response = await post({ endpoint: "authentication/signin", body: data });
      localStorage.setItem("nexusBackendAccessToken", response.token);
      localStorage.setItem("nexusRefreshToken", response.refreshToken);
      await fetchCurrentUser();
      hideLoader();
      return {
        status: "OK",
      };
    } catch (error) {
      hideLoader();
      if (error.code === "changeTemporaryPasswordRequired") {
        return {
          status: "RESET",
          ...data,
        };
      }
      return {
        status: "ERROR",
        message: error.message,
      };
    }
  };

  const me = async errorCb => {
    showLoader();
    try {
      await fetchCurrentUser();
      hideLoader();
    } catch (e) {
      hideLoader();
      errorCb();
    }
  };

  const fetchCurrentUser = async () => {
    const user = await get({ endpoint: "users/me" });
    new MqttService({
      ...user.settings.mqtt,
      username: user.username,
      id: user._id,
      token: localStorage.nexusBackendAccessToken,
    });
    if (!localStorage.getItem("nexus-region")) dispatch(setUser(user));
    else dispatch(setUser({ ...user, company: { ...user.company, region: localStorage.getItem("nexus-region") } }));
  };

  const logout = async () => {
    showLoader();
    try {
      unregisterFBtoken();
      await post({ endpoint: "authentication/signOut", body: {} });
      localStorage.removeItem("nexusBackendAccessToken");
      localStorage.removeItem("nexusRefreshToken");
      dispatch(userLogout());
      history.push("/login");
      MqttService.destroy();
      hideLoader();
    } catch (error) {
      hideLoader();
    }
  };

  const recoverPassword = async data => {
    return await post({ endpoint: "authentication/retrievePassword", body: data });
  };

  const resetPassword = async data => {
    return await post({ endpoint: "authentication/confirmRetrieve", body: data });
  };

  const changePassword = async data => {
    return await post({ endpoint: "authentication/changeTemporary", body: data });
  };

  const changeCurrentPassword = async data => {
    showLoader();
    try {
      await post({ endpoint: "authentication/changePassword", body: data });
      return "success";
    } catch (error) {
      return "error";
    } finally {
      hideLoader();
    }
  };

  const resendConfirmationCode = async username => {
    return await post({ endpoint: "users/resendTemporary", body: { username } });
  };

  const getAvailableRegions = async () => {
    return await get({ endpoint: "regions" });
  };

  const fetchCenters = async () => {
    return await get({ endpoint: "common/centerList" });
  };

  const registerFBtoken = async deviceToken => {
    const registeredFBtoken = localStorage.getItem("nexusFirebaseToken") || 0;
    if (deviceToken !== registeredFBtoken) {
      await unregisterFBtoken();
      localStorage.setItem("nexusFirebaseToken", deviceToken);
      return await patch({ endpoint: "authentication/registerDevice", id: "", body: { deviceToken } });
    }
  };

  const unregisterFBtoken = async () => {
    if (localStorage.hasOwnProperty("nexusFirebaseToken")) {
      const registeredFBtoken = localStorage.getItem("nexusFirebaseToken") || 0;
      localStorage.removeItem("nexusFirebaseToken");
      return await patch({ endpoint: "authentication/unregisterDevice", id: "", body: { deviceToken: registeredFBtoken } });
    }
  };



  useEffect(() => {
    if (!auth?.user?.company) return;
    if (!localStorage.getItem("nexus-region")) {
      localStorage.setItem("nexus-region", auth.user.company.region);
    } else {
      dispatch(setUser({ ...auth.user, company: { ...auth.user.company, region: localStorage.getItem("nexus-region") } }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); //[auth.user]);

  return {
    methods: {
      login,
      me,
      logout,
      recoverPassword,
      resetPassword,
      changePassword,
      changeCurrentPassword,
      resendConfirmationCode,
      setAuthUser: user => dispatch(setUser(user)),
      getAvailableRegions,
      fetchCenters,
      registerFBtoken,
      unregisterFBtoken,
    },
    data: auth,
  };
};

export default useAuth;
