export const SET_DOCTYPE_LIST = 'SET_DOCTYPE_LIST'
export const setDocTypesList = payload => ({
  type: SET_DOCTYPE_LIST,
  payload
})

export const SET_DOCTYPE_DETAIL = 'SET_DOCTYPE_DETAIL'
export const setDocTypeDetail = payload => ({
  type: SET_DOCTYPE_DETAIL,
  payload
})
