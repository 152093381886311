export const SET_TICKETS_LIST = 'SET_TICKETS_LIST'
export const setTicketsList = payload => ({
  type: SET_TICKETS_LIST,
  payload
})

export const SET_TICKET_DETAIL = 'SET_TICKET_DETAIL'
export const setTicketDetail = payload => ({
  type: SET_TICKET_DETAIL,
  payload
})
