import { useState } from "react";

import { useTranslation } from "react-i18next";

import { useForm, FormProvider } from "react-hook-form";
import useErrors from "../../../utils/hooks/useErrors";

import useUi from "../../../DataProviders/ui/useUi";
import useProfiles from "../../../DataProviders/profiles/useProfiles";

import { Panel } from "ui-components";

import { Input } from "ui-components";

import { toastNotify } from "../../../components/ToastNotify";

import "./styles.scss";

const AddProfile = () => {
  const { t } = useTranslation();

  const [isFetching, setIsFetching] = useState(false);

  const {
    methods: { closePanel },
  } = useUi();

  const {
    methods: { fetchList, addProfile },
  } = useProfiles();

  const methods = useForm({
    mode: "onChange",
  });
  const { errors, formState, getValues } = methods;
  const { isValid } = formState;

  const { getError } = useErrors();

  const requestAddProfile = async () => {
    const data = getValues();

    setIsFetching(true);
    try {
      await addProfile(data);
      await fetchList();
      toastNotify({
        t,
        type: "success",
        title: t("profiles-opt.toastAddSuccessTitle"),
        text: t("profiles-opt.toastAddSuccessText"),
      });
      closePanel();
    } catch (error) {
      toastNotify({ t, type: "error" });
    }
    setIsFetching(false);
  };

  return (
    <Panel
      t={t}
      entity="profiles-opt"
      action="add"
      btnLabel={t("add")}
      onClick={requestAddProfile}
      disabled={!isValid || isFetching}
    >
      <FormProvider {...methods}>
        <form className="" noValidate>
          <div className="Panel__item">
            <div className="Panel__item__row">
              <Input
                name="name"
                label={t("name")}
                required={true}
                rules={{ required: t("validation.fieldRequired") }}
                errorobj={errors}
              />
            </div>
            <div className="Panel__item__row">
              <Input
                name="profileCode"
                label={t("code")}
                required={true}
                rules={{ required: t("validation.fieldRequired") }}
                errorobj={errors}
              />
            </div>
          </div>
        </form>
      </FormProvider>
    </Panel>
  );
};

export default AddProfile;
