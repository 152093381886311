import mqtt from 'mqtt'

const MqttService = (function() {
  'use strict'
  var instance //prevent modification of "instance" variable

  function Singleton({ host, token, ttl, id, username, wsport }) {
    if (instance) {
      return instance
    } else {
      const clientId =
        id +
        '-' +
        Math.random()
          .toString(16)
          .substr(2, 8)
      const url = `wss://${host}:${wsport}/mqtt`
      const client = mqtt.connect(url, {
        clientId: clientId,
        username,
        password: token,
        keepalive: ttl,
        reconnectPeriod: 2000
      });
      instance = client
      return client
    }
    //Singleton initialization code
  }

  // Instance accessor
  Singleton.getInstance = function(data) {
    return instance || new Singleton(data)
  }

  Singleton.destroy = () => {
    if (instance) instance.end()
    instance = null
  }

  return Singleton
})()

export default MqttService
