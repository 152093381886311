import React from "react";
import useUi from "../../DataProviders/ui/useUi";
import { Spinner } from "ui-components";

import "./styles.scss";

const Loader = () => {
  const {
    data: { isLoaderVisible },
  } = useUi();

  return isLoaderVisible && <Spinner />;
};

export default Loader;
