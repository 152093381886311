export const SET_USER = 'SET_USER'
export const setUser = payload => ({
  type: SET_USER,
  payload
})

export const USER_LOGOUT = 'USER_LOGOUT'
export const userLogout = () => ({
  type: USER_LOGOUT
})
