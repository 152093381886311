export const SHOW_PANEL = "SHOW_PANEL";
export const showPanel = payload => ({
  type: SHOW_PANEL,
  payload,
});

export const HIDE_PANEL = "HIDE_PANEL";
export const hidePanel = () => ({
  type: HIDE_PANEL,
});

export const SHOW_LOADER = "SHOW_LOADER";
export const showLoader = () => ({
  type: SHOW_LOADER,
});

export const HIDE_LOADER = "HIDE_LOADER";
export const hideLoader = () => ({
  type: HIDE_LOADER,
});

export const SEND_MENUWRAPPER_BACK = "SEND_MENUWRAPPER_BACK";
export const sendMenuWrapperBack = () => ({
  type: SEND_MENUWRAPPER_BACK,
});

export const SEND_MENUWRAPPER_FRONT = "SEND_MENUWRAPPER_FRONT";
export const sendMenuWrapperFront = () => ({
  type: SEND_MENUWRAPPER_FRONT,
});
