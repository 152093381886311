import { useDispatch, useSelector } from "react-redux";

import { setOperationTypeDetail, setOperationTypesList } from "./actions";
import useFetch from "../../utils/hooks/useFetch";

const useOperationTypes = () => {
  const endpoint = "operationtypes";
  const instance = useSelector(state => state.operationTypes);
  const dispatch = useDispatch();
  const { get, post, put, patch } = useFetch();

  //LIST
  const fetchList = async params => {
    const data = await get({ endpoint, params });
    dispatch(setOperationTypesList({ ...data, search: params?.search }));
  };

  const handleChangePage = page => {
    fetchList({ ...instance.params, page });
  };

  const handleChangePageSize = limit => {
    fetchList({ ...instance.params, page: 1, limit });
  };

  const handleChangeSearch = search => {
    fetchList({ ...instance.params, page: 1, search });
  };

  //DETAIL
  const updateOperationType = async (id, body) => {
    const response = await put({ endpoint, id, body });
    const listIndex = instance.list.findIndex(item => item._id === response._id);
    if (listIndex !== -1) {
      const newList = [...instance.list];
      newList[listIndex] = response;
      dispatch(setOperationTypesList({ docs: newList, metadata: { totalDocs: instance.totalDocs } }));
    }
    return response;
  };

  const toggleActiveOperationType = async (id, active) => {
    const response = await patch({ endpoint, id, body: { active } });
    const listIndex = instance.list.findIndex(item => item._id === response._id);
    if (listIndex !== -1) {
      const newList = [...instance.list];
      newList[listIndex] = { ...newList[listIndex], active: response.active };
      dispatch(setOperationTypesList({ docs: newList }));
    }
    return response;
  };

  const fetchAllOperationTypes = async () => {
    return await get({ endpoint: "operationtypes/all" });
  };

  const addOperationType = async body => {
    return await post({ endpoint, body });
  };

  const fetchOperationTypeDetail = async id => {
    const response = await get({ endpoint: `${endpoint}/${id}` });
    dispatch(setOperationTypeDetail(response));
  };

  const unsetOperationTypeDetail = () => {
    dispatch(setOperationTypeDetail(null));
  };

  return {
    methods: {
      fetchList,
      handleChangePage,
      handleChangePageSize,
      handleChangeSearch,
      toggleActiveOperationType,
      fetchAllOperationTypes,
      updateOperationType,
      addOperationType,
      fetchOperationTypeDetail,
      setOperationTypeDetail: data => dispatch(setOperationTypeDetail(data)),
      unsetOperationTypeDetail,
    },
    data: instance,
  };
};

export default useOperationTypes;
