import { useState, useEffect, useRef } from "react";

import { useTranslation } from "react-i18next";

import MqttService from "../../services/MqttService";

import useAuth from "../../DataProviders/auth/useAuth";
import useDashboard from "../../DataProviders/dashboard/useDashboard";
import useMediums from "../../DataProviders/mediums/useMediums";
import useFunction from "../../utils/hooks/useFunction";

import { BarChart } from "ui-components";
import { CircleChart } from "ui-components";
import MapView from "./MapView";
import SearchResources from "./SearchResources";
import { SwitchBox } from "ui-components";
import { formatDatetime } from "ui-components";

import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { SelectBox, Chip } from "ui-components";
import { toast } from "react-toastify";

import "overlayscrollbars/css/OverlayScrollbars.css";
import "./styles.scss";

let refreshData = false;

/* RESOURCEGROUP COMPONENT */

const ResourceGroup = ({ title, color, items, onClickResource, selectedResource }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="ResourceGroup">
      <div
        className={`ResourceGroup__title ResourceGroup__title--${color} flex end v-center`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <img src={!isOpen ? "/assets/icons/dropdown-open.svg" : "/assets/icons/dropdown-close.svg"} />
        {items.length} {title}
        <div className="ResourceGroup__title__decorator" />
      </div>
      {isOpen && (
        <div className="ResourceGroup__list">
          {items.map((item, index) => (
            <div
              className={`ResourceGroup__item flex v-center nowrap ${selectedResource === item.id ? "ResourceGroup__item--" + color : ""
                }`}
              key={index}
              onClick={() => onClickResource(selectedResource === item.id ? null : item.id)}
            >
              <div className={`ResourceGroup__item__photo ResourceGroup__item__photo--${color}`}>
                {(item.user?.photo && <img src={item.user?.photo} />) || (
                  <div className="flex v-center h-center">
                    {item.user.name[0].toUpperCase()}
                    {item.user.surname && item.user.surname[0].toUpperCase()}
                  </div>
                )}
              </div>
              <div className="ResourceGroup__item__info-wrapper">
                <div className="ResourceGroup__item__user flex">
                  <div className="ResourceGroup__item__user__name ">
                    {item.user.name} {item.user.surname}
                  </div>
                  <div className="ResourceGroup__item__user__tag">{item.user.nickname}</div>
                </div>
                <div className="ResourceGroup__item__medium flex v-center">
                  <div>{item.medium?.type?.name} </div>
                  <div className="ResourceGroup__item__medium__plate"> {item.medium?.plate}</div>
                  <div className="ResourceGroup__item__medium__code"> {item.medium?.code}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

/* DASHBOARD COMPONENT */

const Dashboard = () => {
  const { t } = useTranslation();

  const DRIVER_STATUS = [
    { label: t("all", { context: "male" }), value: "all" },
    { label: t("available"), value: "AVAILABLE" },
    { label: t("unavailable"), value: "UNAVAILABLE" },
  ];

  const clientRef = useRef();

  const [showStats, setShowStats] = useState(true);
  const [showMap, setShowMap] = useState(false);
  const [resources, setResources] = useState([]);

  const [eventFilters, setEventFilters] = useState({});
  const [availableFilters, setAvailableFilters] = useState([]);

  const [logMessages, setLogMessages] = useState([]);

  const [vehicleType, setVehicleType] = useState("all");
  const [driverStatus, setDriverStatus] = useState("all");
  const [selectedResource, setSelectedResource] = useState(null);

  const [mediumTypes, setMediumTypes] = useState([]);
  const [mediumImages, setMediumImages] = useState([]);

  const [resourceSearch, setResourceSearch] = useState(null);

  /* HOOKS */

  const {
    methods: { logout },
    data: { user },
  } = useAuth();

  const {
    methods: { fetchDashboardData, fetchDashboardFilters, fetchDashboardEvents },
    data: dashboardData,
  } = useDashboard();

  const {
    methods: { getMediumTypes },
  } = useMediums();

  const servicesDataset = {
    data: [resources.length, dashboardData?.summary?.drivers - resources.length],
    backgroundColor: ["#ec754a", "#999"],
  };

  const usersDataset = {
    data: [
      resources.filter(item => item.data?.status === "COMPLETED").length,
      resources.filter(item => item.data?.status === "LOADED").length,
      resources.filter(item => item.data?.status === "INRIS").length,
      resources.filter(item => item.data?.status === "LOCATED").length,
      resources.filter(item => item.data?.status === "STARTED").length,
      resources.filter(item => item.data?.status === "ACCEPTED").length,
      resources.filter(item => item.data?.status === "AVAILABLE").length,
      resources.filter(item => item.data?.status === "UNAVAILABLE").length,
    ],
    backgroundColor: ["#ec754a", "#dd7ed0", "#2598e4", "#069ead", "#dcac21", "#a1b24e", "#24a453", "#b3b3b3"],
  };

  const barDataset = {
    data: [
      resources.filter(item => item.data?.status === "UNAVAILABLE").length,
      resources.filter(item => item.data?.status === "AVAILABLE").length,
      resources.filter(item => item.data?.status === "ACCEPTED").length,
      resources.filter(item => item.data?.status === "STARTED").length,
      resources.filter(item => item.data?.status === "LOCATED").length,
      resources.filter(item => item.data?.status === "INRIS").length,
      resources.filter(item => item.data?.status === "LOADED").length,
      resources.filter(item => item.data?.status === "COMPLETED").length,
    ],
    backgroundColor: ["#b3b3b3", "#24a453", "#a1b24e", "#dcac21", "#069ead", "#2598e4", "#dd7ed0", "#ec754a"],
  };

  /* METHODS */

  const fetchFilters = async () => {
    /*
    const _filters = await fetchDashboardFilters();
    const _eventFilters = {};
    for (const filter of _filters) {
      _eventFilters[filter.type] = true;
    }
    */
    setEventFilters({});
    setAvailableFilters([]);
    //setEventFilters(_eventFilters);
    //setAvailableFilters(_filters.map(item => item.type));
  };

  const fetchEvents = async () => {
    const messages = await fetchDashboardEvents(eventFilters, selectedResource?.split(":")[0]);
    const _logs = messages.map(item => {
      try {
        return {
          _id: item._id,
          text: generateLogText(item),
          company: generateLogCompany(item),
          date: formatDatetime(item.createdAt, "short"),
        };
      } catch (e) {
        return null;
      }
    });
    setLogMessages(_logs.filter(item => item));
  };

  const generateLogText = data => {
    switch (data.eventType) {
      case "resourceActivity":
        return getResourceActivity(data);
      case "serviceActivity":
        return getServiceActivityLog(data);
      case "rejection":
        return getRejectionLog(data);
      case "rating":
        return "RATING LOG";
    }
  };

  const generateLogCompany = item => {
    switch (item.eventType) {
      case "serviceActivity":
      case "rejection":
        return item.customer.name;
      default:
        return item.provider?.name;
    }
  };

  const getResourceActivity = data => {
    switch (data.event.type) {
      case "unavailable":
        return data.customer ? (
          <div>
            {data.user.name} <span>ha perdido</span> la conexión
          </div>
        ) : (
          <div>
            {data.user.name} <span>ha cambiado su estado</span> a No disponible
          </div>
        );
      case "available":
        return data.customer ? (
          <div>
            {data.user.name} <span>ha recuperado</span> la conexión
          </div>
        ) : (
          <div>
            {data.user.name} <span>ha cambiado su estado</span> a Disponible
          </div>
        );
      case "bind":
        return (
          <div>
            {data.user.name} <span>ha entrado en Nexus</span> con el medio {data.medium.name}
          </div>
        );
      case "unbind":
        return (
          <div>
            {data.user.name} <span>ha salido de Nexus</span> dejando el medio {data.medium.name}
          </div>
        );
      case "release":
        return (
          <div>
            El medio {data.medium.name} <span>ha sido liberado</span> por un administrador
          </div>
        );
      default:
        return "SIN DEFINIR";
    }
  };

  const getServiceActivityLog = data => {
    switch (data.event.type) {
      case "canceled":
        return (
          <div>
            El servicio de {data.user.name} <span>ha sido cancelado</span>
          </div>
        );

      case "proposal":
        return (
          <div>
            {data.user.name} <span>ha recibido</span> una propuesta de servicio
          </div>
        );
      case "accept":
        return (
          <div>
            {data.user.name} <span>ha aceptado</span> un servicio
          </div>
        );
      case "start":
        return (
          <div>
            {data.user.name} <span>ha iniciado</span> un servicio
          </div>
        );
      case "locate":
        return (
          <div>
            {data.user.name} <span>ha localizado</span> el vehículo
          </div>
        );
      case "load":
        return (
          <div>
            {data.user.name} <span>ha cargado</span> el vehículo
          </div>
        );
      case "complete":
        return (
          <div>
            {data.user.name} <span>ha completado</span> el servicio
          </div>
        );
      case "finish":
        return (
          <div>
            {data.user.name} <span>ha finalizado</span> el servicio
          </div>
        );

      case "risStart":
        return (
          <div>
            {data.user.name} <span>ha iniciado el RIS</span>
          </div>
        );
      case "risEnd":
        return (
          <div>
            {data.user.name} <span>ha finalizado el RIS</span>
          </div>
        );

      case "addressChange":
        return (
          <div>
            {data.user?.name} <span>ha cambiado la dirección</span> de entrega
          </div>
        );

      default:
        return "SIN DEFINIR";
    }
  };

  const getRejectionLog = data => {
    switch (data.event.type) {
      case "timeout":
        return (
          <div>
            {data.user.name} <span>ha perdido</span> un servicio (exceso de tiempo)
          </div>
        );
      case "pending":
        return (
          <div>
            {data.user.name} <span>está rechazando</span> un servicio
          </div>
        );
      case "driver":
        return (
          <div>
            {data.user.name} <span>ha rechazado</span> un servicio ({data.event.description})
          </div>
        );
      default:
        return "SIN DEFINIR";
    }
  };





  const initMqtt = () => {
    const client = new MqttService({});
    const resourceTopic = `nexus/${user?.company?.region || "ES"}/resources/#`;
    client.subscribe(resourceTopic, hasError => {
      if (hasError) {
        // console.log("MQTT ERROR");
        /*
        toast.error(
          <>
            <div className="Toastify__toast__title">{t("anErrorHasOccurred")}</div>
            <div className="Toastify__toast__subtitle">No hemos podido conectarle a nuestra red.</div>
          </>
        );
        logout();
        */
      }
    });
    client.on("connect", () => {
      // console.log(`*** mqtt connect (${new Date().toLocaleTimeString()})`);
      if (refreshData) {
        // console.log(`onConnect refreshData before fetching data: ${refreshData}`);
        fetchDashboardData();
        fetchFilters();
        initMediumTypes();
        refreshData = false;
        // console.log(`onConnect refreshData after fetching data: ${refreshData}`);
      }
    });
    client.on("close", () => {
      // console.log(`*** mqtt close (${new Date().toLocaleTimeString()})`);
      // console.log(`onClose refreshData before change its value: ${refreshData}`);
      refreshData = true;
      // console.log(`onClose refreshData after change its value: ${refreshData}`);
    });
    client.on("message", handleMessage);
    clientRef.current = client;
  };




  const handleMessage = useFunction((topic, message) => {
    const msg = JSON.parse(message.toString());
    // console.log("mqtt topic");
    // console.log(topic);
    // console.log("mqtt message");
    // console.log(msg);
    return;
    const topicArr = topic.split("/");
    const mediumId = topicArr.pop();
    const userId = topicArr.pop();
    const resourceId = `${userId}:${mediumId}`;
    if (msg.type === "location" && msg.data) {
      const latLng = { lat: msg.data.lat, lng: msg.data.lng };
      handleChangeLocation(resourceId, latLng);
    }
    if (msg.type === "eventTrack" && msg.data) {
      handleEventAction(msg.data);
      if (!eventFilters[msg.data.eventType] || (selectedResource && selectedResource !== resourceId)) return;
      try {
        const _logMessage = {
          _id: msg.data._id,
          text: generateLogText(msg.data),
          company: generateLogCompany(msg.data),
          date: formatDatetime(msg.data.createdAt, "short"),
        };
        if (msg.data.eventType === "rejection" && msg.data.event.type === "driver") {
          const logMessageIndex = logMessages.findIndex(m => m._id === msg.data._id);
          if (logMessageIndex === -1) {
            setLogMessages([_logMessage, ...logMessages]);
          } else {
            const logMessagesUpdated = [...logMessages];
            logMessagesUpdated.splice(logMessageIndex, 1, _logMessage);
            setLogMessages(logMessagesUpdated);
          }
        } else {
          setLogMessages([_logMessage, ...logMessages]);
        }
      } catch (e) { }
    }
  });

  const handleChangeLocation = (id, location) => {
    if (!dashboardData.users) return;
    const resource = resources.find(item => item.id === id);
    if (resource) {
      resource.location = location;
    } else {
      const user = dashboardData.users.find(item => item._id === id.split(":")[0]);
      const medium = dashboardData.mediums.find(item => item._id === id.split(":")[1]);
      resources.push({ id, location, user, medium, data: { status: "AVAILABLE" } });
    }
    setResources([...resources]);
  };

  const handleEventAction = data => {
    switch (data.event.type) {
      case "bind":
        handleBind(data);
        break;
      case "unbind":
      case "release":
        handleUnbind(data);
        break;
      case "available":
        handleAvailable(data);
        break;
      case "unavailable":
        handleUnavailable(data);
        break;
      case "canceled":
        handleChangeServiceStatus(data, "CANCELED");
        break;
      case "accept":
        handleChangeServiceStatus(data, "ACCEPTED");
        break;
      case "start":
        handleChangeServiceStatus(data, "STARTED");
        break;
      case "locate":
        handleChangeServiceStatus(data, "LOCATED");
        break;
      case "load":
        handleChangeServiceStatus(data, "LOADED");
        break;
      case "complete":
        handleChangeServiceStatus(data, "COMPLETED");
        break;
      case "risStart":
        handleChangeServiceStatus(data, "INRIS");
        break;
      case "finish":
        handleChangeServiceStatus(data, "FINISHED");
        break;
    }
  };

  const handleBind = data => {
    const id = `${data.user.id}:${data.medium.id}`;
    const exists = resources.find(item => item.id === id);
    if (exists) return;
    const user = dashboardData.users.find(item => item._id === data.user.id);
    const medium = dashboardData.mediums.find(item => item._id === data.medium.id);
    setResources([
      ...resources,
      {
        id,
        location: null,
        user,
        medium,
        data: { status: "AVAILABLE" },
      },
    ]);
  };

  const handleUnbind = data => {
    const id = `${data.user.id}:${data.medium.id}`;
    const idx = resources.findIndex(item => item.id === id);
    if (idx === -1) return;
    resources.splice(idx, 1);
    setResources([...resources]);
  };

  const handleAvailable = data => {
    const id = `${data.user.id}:${data.medium.id}`;
    const exists = resources.find(item => item.id === id);
    if (exists) {
      if (exists.data?.status === "UNAVAILABLE") {
        exists.data = { ...exists.data, status: "AVAILABLE" };
        setResources([...resources]);
      }
    } else {
      const user = dashboardData.users.find(item => item._id === data.user.id);
      const medium = dashboardData.mediums.find(item => item._id === data.medium.id);
      const medData = { status: "AVAILABLE" };
      setResources([
        ...resources,
        {
          id,
          location: null,
          user,
          medium,
          data: medData,
        },
      ]);
    }
  };

  const handleUnavailable = data => {
    const id = `${data.user.id}:${data.medium.id}`;
    const exists = resources.find(item => item.id === id);
    if (exists && exists?.data?.status === "AVAILABLE") {
      exists.data = { ...exists.data, status: "UNAVAILABLE" };
      setResources([...resources]);
    }
  };

  const handleChangeServiceStatus = (data, status) => {
    const id = `${data.user.id}:${data.medium.id}`;
    const exists = resources.find(item => item.id === id);
    if (status === "CANCELED" || status === "FINISHED") {
      exists.data = {
        ...exists.data,
        currentService: null,
        status: "AVAILABLE",
      };
    } else {
      if (exists) {
        exists.data = {
          ...exists.data,
          currentService: data.customer?.service?.id
            ? { ...exists.data.currentService, serviceId: data.customer?.service?.id }
            : null,
          status,
        };
      }
    }
    setResources([...resources]);
  };

  const initResources = () => {
    const _resources = dashboardData.resources.map(item => ({
      id: `${item.user?._id}:${item.medium?._id}`,
      location: item.data.lastLocation
        ? {
          lat: item.data.lastLocation.coordinates[0],
          lng: item.data.lastLocation.coordinates[1],
        }
        : null,
      ...item,
      data: { ...item.data, status: item.data.serviceStatus ? item.data.serviceStatus : item.data.status },
    }));
    setResources(_resources);
  };

  const initMediumTypes = async () => {
    const response = await getMediumTypes();
    const types = response.map(item => ({ value: item.name, label: item.name }));
    types.unshift({ label: t("all", { context: "male" }), value: "all" });
    setMediumImages(response);
    setMediumTypes(types);
  };

  const filterResourceGroup = status => {
    const resourcesByStatus = resources.filter(item => item.data?.status === status);
    if (resourceSearch) {
      return resourcesByStatus.filter(
        item =>
          item.user?.name?.toLowerCase().includes(resourceSearch) ||
          item.user?.nickname?.toLowerCase().includes(resourceSearch) ||
          item.user?.surname?.toLowerCase().includes(resourceSearch) ||
          item.medium?.code?.toLowerCase().includes(resourceSearch) ||
          item.medium?.name?.toLowerCase().includes(resourceSearch) ||
          item.medium?.plate?.toLowerCase().includes(resourceSearch)
      );
    }
    return resources.filter(item => item.data?.status === status);
  };

  /* USEEFFECTS */

  useEffect(() => {
    fetchDashboardData();
    fetchFilters();
    initMediumTypes();
    return () => {
      const resourceTopic = `nexus/${user?.company?.region || "ES"}/resources/${user?.company?.id}/#`;
      clientRef.current && clientRef.current.unsubscribe(resourceTopic);
      const locationsTopic = `nexus/${user?.company?.region || "ES"}/locations/${user?.company?.id}/#`;
      clientRef.current && clientRef.current.unsubscribe(locationsTopic);
    };
  }, []);

  useEffect(() => {
    initMqtt();
    /*
    if (dashboardData.providerId) {
      initResources();
      initMqtt();
    }
    */
  }, [dashboardData]);

  useEffect(() => {
    if (Object.keys(eventFilters).length) {
      fetchEvents();
    }
  }, [eventFilters, selectedResource]);

  useEffect(() => {
    if (showMap) {
      const locationsTopic = `nexus/${user?.company?.region || "ES"}/locations/${user?.company?.id}/#`;
      clientRef.current && clientRef.current.subscribe(locationsTopic);
    } else {
      setVehicleType("all");
      setDriverStatus("all");
      const locationsTopic = `nexus/${user?.company?.region || "ES"}/locations/${user?.company?.id}/#`;
      clientRef.current && clientRef.current.unsubscribe(locationsTopic);
    }
  }, [showMap]);

  /* RENDER BLOCK */

  return (
    <div className="Dashboard flex column">
      {/* STATS RENDER BLOCK */}
      <div className={`Dashboard__stats flex column ${!showStats ? "collapsed" : ""}`}>
        <div className="Dashboard__stats__filters flex h-center v-center">
          {showMap ? (
            <>
              <SelectBox
                label={t("filterByVehicleType")}
                options={mediumTypes}
                name="filter"
                onChange={evnt => setVehicleType(evnt.target.value)}
              />
              <SelectBox
                label={t("filterByDriverStatus")}
                options={DRIVER_STATUS}
                name="filter"
                onChange={evnt => setDriverStatus(evnt.target.value)}
              />
            </>
          ) : (
            <div className="Dashboard__stats__filters__wrapper flex v-center">
              <div className="Dashboard__stats__filters__wrapper__text">Filtrar por evento:</div>
              {availableFilters.map((item, index) => (
                <div className="Dashboard__stats__filters__wrapper__item" key={index}>
                  <Chip
                    color="236, 117, 74"
                    width="89px"
                    label={t(`${item}`)}
                    isActive={eventFilters[item]}
                    key={index}
                    onChange={value => setEventFilters({ ...eventFilters, [item]: value })}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="Dashboard__stats__charts flex between">
          <div className="Dashboard__stats__charts__drivers Dashboard__stats__charts__drivers--first flex v-center h-center">
            <CircleChart
              dataset={servicesDataset}
              label={t("drivers").toUpperCase()}
              number={resources.length}
              sublabel={t("operational").toUpperCase()}
            />
          </div>
          <div className="Dashboard__stats__charts__services flex  v-center h-center">
            <div className="Dashboard__chart Dashboard__chart  flex column v-center h-center">
              <div className="Dashboard__chart__main Dashboard__chart__main--bar">
                <BarChart
                  labels={[
                    t("unavailable"),
                    t("available"),
                    t("accepted"),
                    t("started"),
                    t("located"),
                    t("inRis"),
                    t("loaded"),
                    t("completed"),
                  ]}
                  dataset={barDataset}
                />
              </div>
              <div className="Dashboard__chart__description">
                <span className="bold">Recursos </span>por estado
              </div>
            </div>
          </div>
          <div className="Dashboard__stats__charts__drivers flex v-center h-center">
            <CircleChart
              dataset={usersDataset}
              label={t("resources").toUpperCase()}
              sublabel={t("available_plural").toUpperCase()}
              number={resources.filter(item => item.data?.status === "AVAILABLE").length}
            />
          </div>
        </div>
      </div>

      {/* MAPVIEW, ACTIVITY & RESOURCES RENDER BLOCK*/}
      <div className={`Dashboard__main flex ${!showStats ? "expanded" : ""}`}>
        <div className="Dashboard__activity flex column">
          {/* HEADER */}
          <div className="Dashboard__activity__header flex between v-center">
            <div className="Dashboard__activity__header__title">{showMap ? "MAPA" : "ACTIVIDAD"}</div>
            <div className="Dashboard__activity__header__actions flex end">
              <SwitchBox label={t("fullScreenAbbrev").toUpperCase()} value={!showStats} onChange={v => setShowStats(!v)} />
              <SwitchBox label={t("mapView").toUpperCase()} value={showMap} onChange={v => setShowMap(v)} />
            </div>
          </div>

          {/* MAPVIEW OR ACTIVITY */}
          {showMap ? (
            <MapView
              resources={resources}
              selectedResource={selectedResource}
              driverFilter={driverStatus}
              vehicleFilter={vehicleType}
              mediumImages={mediumImages}
            />
          ) : (
            <div className="Dashboard__activity__list">
              <OverlayScrollbarsComponent
                options={{ scrollbars: { autoHide: 'scroll' } }}
                style={{ height: "100%" }}
              >
                {logMessages.map((item, index) => (
                  <div className="LogMessage flex between v-center" key={index}>
                    <div className="LogMessage__text">{item.text}</div>
                    <div className="LogMessage__company">{item.company}</div>
                    <div className="LogMessage__date flex end">{item.date}</div>
                  </div>
                ))}
              </OverlayScrollbarsComponent>
            </div>
          )}
        </div>

        {/* RESOURCES */}
        <div className="Dashboard__resources flex column">
          <div className="Dashboard__resources__header flex between">
            <div className="Dashboard__resources__header__title">{t("resources").toUpperCase()}</div>
            <SearchResources onChange={v => setResourceSearch(v)} placeholder={t("searchAResource")} clearable />
          </div>
          <div className="Dashboard__resources__list">
            <OverlayScrollbarsComponent
              options={{ scrollbars: { autoHide: 'scroll' } }}
              style={{ height: "100%" }}
            >
              <ResourceGroup
                items={filterResourceGroup("COMPLETED")}
                color="orange"
                title={t("completed")}
                onClickResource={setSelectedResource}
                selectedResource={selectedResource}
              />
              <ResourceGroup
                items={filterResourceGroup("LOADED")}
                color="purple"
                title={t("loaded")}
                onClickResource={setSelectedResource}
                selectedResource={selectedResource}
              />
              <ResourceGroup
                items={filterResourceGroup("INRIS")}
                color="blue"
                title={t("inRis")}
                onClickResource={setSelectedResource}
                selectedResource={selectedResource}
              />
              <ResourceGroup
                items={filterResourceGroup("LOCATED")}
                color="bluelight"
                title={t("located")}
                onClickResource={setSelectedResource}
                selectedResource={selectedResource}
              />
              <ResourceGroup
                items={filterResourceGroup("STARTED")}
                color="yellow"
                title={t("started")}
                onClickResource={setSelectedResource}
                selectedResource={selectedResource}
              />
              <ResourceGroup
                items={filterResourceGroup("ACCEPTED")}
                color="greenlight"
                title={t("accepted")}
                onClickResource={setSelectedResource}
                selectedResource={selectedResource}
              />
              <ResourceGroup
                items={filterResourceGroup("AVAILABLE")}
                color="green"
                title={t("available")}
                onClickResource={setSelectedResource}
                selectedResource={selectedResource}
              />
              <ResourceGroup
                items={filterResourceGroup("UNAVAILABLE")}
                color="gray"
                title={t("unavailable")}
                onClickResource={setSelectedResource}
                selectedResource={selectedResource}
              />
            </OverlayScrollbarsComponent>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
