const errors = {
  default: {
    title: "anErrorHasOccurred",
    text: "pleaseTryAgainLater",
  },
  ExpiredCodeException: {
    title: "expiredCode",
    text: "reAskForResetEmail",
  },
  UserNotFoundException: {
    title: "wrongUser",
    text: "enterTheDataAgain",
  },
  LimitExceededException: {
    title: "youHaveExceededTheNumberOfAtempsts",
    text: "pleaseTryAgainLater",
  },
  InvalidData: {
    text: "invalidText",
  },
  AlredadyExists: {
    text: "itAlreadyExists",
  },
};

export default errors;
