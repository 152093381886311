import { useState, useEffect, useRef } from "react";

import useDashboard from "../../../DataProviders/dashboard/useDashboard";

import { computeHeading } from "spherical-geometry-js";
import MarkerClusterer from "@googlemaps/markerclustererplus";
import MarkerWithLabel from "@googlemaps/markerwithlabel";

import "./styles.scss";
import mapStyles from "./mapStyles";

const google = (window.google = window.google ? window.google : {});

const MapView = ({ resources, selectedResource, driverFilter, vehicleFilter, mediumImages }) => {
  const clusterRef = useRef(null);
  const mapRef = useRef();
  const mapElementRef = useRef();
  const infoRef = useRef();
  const infoElementRef = useRef();
  const initialLoadRef = useRef(true);
  const resourcesRef = useRef([]);

  const [serviceDetail, setServiceDetail] = useState(null);
  const [isInfoWindowOpen, setIsInfoWindowOpen] = useState(false);
  const baseUrl = process.env.REACT_APP_API;
  const ICON_SIZE = 64;

  const {
    methods: { fetchDashboardService },
  } = useDashboard();

  const resourceUrl = resource => {
    const mediumId = mediumImages.find(item => item.name === resource.medium.type.name)._id;
    const url = `${baseUrl}/public/svgMap/${mediumId}/${resource.id}.svg`;
    return url;
  };

  const rotateMarkerImage = (resource, rotationAngle) => {
    let toggle = document.querySelector('img[src="' + resourceUrl(resource) + '"]');
    toggle.style.transform = "rotate(" + rotationAngle + "deg)";
  };

  const createMarker = resource => {
    const icon = {
      url: resourceUrl(resource),
      anchor: new google.maps.Point(ICON_SIZE / 2, ICON_SIZE / 2),
      scaledSize: new google.maps.Size(ICON_SIZE, ICON_SIZE), // scaled size
      origin: new google.maps.Point(0, 0), // origin
    };

    const marker = new MarkerWithLabel({
      position: resource.location,
      clickable: true,
      map: mapRef.current,
      labelContent: resource.user?.nickname + " " + resource.medium.code, // can also be HTMLElement
      labelAnchor: new google.maps.Point(0, -50),
      labelClass: `MapView__markerlabel MapView__markerlabel--${resource?.data?.status?.toLowerCase()}`, // the CSS class for the label
      labelZIndexOffset: -1,
      icon,
    });

    marker.addListener("click", async () => {
      let service = null;
      if (resource.data?.currentService) {
        service = await fetchDashboardService(resource.data.currentService.serviceId);
      }
      setServiceDetail({ resource: { ...resource }, service: { ...service } });
      setIsInfoWindowOpen(true);
      google.maps.event.addListener(infoRef.current, "closeclick", function () {
        setIsInfoWindowOpen(false);
        setServiceDetail(null);
      });
      infoRef.current.setOptions({position:marker.getPosition(),
        pixelOffset:new google.maps.Size(0,-55),
        map:mapRef.current,
      });
      infoRef.current.open(mapRef.current, marker);
    });
    return marker;
  };

  const clearExtraMarkers = () => {
    const deleteIds = [];

    for (const item of resourcesRef.current) {
      const instance = resources.find(resource => resource.id === item.id);
      if (
        !instance ||
        instance.data.status === "UNAVAILABLE" ||
        (driverFilter !== "all" && driverFilter !== instance.data.status) ||
        (vehicleFilter !== "all" && vehicleFilter !== instance.medium.type.name)
      ) {
        deleteIds.push(item.id);
        item.marker.setMap(null);
      } else {
      }
    }

    for (const id of deleteIds) {
      const idx = resourcesRef.current.findIndex(item => item.id === id);
      if (idx !== -1) {
        resourcesRef.current.splice(idx, 1);
      }
    }
  };

  const createClusters = () => {
    if (clusterRef.current) {
      clusterRef.current.clearMarkers();
    }
    clusterRef.current = new MarkerClusterer(
      mapRef.current,
      resourcesRef.current.map(item => item.marker),
      {
        imagePath: "assets/images/cluster",
        imageSizes: [32, 32],
        maxZoom: 17,
        clusterClass: "MapView__cluster",
      }
    );
  };

  const renderResources = async () => {
    clearExtraMarkers();

    for (const resource of resources) {
      if (
        !resource.location ||
        resource.data.status === "UNAVAILABLE" ||
        (driverFilter !== "all" &&
          ((driverFilter === "AVAILABLE" && resource.data.status !== "AVAILABLE") ||
            (driverFilter === "UNAVAILABLE" && resource.data.status === "AVAILABLE"))) ||
        (vehicleFilter !== "all" && vehicleFilter !== resource.medium.type.name)
      ) {
        if (serviceDetail !== null && resource.id === serviceDetail.resource.id) {
          setIsInfoWindowOpen(false);
          setServiceDetail(null);
        }
        continue;
      }
      const currentResource = resourcesRef.current.find(item => item.id === resource.id);
      if (!currentResource) {
        let marker = createMarker(resource);
        resourcesRef.current.push({
          ...resource,
          marker,
        });
      } else if (currentResource && JSON.stringify(currentResource.location) !== JSON.stringify(resource.location)) {
        const prevPosn = currentResource.marker.getPosition();
        const rotation = computeHeading(prevPosn, resource.location);
        if (rotation !== 0) {
          rotateMarkerImage(resource, rotation);
        }
        currentResource.marker.setPosition(resource.location);
      }

      if (currentResource) {
        currentResource.marker.set(
          "labelClass",
          `MapView__markerlabel MapView__markerlabel--${resource?.data?.status?.toLowerCase()}`
        );

        if (serviceDetail?.resource?.id === resource.id && isInfoWindowOpen) {
          if (resource.data?.currentService) {
            if (resource.data.status !== serviceDetail.resource.data.status) {
              let service = await fetchDashboardService(resource.data.currentService.serviceId);
              setServiceDetail({ resource: { ...resource }, service: { ...service } });
            }
          } else {
            setServiceDetail({ resource: { ...resource }, service: null });
          }
        }
      }
    }
    if (selectedResource) {
      const resource = resourcesRef.current.find(item => item.id === selectedResource);
      if (resource) {
        mapRef.current.panTo(resource.marker.getPosition());
        mapRef.current.setZoom(19);
      }
    }
    if (initialLoadRef.current) {
      if (selectedResource) {
        const resource = resourcesRef.current.find(item => item.id === selectedResource);
        if (resource) {
          mapRef.current.panTo(resource.marker.getPosition());
          mapRef.current.setZoom(19);
        } else {
          goToBounds();
        }
      } else {
        goToBounds();
      }
    }
    // createClusters();
  };

  const goToBounds = () => {
    initialLoadRef.current = false;
    var bounds = new google.maps.LatLngBounds();
    for (const item of resourcesRef.current) {
      bounds.extend(item.location);
    }
    if (!bounds.isEmpty()) {
      if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
        const extendPoint1 = new google.maps.LatLng(
          bounds.getNorthEast().lat() + 0.01,
          bounds.getNorthEast().lng() + 0.01
        );
        const extendPoint2 = new google.maps.LatLng(
          bounds.getNorthEast().lat() - 0.01,
          bounds.getNorthEast().lng() - 0.01
        );
        bounds.extend(extendPoint1);
        bounds.extend(extendPoint2);
      }
      mapRef.current.fitBounds(bounds);
    }
  };

  useEffect(() => {
    mapRef.current = new google.maps.Map(mapElementRef.current, {
      center: { lat: 40.4482071, lng: -3.7980438 },
      zoom: 8,
      streetViewControl: false,
      fullscreenControl: false,
      options: { styles: mapStyles },
    });

    // const trafficLayer = new google.maps.TrafficLayer();
    // trafficLayer.setMap(mapRef.current);

    infoRef.current = new google.maps.InfoWindow({
      content: infoElementRef.current,
      pixelOffset: new google.maps.Size(0, -40),
    });
    resourcesRef.current = [];
  }, []);

  useEffect(() => {
    if (selectedResource) {
      const resource = resourcesRef.current.find(item => item.id === selectedResource);
      if (resource) {
        mapRef.current.panTo(resource.marker.getPosition());
        mapRef.current.setZoom(19);
      } else {
        goToBounds();
      }
    } else if (!initialLoadRef.current) {
      goToBounds();
    }
  }, [selectedResource]);

  useEffect(() => {
    setTimeout(() => {
      renderResources();
    }, 0);
  }, [resources, driverFilter, vehicleFilter]);

  return (
    <div className="MapView">
      <div ref={infoElementRef} className="MapWindow">
        {serviceDetail && (
          <>
            <div className="flex">
              <div className="MapWindow__avatar">
                {(serviceDetail.resource.user?.photo && <img src={serviceDetail.resource.user?.photo} />) || (
                  <div className="flex v-center h-center">
                    {serviceDetail.resource.user.name[0].toUpperCase()}
                    {serviceDetail.resource.user.surname && serviceDetail.resource.user.surname[0].toUpperCase()}
                  </div>
                )}
              </div>
              <div>
                <div className="MapWindow__header flex">
                  <div className="MapWindow__header__name">{serviceDetail.resource.user?.name}</div>
                  <div className="MapWindow__header__tag flex h-center v-center">
                    {serviceDetail.resource.user?.nickname}
                  </div>
                </div>
                <div className="MapWindow__subheader flex">
                  <div className="MapWindow__subheader__plate">{serviceDetail.resource.medium.plate}</div>
                  <div className="MapWindow__subheader__tag flex h-center v-center">
                    {serviceDetail.resource.medium.code}
                  </div>
                </div>
                <div className="MapWindow__subheader flex">
                  <div className="MapWindow__subheader__type">{serviceDetail.resource.medium.type.name}</div>
                </div>
              </div>
            </div>

            {serviceDetail.service?.customer && (
              <div className="MapWindow__body">
                <div className="MapWindow__row flex v-center">
                  <img src="/assets/icons/folder.svg" />
                  <div>{serviceDetail.service?.customer.name}</div>
                </div>
                <div className="MapWindow__row flex v-center">
                  <img src="/assets/icons/car.svg" />
                  <div>
                    {serviceDetail.service?.vehicle?.brand} {serviceDetail.service?.vehicle?.model}{" "}
                    <span className="uppercase">{serviceDetail.service?.vehicle?.plate}</span>
                  </div>
                </div>
                {serviceDetail.service?.fault && (
                  <div className="MapWindow__row flex v-center">
                    <img src="/assets/icons/fault.svg" />
                    <div>{serviceDetail.service?.fault}</div>
                  </div>
                )}
                <div className="MapWindow__address">
                  <div className="MapWindow__row flex">
                    <img src="/assets/icons/icon-pickup.svg" />
                    <div>{serviceDetail.service?.pickup?.fullAddress}</div>
                  </div>
                  <div className="MapWindow__row flex ">
                    <img src="/assets/icons/icon-delivery.svg" />
                    <div>{serviceDetail.service?.delivery?.fullAddress || "Sin definir"}</div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>

      <div ref={mapElementRef} className="MapView__map" />
    </div>
  );
};

export default MapView;
