import React from "react";

import useFetch from "../../utils/hooks/useFetch";

const useBI = () => {
  const { get, post, del } = useFetch();
  const endpoint = "bi";

  const fetchDefaultCharts = async () => await get({ endpoint: `${endpoint}/auth` });

  const fetchUserProfile = async () => await get({ endpoint: `${endpoint}/userProfile` });
  const saveUserProfile = async body => await post({ endpoint: `${endpoint}/userProfile`, body });
  const removeUserProfile = async () => await del({ endpoint: `${endpoint}/userProfile` });

  return {
    fetchDefaultCharts,
    fetchUserProfile,
    saveUserProfile,
    removeUserProfile,
  };
};

export default useBI;
