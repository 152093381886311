export const SET_USER_LIST = 'SET_USER_LIST'
export const setUserList = payload => ({
  type: SET_USER_LIST,
  payload
})

export const SET_USER_DETAIL = 'SET_USER_DETAIL'
export const setUserDetail = payload => ({
  type: SET_USER_DETAIL,
  payload
})
