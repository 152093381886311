import { useDispatch, useSelector } from 'react-redux'

import { setLabeledDetail, setLabeledList } from './actions'
import useFetch from '../../utils/hooks/useFetch'

const useLabeled = () => {
  const labeled = useSelector(state => state.labeled)
  const endpoint = 'mediumBrands'
  const dispatch = useDispatch()
  const { get, post, put, patch } = useFetch()

  //LIST
  const fetchList = async params => {
    const data = await get({ endpoint, params })
    dispatch(setLabeledList({ ...data, search: params?.search }))
  }

  const handleChangePage = page => {
    fetchList({ ...labeled.params, page })
  }

  const handleChangePageSize = limit => {
    fetchList({ ...labeled.params, page: 1, limit })
  }

  const handleChangeSearch = search => {
    fetchList({ ...labeled.params, page: 1, search })
  }

  //LABELED
  const updateLabeled = async (id, body) => {
    const response = await put({ endpoint, id, body })
    const listIndex = labeled.list.findIndex(item => item._id === response._id)
    if (listIndex !== -1) {
      const newList = [...labeled.list]
      newList[listIndex] = response
      dispatch(setLabeledList({ docs: newList, metadata: { totalDocs: labeled.totalDocs } }))
    }
    return response
  }

  const toggleActiveLabeled = async (id, active) => {
    const response = await patch({ endpoint, id, body: { active } })
    const listIndex = labeled.list.findIndex(item => item._id === response._id)
    if (listIndex !== -1) {
      const newList = [...labeled.list]
      newList[listIndex] = { ...newList[listIndex], active: response.active }
      dispatch(setLabeledList({ docs: newList }))
    }
    return response
  }

  const fetchAllLabeled = async () => {
    return await get({ endpoint: `${endpoint}/all` })
  }

  const addLabeled = async body => {
    return await post({ endpoint, body })
  }

  const fetchLabeledDetail = async id => {
    const response = await get({ endpoint: `${endpoint}/${id}` })
    dispatch(setLabeledDetail(response))
  }

  const unsetLabeledDetail = () => {
    dispatch(setLabeledDetail(null))
  }

  return {
    methods: {
      fetchList,
      handleChangePage,
      handleChangePageSize,
      handleChangeSearch,
      toggleActiveLabeled,
      fetchAllLabeled,
      updateLabeled,
      addLabeled,
      fetchLabeledDetail,
      setLabeledDetail: data => dispatch(setLabeledDetail(data)),
      unsetLabeledDetail
    },
    data: labeled
  }
}

export default useLabeled
