import { useState, useEffect, useRef } from "react";

import { useTranslation } from "react-i18next";

import useTickets from "../../DataProviders/tickets/useTickets";
import useUi from "../../DataProviders/ui/useUi";

import useClickOut from "../../utils/hooks/useClickOut";
import usePrevious from "../../utils/hooks/usePrevious";

import { Button, Chip, List, SearchInput } from "ui-components";

import AddTicket from "./AddTicket";
import Detail from "./Detail";

import { lightFormat } from "date-fns";

import { getTicketStatusAvatar } from "ui-components";

import "./styles.scss";

const Tickets = () => {
  const { t } = useTranslation();

  const TICKET_STATUS_OPTIONS = [
    {
      label: t("new"),
      value: "new",
      color: "236, 117, 74",
    },
    {
      label: t("open"),
      value: "open",
      color: "161, 178, 78",
    },
    {
      label: t("closed"),
      value: "closed",
      color: "237, 90, 95",
    },
  ];

  const getColumns = ({ t }) => {
    return [
      {
        id: "status",
        label: t("sta"),
        width: 26,
        maxWidth: 26,
        orderId: "status",
        renderCell: value => {
          return (
            <div className="avatar">
              <div className={`avatar__initial flex h-center v-center ${value}`}>{getTicketStatusAvatar(value)}</div>
              <div className="avatar__tip">{t(value)}</div>
            </div>
          );
        },
      },
      {
        id: "ticketNumber",
        label: t("id"),
        width: 100,
        maxWidth: 100,
        orderId: "ticketNumber",
      },
      {
        id: "lastActivity",
        label: t("lastAct"),
        width: 99,
        maxWidth: 99,
        orderId: "lastActivity",
        renderCell: value => lightFormat(new Date(value), "dd/MM/yy HH:mm"),
      },
      {
        id: "title",
        label: t("subject"),
        orderId: "title",
      },
      {
        id: "destination",
        label: t("for"),
        orderId: "request.name",
        renderCell: value => value.name,
      },
      {
        id: "destination",
        className: "hideable",
        label: t("supportCenter"),
        orderId: "destination.teamName",
        renderCell: value => value.teamName,
      },
      {
        id: "filecase",
        label: t("nFilecase"),
        width: 116,
        maxWidth: 116,
        orderId: "filecase",
        renderCell: value => value || "-",
      },
    ];
  };

  const statusRef = useRef();
  const firstRender = useRef(true);

  const [searchIsClearable, setSearchIsClearable] = useState(false);

  const [showStatus, setShowStatus] = useState(false);
  const [showTicketDetail, setShowTicketDetail] = useState(false);

  const [orderBy, setOrderBy] = useState({ status: "asc" });
  const prevOrderBy = usePrevious(orderBy);

  const [statusFilter, setStatusFilter] = useState({
    new: false,
    closed: false,
    open: false,
  });
  const [statusFilterCount, setStatusFilterCount] = useState(0);

  const {
    data: {
      list,
      totalDocs,
      params: { page, limit },
    },
    methods: {
      fetchTicketDetail,
      handleChangeFilters,
      handleChangeSort,
      handleChangeSearch,
      handleChangePage,
      handleChangePageSize,
    },
  } = useTickets();

  const {
    methods: { showPanel, closePanel },
  } = useUi();

  useClickOut(statusRef, () => setShowStatus(false));

  const initialFetch = async () => {
    const orderArr = Object.keys(orderBy).map(key => (orderBy[key] === "asc" ? `${key}` : `-${key}`));
    handleChangeSort(orderArr.join(","));
  };

  const handleAddClick = () => {
    showPanel(() => <AddTicket fetchList={initialFetch} />);
  };

  const handleDoubleClick = async row => {
    await fetchTicketDetail(row._id);
    setShowTicketDetail(true);
  };

  const handleSearch = search => {
    setSearchIsClearable(!!search);
    handleChangeSearch(search);
  };

  const handleSortColumn = column => {
    const prevColumn = Object.keys(prevOrderBy)[0];
    if (prevColumn === column) setOrderBy({ [column]: prevOrderBy[prevColumn] === "asc" ? "desc" : "asc" });
    else setOrderBy({ [column]: "asc" });
  };

  useEffect(() => {
    if (firstRender.current) return;
    const filter = {};
    const status = Object.keys(statusFilter).filter(item => statusFilter[item]);
    setStatusFilterCount(status.length);
    if (status.length !== TICKET_STATUS_OPTIONS.length) {
      filter.status = status;
    }
    handleChangeFilters(filter);
  }, [statusFilter]);

  useEffect(() => {
    const orderArr = Object.keys(orderBy).map(key => (orderBy[key] === "asc" ? `${key}` : `-${key}`));
    handleChangeSort(orderArr.join(","));
    firstRender.current = false;
  }, [orderBy]);

  return (
    <>
      <List
        t={t}
        id="Tickets"
        hide={!list || showTicketDetail}
        totalDocs={totalDocs}
        title={t("ticketList.ticketWithCount", { count: totalDocs || 0 })}
        headerFilters={
          <>
            <Button variant="secondary" onClick={() => setShowStatus(!showStatus)}>
              <img src="/assets/icons/filter.svg" />
              {t("statuses")}
              {statusFilterCount > 0 && ` (${statusFilterCount})`}
            </Button>
            {showStatus && (
              <div className="PageHeader__filters__dropdown" ref={statusRef}>
                {TICKET_STATUS_OPTIONS.map((item, index) => (
                  <Chip
                    color={item.color}
                    width="69px"
                    hasInactiveColor={true}
                    label={item.label}
                    isActive={statusFilter[item.value]}
                    key={index}
                    onChange={value => setStatusFilter({ ...statusFilter, [item.value]: value })}
                  />
                ))}
              </div>
            )}
          </>
        }
        headerActions={
          <>
            <div className="PageHeader__actions__search">
              <SearchInput
                onChange={handleSearch}
                placeholder={t("searchByIdForOrFilecaseNumber")}
                tip={t("wholeWordSearch")}
                clearable={searchIsClearable}
              />
            </div>
            <div className="PageHeader__actions__lastButton flex end v-center">
              <Button onClick={handleAddClick} prefixIcon="add-white">
                {t("new")}
                <span className="newTicket"> {t("ticket").toLowerCase()}</span>
              </Button>
            </div>
          </>
        }
        list={[...list]}
        columns={getColumns({ t })}
        pageData={{ page, limit }}
        pageActions={{
          onChangePage: handleChangePage,
          onChangePageSize: handleChangePageSize,
          onDoubleClick: handleDoubleClick,
        }}
        params={{ orderBy, onSort: handleSortColumn }}
      />
      {showTicketDetail && <Detail onClose={() => setShowTicketDetail(false)} />}
    </>
  );
};

export default Tickets;
