import { useRef, useState, useEffect } from "react";

import { useTranslation } from "react-i18next";

import { useForm, FormProvider } from "react-hook-form";
import { toast } from "react-toastify";

import { Button, Input, Select as FormSelect } from "ui-components";
import useUi from "../../../DataProviders/ui/useUi";
import useErrors from "../../../utils/hooks/useErrors";
import useLabeled from "../../../DataProviders/labeled/useLabeled";

import "./styles.scss";

const AddLabeled = () => {
  const { t } = useTranslation();

  const [isFetching, setIsFetching] = useState(false);
  const [categories, setCategories] = useState([]);
  const {
    methods: { fetchList, addLabeled },
  } = useLabeled();
  const {
    methods: { closePanel },
  } = useUi();
  const { getError } = useErrors();
  const methods = useForm({
    mode: "onChange",
  });
  const { handleSubmit, errors, formState, watch, trigger, control, setValue, getValues, setError } = methods;
  const { isValid } = formState;

  const onSubmit = async data => {
    setIsFetching(true);
    try {
      const response = await addLabeled(data);

      await fetchList();
      toast.success(
        <>
          <div className="Toastify__toast__title">Rotulación creada correctamente</div>
          <div className="Toastify__toast__subtitle">La rotulación ha sido creada y añadida a la lista.</div>
        </>
      );
      closePanel();
    } catch (e) {
      toast.error(
        <>
          <div className="Toastify__toast__title">Error al crear rotulación</div>
          <div className="Toastify__toast__subtitle">Ha ocurrido un error en la creación de la rotulación.</div>
        </>
      );
    }
    setIsFetching(false);
  };

  return (
    <div className="AddLabeled">
      <div className="AddLabeled__header">
        <div className="AddLabeled__header__title">Añadir rotulación</div>
        <div className="AddLabeled__header__actions">
          <div className="AddLabeled__header__description">
            Completa los datos de cada rotulación para añadirlos. <br />
            Los campos con (*) son obligatorios.
          </div>
          <div className="AddLabeled__header__submit">
            <Button onClick={handleSubmit(onSubmit)} disabled={!isValid || isFetching}>
              {t("add")}
            </Button>
          </div>
        </div>
      </div>
      <div className="AddLabeled__body">
        <FormProvider {...methods}>
          <form className="" noValidate>
            <div className="AddLabeled__item">
              <div className="AddLabeled__item__row">
                <Input label={t("name")} name="name" required={true} rules={{ required: true }} errorobj={errors} />
              </div>
              <div className="AddLabeled__item__row">
                <Input
                  label={t("description")}
                  name="description"
                  required={true}
                  rules={{
                    required: true,
                  }}
                  errorobj={errors}
                />
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

export default AddLabeled;
