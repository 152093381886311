import { useState } from "react";

import { useTranslation } from "react-i18next";

import { useForm, FormProvider } from "react-hook-form";
import { toast } from "react-toastify";

import { Button, Input, Select as FormSelect, SwitchChip } from "ui-components";
import useUi from "../../../DataProviders/ui/useUi";

import useRejections from "../../../DataProviders/rejections/useRejections";

import "./styles.scss";

const AddRejection = () => {
  const { t } = useTranslation();

  const [isFetching, setIsFetching] = useState(false);

  const {
    methods: { fetchList, addRejection },
  } = useRejections();
  const {
    methods: { closePanel },
  } = useUi();

  const methods = useForm({
    mode: "onChange",
  });
  const { handleSubmit, errors, formState } = methods;
  const { isValid } = formState;

  let ratings = [];
  for (let i = 0; i <= 10; i++) {
    ratings.push({ value: i, label: i });
  }

  const types = [
    {value: "driver", label: t("driver")},
    {value: "planned", label: t("scheduled")},
    {value: "queued", label: t("onQueue")},
  ];


  const onSubmit = async values => {
    const data = { ...values };
    setIsFetching(true);
    try {
      await addRejection(data);
      await fetchList();
      toast.success(
        <>
          <div className="Toastify__toast__title">Motivo de rechazo creado correctamente</div>
          <div className="Toastify__toast__subtitle">El Motivo de rechazo ha sido creado y añadido a la lista.</div>
        </>
      );
      closePanel();
    } catch (e) {
      toast.error(
        <>
          <div className="Toastify__toast__title">Error al crear motivo de rechazo</div>
          <div className="Toastify__toast__subtitle">Ha ocurrido un error en la creación del motivo de rechazo.</div>
        </>
      );
    }
    setIsFetching(false);
  };

  return (
    <div className="AddRejection">
      <div className="AddRejection__header">
        <div className="AddRejection__header__title">Añadir motivo de rechazo</div>
        <div className="AddRejection__header__actions">
          <div className="AddRejection__header__description">
            Completa los datos de cada motivo de rechazo para añadirlos. <br />
            Los campos con (*) son obligatorios.
          </div>
          <div className="AddRejection__header__submit">
            <Button onClick={handleSubmit(onSubmit)} disabled={!isValid || isFetching}>
              {t("add")}
            </Button>
          </div>
        </div>
      </div>
      <div className="AddRejection__body">
        <FormProvider {...methods}>
          <form className="" noValidate>
            <div className="AddRejection__item">
              <div className="AddRejection__item__row">
                <Input
                  label={t("description")}
                  name="description"
                  required={true}
                  rules={{ required: true }}
                  errorobj={errors}
                />
              </div>
              <div className="AddRejection__item__row">
                <FormSelect
                  label={t("rating")}
                  name="rating"
                  required={true}
                  rules={{ required: true }}
                  errorobj={errors}
                  options={ratings}
                  disableEmpty={true}
                />
                <FormSelect
                  label={t("rejectionType")}
                  name="type"
                  defaultValue={types[0].value}
                  required={true}
                  rules={{ required: true }}
                  errorobj={errors}
                  options={types}
                  disableEmpty={true}
                />
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

export default AddRejection;
