import React from "react";

import { useTranslation } from "react-i18next";

import { DatePicker } from "@material-ui/pickers";
import { useFormContext, Controller } from "react-hook-form";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";

const defaultMaterialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#ec754a",
      },
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: "#ec754a",
      },
    },
  },
});

const Input = props => {
  const { t } = useTranslation();

  const handleChange = (a, b, c, d) => {
    props.onChange(a, b, c, d);
    props.handleChange && props.handleChange(a);
  };

  const handleBlur = (a, b, c, d) => {
    props.onBlur(a, b, c, d);
    props.handleBlur && props.handleBlur(a);
  };

  const handleFocus = (a, b, c, d) => {
    props.onFocus && props.onFocus(a, b, c, d);
    props.handleFocus && props.handleFocus(a);
  };

  return (
    <ThemeProvider theme={defaultMaterialTheme}>
      <DatePicker
        label={t("validFrom")}
        {...props}
        autoOk
        format="dd/MM/yyyy"
        variant="inline"
        inputVariant="outlined"
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
      />
    </ThemeProvider>
  );
};

const DateInput = props => {
  const { control } = useFormContext();
  const { label, name, required, errorobj } = props;

  let isError = false;
  let errorMessage = "";
  if (errorobj && errorobj.hasOwnProperty(name)) {
    isError = true;
    errorMessage = errorobj[name].message || "Campo requerido";
  }

  return (
    <div className="DateInput">
      <Controller
        as={Input}
        name={name}
        control={control}
        defaultValue=""
        label={label}
        variant="outlined"
        error={isError}
        helperText={errorMessage}
        {...props}
      />
    </div>
  );
};

export default DateInput;
