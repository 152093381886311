import { useEffect } from "react";

import { useTranslation } from "react-i18next";

import useAuth from "../../DataProviders/auth/useAuth";
import useOutlays from "../../DataProviders/outlays/useOutlays";
import useUi from "../../DataProviders/ui/useUi";

import { Button, List, SwitchChip } from "ui-components";

import Add from "./Add";
import Edit from "./Edit";

const Outlays = () => {
  const { t } = useTranslation();

  const getColumns = ({ t, onToggleActive, onEdit }) => {
    return [
      {
        id: "name",
        label: t("name"),
      },
      {
        id: "chargeType",
        label: t("type"),
        renderCell: value => t(`${value}`),
      },
      {
        id: "chargeClass",
        label: t("class"),
        renderCell: value => t(`${value}`),
      },

      {
        id: "requirePictures",
        label: t("photos"),
        align: "center",
        renderCell: value => (value ? t("yes") : t("no")),
      },
      {
        id: "active",
        label: t("active"),
        align: "center",
        renderCell: (value, obj) => <SwitchChip value={value} onChange={v => onToggleActive(v, obj._id)} />,
      },
      {
        align: "right",
        renderCell: (_, obj) => {
          return (
            <Button onClick={() => onEdit(obj._id)} variant="secondary" prefixIcon="edit" disabled={!obj.active}>
              {t("edit")}
            </Button>
          );
        },
      },
    ];
  };

  const {
    data: { user },
  } = useAuth();

  const {
    data: {
      list,
      totalDocs,
      params: { page, limit },
      detail,
    },
    methods: {
      fetchList,
      fetchOutlaysDetail,
      unsetOutlaysDetail,
      toggleActiveOutlays,
      handleChangePage,
      handleChangePageSize,
    },
  } = useOutlays();

  const {
    methods: { showPanel, closePanel },
  } = useUi();

  const onToggleActive = async (active, id) => {
    toggleActiveOutlays(id, active);
  };

  const handleAddClick = () => {
    showPanel(() => <Add />);
  };

  useEffect(() => {
    if (detail) showPanel(() => <Edit />, unsetOutlaysDetail);
    else closePanel();
  }, [detail]);

  useEffect(() => {
    fetchList({ sort: "name" });
  }, [user.company?.region]);

  return (
    <List
      t={t}
      id="Outlays"
      hide={!list}
      totalDocs={totalDocs}
      title={t("outlays-opt.listWithCount", { count: totalDocs || 0 })}
      headerFilter={null}
      headerActions={
        <div className="PageHeader__actions__lastButton flex end v-center">
          <Button onClick={handleAddClick} prefixIcon="add-white">
            <span className="request">{t("add")}</span>
          </Button>
        </div>
      }
      list={[...list]}
      columns={getColumns({ t, onToggleActive, onEdit: fetchOutlaysDetail })}
      pageData={{ page, limit }}
      pageActions={{
        onChangePage: handleChangePage,
        onChangePageSize: handleChangePageSize,
        onDoubleClick: () => {},
      }}
      params={{}}
    />
  );
};

export default Outlays;
