import { ToastContainer, Slide } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import "./styles.scss";

const ToastWrapper = () => {
  return (
    <ToastContainer
      autoClose={5000}
      position="top-right"
      closeButton={true}
      closeOnClick
      hideProgressBar={true}
      newestOnTop={false}
      rtl={false}
      transition={Slide}
      pauseOnVisibilityChange
      draggable
    />
  );
};

export default ToastWrapper;
