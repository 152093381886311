import { useEffect, useRef } from "react";

import { useTranslation } from "react-i18next";

import { useForm, FormProvider } from "react-hook-form";

import { Button, Password } from "ui-components";

import "./styles.scss";

const ChangePassword = ({ onSubmit, onCancel }) => {
  const { t } = useTranslation();

  const password = useRef({});
  const methods = useForm({ mode: "onChange" });
  const { handleSubmit, errors, formState, trigger, watch } = methods;
  const { isValid } = formState;
  password.current = watch("newPassword", "");

  useEffect(() => {
    if (password.current) trigger("passwordRepeat"); // retriggers validation
  }, [password.current]);

  return (
    <div className="ChangePassword">
      <div className="ChangePassword__title">{t("changePassword").toUpperCase()}</div>
      <div className="ChangePassword__form">
        <div className="ChangePassword__form__tip">{t("Complete the fields to change your access password")}.</div>
        <FormProvider {...methods}>
          <Password
            label={t("currentPassword")}
            name="oldPassword"
            errorobj={errors}
            rules={{
              required: "Campo requerido",
            }}
          />
          <Password
            label={t("newPassword")}
            name="newPassword"
            errorobj={errors}
            rules={{
              required: "Campo requerido",
              pattern: {
                value: /^(?=\w*\d)(?=\w*[A-ZÑ])(?=\w*[a-zñ])\S{8,12}$/,
                message:
                  "La constraseña debe tener entre 8 y 12 caracteres, al menos una mayúscula, una minúscula y un dígito numérico",
              },
            }}
          />
          <Password
            label={t("repeatPassword")}
            name="passwordRepeat"
            errorobj={errors}
            rules={{
              validate: value => value === password.current || t("passwordsNotMatch"),
            }}
          />
          <div className="ChangePassword__form__actions flex end">
            <Button variant="secondary" onClick={onCancel}>
              {t("cancel")}
            </Button>
            <Button variant="secondary" onClick={handleSubmit(onSubmit)} disabled={!isValid}>
              {t("change")}
            </Button>
          </div>
        </FormProvider>
      </div>
    </div>
  );
};

export default ChangePassword;
