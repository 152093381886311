import { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";

import useAuth from "../../DataProviders/auth/useAuth";
import useUsers from "../../DataProviders/users/useUsers";
import useUi from "../../DataProviders/ui/useUi";

import { Button, List, SearchInput, SwitchChip } from "ui-components";

import AddUser from "./AddUser";

import { toast } from "react-toastify";

import "./styles.scss";

const Users = () => {
  const { t } = useTranslation();

  const getColumns = ({ t, onToggleActive, onEdit, onResend }) => {
    return [
      {
        width: 28,
        maxWidth: 28,
        renderCell: (_, obj) => {
          return (
            <div className="UserList__avatar flex h-center v-center">
              {obj.photo ? <img src={obj.photo} /> : <div className={obj.type}>{`${obj.name?.[0]}${obj.surname?.[0] || ""}`}</div>}
            </div>
          );
        },
      },
      {
        id: "name",
        label: t("nameSurname"),
        renderCell: (value, obj) => `${obj.name} ${obj.surname || ""}`,
      },
      {
        id: "username",
        label: t("user")
      },
      {
        id: "nickname",
        label: t("nickName"),
        width: 54,
        maxWidth: 54,
      },
      {
        id: "type",
        label: t("type"),
        width: 91,
        maxWidth: 91,
        renderCell: value => <span className="capitalize">{t(`${value}`)}</span>,
      },
      {
        id: "company",
        label: t("center"),
        className: "hideable",
        renderCell: value => value.name,
      },
      {
        id: "status",
        label: t("status"),
        renderCell: value => t(`${value}`).replace(t("pending"), t("pendingAbbrev")),
      },
      {
        id: "active",
        label: t("active"),
        width: 45,
        maxWidth: 45,
        align: "center",
        renderCell: (value, obj) => <SwitchChip value={value} onChange={v => onToggleActive(v, obj._id)} />,
      },
      {
        width: 71,
        maxWidth: 71,
        align: "right",
        renderCell: (_, obj) => {
          return (
            <Button className="ShortenButton" onClick={() => onEdit(obj._id)} variant="secondary" prefixIcon="edit" disabled={!obj.active}>
              <span>{t("edit")}</span>
            </Button>
          );
        },
      },
    ];
  };

  const [searchIsClearable, setSearchIsClearable] = useState(false);

  const {
    methods: { resendConfirmationCode },
  } = useAuth();

  const {
    data: {
      list,
      totalDocs,
      params: { page, limit },
    },
    methods: {
      fetchList,
      fetchUserDetail,
      toggleActiveUser,
      handleChangeSearch,
      handleChangePage,
      handleChangePageSize,
    },
  } = useUsers();

  const {
    methods: { showPanel },
  } = useUi();

  const handleSearch = search => {
    setSearchIsClearable(!!search);
    handleChangeSearch(search);
  }

  const onToggleActive = async (active, userId) => {
    try {
      await toggleActiveUser(userId, active);
    } catch (error) {
      if (error.statusCode === 403) {
        toast.error(
          <>
            <div className='Toastify__toast__title'>{t("user2.toastUserDeactivateErrorTitle")}</div>
            <div className='Toastify__toast__subtitle'>{t("user2.toastUserDeactivateErrorText")}</div>
          </>
        )
      } else {
        toast.error(
          <>
            <div className='Toastify__toast__title'>{t("user2.toastUserDeactivateErrorTitle")}</div>
            <div className='Toastify__toast__subtitle'>{t("user2.toastUserDeactivateErrorTextDefault")}</div>
          </>
        )
      }
    }
  };

  const handleAddClick = () => {
    showPanel(() => <AddUser />);
  };

  const handleResend = async username => {
    try {
      await resendConfirmationCode(username);
      toast.success(
        <>
          <div className="Toastify__toast__title">{t("user2.toastUserNotificationSuccessTitle")}</div>
          <div className="Toastify__toast__subtitle">{t("user2.toastUserNotificationSuccessText")}</div>
        </>
      );
    } catch (e) {
      toast.error(
        <>
          <div className="Toastify__toast__title">{t("user2.toastUserNotificationErrorTitle")}</div>
          <div className="Toastify__toast__subtitle">{t("user2.toastUserNotificationErrorText")}</div>
        </>
      );
    }
  };

  useEffect(() => {
    fetchList({ sort: "name,surname" });
  }, []);

  return (
    <List
      t={t}
      id="Users"
      hide={!list}
      totalDocs={totalDocs}
      title={t("users-opt.listWithCount", { count: totalDocs || 0 })}
      headerFilters={null}
      headerActions={
        <>
          <div className="PageHeader__actions__search">
            <SearchInput
              onChange={handleSearch}
              placeholder={t("searchByNameSurnameUserOrNick")}
              tip={t("wholeWordSearch")}
              clearable={searchIsClearable}
            />
          </div>
          <div className="PageHeader__actions__lastButton flex end v-center">
            <Button onClick={handleAddClick} prefixIcon="add-white">
              <span className="request">{t("add")}</span>
            </Button>
          </div>
        </>
      }
      list={[...list]}
      columns={getColumns({ t, onToggleActive, onEdit: fetchUserDetail, onResend: handleResend })}
      pageData={{ page, limit }}
      pageActions={{
        onChangePage: handleChangePage,
        onChangePageSize: handleChangePageSize,
        onDoubleClick: (() => { }),
      }}
      params={{}}
    />
  );
};

export default Users;
