import React, { useState, useEffect, useRef } from "react";

import { useTranslation } from "react-i18next";

import { useForm, FormProvider } from "react-hook-form";

import useCompanies from "../../../DataProviders/companies/useCompanies";

import { PageWrapper } from "ui-components";
import { PageHeader } from "ui-components";
import { PageBody } from "ui-components";

import { CardRowWrapper, Card, Button } from "ui-components";

import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { Input } from "ui-components";

import _ from "lodash";
import produce from "immer";

import { ModalWindow } from "ui-components";

import "./styles.scss";

let initialMapping;
let optionSelected = null;
let usedCodeArray = [];

const ProvidersMapping = ({ company, onClose }) => {
  const { t } = useTranslation();

  const {
    methods: { fetchCompanyProvidersMap, saveCompanyProvidersMap },
  } = useCompanies();

  const methods = useForm({ mode: "onChange" });
  const { register, handleSubmit, errors, formState, watch, trigger, control, setValue, getValues, setError, reset } =
    methods;

  const [isAutocompleteError, setIsAutocompleteError] = useState(false);
  const autocompleteErrorMsg = t("providerAlreadyMapped");
  const [isUsedCodeError, setIsUsedCodeError] = useState(false);
  const usedCodeErrorMsg = t("codeAlreadyAssigned");

  const [providers, setProviders] = useState([]);
  const [autocompleteOptions, setAutocompleteOptions] = useState([]);

  const [mapping, setMapping] = useState({});

  const [selectedProvider, setSelectedProvider] = useState(null);
  const customerCode = watch("customerCode", "");

  const [providerToUnmap, setProviderToUnmap] = useState(null);
  const [showModalUnmap, setShowModalUnmap] = useState(false);

  const [saveBtnDisabled, setSaveBtnDisabled] = useState(false);
  const [showModalSave, setShowModalSave] = useState(false);

  const initProviders = async () => {
    try {
      const response = await fetchCompanyProvidersMap(company.id);
      // response array: [{}, ...] {_id, code, groupName, |customerCode|}
      setProviders([...response]);
    } catch (error) {
      // console.log(error);
    }
  };

  const fillAutocompleteOptions = () => {
    setAutocompleteOptions(
      providers.map(provider => {
        return {
          label: provider.groupName,
          value: provider, // value object: {_id, code, groupName, |customerCode|}
        };
      })
    );
  };

  const initMappingAZ = () => {
    let mapAZ = {};
    const providersAlreadyMapped = providers.filter(provider => _.has(provider, "customerCode"));
    providersAlreadyMapped.map(provider => {
      usedCodeArray = [...usedCodeArray, provider.customerCode.toUpperCase()];
      const letterAZ = provider.groupName.slice(0, 1).toUpperCase();
      mapAZ = produce(mapAZ, draft => {
        if (letterAZ in draft) {
          draft[letterAZ].push({ ...provider });
        } else {
          draft[letterAZ] = [{ ...provider }];
        }
        return draft;
      });
    });
    initialMapping = mapAZ;
    setMapping(mapAZ);
  };

  const checkProviderBeforeMapping = (event, provider) => {
    if (_.isNull(provider)) {
      setIsAutocompleteError(false);
      setSelectedProvider(null);
      return;
    }
    const letterAZ = provider.groupName.slice(0, 1).toUpperCase();
    let providerAlreadyMapped;
    if (_.isEmpty(mapping) || !_.has(mapping, letterAZ)) providerAlreadyMapped = undefined;
    else {
      providerAlreadyMapped = mapping[letterAZ].find(item => item._id === provider._id);
    }
    if (!_.isUndefined(providerAlreadyMapped)) {
      setIsAutocompleteError(true);
      setSelectedProvider(providerAlreadyMapped);
      if (customerCode !== "") setValue("customerCode", providerAlreadyMapped.customerCode);
    } else {
      setIsAutocompleteError(false);
      setSelectedProvider(_.omit(provider, ["customerCode"]));
    }
  };

  const mapProvider = () => {
    optionSelected = null;
    usedCodeArray = [...usedCodeArray, customerCode.toUpperCase()];
    let mapAZ = mapping;
    const letterAZ = selectedProvider.groupName.slice(0, 1).toUpperCase();
    mapAZ = produce(mapAZ, draft => {
      if (letterAZ in draft) {
        draft[letterAZ].push({ ...selectedProvider, customerCode });
      } else {
        draft[letterAZ] = [{ ...selectedProvider, customerCode }];
      }
      return draft;
    });
    setMapping(mapAZ);
    setSelectedProvider(null);
    setValue("customerCode", "");
  };

  const unmapProvider = () => {
    usedCodeArray = usedCodeArray.filter(code => code !== providerToUnmap.customerCode.toUpperCase());
    let mapAZ = mapping;
    const letterAZ = providerToUnmap.groupName.slice(0, 1).toUpperCase();
    mapAZ = produce(mapAZ, draft => {
      draft[letterAZ] = draft[letterAZ].filter(item => item._id !== providerToUnmap._id);
      return draft;
    });
    setSelectedProvider(null);
    setValue("customerCode", "");
    setProviderToUnmap(null);
    setMapping(!_.isEmpty(mapAZ[letterAZ]) ? mapAZ : _.omit(mapAZ, [letterAZ]));
    setShowModalUnmap(false);
  };

  const saveMap = async () => {
    let mappingList = Object.keys(mapping).sort().map(letterAZ => {
      return mapping[letterAZ].map(item => ({ id: item._id, customerCode: item.customerCode }));
    });
    mappingList = [].concat.apply([], mappingList);
    try {
      const response = await saveCompanyProvidersMap(company.id, mappingList);
      initialMapping = mapping;
      setSaveBtnDisabled(true);
    } catch (error) {
      // console.log(error);
    }
  };

  useEffect(() => {
    if (!customerCode) setIsUsedCodeError(false);
    else setIsUsedCodeError(usedCodeArray.includes(customerCode.toUpperCase())); // code already assigned.
  }, [customerCode]);

  useEffect(() => {
    if (!mapping) {
      setSaveBtnDisabled(true);
      return;
    }
    setSaveBtnDisabled(_.isEqual({ ...mapping }, { ...initialMapping }));
  }, [mapping]);

  useEffect(() => {
    if (_.isEmpty(autocompleteOptions)) return;
    initMappingAZ();
  }, [autocompleteOptions]);

  useEffect(() => {
    if (_.isEmpty(providers)) return;
    fillAutocompleteOptions();
  }, [providers]);

  useEffect(() => {
    initProviders();
    return () => {
      initialMapping = null;
      optionSelected = null;
      usedCodeArray = [];
    };
  }, []);


  return (
    <PageWrapper id="ProvidersMapping">

      <PageHeader
        goBack={() => {
          if (saveBtnDisabled) onClose();
          else setShowModalSave(true);
        }}
        title={`${t("providersMap").toUpperCase()} - ${company.groupName}`}
        actions={
          <Button onClick={saveMap} disabled={saveBtnDisabled}>
            {t("save")}
          </Button>
        }
      />

      <PageBody>
        <FormProvider {...methods}>
          <form className="Form">
            <CardRowWrapper>
              <Card className="CardForm">
                <div className="flex column start">
                  <div className="CardFormTitle">{t("provider").toUpperCase()}</div>
                  <div className="CardFormContent flex row start">

                    <Autocomplete
                      id="ProvidersMappingAutocomplete"
                      options={autocompleteOptions}
                      getOptionLabel={option => option.label}
                      value={optionSelected}
                      limitTags={5}
                      required={true}
                      rules={{
                        required: true,
                      }}
                      onChange={(event, newValue) => {
                        optionSelected = newValue;
                        checkProviderBeforeMapping(event, newValue?.value || null);
                      }}
                      renderInput={params => {
                        return (
                          <TextField
                            {...params}
                            label={t("searchByName")}
                            variant="outlined"
                            required={true}
                            rules={{
                              required: true,
                            }}
                            error={isAutocompleteError}
                            helperText={isAutocompleteError ? autocompleteErrorMsg : ""}
                          />
                        )
                      }}
                      style={{ width: 300 }}
                    />

                    <Input
                      name="customerCode"
                      label={t("companyProviderCode")}
                      required={true}
                      rules={{
                        required: true,
                      }}
                      error={isUsedCodeError}
                      helperText={isUsedCodeError ? usedCodeErrorMsg : ""}
                    />

                    <Button
                      key={customerCode || 0}
                      onClick={event => {
                        event.stopPropagation();
                        event.preventDefault();
                        mapProvider();
                      }}
                      prefixIcon={`add${_.isNull(selectedProvider) ||
                          _.has(selectedProvider, "customerCode") ||
                          customerCode === "" ||
                          isUsedCodeError
                          ? ""
                          : "-white"
                        }`}
                      disabled={
                        _.isNull(selectedProvider) ||
                        _.has(selectedProvider, "customerCode") ||
                        !customerCode ||
                        isUsedCodeError === true
                      }
                    >
                      {t("add")}
                    </Button>

                  </div>
                </div>
              </Card>
            </CardRowWrapper>
          </form>
        </FormProvider>

        {!_.isEmpty(mapping) && (
          <CardRowWrapper>
            <Card>
              <div className="AZ flex column between">
                {Object.keys(mapping)
                  .sort()
                  .map(letterAZ => {
                    return (
                      <div key={letterAZ} className="AZ-group flex column between">
                        <div className="AZ-header">{letterAZ}</div>
                        <div className="AZ-content flex row start wrap">
                          {mapping[letterAZ].map(provider => {
                            return (
                              <div key={provider._id} className="AZ-provider flex row between">
                                <img
                                  src="/assets/icons/close.svg"
                                  onClick={() => {
                                    setProviderToUnmap(provider); // provider:  {_id, code, groupName, |customerCode|}
                                    setShowModalUnmap(true);
                                  }}
                                />
                                <div><span className="customerCodeData">{provider.customerCode}</span> {provider.groupName}</div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </Card>
          </CardRowWrapper>
        )}
      </PageBody>

      {showModalUnmap && (
        <ModalWindow
          title="Eliminar asignación"
          text={`¿Seguro que deseas eliminar la asignación al proveedor ${providerToUnmap.groupName}?`}
          cancelLabel={t("cancel")}
          handleCancel={() => {
            setProviderToUnmap(null);
            setShowModalUnmap(false);
          }}
          acceptLabel={t("accept")}
          handleAccept={unmapProvider}
          modalWidth={363}
        />
      )}

      {showModalSave && (
        <ModalWindow
          title={t("There are unsaved changes")}
          text="Hemos encontrado cambios que no has guardado. ¿Guardar antes de salir?"
          cancelLabel={t("exit")}
          handleCancel={onClose}
          acceptLabel={t("save")}
          handleAccept={async () => {
            await saveMap();
            onClose();
            setShowModalSave(false);
          }}
          modalWidth={363}
        />
      )}

    </PageWrapper>
  );
};

export default ProvidersMapping;
