export const SET_AUXSERVICETYPES_LIST = 'SET_AUXSERVICETYPES_LIST'
export const setAuxServiceTypesList = payload => ({
  type: SET_AUXSERVICETYPES_LIST,
  payload
});

export const SET_AUXSERVICETYPE_DETAIL = 'SET_AUXSERVICETYPE_DETAIL'
export const setAuxServiceTypeDetail = payload => ({
  type: SET_AUXSERVICETYPE_DETAIL,
  payload
});
