import { useRef, useState, useEffect } from "react";

import { useTranslation } from "react-i18next";

import { useForm, FormProvider, useFieldArray } from "react-hook-form";
import { toast } from "react-toastify";

import { Button, Input, Select as FormSelect, SwitchChip } from "ui-components";
import useUi from "../../../DataProviders/ui/useUi";
import useErrors from "../../../utils/hooks/useErrors";
import useOutlays from "../../../DataProviders/outlays/useOutlays";
import DateInput from "../../../components/DateInput";

import "./styles.scss";

const AddOutlays = () => {
  const { t } = useTranslation();

  const [isFetching, setIsFetching] = useState(false);
  const [isPhotoRequired, setIsPhotoRequired] = useState(false);
  const {
    methods: { fetchList, addOutlays },
  } = useOutlays();
  const {
    methods: { closePanel },
  } = useUi();
  const methods = useForm({
    mode: "onChange",
  });
  const { handleSubmit, errors, formState, watch, trigger, control, setValue, getValues, setError } = methods;
  const { isValid } = formState;

  const onSubmit = async values => {
    const data = { ...values, requirePictures: isPhotoRequired };
    setIsFetching(true);
    try {
      await addOutlays(data);
      await fetchList();
      toast.success(
        <>
          <div className="Toastify__toast__title">Gasto adicional creado correctamente</div>
          <div className="Toastify__toast__subtitle">El gasto adicional ha sido creado y añadido a la lista.</div>
        </>
      );
      closePanel();
    } catch (e) {
      toast.error(
        <>
          <div className="Toastify__toast__title">Error al crear gasto adicional</div>
          <div className="Toastify__toast__subtitle">Ha ocurrido un error en la creación del gasto adicional.</div>
        </>
      );
    }
    setIsFetching(false);
  };

  return (
    <div className="AddOutlays">
      <div className="AddOutlays__header">
        <div className="AddOutlays__header__title">Añadir gasto adicional</div>
        <div className="AddOutlays__header__actions">
          <div className="AddOutlays__header__description">
            Completa los datos del gasto adicional para añadirlo. <br />
            Los campos con (*) son obligatorios.
          </div>
          <div className="AddOutlays__header__submit">
            <Button onClick={handleSubmit(onSubmit)} disabled={!isValid || isFetching}>
              {t("add")}
            </Button>
          </div>
        </div>
      </div>
      <div className="AddOutlays__body">
        <FormProvider {...methods}>
          <form className="" noValidate>
            <div className="AddOutlays__item">
              <div className="AddOutlays__item__row">
                <Input label={t("name")} name="name" required={true} rules={{ required: true }} errorobj={errors} />
              </div>
              <div className="AddOutlays__item__row">
                <FormSelect
                  label={t("type")}
                  name="chargeType"
                  rules={{ required: true }}
                  errorobj={errors}
                  options={[
                    { value: "outlay", label: t("outlay") },
                    { value: "expense", label: t("expense") },
                  ]}
                />
                <FormSelect
                  label={t("class")}
                  name="chargeClass"
                  rules={{ required: true }}
                  errorobj={errors}
                  options={[
                    { value: "fixed", label: t("fixed") },
                    { value: "variable", label: t("variable") },
                    { value: "time", label: t("time") },
                  ]}
                />
                <div className="flex around">
                  <div>
                    <div className="AddOutlays__SwitchLabel">Fotos</div>
                    <SwitchChip value={isPhotoRequired} onChange={v => setIsPhotoRequired(v)} />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

export default AddOutlays;
