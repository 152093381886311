import { useEffect, useState, useRef } from "react";

import { useTranslation } from "react-i18next";

import { useForm, FormProvider } from "react-hook-form";
import { toast } from "react-toastify";

import useAuth from "../../../DataProviders/auth/useAuth";
import useRouter from "../../../utils/hooks/useRouter";

import { Input, Button, Password } from "ui-components";

import "./styles.scss";

const Login = () => {
  const { t } = useTranslation();

  const methods = useForm({ mode: "onChange" });
  const { handleSubmit, errors, formState, watch, trigger } = methods;
  const { isValid } = formState;
  const { history } = useRouter();
  const {
    methods: { login, changePassword },
    data: { isAuthenticated },
  } = useAuth();
  const password = useRef({});
  password.current = watch("password", "");
  const [passwordRequired, setPasswordRequired] = useState(null);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState("NOTFOUND");
  const [isFetching, setIsFetching] = useState(false);

  const onSubmit = async data => {
    setIsFetching(true);
    const response = await login(data);
    if (response.status === "RESET") {
      setPasswordRequired({ username: response.username, temporaryPassword: data.password });
      setShowError(false);
    }
    if (response.status === "OK") {
      setShowError(false);
    }
    if (response.status === "ERROR") {
      if (response.message === "User is disabled.") {
        setError("BLOCKED");
      } else {
        setError("NOTFOUND");
      }
      setShowError(true);
    }
    setIsFetching(false);
  };

  const onSubmitSetPassword = async data => {
    setIsFetching(true);
    await changePassword({ ...passwordRequired, newPassword: data.password });
    toast.success(
      <>
        <div className="Toastify__toast__title">{t("resetPassword")}</div>
        <div className="Toastify__toast__subtitle">{t("Now you can use it when you login")}.</div>
      </>
    );
    setPasswordRequired(null);
    setIsFetching(false);
  };

  useEffect(() => {
    if (isAuthenticated) history.push("/");
  }, [isAuthenticated]);

  useEffect(() => {
    if (password.current) trigger("passwordRepeat"); // retriggers validation
  }, [password.current]);

  const renderLogin = () => (
    <>
      <div className="Login__title">{t("loginYourAccount")}</div>
      <FormProvider {...methods}>
        <form className="">
          <Input label={t("user")} name="username" rules={{ required: true }} errorobj={errors} />
          <Password label={t("password")} name="password" rules={{ required: true }} errorobj={errors} />
        </form>
      </FormProvider>
      <div className="Login__remember flex end between v-center">
        <div
          className="Login__recovery"
          onClick={() => {
            history.push("/recovery");
          }}
        >
          {t("forgotPassword?")}
        </div>
      </div>
      {showError && error === "NOTFOUND" && (
        <div className="Login__error">
          <div className="Login__error__title">{t("wrongUsernameOrPassword")}</div>
          <div className="Login__error__subtitle">Introduce los datos de nuevo</div>
        </div>
      )}
      {showError && error === "BLOCKED" && (
        <div className="Login__error">
          <div className="Login__error__title">Su usuario se encuentra inactivo</div>
          <div className="Login__error__subtitle">Contacte con algún administrador de su empresa</div>
        </div>
      )}
      <Button onClick={handleSubmit(onSubmit)} disabled={!isValid || isFetching}>
        {t("login")}
      </Button>
    </>
  );

  const renderSetPassword = () => (
    <>
      <div className="Login__title">{t("changePassword")}</div>
      <div className="Login__text">
        {t("It's necessary to change the access password to continue with the registration")}.
      </div>
      <FormProvider {...methods}>
        <form className="">
          <Password
            label={t("newPassword")}
            name="password"
            errorobj={errors}
            rules={{
              required: "Campo requerido",
              pattern: {
                value: /^(?=\w*\d)(?=\w*[A-ZÑ])(?=\w*[a-zñ])\S{8,12}$/,
                message:
                  "La constraseña debe tener entre 8 y 12 caracteres, al menos una mayúscula, una minúscula y un dígito numérico",
              },
            }}
          />
          <Password
            label={t("repeatPassword")}
            name="passwordRepeat"
            errorobj={errors}
            rules={{
              validate: value => value === password.current || t("passwordsNotMatch"),
            }}
          />
        </form>
      </FormProvider>
      <Button onClick={handleSubmit(onSubmitSetPassword)} disabled={!isValid || isFetching}>
        {t("changePassword")}
      </Button>
    </>
  );

  return (
    <div className="Login flex h-center v-center">
      <div className="Login__wrapper">
        <div className="Login__logo">
          <img src="/assets/images/logo-full.svg" />
        </div>
        {(passwordRequired && renderSetPassword()) || renderLogin()}
      </div>
    </div>
  );
};

export default Login;
