import { useForm, FormProvider } from "react-hook-form";

import { useTranslation } from "react-i18next";

import { toast } from "react-toastify";

import { Input, Button } from "ui-components";
import useRouter from "../../../utils/hooks/useRouter";
import useAuth from "../../../DataProviders/auth/useAuth";

import "./styles.scss";

const PasswordRecovery = () => {
  const { t } = useTranslation();

  const methods = useForm({ mode: "onChange" });
  const { handleSubmit, errors, formState } = methods;
  const { isValid } = formState;
  const { history } = useRouter();
  const {
    methods: { recoverPassword },
  } = useAuth();

  const onSubmit = async data => {
    recoverPassword(data);
    toast.info(
      <>
        <div className="Toastify__toast__title">{t("checkYourEmail")}</div>
        <div className="Toastify__toast__subtitle">
          Si el usuario es correcto, te habremos enviado un correo con las instrucciones.
        </div>
      </>
    );
    history.push("/login");
  };

  return (
    <div className="PasswordRecovery flex h-center v-center">
      <div className="PasswordRecovery__wrapper">
        <div className="PasswordRecovery__logo">
          <img src="/assets/images/logo-full.svg" />
        </div>
        <div className="PasswordRecovery__title">{t("recoverYourPassword")}</div>
        <div className="PasswordRecovery__text">
          Escribe tu usuario, y te enviaremos un correo de recuperación a su cuenta de correo asociada{" "}
        </div>
        <FormProvider {...methods}>
          <form className="">
            <Input label={t("user")} name="username" rules={{ required: true }} errorobj={errors} />
          </form>
        </FormProvider>
        <Button onClick={handleSubmit(onSubmit)} disabled={!isValid}>
          {t("sendEmail")}
        </Button>
        <Button onClick={() => history.push("/login")} variant="secondary">
          {t("cancel")}
        </Button>
      </div>
    </div>
  );
};

export default PasswordRecovery;
