import { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";

import { Button, FileInput } from "ui-components";

import "./styles.scss";

const AttachDocuments = ({ attachingDoc, onCancelAttaching, onSubmit }) => {
  const { t } = useTranslation();

  const [fileObj, setFileObj] = useState({});

  const checkAttachingValidity = () => {
    let isValid = true;
    for (const key in fileObj) {
      if (!fileObj[key]) isValid = false;
    }
    return isValid;
  };

  const isValidType = (fileType, expType) => {
    switch (expType) {
      case "image/*":
        return fileType.split("/")[0] === expType.split("/")[0];
      default:
        return fileType === expType;
    }
  };

  const handleAttachFile = (e, code, type) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      if (!isValidType(file.type, type)) return;
      const url = URL.createObjectURL(file);
      setFileObj({ ...fileObj, [code]: { url, file } });
    }
  };

  const handleRemoveImage = code => {
    setFileObj({ ...fileObj, [code]: null });
  };
  const handleCancelAttaching = () => {
    setFileObj({});
    onCancelAttaching();
  };

  const handleSubmitAttaching = () => {
    onSubmit(fileObj);
  };

  const normalizeDocName = type => {
    switch (type) {
      case "application/pdf":
        return "pdf";
      default:
        return "image";
    }
  };

  const renderItemType = (type, code) => {
    switch (type) {
      case "image/*":
        return (
          <div className="AttachDocuments__list__item__image">
            <img src={fileObj[code].url} />
          </div>
        );
      case "application/pdf":
        return (
          <div className="AttachDocuments__list__item__pdf flex h-center v-center">
            <img src="/assets/icons/pdf-icon.svg" />
          </div>
        );
    }
  };

  useEffect(() => {
    const newFileObj = {};
    for (const item of attachingDoc.attachments) {
      newFileObj[item.code] = null;
    }
    setFileObj(newFileObj);
  }, [attachingDoc]);

  return (
    <div className="AttachDocuments">
      <div className="AttachDocuments__name">{attachingDoc.name.toUpperCase()}</div>
      <div className="AttachDocuments__tip">{t("attach the following documents")}:</div>
      <div className="AttachDocuments__list flex">
        {attachingDoc.attachments.map((item, index) => (
          <div className="AttachDocuments__list__item flex v-center" key={index}>
            {(fileObj[item.code] && (
              <>
                {renderItemType(item.type, item.code)}
                <img
                  className="AttachDocuments__list__item__remove"
                  src="/assets/icons/remove-image.svg"
                  onClick={() => handleRemoveImage(item.code)}
                />
              </>
            )) || (
              <FileInput
                trigger={
                  <div className="AttachDocuments__list__item__placeholder">
                    <div>({t(normalizeDocName(item.type)).toLowerCase()})</div>
                  </div>
                }
                onChange={e => handleAttachFile(e, item.code, item.type)}
                accept={item.type}
              />
            )}
            <div className="AttachDocuments__list__item__code">{item.code}</div>
          </div>
        ))}
      </div>
      <div className="AttachDocuments__actions flex end">
        <Button variant="secondary" onClick={handleCancelAttaching}>
          {t("cancel")}
        </Button>
        <Button onClick={handleSubmitAttaching} disabled={!checkAttachingValidity()}>
          {t("send")}
        </Button>
      </div>
    </div>
  );
};

export default AttachDocuments;
