import { useState, useRef, useEffect } from "react";

import { useTranslation } from "react-i18next";

import { useForm, FormProvider, useFieldArray } from "react-hook-form";
import { toast } from "react-toastify";

import { Button, Input, Select as FormSelect, SwitchChip } from "ui-components";
import useUi from "../../../DataProviders/ui/useUi";
import Documents from "../../../containers/Documents";
import useDocTypes from "../../../DataProviders/docTypes/useDocTypes";
import useDocProfile from "../../../DataProviders/docProfiles/useDocProfiles";

import "./styles.scss";

const EditDocProfile = () => {
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState("summary");
  const [isFetching, setIsFetching] = useState(false);
  const [categories, setCategories] = useState([]);

  const {
    methods: { closePanel },
  } = useUi();
  const {
    methods: { fetchAllDocTypes },
  } = useDocTypes();
  const {
    data: { detail },
    methods: { updateDocProfile },
  } = useDocProfile();

  const newDetail = { ...detail, documentTypes: detail.documentTypes?.map(item => ({ name: item })) };
  const methods = useForm({
    mode: "onChange",
    defaultValues: newDetail,
  });
  const { handleSubmit, errors, formState, setValue, trigger, control } = methods;
  const { isValid } = formState;
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
    control,
    name: "documentTypes",
  });

  const fetchCategories = async () => {
    const response = await fetchAllDocTypes();
    const _documentTypes = response.map(item => ({ value: item._id, label: item.name }));
    setCategories(_documentTypes);
  };

  const parseArrayError = (errors, index, fieldName) => {
    const name = `documentTypes[${index}].${fieldName}`;
    return (
      errors?.documentTypes?.[index]?.[fieldName] && {
        [name]: errors?.documentTypes?.[index]?.[fieldName],
      }
    );
  };

  const handleRemoveRow = index => {
    if (fields.length > 1) {
      remove(index);
    }
  };

  const onSubmit = async data => {
    data.documentTypes = data.documentTypes.map(item => item.name);
    setIsFetching(true);
    try {
      await updateDocProfile(detail._id, {
        ...detail,
        ...data,
      });
      toast.success(
        <>
          <div className="Toastify__toast__title">Perfil de documentos actualizado</div>
          <div className="Toastify__toast__subtitle">Hemos actualizado la información del perfil de documentos.</div>
        </>
      );
      closePanel();
    } catch (e) {
      toast.error(
        <>
          <div className="Toastify__toast__title">{t("anErrorHasOccurred")}</div>
          <div className="Toastify__toast__subtitle">
            No hemos podido actualizar la información del perfil de documentos.
          </div>
        </>
      );
    }
    setIsFetching(false);
  };

  useEffect(() => {
    if (detail) {
      fetchCategories();
    }
  }, [detail]);

  return (
    <div className="EditDocProfile">
      <FormProvider {...methods}>
        <div className="EditDocProfile__header flex">
          <div className="EditDocProfile__header__info">
            <div className="EditDocProfile__header__personal flex">
              <div className="EditDocProfile__header__name">{detail.name}</div>
            </div>
            <div className="EditDocProfile__header__misc flex v-center capitalize">
              {detail.active ? "activo" : "no activo"}
            </div>
            <div className="EditDocProfile__header__actions flex end">
              <Button onClick={handleSubmit(onSubmit)} disabled={!isValid || isFetching}>
                {t("save")}
              </Button>
            </div>
          </div>
        </div>
        <div className="EditDocProfile__body">
          <div className="EditDocProfile__main">
            <div className="EditDocProfile__tabs flex">
              <div
                className={`EditDocProfile__tabs__item ${activeTab === "summary" ? "active" : ""}`}
                onClick={() => setActiveTab("summary")}
              >
                RESUMEN
              </div>
              <div className={`EditDocProfile__tabs__item ${activeTab === "docs" ? "active" : ""}`} onClick={() => { }}>
                DOCUMENTOS
              </div>
            </div>
            {activeTab === "summary" ? (
              <div className="EditDocProfile__summary">
                <div className="EditDocProfile__summary__section">
                  <div className="EditDocProfile__summary__title">INFORMACIÓN GENERAL</div>
                  <div className="EditDocProfile__summary__form">
                    <div className="EditDocProfile__summary__form__row">
                      <Input label={t("name")} name="name" required={true} rules={{ required: true }} errorobj={errors} />
                      < FormSelect
                        label={t("userType")}
                        name="userType"
                        rules={{ required: true }}
                        errorobj={errors}
                        options={[
                          { value: "office", label: t("office") },
                          { value: "admin", label: t("admin") },
                          { value: "driver", label: t("driver") },
                          { value: "none", label: t("typeless") },
                        ]}
                      />
                    </div>
                  </div>
                </div>
                <div className="EditDocProfile__summary__section">
                  <div className="EditDocProfile__summary__title">TIPOS DE DOCUMENTO</div>
                  <div className="EditDocProfile__summary__form">
                    {fields.map((item, index) => (
                      <div key={item.id} className="EditDocProfile__item">
                        {fields.length > 1 && (
                          <div className="EditDocProfile__item__close" onClick={() => handleRemoveRow(index)}>
                            <img src="/assets/icons/close.svg" />
                          </div>
                        )}
                        <div className="EditDocProfile__summary__form__row">
                          <FormSelect
                            label={t("type")}
                            defaultValue={item.name}
                            name={`documentTypes[${index}].name`}
                            required={true}
                            rules={{ required: true }}
                            errorobj={parseArrayError(errors, index, "name")}
                            options={categories}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="EditDocProfile__add">
                    <Button
                      variant="secondary"
                      onClick={() => {
                        append({});
                      }}
                    >
                      <img src="/assets/icons/add.svg" />
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              <Documents
                documentTypes={detail.documentation?.documentTypes || []}
                referenceId={detail._id}
                reference="deposits"
              />
            )}
          </div>
        </div>
      </FormProvider>
    </div>
  );
};

export default EditDocProfile;
