import { toast } from "react-toastify";

import capitalize from "../../helpers/capitalize";

const toastNotify = ({ t, type, title = "", text = "" }) => {
  toast[type](
    <>
      <div className="Toastify__toast__title">
        {title || t(`toast${capitalize(type)}TitleDefault`)}
      </div>
      <div className="Toastify__toast__subtitle">
        {text || t(`toast${capitalize(type)}TextDefault`)}
      </div>
    </>
  );
};

export { toastNotify };
