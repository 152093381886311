import { SET_USER, USER_LOGOUT } from "./actions";

const INITIAL_STATE = {
  isAuthenticated: false,
  user: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user: action.payload,
        isAuthenticated: true,
      };
    case USER_LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};
