export const SET_MEDIUMS_LIST = 'SET_MEDIUMS_LIST'
export const setMediumsList = payload => ({
  type: SET_MEDIUMS_LIST,
  payload
})

export const SET_MEDIUM_DETAIL = 'SET_MEDIUM_DETAIL'
export const setMediumDetail = payload => ({
  type: SET_MEDIUM_DETAIL,
  payload
})
