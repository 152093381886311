import { useState, useEffect, useRef, useCallback } from "react";

import { useTranslation } from "react-i18next";

import Cropper from "react-easy-crop";
import Slider from "@material-ui/core/Slider";

import { Button } from "ui-components";
import getCroppedImg from "./cropImage";

import "./styles.scss";

function readFile(file) {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}

const ChangeAvatar = ({ imageSrc, onSubmit, onCancel }) => {
  const { t } = useTranslation();

  const [srcImage, setSrcImage] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const fileRef = useRef(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleSubmit = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(srcImage, croppedAreaPixels, rotation);
      onSubmit(croppedImage);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, rotation]);

  const onFileChange = async e => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      let imageDataUrl = await readFile(file);
      setSrcImage(imageDataUrl);
    }
  };

  useEffect(() => {
    setSrcImage(imageSrc);
  }, [imageSrc]);

  return (
    <div className="ChangeAvatar">
      <div className="ChangeAvatar__title">FOTO DE PERFIL</div>
      <div className="ChangeAvatar__content">
        <div className="ChangeAvatar__wrapper-crop">
          <Cropper
            image={srcImage}
            crop={crop}
            rotation={rotation}
            zoom={zoom}
            aspect={4 / 4}
            onCropChange={setCrop}
            onRotationChange={setRotation}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            cropShape="round"
            showGrid={false}
          />
        </div>
        <div className="flex h-center">
          <Button variant="secondary" onClick={() => fileRef.current.click()}>
            {t("browse")}
          </Button>
          <input type="file" onChange={onFileChange} accept="image/*" hidden ref={fileRef} />
        </div>
        <Slider value={zoom} min={1} max={3} step={0.1} aria-labelledby="Zoom" onChange={(e, zoom) => setZoom(zoom)} />
        <div className="flex end">
          <Button variant="secondary" onClick={onCancel}>
            {t("cancel")}
          </Button>
          <Button onClick={handleSubmit}>{t("save")}</Button>
        </div>
      </div>
    </div>
  );
};

export default ChangeAvatar;
