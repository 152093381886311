import React from "react";
import ReactDOM from "react-dom";

import { Provider } from "react-redux";
import store from "./DataProviders";

import "./i18n/i18n";

import DateFnsUtils from "@date-io/date-fns";
import locale from "date-fns/locale/es";

import "typeface-open-sans";
import "typeface-poppins";

import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import App from "./App";

import { registerServiceWorker } from "./serviceWorker";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
        <App />
      </MuiPickersUtilsProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

registerServiceWorker();
