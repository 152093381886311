import { SET_USER_LIST, SET_USER_DETAIL } from './actions'

const INITIAL_STATE = {
  userDetail: null,
  list: [],
  totalPages: 1,
  totalDocs: 0,
  params: {
    page: 1,
    limit: 10,
    search: '',
    sort: 'name,surname'
  }
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_USER_LIST:
      return {
        ...state,
        list: action.payload.docs,
        totalPages: action.payload.metadata?.totalPages,
        totalDocs: action.payload.metadata?.totalDocs,
        params: {
          ...state.params,
          page: action.payload.metadata?.page || state.params.page,
          limit: action.payload.metadata?.limit || state.params.limit,
          search: action.payload.search
        }
      }
    case SET_USER_DETAIL:
      return {
        ...state,
        userDetail: action.payload
      }

    default:
      return state
  }
}
