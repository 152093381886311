import { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";

import useUi from "../../../DataProviders/ui/useUi";

import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import { Button } from "ui-components";

import _ from "lodash";

import "../../../styles/add-edit.scss";
import "./styles.scss";

const AddCharts = ({hiddenCharts, onChangeChartVisibility}) => {

  const { t } = useTranslation();

  const [chartsToShow, setChartsToShow] = useState(hiddenCharts);

  const {
    methods: { closePanel },
  } = useUi();

  const handleAddChart = chartId => {
    setChartsToShow(chartsToShow.filter(chart => chart._id !== chartId));
    onChangeChartVisibility(chartId);
  }

  useEffect(() => {
    if (_.isEmpty(chartsToShow)) closePanel();
  }, [chartsToShow]);

  return (

    <div id="AddCharts" className="Add">
      <div className="Add__header">
        <IconButton onClick={closePanel}>
          <CloseIcon />
        </IconButton>
        <div className="Add__header__title">{t("addChartTitle")}</div>
        <div className="Add__header__content">
          <div className="Add__header__content__description">{t("addChartText")}</div>
        </div>
      </div>
      <div className="Add__body">
        {chartsToShow.length > 0 && chartsToShow.map(chart => {
          return (
            <div key={chart._id} className="chartWrapper flex row between">
              <div className="chartThumbnail">
                <img src={chart.thumbnail} alt="chart thumbnail" />
              </div>
              <div className="chartInfoBtn flex column start">
                <div className="chartHeader flex row between">
                  <div className="chartName">{chart.name.toUpperCase()}</div>
                  <Button variant="secondary" prefixIcon="add-10-gray" onClick={() => handleAddChart(chart._id)}>{t("add")}</Button>
                </div>
                <div className="chartDescription">{chart.description}</div>
              </div>

            </div>
          )
        })}
      </div>
    </div>
  );
};

export default AddCharts;
