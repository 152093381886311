import { useDispatch, useSelector } from "react-redux";

import { resetPaymentsList, setPaymentDetail, setPaymentsList } from "./actions";
import useFetch from "../../utils/hooks/useFetch";

const usePayments = () => {
  const payments = useSelector(state => state.payments);
  const dispatch = useDispatch();
  const { get, post, put, patch } = useFetch();

  //LIST
  const initPaymentsList = () => {
    dispatch(resetPaymentsList());
  };

  const fetchList = async params => {
    const data = await get({ endpoint: "billing/providersPayment", params });
    dispatch(setPaymentsList({ ...data, search: params?.search, filter: params?.filter, sort: params?.sort }));
  };

  const handleChangePage = page => {
    fetchList({ ...payments.params, page });
  };

  const handleChangePageSize = limit => {
    fetchList({ ...payments.params, page: 1, limit });
  };

  const handleChangeSearch = search => {
    fetchList({ ...payments.params, page: 1, search });
  };

  const handleChangeFilters = filter => {
    fetchList({ ...payments.params, page: 1, filter: JSON.stringify(filter) });
  };

  const handleChangeSort = sort => {
    fetchList({ ...payments.params, page: 1, sort });
  };

  //payment

  const fetchAllPayments = async () => {
    return await get({ endpoint: "billing/providersPayment" });
  };

  const generatePayments = async body => {
    return await post({ endpoint: "billing/providersPayment", body });
  };

  const processPayment = async (paymentId, body) => {
    return await patch({
      endpoint: "billing/providersPayment",
      id: paymentId,
      body
    });
  }

  return {
    methods: {
      initPaymentsList,
      fetchList,
      handleChangePage,
      handleChangePageSize,
      handleChangeSearch,
      handleChangeFilters,
      handleChangeSort,
      fetchAllPayments,
      generatePayments,
      processPayment,
    },
    data: payments,
  };
};

export default usePayments;
