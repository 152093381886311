import { useState } from 'react'
import useFetch from '../../utils/hooks/useFetch'

const useDocuments = () => {
  const [documents, setDocuments] = useState([])
  const { get, post, put, patch, postFile } = useFetch()

  const fetchDocuments = async id => {
    const response = await get({ endpoint: `docs/${id}` })
    setDocuments(response)
  }

  // const uploadDocuments = async (data) => {
  //   return await postFile({ endpoint: 'docs', body: data })
  // }

  // ÑAPA DEL SIGLO DE ADOLFO
  const uploadDocuments = async (data, reference, referenceId, docType) => {
    return await postFile({ endpoint: `docs/${reference}/${referenceId}/${docType}`, body: data })
  }

  return {
    methods: {
      fetchDocuments,
      uploadDocuments
    },
    data: documents
  }
}

export default useDocuments
