import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { lightFormat } from "date-fns";

import useAuth from "../../DataProviders/auth/useAuth";
import useDocReviews from "../../DataProviders/docReviews/useDocReviews";
import Detail from "./Detail";

import { Button, List } from "ui-components";

const DocReviews = () => {
  const { t } = useTranslation();

  const getColumns = ({ t, onEdit }) => {
    return [
      {
        id: "reference", // users
        label: t("origin"),
        renderCell: value => t(`${value}`),
      },
      {
        id: "company",
        label: t("holder"),
        renderCell: value => value.groupName,
      },
      { id: "documentType", label: t("type"), renderCell: value => value.name },
      {
        id: "attachments",
        label: t("attachments"),
        align: "center",
        renderCell: value => value.length,
      },
      {
        id: "createdAt",
        label: t("sended"),
        renderCell: value => lightFormat(new Date(value), "dd/MM/yyyy HH:mm"),
      },
      {
        align: "right",
        renderCell: (_, obj) => {
          return (
            <Button onClick={() => onEdit(obj._id)} variant="secondary" prefixIcon="search-small">
              {t("check")}
            </Button>
          );
        },
      },
    ];
  };

  const [showDetail, setShowDetail] = useState(false);

  const {
    data: { user },
  } = useAuth();

  const {
    data: {
      list,
      totalDocs,
      params: { page, limit },
    },
    methods: {
      fetchList,
      fetchDocReviewsDetail,
      handleChangePage,
      handleChangePageSize,
    },
  } = useDocReviews();

  const handleEdit = async id => {
    await fetchDocReviewsDetail(id);
    setShowDetail(true);
  };

  useEffect(() => {
    fetchList();
  }, [user.company?.region]);

  return (
    <>
      <List
        t={t}
        id="DocReviews"
        hide={!list || showDetail}
        totalDocs={totalDocs}
        title={t("pendingDocuments-opt.listWithCount", { count: totalDocs || 0 })}
        headerFilter={null}
        headerActions={null}
        list={[...list]}
        columns={getColumns({ t, onEdit: handleEdit })}
        pageData={{ page, limit }}
        pageActions={{
          onChangePage: handleChangePage,
          onChangePageSize: handleChangePageSize,
          onDoubleClick: () => { },
        }}
        params={{}}
      />
      {showDetail && <Detail onClose={() => setShowDetail(false)} />}
    </>
  );
};

export default DocReviews;
