import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import thunk from "redux-thunk";

/* Reducers */
import auth from "./auth/reducer";
import companies from "./companies/reducer";
import profiles from "./profiles/reducer";
import docProfile from "./docProfiles/reducer";
import docReviews from "./docReviews/reducer";
import docTypes from "./docTypes/reducer";
import labeled from "./labeled/reducer";
import mediumTypes from "./mediumTypes/reducer";
import operationTypes from "./operationTypes/reducer";
import outlays from "./outlays/reducer";
import rejections from "./rejections/reducer";
import tickets from "./tickets/reducer";
import ui from "./ui/reducer";
import users from "./users/reducer";
import vehicleTypes from "./vehicleTypes/reducer";
import shipments from "./shipments/reducer";
import payments from "./payments/reducer";
import auxServiceTypes from "./auxServiceTypes/reducer";

const composeEnhancers = (typeof window !== "undefined" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const rootReducer = combineReducers({
  auth,
  companies,
  profiles,
  docProfile,
  docReviews,
  docTypes,
  labeled,
  mediumTypes,
  operationTypes,
  outlays,
  rejections,
  tickets,
  ui,
  users,
  vehicleTypes,
  shipments,
  payments,
  auxServiceTypes,
});

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

export default store;
