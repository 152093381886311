import {
  SHOW_PANEL,
  HIDE_PANEL,
  HIDE_LOADER,
  SHOW_LOADER,
  SEND_MENUWRAPPER_BACK,
  SEND_MENUWRAPPER_FRONT,
} from "./actions";

const INITIAL_STATE = {
  isPanelVisible: false,
  closePanelCb: null,
  renderPanelContent: null,
  loaderCount: 0,
  menuWrapperBack: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SHOW_PANEL:
      return {
        ...state,
        isPanelVisible: true,
        renderPanelContent: action.payload.render,
        closePanelCb: action.payload.closeCb || null,
      };
    case HIDE_PANEL:
      return {
        ...state,
        isPanelVisible: false,
        renderPanelContent: null,
        closePanelCb: null,
      };
    case SHOW_LOADER:
      return {
        ...state,
        loaderCount: state.loaderCount + 1,
      };
    case HIDE_LOADER:
      return {
        ...state,
        loaderCount: state.loaderCount - 1,
      };
    case SEND_MENUWRAPPER_BACK:
      return {
        ...state,
        menuWrapperBack: true,
      };
    case SEND_MENUWRAPPER_FRONT:
      return {
        ...state,
        menuWrapperBack: false,
      };

    default:
      return state;
  }
};
