import { useState } from "react";

import { useTranslation } from "react-i18next";

import { useForm, FormProvider } from "react-hook-form";
import useDocReviews from "../../../DataProviders/docReviews/useDocReviews";

import { PageWrapper } from "ui-components";
import { PageHeader } from "ui-components";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { PageBody } from "ui-components";

import { SwitchChip, Button, Input } from "ui-components";
import DateInput from "../../../components/DateInput";

import { toast } from "react-toastify";

import { lightFormat } from "date-fns";

import "overlayscrollbars/css/OverlayScrollbars.css";
import "./styles.scss";

const DocDetail = ({ onClose }) => {
  const { t } = useTranslation();

  const [isFetching, setIsFetching] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [attachStatus, setAttachStatus] = useState({});

  const {
    data: { detail },
    methods: { fetchList, rejectDoc, approveDoc },
  } = useDocReviews();

  const form1 = useForm({
    mode: "onChange",
  });

  const form2 = useForm({
    mode: "onChange",
  });

  const {
    handleSubmit: handleSubmitForm1,
    formState: formStateForm1,
    errors: errorsForm1,
    control: controlForm1,
  } = form1;
  const { isValid: isValidForm1 } = formStateForm1;

  const {
    handleSubmit: handleSubmitForm2,
    formState: formStateForm2,
    errors: errorsForm2,
    control: controlForm2,
  } = form2;
  const { isValid: isValidForm2 } = formStateForm2;

  const normalizeDocName = type => {
    switch (type) {
      case "application/pdf":
        return "pdf";
      default:
        return "image";
    }
  };

  const handleChangeSwitch = (code, value) => {
    setAttachStatus({ ...attachStatus, [code]: value });
  };

  const areAttachValid = () => {
    let isOk = true;
    for (const key in attachStatus) {
      if (!attachStatus[key]) isOk = false;
    }
    if (Object.keys(attachStatus).length !== detail.attachments.length) isOk = false;
    return isOk;
  };

  const onSubmitForm1 = async data => {
    setIsFetching(true);
    try {
      await rejectDoc(detail._id, data);
      await fetchList();
      toast.success(
        <>
          <div className="Toastify__toast__title">Revisión de documento rechazada</div>
          <div className="Toastify__toast__subtitle">Se ha actualizado la lista de pendientes.</div>
        </>
      );
      onClose();
    } catch (e) {
      toast.error(
        <>
          <div className="Toastify__toast__title">{t("Error rejecting the document")}</div>
          <div className="Toastify__toast__subtitle">{t("An error occurred while rejecting this document")}</div>
        </>
      );
    }
    setIsFetching(false);
  };

  const onSubmitForm2 = async data => {
    setIsFetching(true);
    try {
      await approveDoc(detail._id, data);
      await fetchList();
      toast.success(
        <>
          <div className="Toastify__toast__title">Revisión de documento aprobada</div>
          <div className="Toastify__toast__subtitle">Se ha actualizado la lista de pendientes.</div>
        </>
      );
      onClose();
    } catch (e) {
      toast.error(
        <>
          <div className="Toastify__toast__title">{t("Error approving the document")}</div>
          <div className="Toastify__toast__subtitle">{t("An error occurred while approving this document")}</div>
        </>
      );
    }
    setIsFetching(false);
  };

  return (
    <PageWrapper id="DocReviewsDetail">

      <PageHeader
        goBack={onClose}
        title={`${t("check").toUpperCase()} ${detail.documentType.name}`}
        actions={null}
      />

      <OverlayScrollbarsComponent style={{ height: "100%", width: "100%" }}>
        <PageBody>
          <div className="DocDetail__main-info flex between">
            <div className="DocDetail__main-info__col">
              <div className="DocDetail__main-info__item">{detail.documentProfile.name}</div>
              <div className="DocDetail__main-info__item">{detail.documentType.graceDays} días de gracia</div>
              <div className="DocDetail__main-info__item">{detail.referenceData.company.name}</div>
            </div>
            <div className="DocDetail__main-info__col">
              <div className="DocDetail__main-info__item">{t(`${detail.reference}`)}</div>
              <div className="DocDetail__main-info__item">{detail.documentType.expires ? "Caduca" : "No caduca"}</div>
              <div className="DocDetail__main-info__item">
                {detail.referenceData.name} {detail.reference === "users" ? detail.referenceData.surname || "" : ""}{" "}
                {detail.reference === "users" && detail.referenceData.legalId
                  ? `(${detail.referenceData.legalId})`
                  : ""}
                {detail.reference === "mediums" && detail.referenceData.plate
                  ? `(${detail.referenceData.plate})`
                  : ""}
              </div>
            </div>
            <div className="DocDetail__main-info__col">
              <div className="DocDetail__main-info__item">
                Vigente desde {lightFormat(new Date(detail.documentType.validFrom), "dd/MM/yyyy")}
              </div>
              <div className="DocDetail__main-info__item">
                {detail.documentType.required ? "Obligatorio" : "No obligatorio"}
              </div>
              <div className="DocDetail__main-info__item">{detail.company.groupName}</div>
            </div>
          </div>
          <div className="DocDetail__attachments__header flex between v-center">
            <div className="DocDetail__attachments__title">{t("attachments").toUpperCase()}</div>
            <div className="DocDetail__attachments__date">
              Enviado {lightFormat(new Date(detail.createdAt), "dd/MM/yyyy")}
            </div>
          </div>
          <div className="DocDetail__attachments flex">
            {detail.attachments.map((item, index) => (
              <div className="DocAttachment" key={index}>
                <div className="DocAttachment__header flex between v-center">
                  <div className="DocAttachment__code uppercase">{item.code}</div>
                  <div className="DocAttachment__type">({  t(normalizeDocName(item.contentType)).toLowerCase()})</div>
                </div>
                {(item.contentType === "application/pdf" && (
                  <a href={item.url} target="_blank">
                    <div className="DocAttachment__image DocAttachment__image--pdf flex v-center h-center pointer">
                      <img src="assets/icons/pdf-icon.svg" />
                    </div>
                  </a>
                )) || (
                    <div className="DocAttachment__image pointer" onClick={() => setImagePreview(item.url)}>
                      <img src={item.url} />
                    </div>
                  )}
                <div className="DocAttachment__switch flex v-center">
                  CORRECTO
                  <SwitchChip value={attachStatus[item.code]} onChange={v => handleChangeSwitch(item.code, v)} />
                </div>
              </div>
            ))}
          </div>
          <div className="DocDetail__footer">
            <div className="DocDetail__footer__title">{t("result").toUpperCase()}</div>
            <div className="DocDetail__footer__form flex">
              <div className="DocDetail__footer__form__reject flex">
                <FormProvider {...form1}>
                  <Input
                    label={t("rejectionReason")}
                    name="rejectNotes"
                    multiline
                    rules={{ required: true }}
                    errorobj={errorsForm1}
                    rowsMax={2}
                  />
                  <Button onClick={handleSubmitForm1(onSubmitForm1)} disabled={!isValidForm1 || isFetching}>
                    {t("reject")}
                  </Button>
                </FormProvider>
              </div>
              <div className="DocDetail__footer__form__accept flex">
                <FormProvider {...form2}>
                  {detail.documentType.expires && (
                    <DateInput
                      label={t("validUntil")}
                      name="expirationDate"
                      rules={{ required: true }}
                      errorobj={errorsForm2}
                      disablePast
                    />
                  )}
                  <Button
                    onClick={handleSubmitForm2(onSubmitForm2)}
                    disabled={!isValidForm2 || isFetching || !areAttachValid()}
                  >
                    {t("approve")}
                  </Button>
                </FormProvider>
              </div>
            </div>
          </div>
        </PageBody>
      </OverlayScrollbarsComponent>

      {imagePreview && (
        <div className="DocDetail__preview flex v-center h-center" onClick={() => setImagePreview(null)}>
          <div className="DocDetail__preview__container">
            <div className="DocDetail__preview__close" onClick={() => setImagePreview(null)}>
              <img src="/assets/icons/close.svg" />
            </div>
            <div className="DocDetail__preview__image">
              <img src={imagePreview} />
            </div>
          </div>
        </div>
      )}

    </PageWrapper>

  );
};

export default DocDetail;
