export const RESET_SHIPMENTS_LIST = 'RESET_SHIPMENTS_LIST'
export const resetShipmentsList = payload => ({
  type: RESET_SHIPMENTS_LIST,
  payload
})

export const SET_SHIPMENTS_LIST = 'SET_SHIPMENTS_LIST'
export const setShipmentsList = payload => ({
  type: SET_SHIPMENTS_LIST,
  payload
})

export const SET_SHIPMENT_DETAIL = 'SET_SHIPMENT_DETAIL'
export const setShipmentDetail = payload => ({
  type: SET_SHIPMENT_DETAIL,
  payload
})
