import { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";

import { useForm, FormProvider } from "react-hook-form";
import { toast } from "react-toastify";

import { Button, Input, Select as FormSelect, SwitchChip } from "ui-components";
import useUi from "../../../DataProviders/ui/useUi";
import Documents from "../../../containers/Documents";
import useRejections from "../../../DataProviders/rejections/useRejections";

import "./styles.scss";

const EditRejection = () => {
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState("summary");
  const [isFetching, setIsFetching] = useState(false);
  const [isPlanned, setIsPlanned] = useState(false);

  const {
    methods: { closePanel },
  } = useUi();
  const {
    data: { detail },
    methods: { updateRejection },
  } = useRejections();

  const methods = useForm({
    mode: "onChange",
    defaultValues: detail,
  });
  const { handleSubmit, errors, formState } = methods;
  const { isValid } = formState;

  let ratings = [];
  for (let i = 0; i <= 10; i++) {
    ratings.push({ value: i, label: i });
  }

  const types = [
    {value: "driver", label: t("driver")},
    {value: "planned", label: t("scheduled")},
    {value: "queued", label: t("onQueue")},
  ];

  const onSubmit = async values => {
    const data = { ...values };
    setIsFetching(true);
    try {
      await updateRejection(detail._id, {
        ...detail,
        ...data,
      });
      toast.success(
        <>
          <div className="Toastify__toast__title">Motivo de rechazo actualizado</div>
          <div className="Toastify__toast__subtitle">Hemos actualizado la información del motivo de rechazo.</div>
        </>
      );
      closePanel();
    } catch (e) {
      toast.error(
        <>
          <div className="Toastify__toast__title">{t("anErrorHasOccurred")}</div>
          <div className="Toastify__toast__subtitle">
            No hemos podido actualizar la información del motivo de rechazo.
          </div>
        </>
      );
    }
    setIsFetching(false);
  };

  useEffect(() => {
    if (detail) {
      setIsPlanned(detail.planned);
    }
  }, [detail]);

  return (
    <div className="EditRejection">
      <FormProvider {...methods}>
        <div className="EditRejection__header flex">
          <div className="EditRejection__header__info">
            <div className="EditRejection__header__personal flex">
              <div className="EditRejection__header__name">{detail.description}</div>
            </div>
            <div className="EditRejection__header__misc flex v-center capitalize">
              {detail.active ? "activo" : "no activo"}
            </div>
            <div className="EditRejection__header__actions flex end">
              <Button onClick={handleSubmit(onSubmit)} disabled={!isValid || isFetching}>
                {t("save")}
              </Button>
            </div>
          </div>
        </div>
        <div className="EditRejection__body">
          <div className="EditRejection__main">
            <div className="EditRejection__tabs flex">
              <div
                className={`EditRejection__tabs__item ${activeTab === "summary" ? "active" : ""}`}
                onClick={() => setActiveTab("summary")}
              >
                RESUMEN
              </div>
              <div className={`EditRejection__tabs__item ${activeTab === "docs" ? "active" : ""}`} onClick={() => { }}>
                DOCUMENTOS
              </div>
            </div>
            {activeTab === "summary" ? (
              <div className="EditRejection__summary">
                <div className="EditRejection__summary__section">
                  <div className="EditRejection__summary__title">INFORMACIÓN GENERAL</div>
                  <div className="EditRejection__summary__form">
                    <div className="EditRejection__summary__form__row">
                      <Input
                        label={t("description")}
                        name="description"
                        required={true}
                        rules={{
                          required: true,
                        }}
                        errorobj={errors}
                      />
                    </div>
                    <div className="AddRejection__item__row">
                      <FormSelect
                        label={t("rating")}
                        name="rating"
                        required={true}
                        rules={{ required: true }}
                        errorobj={errors}
                        options={ratings}
                        disableEmpty={true}
                      />
                      <FormSelect
                        label={t("rejectionType")}
                        name="type"
                        required={true}
                        rules={{ required: true }}
                        errorobj={errors}
                        options={types}
                        disableEmpty={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <Documents
                documentTypes={detail.documentation?.documentTypes || []}
                referenceId={detail._id}
                reference="deposits"
              />
            )}
          </div>
        </div>
      </FormProvider>
    </div>
  );
};

export default EditRejection;
