import { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";

import { useFormContext, Controller } from "react-hook-form";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { FormHelperText } from "@material-ui/core";

import "./styles.scss";

const MuiSelect = props => {
  const {
    label,
    name,
    required,
    errorobj,
    options,
    noBorder,
    autoSelectFirst,
    onSelectChange,
    disabled,
    disableEmpty,
  } = props;
  const { t } = useTranslation();

  const [value, setValue] = useState(null);

  let isError = false;
  let errorMessage = "";
  if (errorobj && errorobj.hasOwnProperty(name)) {
    isError = true;
    errorMessage = errorobj[name].message || t("fieldRequired");
  }

  const handleChange = (a, b, c, d) => {
    props.onChange(a, b, c, d);
    setValue(a.target.value);
    onSelectChange && onSelectChange(a.target.value);
  };

  useEffect(() => {
    if (autoSelectFirst && options.length > 0) {
      setValue(options[0].value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  return (
    <FormControl
      style={{ color: "#fff" }}
      error={isError}
      required={required}
      variant={noBorder ? "standard" : "outlined"}
      sx={{ m: 1, minWidth: 120 }}
    >
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <Select
        id={name}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          getContentAnchorEl: null,
        }}
        value={value}
        {...props}
        onChange={handleChange}
        disabled={disabled}
      >
        {!disableEmpty && <MenuItem value="">- {t("unselected")} -</MenuItem>}
        {options.map(item => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
      {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
    </FormControl>
  );
};

function FormSelect(props) {
  const { control } = useFormContext();
  const { name, label, required = true, disableEmpty = false } = props;
  return (
    <div className="Select">
      <Controller
        as={MuiSelect}
        control={control}
        name={name}
        label={label}
        required={required}
        rules={{ required }}
        InputLabelProps={{
          className: required ? "required-label" : "",
          required: required || false,
        }}
        defaultValue=""
        disableEmpty={disableEmpty}
        {...props}
      />
    </div>
  );
}
export { MuiSelect };

export default FormSelect;
