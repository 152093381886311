import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { useForm, FormProvider, useFieldArray } from "react-hook-form";
import InputAdornment from "@material-ui/core/InputAdornment";
import { toast } from "react-toastify";

import useUsers from "../../../DataProviders/users/useUsers";
import useAuth from "../../../DataProviders/auth/useAuth";
import useUi from "../../../DataProviders/ui/useUi";
import useErrors from "../../../utils/hooks/useErrors";
import { Select as FormSelect, Button, Input, PhoneInput } from "ui-components";

import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import "./styles.scss";

const AddUser = () => {
  const { t } = useTranslation();

  const [userList, setUserList] = useState([]);
  const [centers, setCenters] = useState([]);
  const [forceRender, setForceRender] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const {
    methods: { fetchCenters },
    data: { user },
  } = useAuth();

  const {
    methods: { fetchAllUsers, bulkInvite, fetchList },
  } = useUsers();

  const {
    methods: { closePanel },
  } = useUi();

  const { getError } = useErrors();
  const methods = useForm({
    mode: "onChange",
  });

  const { handleSubmit, errors, formState, watch, trigger, control, setValue, getValues, setError } = methods;
  const { isValid } = formState;

  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
    control,
    name: "users",
  });

  const fetchUsers = async () => {
    const response = await fetchAllUsers();
    const parsedResponse = response.map(item => ({
      ...item,
      email: item.mainContact.email,
      phone: item.mainContact.phone,
    }));
    setUserList(parsedResponse);
  };

  const initCenters = async () => {
    const response = await fetchCenters();
    const _centers = response.map(item => ({ label: item.name, value: item.id }));
    setCenters(_centers);
  };

  const checkAvailability = (field, value, index) => {
    const formValues = getValues();
    let comparingValue = value;
    if (field === "username") {
      comparingValue = `${value?.toLowerCase()}.${user.company.code.toLowerCase()}`;
    }
    const existInUsers = userList.some(
      item => item[field]?.toLowerCase().replace(/ /g, "") === comparingValue.toLowerCase().replace(/ /g, "")
    );
    const existInForm = formValues.users.some(
      (item, idx) => index !== idx && item[field]?.toLowerCase() === value?.toLowerCase()
    );
    return !existInUsers && !existInForm;
  };

  const parseArrayError = (errors, name, arrayName, index, fieldName) => {
    return (
      errors?.[arrayName]?.[index]?.[fieldName] && {
        [name]: errors?.[arrayName]?.[index]?.[fieldName],
      }
    );
  };

  const setUserName = index => {
    const _values = getValues([`users[${index}].name`, `users[${index}].surname`]);
    setValue(`users[${index}].username`, _values.users[index].name.toLowerCase().replace(/ /g, ""));
    trigger(`users[${index}].username`);
  };

  const handleRemoveRow = index => {
    if (fields.length > 1) {
      remove(index);
    }
  };

  const showEmail = index => {
    const type = getValues(`users[${index}].type`);
    return type !== "driver";
  };

  const onSubmit = async data => {
    setIsFetching(true);
    const users = data.users.map(user => {
      user.mainContact = { email: user.email, phone: user.phone?.replace(/ /g, "") };
      delete user.email;
      delete user.phone;
      return user;
    });
    const response = await bulkInvite(users);
    let hasErrors = 0;
    for (const index in response) {
      if (response[index].error) {
        const srcArr = response[index].error.sourceError.split("mainContact.");
        const source = srcArr.length > 1 ? srcArr[1] : srcArr[0];
        hasErrors += 1;
        setError(`users[${index}].${source}`, {
          type: "manual",
          message: getError(response[index].error.code).text,
        });
      } else {
        remove(index);
      }
    }
    if (!hasErrors) {
      await fetchList();
      toast.success(
        <>
          <div className="Toastify__toast__title">Todos los usuarios invitados</div>
          <div className="Toastify__toast__subtitle">
            Hemos enviado una notificación a los usuarios con las instrucciones.
          </div>
        </>
      );
      closePanel();
    } else if (hasErrors !== response.length) {
      toast.info(
        <>
          <div className="Toastify__toast__title">Error al invitar a algunos usuarios</div>
          <div className="Toastify__toast__subtitle">
            Se han enviado {response.length - hasErrors} invitaciones y {hasErrors} han fallado.
          </div>
        </>
      );
    } else {
      toast.error(
        <>
          <div className="Toastify__toast__title">Error al invitar usuarios</div>
          <div className="Toastify__toast__subtitle">Por favor revise el formulario.</div>
        </>
      );
    }
    setIsFetching(false);
  };

  useEffect(() => {
    fetchUsers();
    initCenters();
    append({ type: "office" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="AddUser">
      <div className="AddUser__header">
        <IconButton onClick={closePanel}>
          <CloseIcon />
        </IconButton>
        <div className="AddUser__header__title">Añadir usuarios</div>
        <div className="AddUser__header__actions">
          <div className="AddUser__header__description">
            Completa los datos de los miembros de tu equipo para enviarles un email de invitación.
          </div>
          <div className="AddUser__header__submit">
            <Button onClick={handleSubmit(onSubmit)} disabled={!isValid || isFetching}>
              {t("invite")}
            </Button>
          </div>
        </div>
      </div>
      <div className="AddUser__body">
        <FormProvider {...methods}>
          <form className="" noValidate>
            {fields.map((item, index) => (
              <div key={item.id} className="AddUser__item">
                {fields.length > 1 && (
                  <div className="AddUser__item__close" onClick={() => handleRemoveRow(index)}>
                    <img src="/assets/icons/close.svg" alt="" />
                  </div>
                )}
                <div className="AddUser__item__row">
                  <FormSelect
                    label={t("center")}
                    name={`users[${index}].company.center`}
                    rules={{ required: true }}
                    defaultValue={user?.company?.center}
                    onSelectChange={v => setForceRender(!forceRender)}
                    errorobj={parseArrayError(
                      errors,
                      `users[${index}].company.center`,
                      "users",
                      index,
                      "company.center"
                    )}
                    options={centers}
                    disableEmpty={true}
                  />
                  <FormSelect
                    label={t("type")}
                    name={`users[${index}].type`}
                    rules={{ required: true }}
                    defaultValue={item.type}
                    onSelectChange={v => setForceRender(!forceRender)}
                    errorobj={parseArrayError(errors, `users[${index}].type`, "users", index, "type")}
                    options={[
                      { value: "office", label: t("office") },
                      { value: "admin", label: t("admin") },
                    ]}
                    disableEmpty={true}
                  />
                </div>
                <div className="AddUser__item__row">
                  <Input
                    label={t("name")}
                    name={`users[${index}].name`}
                    rules={{ required: true }}
                    handleBlur={() => setUserName(index)}
                    errorobj={parseArrayError(errors, `users[${index}].name`, "users", index, "name")}
                  />
                  <Input
                    label={t("surname")}
                    name={`users[${index}].surname`}
                    rules={{ required: true }}
                    errorobj={parseArrayError(errors, `users[${index}].surname`, "users", index, "surname")}
                  />
                </div>

                <div className="AddUser__item__row">
                  <Input
                    label={t("user")}
                    name={`users[${index}].username`}
                    rules={{
                      required: true,
                      validate: {
                        availability: value => checkAvailability("username", value, index) || t("repeated"),
                        lowerCase: value => value.toLowerCase() === value || "Solo se aceptan minúsculas",
                      },
                    }}
                    errorobj={parseArrayError(errors, `users[${index}].username`, "users", index, "username")}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">.{user.company.code.toLowerCase()}</InputAdornment>,
                    }}
                  />
                  <Input
                    label={t("nick")}
                    name={`users[${index}].nickname`}
                    rules={{
                      required: true,
                      validate: value => {
                        if (value.length > 5) return "No mas de 5 caracteres";
                        if (!checkAvailability("nickname", value, index)) return t("repeated");
                      },
                    }}
                    errorobj={parseArrayError(errors, `users[${index}].nickname`, "users", index, "nickname")}
                  />
                </div>
                <div>
                  {(showEmail(index) && (
                    <Input
                      label={t("email")}
                      name={`users[${index}].email`}
                      rules={{
                        required: true,
                        pattern: {
                          value: /^[A-Z0-9.!#$%&'*+-/=?^_`{|}~]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: "Formato no válido",
                        },
                        validate: value => checkAvailability("email", value, index) || t("repeated"),
                      }}
                      errorobj={parseArrayError(errors, `users[${index}].email`, "users", index, "email")}
                    />
                  )) || (
                      <PhoneInput
                        defaultCountry={user.company.region.toLowerCase()}
                        label={t("phone")}
                        preferredCountries={["es", "pt", "fr", "it", "de"]}
                        name={`users[${index}].phone`}
                        errorobj={parseArrayError(errors, `users[${index}].phone`, "users", index, "phone")}
                        rules={{
                          required: true,
                          validate: value => checkAvailability("phone", value, index) || t("repeated"),
                        }}
                      />
                    )}
                </div>
              </div>
            ))}
          </form>
        </FormProvider>

        <div className="AddUser__add">
          <Button
            variant="secondary"
            onClick={() => {
              const _values = getValues();
              append({ type: _values.users[_values.users.length - 1].type });
            }}
          >
            <img src="/assets/icons/add.svg" alt="" />
          </Button>
        </div>

      </div>
    </div>
  );
};

export default AddUser;
