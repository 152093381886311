export const SET_COMPANIES_LIST = 'SET_COMPANIES_LIST'
export const setCompaniesList = payload => ({
  type: SET_COMPANIES_LIST,
  payload
})

export const SET_COMPANY_DETAIL = 'SET_COMPANY_DETAIL'
export const setCompanyDetail = payload => ({
  type: SET_COMPANY_DETAIL,
  payload
})
