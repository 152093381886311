import { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";

import useAuth from "../../DataProviders/auth/useAuth";
import useProfiles from "../../DataProviders/profiles/useProfiles";
import useUi from "../../DataProviders/ui/useUi";

import { Button, List, SwitchChip } from "ui-components";

import Add from "./Add";
import Edit from "./Edit";

import "./styles.scss";

const Profiles = () => {
  const { t } = useTranslation();

  const getColumns = ({ t, onToggleActive, onEdit }) => {
    return [
      {
        id: "name",
        label: t("name"),
      },
      {
        id: "profileCode",
        label: t("code"),
        className: "hideable",
      },
      {
        id: "childProfile",
        label: t("childProfile"),
        className: "hideable",
        renderCell: value => (value ? t("yes") : t("no")),
      },
      {
        id: "labels",
        label: t("labels"),
        renderCell: (value, obj) => {
          return obj.labeledType === "all" ? t("all", { context: "female" }) : value.join(", ");
        },
      },
      {
        id: "active",
        label: t("active"),
        align: "center",
        renderCell: (value, obj) => <SwitchChip value={value} onChange={v => onToggleActive(v, obj._id)} />,
      },
      {
        width: 120,
        maxWidth: 120,
        align: "right",
        renderCell: (_, obj) => {
          return (
            <Button onClick={() => onEdit(obj._id)} variant="secondary" prefixIcon="edit">
              {t("edit")}
            </Button>
          );
        },
      },
    ];
  };

  const [isFetching, setIsFetching] = useState(false);
  const [isUserAdmin, setIsUserAdmin] = useState(false);
  const [editProfileId, setEditProfileId] = useState(null);
  const [showEdit, setShowEdit] = useState(false);

  const {
    data: { user },
  } = useAuth();

  const {
    data: {
      list: profiles,
      totalDocs,
      params: { page, limit },
    },
    methods: {
      initProfilesList,
      fetchList,
      unsetProfileDetail,
      toggleActiveProfile,
      handleChangePage,
      handleChangePageSize,
    },
  } = useProfiles();

  const {
    methods: { showPanel, closePanel },
  } = useUi();

  const onToggleActive = async (active, id) => {
    toggleActiveProfile(id, active);
  };

  const handleAddProfile = () => {
    showPanel(() => <Add />);
  };

  const handleEditProfile = _id => {
    setEditProfileId(_id);
    setShowEdit(true);
  };

  const handleCloseEdit = () => {
    setShowEdit(false);
    unsetProfileDetail();
    fetchList();
  };

  useEffect(() => {
    setIsFetching(true);
    fetchList();
    return () => {
      closePanel();
      setShowEdit(false);
      unsetProfileDetail();
      initProfilesList();
    };
  }, []);

  useEffect(() => {
    if (!user) return;
    if (user.type === "admin") setIsUserAdmin(true);
  }, [user]);

  useEffect(() => {
    if (!profiles) return;
    setIsFetching(false);
  }, [profiles]);

  return (
    <>
      <List
        t={t}
        id="Profiles"
        hide={!profiles || !isUserAdmin || showEdit}
        title={t("profiles-opt.listWithCount", { count: profiles.length || 0 })}
        headerActions={
          <div className="PageHeader__actions flex end v-center">
            <Button onClick={handleAddProfile} prefixIcon="add-white">
              {t("add")}
            </Button>
          </div>
        }
        totalDocs={totalDocs}
        list={[...profiles]}
        columns={getColumns({ t, onToggleActive, onEdit: handleEditProfile })}
        pageData={{ page, limit }}
        pageActions={{
          onChangePage: handleChangePage,
          onChangePageSize: handleChangePageSize,
          onDoubleClick: () => { },
        }}
        params={{}}
      />
      {showEdit && <Edit _id={editProfileId} onClose={handleCloseEdit} />}
    </>
  );
};

export default Profiles;
