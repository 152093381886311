import { useDispatch, useSelector } from 'react-redux'

import { setDocReviewsDetail, setDocReviewsList } from './actions'
import useFetch from '../../utils/hooks/useFetch'

const useDocReviews = () => {
  const endpoint = 'documents'
  const instance = useSelector(state => state.docReviews)
  const dispatch = useDispatch()
  const { get, post, put, patch } = useFetch()

  //LIST
  const fetchList = async params => {
    const data = await get({ endpoint: `${endpoint}/pending`, params })
    dispatch(setDocReviewsList({ ...data, search: params?.search }))
  }

  const handleChangePage = page => {
    fetchList({ ...instance.params, page })
  }

  const handleChangePageSize = limit => {
    fetchList({ ...instance.params, page: 1, limit })
  }

  const handleChangeSearch = search => {
    fetchList({ ...instance.params, page: 1, search })
  }

  //DETAIL
  const updateDocReviews = async (id, body) => {
    const response = await put({ endpoint, id, body })
    const listIndex = instance.list.findIndex(item => item._id === response._id)
    if (listIndex !== -1) {
      const newList = [...instance.list]
      newList[listIndex] = response
      dispatch(setDocReviewsList({ docs: newList, metadata: { totalDocs: instance.totalDocs } }))
    }
    return response
  }

  const toggleActiveDocReviews = async (id, active) => {
    const response = await patch({ endpoint, id, body: { active } })
    const listIndex = instance.list.findIndex(item => item._id === response._id)
    if (listIndex !== -1) {
      const newList = [...instance.list]
      newList[listIndex] = { ...newList[listIndex], active: response.active }
      dispatch(setDocReviewsList({ docs: newList }))
    }
    return response
  }

  const fetchAllDocReviews = async () => {
    return await get({ endpoint: 'documenttypes/all' })
  }

  const addDocReviews = async body => {
    return await post({ endpoint, body })
  }

  const fetchDocReviewsDetail = async id => {
    const response = await get({ endpoint: `${endpoint}/${id}` })
    dispatch(setDocReviewsDetail(response))
  }

  const unsetDocReviewsDetail = () => {
    dispatch(setDocReviewsDetail(null))
  }

  const getDocReviewsCategories = async () => {
    return await get({ endpoint: `${endpoint}/attachmentTypes` })
  }

  const approveDoc = async (id, body) => {
    const response = await patch({ endpoint: `${endpoint}/validate`, id, body })
  }

  const rejectDoc = async (id, body) => {
    const response = await patch({ endpoint: `${endpoint}/reject`, id, body })
  }

  return {
    methods: {
      fetchList,
      handleChangePage,
      handleChangePageSize,
      handleChangeSearch,
      toggleActiveDocReviews,
      fetchAllDocReviews,
      updateDocReviews,
      addDocReviews,
      fetchDocReviewsDetail,
      setDocReviewsDetail: data => dispatch(setDocReviewsDetail(data)),
      unsetDocReviewsDetail,
      getDocReviewsCategories,
      approveDoc,
      rejectDoc
    },
    data: instance
  }
}

export default useDocReviews
