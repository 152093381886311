import { useState, useRef, useEffect } from "react";

import { useTranslation } from "react-i18next";

import { useForm, FormProvider } from "react-hook-form";
import { toast } from "react-toastify";

import { Button, Input, Select as FormSelect } from "ui-components";
import useUi from "../../../DataProviders/ui/useUi";
import Documents from "../../../containers/Documents";
import useLabeled from "../../../DataProviders/labeled/useLabeled";

import "./styles.scss";

const EditLabeled = () => {
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState("summary");
  const [isFetching, setIsFetching] = useState(false);
  const [categories, setCategories] = useState([]);

  const {
    methods: { closePanel },
  } = useUi();
  const {
    data: { detail },
    methods: { updateLabeled },
  } = useLabeled();

  const methods = useForm({
    mode: "onChange",
    defaultValues: detail,
  });
  const { handleSubmit, errors, formState, setValue, trigger } = methods;
  const { isValid } = formState;

  const onSubmit = async data => {
    setIsFetching(true);
    try {
      const response = await updateLabeled(detail._id, {
        ...detail,
        ...data,
      });
      toast.success(
        <>
          <div className="Toastify__toast__title">Rotulación actualizado</div>
          <div className="Toastify__toast__subtitle">Hemos actualizado la información de la rotulación.</div>
        </>
      );
      closePanel();
    } catch (e) {
      toast.error(
        <>
          <div className="Toastify__toast__title">{t("anErrorHasOccurred")}</div>
          <div className="Toastify__toast__subtitle">No hemos podido actualizar la información de la rotulación.</div>
        </>
      );
    }
    setIsFetching(false);
  };

  return (
    <div className="EditLabeled">
      <FormProvider {...methods}>
        <div className="EditLabeled__header flex">
          <div className="EditLabeled__header__info">
            <div className="EditLabeled__header__personal flex">
              <div className="EditLabeled__header__name">{detail.name}</div>
            </div>
            <div className="EditLabeled__header__misc flex v-center capitalize">
              {detail.active ? "activo" : "no activo"}
            </div>
            <div className="EditLabeled__header__actions flex end">
              <Button onClick={handleSubmit(onSubmit)} disabled={!isValid || isFetching}>
                {t("save")}
              </Button>
            </div>
          </div>
        </div>
        <div className="EditLabeled__body">
          <div className="EditLabeled__main">
            <div className="EditLabeled__tabs flex">
              <div
                className={`EditLabeled__tabs__item ${activeTab === "summary" ? "active" : ""}`}
                onClick={() => setActiveTab("summary")}
              >
                RESUMEN
              </div>
              <div className={`EditLabeled__tabs__item ${activeTab === "docs" ? "active" : ""}`} onClick={() => { }}>
                DOCUMENTOS
              </div>
            </div>
            {activeTab === "summary" ? (
              <div className="EditLabeled__summary">
                <div className="EditLabeled__summary__section">
                  <div className="EditLabeled__summary__title">INFORMACIÓN GENERAL</div>
                  <div className="EditLabeled__summary__form">
                    <div className="EditLabeled__summary__form__row">
                      <Input label={t("name")} name="name" required={true} rules={{ required: true }} errorobj={errors} />
                    </div>
                    <div className="EditLabeled__summary__form__row">
                      <Input
                        label={t("description")}
                        name="description"
                        required={true}
                        rules={{
                          required: true,
                        }}
                        errorobj={errors}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <Documents
                documentTypes={detail.documentation?.documentTypes || []}
                referenceId={detail._id}
                reference="deposits"
              />
            )}
          </div>
        </div>
      </FormProvider>
    </div>
  );
};

export default EditLabeled;
