import errors from "../../config/errors";

import { useTranslation } from "react-i18next";

const useErrors = () => {
  const { t } = useTranslation();

  const getError = error => {
    if (errors[error]) {
      if (errors[error].hasOwnProperty("title")) {
        return {
          title: t(errors[error].title),
          text: t(errors[error].text) + "."
        }
      } else {
        return {
          text: t(errors[error].text) + "."
        }
      };
    } else {
      return {
        title: t(errors["default"].title),
        text: t(errors["default"].text) + "."
      }
    }
  };

  return {
    getError,
  };
};

export default useErrors;
