import React, { useState, useEffect, useRef } from "react";

import { useTranslation } from "react-i18next";

import { useForm, FormProvider } from 'react-hook-form'

import useAuth from "../../DataProviders/auth/useAuth";
import usePayments from "../../DataProviders/payments/usePayments";

import { lightFormat, format } from "date-fns";
import DateInput from "../../components/DateInput";

import { List, Button, ModalWindow } from "ui-components";

import { parseCurrency } from "ui-components";

import { toastNotify } from "../../components/ToastNotify";

import "react-datepicker/dist/react-datepicker.css";
import "./styles.scss";

const Payments = () => {
  const { t } = useTranslation();

  const [isUserAdmin, setIsUserAdmin] = useState(false);
  const [showPayModal, setShowPayModal] = useState(false);
  const [showProcessModal, setShowProcessModal] = useState(false);
  const [paymentId, setPaymentId] = useState(null);
  const [payDate, setPayDate] = useState(lightFormat(new Date(), "dd/MM/yy"));
  const [processDate, setProcessDate] = useState(lightFormat(new Date(), "dd/MM/yy"));
  const [isFetching, setIsFetching] = useState(false);

  const methods = useForm({
    mode: "onChange",
  });

  const { handleSubmit, errors, formState, watch, trigger, control, setValue, getValues, setError } = methods;
  const { isValid } = formState;

  const {
    data: { user },
  } = useAuth();

  const currency = user.company.localization.currency;

  const {
    data: {
      totalDocs,
      list: payments,
      params: { page, limit },
    },
    methods: {
      initPaymentsList,
      fetchList,
      handleChangePage,
      handleChangePageSize,
      generatePayments,
      processPayment,
    },
  } = usePayments();

  const getColumns = ({ t, onProcess }) => {
    return [
      {
        id: "date",
        label: t("deadLine"),
        renderCell: value => lightFormat(new Date(value), "dd/MM/yyyy"),
      },
      {
        id: "status",
        label: t("status"),
        renderCell: value => <span className={`status__${value}`}>{t(value)}</span>,
      },
      {
        id: "status",
        label: t("processed"),
        align: "center",
        renderCell: (value, obj) => {
          if (value === "pending") {
            return (
              <Button onClick={() => onProcess(obj._id)} variant="secondary">
                {t("process")}
              </Button>
            );
          }
          if (value === "processed") {
            return lightFormat(new Date(obj.processDate), "dd/MM/yyyy");
          }
        },
      },

      {
        id: "invoices",
        label: t("nInvoices"),
        align: "center",
        renderCell: value => <span>{value.toLocaleString('de-DE')}</span>,
      },
      {
        id: "payments",
        label: t("nPayments"),
        align: "center",
        renderCell: value => <span>{value ? value.toLocaleString('de-DE') : ""}</span>,
      },
      {
        id: "amount",
        label: t("amount"),
        align: "right",
        renderCell: value => <span>{value ? parseCurrency(value, currency) : ""}</span>,
      },
      {
        id: "attachments",
        label: t("files"),
        align: "right",
        renderCell: value => {
          return (
            <div className="ShipmentDetail__box__body__text">
              <div className="download-file-wrapper">
                <a href={`${value[0].url}`} target="_blank">
                  <img src="assets/icons/xlsFileIcon.svg" alt="" />
                  <span>{t("payments")}</span>
                </a>
              </div>
            </div>
          )
        }
      },
    ];
  };

  const handleShowPayModal = () => {
    setShowPayModal(true);
  };

  const handleShowProcessModal = id => {
    setPaymentId(id);
    setShowProcessModal(true);
  };

  const handleGeneratePayments = async values => {
    const requestBody = { paymentLimit: values.paymentLimit };
    setShowPayModal(false);
    setIsFetching(true);
    try {
      await generatePayments(requestBody);
      await fetchList();
      toastNotify({
        t,
        type: "success",
        title: t("paymentList.toastGeneratePaymentsSuccessTitle"),
        text: t("paymentList.toastGeneratePaymentsSuccessText"),
      });
    } catch (error) {
      if (error.code === "invoiceNotFound") {
        toastNotify({
          t,
          type: "error",
          title: t("paymentList.toastGeneratePaymentsWarningTitle"),
          text: t("paymentList.toastGeneratePaymentsWarningText"),
        });
      } else {
        toastNotify({ t, type: "error" });
      }
    }
    setIsFetching(false);
  }

  const handleProcessPayment = async values => {
    const id = paymentId;
    setPaymentId(null);
    const requestBody = { processDate: values.processDate };
    setShowProcessModal(false);
    setIsFetching(true);
    try {
      await processPayment(id, requestBody);
      await fetchList();
      toastNotify({
        t,
        type: "success",
        title: t("paymentList.toastProcessPaymentSuccessTitle"),
        text: t("paymentList.toastProcessPaymentSuccessText"),
      });
    } catch (error) {
      toastNotify({ t, type: "error" });
    }
    setIsFetching(false);
  }

  useEffect(() => {
    setIsFetching(true);
    fetchList();
    return () => {
      setShowPayModal(false);
      initPaymentsList();
    };
  }, []);

  useEffect(() => {
    if (!user) return;
    if (user.type === "admin") setIsUserAdmin(true);
  }, [user]);

  useEffect(() => {
    if (!payments) return;
    setIsFetching(false);
  }, [payments]);

  return (
    <>
      <List
        t={t}
        id="PaymentsToProviders"
        hide={!payments || !isUserAdmin}
        title={t("paymentsToProviders-opt.listWithCount", { count: totalDocs || 0 })}
        headerActions={
          <div className="PageHeader__actions flex end v-center">
            <Button onClick={handleShowPayModal} prefixIcon="add-white">
              {t("generate")}
            </Button>
          </div>
        }
        totalDocs={totalDocs}
        list={[...payments]}
        columns={getColumns({ t, onProcess: handleShowProcessModal })}
        pageData={{ page, limit }}
        pageActions={{
          onChangePage: handleChangePage,
          onChangePageSize: handleChangePageSize,
          onDoubleClick: (() => { }),
        }}
        params={{}}
      />

      {showPayModal && (
        <FormProvider {...methods}>
          <form className="GeneratePaymentsForm" noValidate>
            <ModalWindow
              title={t("paymentList.generatePaymentsTitle")}
              text={t("paymentList.generatePaymentsText")}
              acceptLabel={t("accept")}
              cancelLabel={t("cancel")}
              modalWidth={403}
              handleCancel={() => {
                setShowPayModal(false);
              }}
              handleAccept={handleSubmit(handleGeneratePayments)}
            >
              <DateInput
                label={t("deadLine")}
                name="paymentLimit"
                defaultValue={new Date()}
              />
            </ModalWindow>
          </form>
        </FormProvider>
      )}

      {showProcessModal && (
        <FormProvider {...methods}>
          <form className="ProcessPaymentForm" noValidate>
            <ModalWindow
              title={t("paymentList.processPaymentTitle")}
              text={t("paymentList.processPaymentText")}
              acceptLabel={t("accept")}
              cancelLabel={t("cancel")}
              modalWidth={403}
              handleCancel={() => {
                setShowProcessModal(false);
              }}
              handleAccept={handleSubmit(handleProcessPayment)}
            >
              <DateInput
                label={t("processDate")}
                name="processDate"
                defaultValue={new Date()}
              />
            </ModalWindow>
          </form>
        </FormProvider>
      )}

    </>
  );
};

export default Payments;
