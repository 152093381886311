export const SET_LABELED_LIST = 'SET_LABELED_LIST'
export const setLabeledList = payload => ({
  type: SET_LABELED_LIST,
  payload
})

export const SET_LABELED_DETAIL = 'SET_LABELED_DETAIL'
export const setLabeledDetail = payload => ({
  type: SET_LABELED_DETAIL,
  payload
})
