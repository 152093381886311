import { useDispatch, useSelector } from 'react-redux'

import { setVehicleTypeDetail, setVehicleTypesList } from './actions'
import useFetch from '../../utils/hooks/useFetch'

const useVehicleTypes = () => {
  const endpoint = 'vehicleTypes'
  const instance = useSelector((state) => state.vehicleTypes)
  const dispatch = useDispatch()
  const { get, post, put, patch } = useFetch()

  //LIST
  const fetchList = async (params) => {
    const data = await get({ endpoint, params })
    dispatch(setVehicleTypesList({ ...data, search: params?.search }))
  }

  const handleChangePage = (page) => {
    fetchList({ ...instance.params, page })
  }

  const handleChangePageSize = (limit) => {
    fetchList({ ...instance.params, page: 1, limit })
  }

  const handleChangeSearch = (search) => {
    fetchList({ ...instance.params, page: 1, search })
  }

  //DETAIL
  const updateVehicleType = async (id, body) => {
    const response = await put({ endpoint, id, body })
    const listIndex = instance.list.findIndex((item) => item._id === response._id)
    if (listIndex !== -1) {
      const newList = [...instance.list]
      newList[listIndex] = response
      dispatch(setVehicleTypesList({ docs: newList, metadata: { totalDocs: instance.totalDocs } }))
    }
    return response
  }

  const toggleActiveVehicleType = async (id, active) => {
    const response = await patch({ endpoint, id, body: { active } })
    const listIndex = instance.list.findIndex((item) => item._id === response._id)
    if (listIndex !== -1) {
      const newList = [...instance.list]
      newList[listIndex] = { ...newList[listIndex], active: response.active }
      dispatch(setVehicleTypesList({ docs: newList }))
    }
    return response
  }

  const fetchAllVehicleTypes = async () => {
    return await get({ endpoint: 'vehicleTypes/all' })
  }

  const addVehicleType = async (body) => {
    return await post({ endpoint, body })
  }

  const fetchVehicleTypeDetail = async (id) => {
    const response = await get({ endpoint: `${endpoint}/${id}` })
    dispatch(setVehicleTypeDetail(response))
  }

  const unsetVehicleTypeDetail = () => {
    dispatch(setVehicleTypeDetail(null))
  }

  return {
    methods: {
      fetchList,
      handleChangePage,
      handleChangePageSize,
      handleChangeSearch,
      toggleActiveVehicleType,
      fetchAllVehicleTypes,
      updateVehicleType,
      addVehicleType,
      fetchVehicleTypeDetail,
      setVehicleTypeDetail: (data) => dispatch(setVehicleTypeDetail(data)),
      unsetVehicleTypeDetail,
    },
    data: instance,
  }
}

export default useVehicleTypes
