import { useDispatch, useSelector } from "react-redux";

import { setAuxServiceTypeDetail, setAuxServiceTypesList } from "./actions";
import useFetch from "../../utils/hooks/useFetch";

const useAuxServiceTypes = () => {
  const endpoint = "auxServiceTypes";
  const instance = useSelector(state => state.auxServiceTypes);
  const dispatch = useDispatch();
  const { get, post, put, patch } = useFetch();

  //LIST
  const fetchList = async params => {
    const data = await get({ endpoint, params });
    dispatch(setAuxServiceTypesList({ ...data, search: params?.search }));
  };

  const handleChangePage = page => {
    fetchList({ ...instance.params, page });
  };

  const handleChangePageSize = limit => {
    fetchList({ ...instance.params, page: 1, limit });
  };

  const handleChangeSearch = search => {
    fetchList({ ...instance.params, page: 1, search });
  };

  //DETAIL
  const updateAuxServiceType = async (id, body) => {
    const response = await put({ endpoint, id, body });
    const listIndex = instance.list.findIndex(item => item._id === response._id);
    if (listIndex !== -1) {
      const newList = [...instance.list];
      newList[listIndex] = response;
      dispatch(setAuxServiceTypesList({ docs: newList, metadata: { totalDocs: instance.totalDocs } }));
    }
    return response;
  };

  const toggleActiveAuxServiceType = async (id, active) => {
    const response = await patch({ endpoint, id, body: { active } });
    const listIndex = instance.list.findIndex(item => item._id === response._id);
    if (listIndex !== -1) {
      const newList = [...instance.list];
      newList[listIndex] = { ...newList[listIndex], active: response.active };
      dispatch(setAuxServiceTypesList({ docs: newList }));
    }
    return response;
  };

  const fetchAllAuxServiceTypes = async () => {
    return await get({ endpoint: "auxServiceTypes/all" });
  };

  const addAuxServiceType = async body => {
    return await post({ endpoint, body });
  };

  const fetchAuxServiceTypeDetail = async id => {
    const response = await get({ endpoint: `${endpoint}/${id}` });
    dispatch(setAuxServiceTypeDetail(response));
  };

  const unsetAuxServiceTypeDetail = () => {
    dispatch(setAuxServiceTypeDetail(null));
  };

  return {
    methods: {
      fetchList,
      handleChangePage,
      handleChangePageSize,
      handleChangeSearch,
      toggleActiveAuxServiceType,
      fetchAllAuxServiceTypes,
      updateAuxServiceType,
      addAuxServiceType,
      fetchAuxServiceTypeDetail,
      setAuxServiceTypeDetail: data => dispatch(setAuxServiceTypeDetail(data)),
      unsetAuxServiceTypeDetail,
    },
    data: instance,
  };
};

export default useAuxServiceTypes;
