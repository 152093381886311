import { useState, useEffect, useRef } from "react";

import { useTranslation } from "react-i18next";

import { useForm, FormProvider, useFieldArray } from "react-hook-form";

import useAuth from "../../../DataProviders/auth/useAuth";
import useCompanies from "../../../DataProviders/companies/useCompanies";
import useDocProfile from "../../../DataProviders/docProfiles/useDocProfiles";
import useProfiles from "../../../DataProviders/profiles/useProfiles";
import useOutlays from "../../../DataProviders/outlays/useOutlays";
import useUi from "../../../DataProviders/ui/useUi";

import FormSelect from "../../../components/Select";
import { Input } from "ui-components";
import { Button, Input as InputNumber, PlacesInput, PhoneInput } from "ui-components";

import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import { toast } from "react-toastify";

import "./styles.scss";

const AddCompany = () => {
  const { t } = useTranslation();

  const {
    data: { user },
  } = useAuth();

  const countries = user.company.localization.maps.autosuggestionCountries;

  const {
    methods: { fetchList, addCompany },
  } = useCompanies();

  const {
    methods: { fetchAllDocProfile },
  } = useDocProfile();

  const {
    methods: { fetchAllProfiles },
  } = useProfiles();

  const {
    methods: { fetchAllOutlays },
  } = useOutlays();

  const {
    methods: { closePanel },
  } = useUi();

  const methods = useForm({
    mode: "onChange",
  });

  const { handleSubmit, errors, formState, watch, trigger, control, setValue } = methods;
  const { isValid } = formState;

  const watchCompanyType = watch("type", false);
  const watchCompanyOutlays = watch("outlays", false);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "outlays",
  });

  const addressRef = useRef(null);

  const [isFetching, setIsFetching] = useState();

  const [docProfiles, setDocProfiles] = useState([]);
  const [profiles, setProfiles] = useState([]);
  const [outlays, setOutlays] = useState([]);

  const handleRemoveRow = index => {
    remove(index);
  };

  const findInGoogleAddress = (place, type) => {
    const component = place.address_components.find(item => item.types[0] === type);
    return component ? component.long_name : "";
  };

  const handleChangeAddress = (_, place) => {
    const component = (place?.address_components || []).find(item => item.types[0] === "postal_code");
    const zip = component ? component.long_name : null;
    if (!place) {
      //|| !zip
      addressRef.current = null;
      setValue("address", null);
      trigger("address");
      return;
    }
    const _address = {
      fullAddress: place.formatted_address,
      streetNumber: findInGoogleAddress(place, "street_number"),
      street: findInGoogleAddress(place, "route"),
      city: findInGoogleAddress(place, "locality"),
      province: findInGoogleAddress(place, "administrative_area_level_2"),
      country: findInGoogleAddress(place, "country"),
      zip: findInGoogleAddress(place, "postal_code"),
      coordinates: [place.geometry.location.lat(), place.geometry.location.lng()],
    };
    addressRef.current = _address;
    setValue("address", _address.fullAddress);
    trigger("address");
  };

  const fetchDocProfiles = async () => {
    const response = await fetchAllDocProfile(true);
    const _types = response.map(item => ({ value: item._id, label: item.name }));
    setDocProfiles(_types);
  };

  const fetchProfiles = async () => {
    const response = await fetchAllProfiles(true);
    const _types = response.map(item => ({ value: item._id, label: item.name }));
    setProfiles(_types);
  };

  const fetchOutlays = async () => {
    const response = await fetchAllOutlays(true);
    setOutlays(response);
  };

  useEffect(() => {
    fetchProfiles();
    fetchDocProfiles();
    fetchOutlays();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (watchCompanyType === "provider") remove();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchCompanyType]);

  const onSubmit = async data => {
    setIsFetching(true);
    delete data.address;
    data.address = addressRef.current;

    if (data.type === "customer" && data.outlays?.length > 0) {
      const extraCharges = data.outlays.map(outlay => {
        if (outlay.amount) {
          return { _id: outlay._id, amount: parseFloat(outlay.amount.replace(/,/g, ".")) };
        } else {
          return { _id: outlay._id };
        }
      });
      delete data.outlays;
      data.customerData = { ...data.customerData, extraCharges };
    }

    try {
      const response = await addCompany(data);

      await fetchList();
      toast.success(
        <>
          <div className="Toastify__toast__title">Compañía creada correctamente</div>
          <div className="Toastify__toast__subtitle">La compañía ha sido creada y añadida a la lista.</div>
        </>
      );
      closePanel();
    } catch (e) {
      if (e.code === "dataDuplicated") {
        toast.error(
          <>
            <div className="Toastify__toast__title">Error al crear compañía</div>
            <div className="Toastify__toast__subtitle">{`El ${
              e.sourceError.startsWith("code") ? "código" : "NIF/CIF"
            } introducido ya existe.`}</div>
          </>
        );
      } else {
        toast.error(
          <>
            <div className="Toastify__toast__title">Error al crear compañía</div>
            <div className="Toastify__toast__subtitle">Ha ocurrido un error en la creación de la compañía.</div>
          </>
        );
      }
    }
    setIsFetching(false);
  };

  return (
    <div className="Add">
      <div className="Add__header">
        <IconButton onClick={closePanel}>
          <CloseIcon />
        </IconButton>
        <div className="Add__header__title">{t("add_company")}</div>
        <div className="Add__header__actions">
          <div className="Add__header__description">
            {t("add_company_description")}
            <br />
            {t("fieldsRequired")}
          </div>
          <div className="Add__header__submit">
            <Button onClick={handleSubmit(onSubmit)} disabled={!isValid || isFetching}>
              {t("add")}
            </Button>
          </div>
        </div>
      </div>

      <div className="Add__body">
        <FormProvider {...methods}>
          <form className="" noValidate>
            <div className="Add__item">
              <div className="AddCompany__title">{t("company").toUpperCase()}</div>

              <div className="Add__item__row">
                <FormSelect
                  label={t("type")}
                  name={"type"}
                  required={true}
                  rules={{ required: true }}
                  errorobj={errors}
                  options={[
                    { value: "customer", label: t("customer") },
                    { value: "provider", label: t("provider") },
                  ]}
                  disableEmpty={true}
                />
                <Input
                  label={t("code")}
                  name="code"
                  required={true}
                  rules={{
                    required: true,
                  }}
                  errorobj={errors}
                />
              </div>

              <div className="Add__item__row">
                <Input
                  label={t("legalId")}
                  name="legalId"
                  required={true}
                  rules={{
                    required: true,
                  }}
                  errorobj={errors}
                />
                <Input
                  label={t("groupName")}
                  name="groupName"
                  required={true}
                  rules={{
                    required: true,
                  }}
                  errorobj={errors}
                />
              </div>

              <div className="Add__item__row">
                <PlacesInput
                  //countries={countries}
                  label={t("address")}
                  name="address"
                  required={true}
                  rules={{
                    required: true,
                  }}
                  onChange={handleChangeAddress}
                  errorobj={errors}
                />
              </div>

              <div className="Add__item__row documentationProfile">
                {(watchCompanyType === "customer" && (
                  <FormSelect label={t("profile")} name="customerData.profile" options={profiles} disableEmpty={true} />
                )) || (watchCompanyType === "provider" && (
                  <FormSelect
                    label={t("providerTypeVial")}
                    name="providerData.providerType"
                    options={[
                      { value: "automatic", label: t("automatic") },
                      { value: "managed", label: t("managed") },
                      { value: "manual", label: t("manual") },
                    ]}
                    disableEmpty={true}
                  />
                )) ||
                  <div></div>
                }
                <FormSelect
                  id="documentationProfile"
                  label={t("documentationProfile")}
                  name="documentation.profile"
                  options={docProfiles}
                  required={false}
                />
              </div>

              {(watchCompanyType === "provider" && (
                <div className="Add__item__row documentationProfile">
                  <FormSelect
                    label={t("providerTypeAux")}
                    name="providerData.auxProviderType"
                    options={[
                      { value: "automatic", label: t("automatic") },
                      { value: "managed", label: t("managed") },
                      { value: "manual", label: t("manual") },
                    ]}
                    disableEmpty={true}
                  />
                  <div></div>
                </div>
              )) ||
                false}

              <div className="Add__divider"></div>

              <div className="AddCompany__title">CONTACTO</div>

              <div className="Add__item__row">
                <Input
                  label={t("name")}
                  name="mainContact.name"
                  required={true}
                  rules={{ required: true }}
                  errorobj={errors}
                />
                <Input
                  label={t("surname")}
                  name="mainContact.surname"
                  required={true}
                  rules={{ required: true }}
                  errorobj={errors}
                />
              </div>

              <div className="Add__item__row">
                <PhoneInput
                  defaultCountry="es"
                  label={t("phone")}
                  name="mainContact.phone"
                  required={true}
                  rules={{ required: true }}
                  errorobj={errors?.mainContact?.phone && { "mainContact.phone": errors?.mainContact?.phone }}
                />
                <Input
                  label={t("email")}
                  name="mainContact.email"
                  rules={{
                    required: true,
                    pattern: {
                      value: /^[A-Z0-9.!#$%&'*+-/=?^_`{|}~]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Formato no válido",
                    },
                  }}
                  errorobj={errors?.mainContact?.email && { "mainContact.email": errors?.mainContact?.email }}
                />
              </div>

              {watchCompanyType === "customer" && (
                <>
                  <div className="Add__divider"></div>
                  <div className="AddCompany__title">{t("EXTRA_CHARGES")}</div>
                </>
              )}

              {watchCompanyType === "customer" &&
                fields.map((item, index) => (
                  <div key={item.id} className="Add__item plus">
                    {fields.length > 0 && index === fields.length - 1 && (
                      <div className="Add__item__close" onClick={() => handleRemoveRow(index)}>
                        <img src="/assets/icons/close.svg" />
                      </div>
                    )}

                    <div className="Add__item__row">
                      <FormSelect
                        label={t("outlays")}
                        name={`outlays.[${index}]._id`}
                        options={outlays.map(item => ({ value: item._id, label: item.name }))}
                        required={true}
                        rules={{ required: true }}
                        disableEmpty={true}
                      />
                      {(watchCompanyOutlays[index]?._id &&
                        outlays.find(outlay => outlay._id === watchCompanyOutlays[index]?._id).chargeType ===
                          "expense" && (
                          <InputNumber
                            label={t("amount")}
                            name={`outlays.[${index}].amount`}
                            required
                            rules={{
                              required: t("validation.invalidCurrencyAmount"),
                              pattern: {
                                value: /^[0-9]+(\,[0-9]{1,2})?$/,
                                message: t("validation.invalidCurrencyAmount"),
                              },
                            }}
                            errorobj={errors}
                          />
                        )) || <div />}
                    </div>

                    <div className="Add__divider"></div>
                  </div>
                ))}
            </div>
          </form>

          {watchCompanyType === "customer" && (
            <div className="Add__add-btn">
              <Button variant="secondary" onClick={() => append({})}>
                <img src="./assets/icons/add.svg" />
              </Button>
            </div>
          )}
        </FormProvider>
      </div>
    </div>
  );
};

export default AddCompany;
