export const SET_VTYPE_LIST = 'SET_VTYPE_LIST'
export const setVehicleTypesList = payload => ({
  type: SET_VTYPE_LIST,
  payload
})

export const SET_VTYPE_DETAIL = 'SET_VTYPE_DETAIL'
export const setVehicleTypeDetail = payload => ({
  type: SET_VTYPE_DETAIL,
  payload
})
