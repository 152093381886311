export const SET_DOCPROFILE_LIST = 'SET_DOCPROFILE_LIST'
export const setDocProfileList = payload => ({
  type: SET_DOCPROFILE_LIST,
  payload
})

export const SET_DOCPROFILE_DETAIL = 'SET_DOCPROFILE_DETAIL'
export const setDocProfileDetail = payload => ({
  type: SET_DOCPROFILE_DETAIL,
  payload
})
