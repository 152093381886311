import { useState, useEffect, useRef } from "react";

import useRouter from "../../utils/hooks/useRouter";

import { useTranslation } from "react-i18next";

import { useForm, FormProvider } from "react-hook-form";

import useAuth from "../../DataProviders/auth/useAuth";
import useUi from "../../DataProviders/ui/useUi";
import useUsers from "../../DataProviders/users/useUsers";

import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { NavLink as Link } from "react-router-dom";

import { Select as FormSelect } from "ui-components";

import Notifications from "./Notifications";
import ReactTooltip from "react-tooltip";

import "./styles.scss";

const isProd = window.location.origin.includes("//admin.nexus-365.com");

let initialGroupVisibility;
let initialGroupActivation;

const usePrev = value => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

const Menu = ({ status = "expanded", viewportWidth }) => {
  const { t } = useTranslation();

  const { history } = useRouter();

  let initialMenuOptions = [
    {
      title: t("dashboard"),
      images: {
        default: "menu-dashboard",
        active: "menu-dashboard-active",
      },
      route: "/",
    },
    {
      title: t("businessIntelligence"),
      images: {
        default: "menu-bi",
        active: "menu-bi-active",
      },
      route: "/bi",
    },
    {
      title: t("users"),
      images: {
        default: "menu-users",
        active: "menu-users-active",
      },
      route: "/users",
      restricted: ["admin", "nexus"],
    },
    {
      title: t("profiles"),
      images: {
        default: "menu-profiles",
        active: "menu-profiles-active",
      },
      route: "/profiles",
      restricted: ["admin", "nexus"],
    },
    {
      title: t("companies"),
      images: {
        default: "menu-companies",
        active: "menu-companies-active",
      },
      route: "/companies",
      restricted: ["admin", "nexus"],
    },

    // Configuration
    {
      id: "configuration",
      groupTitle: t("configuration"),
      images: {
        default: "menu-configuration",
        active: "menu-configuration-active",
      },
      restricted: ["admin", "nexus"],
      groupOptions: [
        {
          title: t("holidays"),
          images: {
            default: "menu-holidays",
            active: "menu-holidays-active",
          },
          route: "/calendar",
          restricted: ["admin", "nexus"],
        },
        {
          title: t("mediumTypes"),
          images: {
            default: "menu-mediumtype",
            active: "menu-mediumtype-active",
          },
          route: "/medium-types",
          restricted: ["admin", "nexus"],
        },
        {
          title: t("operationTypes"),
          images: {
            default: "menu-operationtype",
            active: "menu-operationtype-active",
          },
          route: "/operation-types",
          restricted: ["admin", "nexus"],
        },
        {
          title: t("auxServices"),
          images: {
            default: "menu-auxservices",
            active: "menu-auxservices-active",
          },
          route: "/aux-service-types",
          restricted: ["admin", "nexus"],
        },
        {
          title: t("vehicleTypes"),
          images: {
            default: "menu-vehicletype",
            active: "menu-vehicletype-active",
          },
          route: "/vehicle-types",
          restricted: ["admin", "nexus"],
        },
        {
          title: t("labeled"),
          images: {
            default: "menu-labeled",
            active: "menu-labeled-active",
          },
          route: "/labeled",
          restricted: ["admin", "nexus"],
        },
        {
          title: t("rejectionReasons"),
          images: {
            default: "menu-rejectionreasons",
            active: "menu-rejectionreasons-active",
          },
          route: "/rejections",
          restricted: ["admin", "nexus"],
        },
        {
          title: t("outlays"),
          images: {
            default: "menu-outlays",
            active: "menu-outlays-active",
          },
          route: "/outlays",
          restricted: ["admin", "nexus"],
        },
      ]
    },

    // Financial
    {
      id: "financial",
      groupTitle: t("financial"),
      images: {
        default: "menu-financial",
        active: "menu-financial-active",
      },
      restricted: ["admin", "nexus"],
      groupOptions: [
        {
          title: t("shipments"),
          images: {
            default: "menu-shipments",
            active: "menu-shipments-active",
          },
          route: "/shipments",
          restricted: ["admin", "nexus"],
        },
        {
          title: t("paymentsToProviders"),
          images: {
            default: "menu-paytoproviders",
            active: "menu-paytoproviders-active",
          },
          route: "/payments",
          restricted: ["admin", "nexus"],
        },
      ]
    },

    // Documentation
    {
      id: "documentation",
      groupTitle: t("documentation"),
      images: {
        default: "menu-doc",
        active: "menu-doc-active",
      },
      restricted: ["admin", "nexus"],
      groupOptions: [
        {
          title: t("documentTypes"),
          images: {
            default: "menu-doctype",
            active: "menu-doctype-active",
          },
          route: "/doc-types",
          restricted: ["admin", "nexus"],
        },
        {
          title: t("documentProfiles"),
          images: {
            default: "menu-docprofile",
            active: "menu-docprofile-active",
          },
          route: "/doc-profiles",
          restricted: ["admin", "nexus"],
        },
        {
          title: t("pendingDocuments"),
          images: {
            default: "menu-docreview",
            active: "menu-docreview-active",
          },
          route: "/doc-reviews",
          restricted: ["admin", "nexus"],
        },
      ]
    }
  ];

  const [regions, setRegions] = useState([]);
  const [regionsCollapsed, setRegionsCollapsed] = useState([]);
  const [showLang, setShowLang] = useState(false);

  const [groupVisibility, setGroupVisibility] = useState([]);
  const [groupActivation, setGroupActivation] = useState([]);

  const [menuCollapsed, setMenuCollapsed] = useState(status === "collapsed");

  const [isMyTicketsActive, setIsMyTicketsActive] = useState(window.location.pathname === "/tickets");
  const {
    data: { user },
    methods: { getAvailableRegions, setAuthUser, logout },
  } = useAuth();

  const prevUserRegion = usePrev(user?.company?.region);

  const {
    data: { menuWrapperBack },
  } = useUi();

  const {
    methods: { setUserDetail },
  } = useUsers();

  const methods = useForm({
    mode: "onChange",
  });

  const { watch } = methods;

  const selectedRegion = watch("region");


  let menuOptions = initialMenuOptions;

  if (user.supportCenterUser) {
    menuOptions = [...initialMenuOptions, {
      title: t("supportCenter"),
      images: {
        default: "menu-support",
        active: "menu-support-active",
      },
      route: "/support",
    }];
  }

  const isAvailable = item => {
    let isOk = true;
    if (item.restricted && !item.restricted.includes(user.type)) {
      isOk = false;
    }
    return isOk;
  };

  const toggleGroupVisibility = group => {

    const visible = groupVisibility[group];
    if (!visible) setGroupVisibility({ ...groupVisibility, [group]: true });
    else setGroupVisibility({ ...groupVisibility, [group]: false });

    if (visible) {
      const currentLocationPath = window.location.pathname;
      const currentGroup = menuOptions.find(item => item.id === group);
      const optionRoutes = currentGroup.groupOptions.map(opt => opt.route);
      const isGroupActive = optionRoutes.includes(currentLocationPath);

      if (!isGroupActive) return;
      setGroupActivation({ ...initialGroupActivation, [group]: true });
    }

  };

  const handleChangeActiveGroup = group => {
    setGroupActivation({ ...initialGroupActivation, [group]: true });
  }

  const fetchRegions = async () => {
    const data = await getAvailableRegions();
    if (data.length) setShowLang(true);
    else setShowLang(false);
    setRegions(data.map(item => ({ value: item.region, label: item.name })));
    setRegionsCollapsed(data.map(item => ({ value: item.region, label: item.region })));
  };

  const setUserRegion = region => {
    const currentPathname = { pathname: window.location.pathname };
    setAuthUser({ ...user, company: { ...user.company, region: region } });
    localStorage.setItem("nexus-region", region);
    history.push({ pathname: null });
    setTimeout(() => {
      history.push(currentPathname);
    }, 100);
  };

  useEffect(() => {
    fetchRegions();
    const groups = menuOptions.filter(opt => opt.id).map(group => group.id);
    initialGroupVisibility = groups.reduce((a, v) => ({ ...a, [v]: false }), {});
    setGroupVisibility(initialGroupVisibility);
    initialGroupActivation = { ...initialGroupVisibility };
    setGroupActivation(initialGroupActivation);
    const currentLocationPath = window.location.pathname;
    try {
      const groupCollection = menuOptions.filter(opt => opt.id);
      const currentGroup = groupCollection.find(item => item.groupOptions.find(opt => opt.route === currentLocationPath));
      setGroupActivation({ ...initialGroupActivation, [currentGroup.id]: true });
      setGroupVisibility({ ...initialGroupVisibility, [currentGroup.id]: true });
    } catch (error) {
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (menuCollapsed) {
      document.body.classList.remove("menu-expanded");
      document.body.classList.add("menu-collapsed");
    } else {
      document.body.classList.remove("menu-collapsed");
      document.body.classList.add("menu-expanded");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuCollapsed]);

  useEffect(() => {
    if (!selectedRegion) return;
    setUserRegion(selectedRegion);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRegion]);

  useEffect(() => {
    if (!prevUserRegion || !user) return;
    if (!user?.company?.region) return;
    if (prevUserRegion === user.company.region) return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <div className={`MenuWrapper ${menuCollapsed ? "Collapsed" : ""} ${menuWrapperBack ? "SendedBack" : ""}`}>
      <div className="Menu flex column">

        <div className="Menu__header flex v-center">

          <div className={`Menu__header wrapper${viewportWidth >= 1024 ? "" : " nonExpandable"}`}>

            {(viewportWidth >= 1024 && (
              <div className="Menu__header__logo">
                <img src={`/assets/images/complete-logo-white${isProd ? "" : "-dev"}.svg`} alt="" />
              </div>
            )) || (
                <div className="Menu__header__logo">
                  <img src={`/assets/images/logo-white${isProd ? "" : "-dev2"}.svg`} alt="" />
                </div>
              )}

            {(viewportWidth >= 1024 && (
              <div className="Menu__header__title">
                <div className={`environment${isProd ? "" : "-dev"}`}>{`${isProd ? "" : "DEV "}Admin`}</div>
              </div>
            )) || (
                <div className="Menu__header__title flex column between">
                  <div className={`environment${isProd ? "" : "-dev"}`}>Admin</div>
                </div>
              )}
          </div>

          {viewportWidth >= 1024 && (
            <div className="Menu__header__arrow" onClick={() => setMenuCollapsed(!menuCollapsed)}>
              <img className="default" src={`/assets/icons/doble-arrow-${menuCollapsed ? "right" : "left"}.svg`} alt="" />
              <img className="active" src={`/assets/icons/doble-arrow-${menuCollapsed ? "right" : "left"}-active.svg`} alt="" />
            </div>
          )}

        </div>

        <div className="Menu__nav">
          <OverlayScrollbarsComponent
            style={{ height: "100%", width: "100%" }}
          >
            {menuOptions.filter(item => isAvailable(item)).map(item => {
              return ((!item.groupTitle && (

                <Link
                  key={item.title}
                  className="Menu__nav__item flex  v-center"
                  to={item.route}
                  activeClassName="Menu__nav__item--active"
                  exact
                  onClick={() => {
                    setIsMyTicketsActive(false);
                    setGroupActivation({ ...initialGroupActivation });
                  }}
                >
                  <div className="Menu__nav__footer__item flex v-center">
                    <div data-tip data-for={item.images.default} className="box-icon">
                      <div className="Menu__nav__item__icon Menu__nav__item__icon--default">
                        <img src={`/assets/images/${item.images.default}.svg`} alt="" />
                      </div>
                      <div className="Menu__nav__item__icon Menu__nav__item__icon--active">
                        <img src={`/assets/images/${item.images.active}.svg`} alt="" />
                      </div>
                      <ReactTooltip id={item.images.default} className="react-tooltip" place="right" border={false} arrowColor="#333333" type="dark" effect="solid">
                        <span>{item.title}</span>
                      </ReactTooltip>
                    </div>
                  </div>
                  <div className="Menu__nav__item__title">{item.title}</div>
                </Link>

              )) || (

                  <div
                    className={`OptionGroup`}
                  >

                    <div
                      key={item.groupTitle}
                      className={`GroupHeader Menu__nav__item ${(!groupVisibility[item.id] && groupActivation[item.id]) ? "Menu__nav__item--active" : ""} flex  v-center `}
                      onClick={() => toggleGroupVisibility(item.id)}
                    >
                      <img className="dropdown default" src={`/assets/icons/menu-dropdown-${groupVisibility[item.id] ? "close" : "open"}.svg`} alt="" />
                      <img className="dropdown active" src={`/assets/icons/menu-dropdown-${groupVisibility[item.id] ? "close" : "open"}-active.svg`} alt="" />
                      <div data-tip data-for={item.images.default} className="box-icon">
                        <div className="Menu__nav__item__icon Menu__nav__item__icon--default">
                          <img src={`/assets/images/${item.images.default}.svg`} alt="" />
                        </div>
                        <div className="Menu__nav__item__icon Menu__nav__item__icon--active">
                          <img src={`/assets/images/${item.images.active}.svg`} alt="" />
                        </div>
                      </div>
                      <ReactTooltip id={item.images.default} className="react-tooltip" place="right" border={false} arrowColor="#333333" type="dark" effect="solid">
                        <span>{item.groupTitle}</span>
                      </ReactTooltip>
                      <div className="Menu__nav__item__title">{item.groupTitle}</div>
                    </div>


                    <div className={`OptionsWrapper ${!groupVisibility[item.id] ? "hidden" : ""}`}>
                      {item.groupOptions.filter(itemGroup => isAvailable(itemGroup)).map(itemGroup => (
                        <>
                          <div className="verticalLine" />
                          <Link
                            key={itemGroup.title}
                            className="Menu__nav__item flex  v-center"
                            to={itemGroup.route}
                            activeClassName="Menu__nav__item--active"
                            exact
                            onClick={() => {
                              setIsMyTicketsActive(false);
                              handleChangeActiveGroup(item.id);
                            }}
                          >
                            <div data-tip data-for={itemGroup.images.default} className="box-icon">
                              <div className="Menu__nav__item__icon Menu__nav__item__icon--default">
                                <img src={`/assets/images/${itemGroup.images.default}.svg`} alt="" />
                              </div>
                              <div className="Menu__nav__item__icon Menu__nav__item__icon--active">
                                <img src={`/assets/images/${itemGroup.images.active}.svg`} alt="" />
                              </div>
                            </div>
                            <ReactTooltip id={itemGroup.images.default} className="react-tooltip" place="right" border={true} type="dark" effect="solid">
                              <span>{itemGroup.title}</span>
                            </ReactTooltip>
                            <div className="Menu__nav__item__title">{itemGroup.title}</div>
                          </Link>
                        </>
                      ))}
                    </div>

                  </div>

                ))
            })}
          </OverlayScrollbarsComponent>
        </div>

        <div className="Menu__nav__footer flex between v-center">

          <Link
            className="Menu__nav__footer__item flex v-center"
            to={"tickets"}
            activeClassName="Menu__nav__footer__item--active"
            exact
            onClick={() => setIsMyTicketsActive(true)}
          >
            <div data-tip data-for="tooltip-tickets" className="box-icon">
              <div className="Menu__nav__footer__item__icon Menu__nav__footer__item__icon--default">
                <img src={`/assets/images/menu-tickets${isMyTicketsActive ? "-active" : ""}.svg`} alt="" />
              </div>
              <div className="Menu__nav__footer__item__icon Menu__nav__footer__item__icon--active">
                <img src={`/assets/images/menu-tickets-active.svg`} alt="" />
              </div>

              <div className="Menu__nav__footer__item__tip">{t("myTickets")}</div>
              <ReactTooltip id="tooltip-tickets" className="react-tooltip" place="right" border={false} arrowColor="#333333" type="dark" effect="solid">
                <span>{t("myTickets")}</span>
              </ReactTooltip>
            </div>
          </Link>

          <Notifications />

          <div className="Menu__nav__footer__item flex v-center" onClick={() => {
            setIsMyTicketsActive(false);
            logout();
          }}>
            <div data-tip data-for="tooltip-logout" className="box-icon">
              <div className="Menu__nav__footer__item__icon Menu__nav__footer__item__icon--default">
                <img src={`/assets/icons/logout.svg`} alt="" />
              </div>
              <div className="Menu__nav__footer__item__icon Menu__nav__footer__item__icon--active">
                <img src={`/assets/icons/logout-hover.svg`} alt="" />
              </div>
              <div className="Menu__nav__footer__item__tip">{t("logout")}</div>
              <ReactTooltip id="tooltip-logout" className="react-tooltip" place="right" border={false} arrowColor="#333333" type="dark" effect="solid">
                <span>{t("logout")}</span>
              </ReactTooltip>
            </div>
          </div>
        </div>

        {showLang && ["nexus", "admin"].includes(user.type) && (
          <div className="Menu__regionSelector">
            <FormProvider {...methods}>
              <form className="" noValidate>
                <FormSelect
                  id="regionSelection"
                  label={t("region")}
                  name={`region`}
                  rules={{ required: true }}
                  defaultValue={user?.company?.region || "ES"}
                  options={menuCollapsed ? regionsCollapsed : regions}
                  required={false}
                  disableEmpty={true}
                />
              </form>
            </FormProvider>
          </div>
        )}

        <div className="Menu__footer">
          <div className="Menu__footer__user flex between">
            <div className="Menu__footer__user__name" onClick={() => setUserDetail(user)}>
              {user?.photo && <img src={user.photo} alt="" />}
              <span className="userName">{`${user.name} ${user.surname}`}</span>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

export default Menu;
