import { useState, useEffect, useRef } from "react";

import { useTranslation } from "react-i18next";

import usePrevious from "../../utils/hooks/usePrevious";
import useClickOut from "../../utils/hooks/useClickOut";
import useAuth from "../../DataProviders/auth/useAuth";
import useShipments from "../../DataProviders/shipments/useShipments";

import { lightFormat } from "date-fns";
import es from "date-fns/locale/es";

import ShipmentDetail from "./Detail";
import { Button, Chip, SearchInput, Table as StickyHeadTable } from "ui-components";

import { parseIntAmount, parseCurrency } from "ui-components";

import "./styles.scss";

const Shipments = () => {
  const { t } = useTranslation();

  const statusRef = useRef();
  const firstRender = useRef(true);
  const [searchIsClearable, setSearchIsClearable] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const [orderBy, setOrderBy] = useState({ date: "desc" });
  const prevOrderBy = usePrevious(orderBy);
  const [statusFilter, setStatusFilter] = useState({
    collecting: false,
    pending: false,
    billed: false,
    collected: false,
    payed: false,
  });
  const [statusFilterCount, setStatusFilterCount] = useState(0);

  const {
    data: { user },
  } = useAuth();

  const currency = user.company.localization.currency;

  const {
    data: {
      list,
      totalDocs,
      params: { page, limit },
    },
    methods: {
      initShipmentsList,
      handleChangeSort,
      handleChangePage,
      handleChangePageSize,
      handleChangeSearch,
      handleChangeFilters,
      fetchShipmentDetail,
      unsetShipmentDetail,
    },
  } = useShipments();

  useClickOut(statusRef, () => setShowStatus(false));

  const SHIPMENT_STATUS_OPTIONS = [
    { label: t("interrupted"), value: "collecting", color: "250, 61, 61" },
    { label: t("generated"), value: "pending", color: "69, 162, 37" },
    { label: t("billed"), value: "billed", color: "220, 172,33" },
    { label: t("collected"), value: "collected", color: "53, 132, 218" },
    { label: t("paid"), value: "payed", color: "236, 117, 74" },
  ];

  const getColumns = ({ t, onCopy }) => {
    return [
      {
        id: "centerName",
        label: t("customer").toUpperCase(),
        renderCell: value => value,
      },
      {
        id: "date",
        label: t("date").toUpperCase(),
        renderCell: value => lightFormat(new Date(value), "dd/MM/yy"),
      },
      {
        id: "status",
        label: t("status").toUpperCase(),
        renderCell: value => <span className={`capitalize ${value}`}>{t(`shipment.${value}`)}</span>
      },
      {
        id: "limit",
        label: t("deadLine").toUpperCase(),
        renderCell: value => lightFormat(new Date(value), "dd/MM/yy"),
      },
      {
        id: "resume",
        label: t("shipment (services - base)").toUpperCase(),
        renderCell: value => parseIntAmount(value.shipment.services, currency),
      },
      {
        id: "resume",
        label: " ",
        renderCell: () => <span className="separator"><hr /></span>,
      },
      {
        id: "resume",
        label: " ",
        renderCell: value => <span className="base-amount">{parseCurrency(value.shipment.base, currency)}</span>,
      },
      {
        id: "resume",
        label: t("invoice (services - base)").toUpperCase(),
        renderCell: value => parseIntAmount(value.invoice?.services, currency) || "",
      },
      {
        id: "resume",
        label: " ",
        renderCell: value => <span className="separator">{value.invoice ? <hr /> : ""}</span>,
      },
      {
        id: "resume",
        label: " ",
        renderCell: value => <span className="base-amount">{value.invoice ? parseCurrency(value.invoice.base, currency) : ""}</span>,
      },
    ];
  };

  const handleSearch = search => {
    setSearchIsClearable(!!search);
    handleChangeSearch(search);
  }

  const handleSortColumn = column => {
    const prevColumn = Object.keys(prevOrderBy)[0];
    if (prevColumn === column) setOrderBy({ [column]: prevOrderBy[prevColumn] === "asc" ? "desc" : "asc" });
    else setOrderBy({ [column]: "asc" });
  };

  const handleDoubleClick = async row => {
    await fetchShipmentDetail(row._id);
    setShowDetail(true);
  };

  const onClose = () => {
    unsetShipmentDetail();
    setShowDetail(false);
    handleChangePage(page);
  };

  useEffect(() => {
    return () => {
      setShowDetail(false);
      unsetShipmentDetail();
      initShipmentsList();
    };
  }, []);

  useEffect(() => {
    if (firstRender.current) return;
    const filter = {};
    const currentStatus = Object.keys(statusFilter).filter(item => statusFilter[item]);
    setStatusFilterCount(currentStatus.length);
    if (currentStatus.length !== SHIPMENT_STATUS_OPTIONS.length) {
      filter.status = currentStatus;
    }
    handleChangeFilters(filter);
  }, [statusFilter]);

  useEffect(() => {
    const orderArr = Object.keys(orderBy).map(key => (orderBy[key] === "asc" ? `${key}` : `-${key}`));
    handleChangeSort(orderArr.join(","));
    firstRender.current = false;
  }, [orderBy]);

  return (
    <div id="Shipments" className="Shipments">
      <div
        className={`Shipments__header flex between ${showDetail ? "display-none" : ""}`}
      >
        <div className="flex v-center">
          <div className="Shipments__header__title uppercase">
            {`${totalDocs || ""} ${totalDocs === 1 ? "Remesa" : "Remesas"}`}
          </div>
          <div className="Shipments__header__filters flex">
            <div className="Shipments__header__filters__status">
              <Button variant="secondary" onClick={() => setShowStatus(!showStatus)}>
                <img src="/assets/icons/filter.svg" />
                {t("statuses")}{statusFilterCount > 0 && ` (${statusFilterCount})`}
              </Button>
              {showStatus && (
                <div className="Shipments__header__filters__dropdown flex" ref={statusRef}>
                  {SHIPMENT_STATUS_OPTIONS.map((item, index) => (
                    <Chip
                      color={item.color}
                      width="92px"
                      hasInactiveColor={true}
                      label={item.label}
                      isActive={statusFilter[item.value]}
                      key={index}
                      onChange={value => setStatusFilter({ ...statusFilter, [item.value]: value })}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="Shipments__header__actions flex end v-center">
          <SearchInput
            onChange={handleSearch}
            placeholder={t("searchByCustomer")}
            tip={t("wholeWordSearch")}
            clearable={searchIsClearable}
          />
        </div>
      </div>

      <div className={`Shipments__list ${showDetail ? "display-none" : ""}`}>
        <StickyHeadTable
          totalDocs={totalDocs}
          rows={list || []}
          columns={getColumns({ t })}
          page={page}
          pageSize={limit}
          sortable={true}
          orderBy={orderBy}
          onSort={handleSortColumn}
          onChangePage={handleChangePage}
          onChangePageSize={handleChangePageSize}
          onDoubleClick={handleDoubleClick}
        />
      </div>

      {showDetail && <ShipmentDetail onClose={onClose} />}
    </div>
  );
};

export default Shipments;
