import { stringify } from "query-string";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import useUi from "../../DataProviders/ui/useUi";
import useRouter from "../../utils/hooks/useRouter";
import { userLogout } from "../../DataProviders/auth/actions";
import MqttService from "../../services/MqttService";

const useFetch = () => {
  const {
    methods: { showLoader, hideLoader },
  } = useUi();
  const { history, location } = useRouter();
  const dispatch = useDispatch();
  const baseUrl = process.env.REACT_APP_API;

  const refreshToken = async callback => {
    try {
      const response = await post({
        endpoint: "authentication/refreshtoken",
        body: { refreshToken: localStorage["nexusRefreshToken"] },
      });
      localStorage.setItem("nexusBackendAccessToken", response.token);
      localStorage.setItem("nexusRefreshToken", response.refreshToken);
      return callback();
    } catch (e) {
      logout();
      throw e;
    }
  };

  const unregisterFBtoken = async () => {
    if (localStorage.hasOwnProperty("nexusFirebaseToken")) {
      const registeredFBtoken = localStorage.getItem("nexusFirebaseToken") || 0;
      localStorage.removeItem("nexusFirebaseToken");
      return await patch({ endpoint: "authentication/unregisterDevice", id: "", body: { deviceToken: registeredFBtoken } });
    }
  };

  const logout = async () => {
    if (!localStorage.getItem("nexusBackendAccessToken")) return;
    try {
      await unregisterFBtoken();
    } catch (e) { }
    await post({ endpoint: "authentication/signOut", body: {} });
    localStorage.removeItem("nexusBackendAccessToken");
    localStorage.removeItem("nexusRefreshToken");
    dispatch(userLogout());
    hideLoader();
    history.push("/login");
    MqttService.destroy();
    toast.error(
      <>
        <div className="Toastify__toast__title">Su sesión ha caducado</div>
        <div className="Toastify__toast__subtitle">Debe identificarse de nuevo.</div>
      </>
    );
  };

  const getHeaders = (isPublic, isUpload) => {
    const headers = {
      "Content-Type": isUpload ? "multipart/form-data" : "application/json",
      "nexus-region": localStorage["nexus-region"],
      Accept: "application/json",
    };
    if (isPublic) {
      return headers;
    }
    return {
      ...headers,
      Authorization: `Bearer ${localStorage.nexusBackendAccessToken}`,
    };
  };

  const responseHandler = async response => {
    const res = await response.json();
    if (response.status.toString()[0] !== "2") {
      throw res;
    } else {
      return res.payload;
    }
  };

  const get = async ({ endpoint, params }) => {
    const url = `${baseUrl}/${endpoint}${params ? `?${stringify(params)}` : ""}`;
    try {
      showLoader();
      const response = await fetch(url, {
        method: "GET",
        headers: getHeaders(),
      });
      const parsedResponse = await responseHandler(response);
      hideLoader();
      return parsedResponse;
    } catch (e) {
      hideLoader();
      if (e.code === "tokenExpired") {
        return await refreshToken(() => get({ endpoint, params }));
      }
      if (e.code === "invalidToken") {
        logout();
      }
      throw e;
    }
  };

  const post = async ({ endpoint, body }) => {
    const url = `${baseUrl}/${endpoint}`;
    showLoader();
    try {
      const response = await fetch(url, {
        body: JSON.stringify(body),
        method: "POST",
        headers: getHeaders(),
      });
      const parsedResponse = await responseHandler(response);
      hideLoader();
      return parsedResponse;
    } catch (e) {
      hideLoader();
      if (e.code === "tokenExpired") {
        return await refreshToken(() => post({ endpoint, body }));
      }
      if (e.code === "invalidToken") {
        logout();
      }
      throw e;
    }
  };

  const postFile = async ({ endpoint, body }) => {
    const url = `${baseUrl}/${endpoint}`;
    showLoader();
    try {
      const response = await fetch(url, {
        body: body,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.nexusBackendAccessToken}`,
        },
      });
      const parsedResponse = await responseHandler(response);
      hideLoader();
      return parsedResponse;
    } catch (e) {
      hideLoader();
      if (e.code === "tokenExpired") {
        return await refreshToken(() => postFile({ endpoint, body }));
      }
      if (e.code === "invalidToken") {
        logout();
      }
      throw e;
    }
  };

  const put = async ({ endpoint, id, body }) => {
    const url = `${baseUrl}/${endpoint}/${id}`;
    showLoader();
    try {
      const response = await fetch(url, {
        body: JSON.stringify(body),
        method: "PUT",
        headers: getHeaders(),
      });
      const parsedResponse = await responseHandler(response);
      hideLoader();
      return parsedResponse;
    } catch (e) {
      hideLoader();
      if (e.code === "tokenExpired") {
        return await refreshToken(() => put({ endpoint, id, body }));
      }
      if (e.code === "invalidToken") {
        logout();
      }
      throw e;
    }
  };

  const patch = async ({ endpoint, id, body }) => {
    const url = `${baseUrl}/${endpoint}/${id}`;
    showLoader();
    try {
      const response = await fetch(url, {
        body: JSON.stringify(body),
        method: "PATCH",
        headers: getHeaders(),
      });
      const parsedResponse = await responseHandler(response);
      hideLoader();
      return parsedResponse;
    } catch (e) {
      hideLoader();
      if (e.code === "tokenExpired") {
        return await refreshToken(() => patch({ endpoint, id, body }));
      }
      if (e.code === "invalidToken") {
        logout();
      }
      throw e;
    }
  };

  const patchImage = async ({ endpoint, id, body }) => {
    const url = `${baseUrl}/${endpoint}/${id}`;
    showLoader();
    try {
      const response = await fetch(url, {
        body: body,
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${localStorage.nexusBackendAccessToken}`,
        },
      });
      const parsedResponse = await responseHandler(response);
      hideLoader();
      return parsedResponse;
    } catch (e) {
      hideLoader();
      if (e.code === "tokenExpired") {
        return await refreshToken(() => patchImage({ endpoint, id, body }));
      }
      if (e.code === "invalidToken") {
        logout();
      }
      throw e;
    }
  };

  const del = async ({ endpoint, body }) => {
    const url = `${baseUrl}/${endpoint}`;
    try {
      showLoader();
      const response = await fetch(url, {
        body: JSON.stringify(body),
        method: "DELETE",
        headers: getHeaders(),
      });
      const parsedResponse = await responseHandler(response);
      hideLoader();
      return parsedResponse;
    } catch (e) {
      hideLoader();
      if (e.code === "tokenExpired") {
        return await refreshToken(() => del({ endpoint, body }));
      }
      if (e.code === "invalidToken") {
        logout();
      }
      throw e;
    }
  };

  return {
    get,
    post,
    put,
    patch,
    patchImage,
    postFile,
    del,
  };
};

export default useFetch;
