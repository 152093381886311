import { useState, useRef } from "react";

import { useTranslation } from "react-i18next";

import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";

import "./styles.scss";
import icon from "./icon.svg";
import clearIcon from "./close.svg";

let timeoutSearch;

const SearchIcon = () => (
  <div>
    <img style={{ width: "16px", height: "16px" }} src={icon} alt="" />
  </div>
);

const ClearIcon = () => (
  <div>
    <img style={{ cursor: "pointer", width: "16px", height: "16px" }} src={clearIcon} alt=""/>
  </div>
);

const SearchResources = ({ placeholder, onChange, clearable }) => {
  const { t } = useTranslation();

  const inputRef = useRef();
  const [isOpen, setIsOpen] = useState(false);

  const handleChange = v => {
    clearTimeout(timeoutSearch);
    timeoutSearch = setTimeout(() => onChange(v ? v.toLowerCase() : v), 1000);
  };

  const handleOpen = () => {
    setIsOpen(true);
    inputRef.current.focus();
  };

  const handleClear = e => {
    e.preventDefault();
    e.stopPropagation();
    handleChange(null);
    inputRef.current.value = "";
    setIsOpen(false);
  };

  return (
    <div className={`SearchResources ${isOpen ? "open" : ""}`} onClick={handleOpen}>
      <FormControl variant="outlined" onChange={e => handleChange(e.target.value)}>
        <OutlinedInput
          placeholder={placeholder || "Buscar"}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
          inputRef={inputRef}
          endAdornment={
            clearable && (
              <InputAdornment position="end" onClick={handleClear}>
                <ClearIcon />
              </InputAdornment>
            )
          }
          inputProps={{
            style: {
              height: 40,
              padding: "0 12px",
            },
          }}
        />
      </FormControl>
    </div>
  );
};

export default SearchResources;
