import { useState, useEffect, useRef } from "react";

import { useTranslation } from "react-i18next";

import useCompanies from "../../DataProviders/companies/useCompanies";
import useUi from "../../DataProviders/ui/useUi";

import usePrevious from "../../utils/hooks/usePrevious";
import useClickOut from "../../utils/hooks/useClickOut";

import { List, SearchInput, Chip, Button, SwitchChip } from "ui-components";

import AddCompany from "./AddCompany";
import ProvidersMapping from "./ProvidersMapping";
//import Edit from "./Edit";

import "./styles.scss";

let currentSearchText = "";

const Companies = () => {
  const { t } = useTranslation();

  const {
    data: {
      list,
      totalDocs,
      params: { page, limit },
    },
    methods: {
      fetchList,
      fetchCompanyDetail,
      toggleActiveCompany,
      toggleLockedCompany,
      handleChangeFilters,
      handleChangeSearch,
      handleChangeSort,
      handleChangePage,
      handleChangePageSize,
    },
  } = useCompanies();

  const {
    methods: { showPanel },
  } = useUi();

  const firstRender = useRef(true);

  const typeRef = useRef();

  const [typeFilter, setTypeFilter] = useState({
    customer: false,
    provider: false,
  });
  const [typeFilterCount, setTypeFilterCount] = useState(0);

  const [showType, setShowType] = useState(false);
  useClickOut(typeRef, () => setShowType(false));

  const [searchIsClearable, setSearchIsClearable] = useState(false);

  const [orderBy, setOrderBy] = useState({ groupName: "asc" });
  const prevOrderBy = usePrevious(orderBy);

  const [companyMapped, setCompanyMapped] = useState(null);
  const [showProvidersMapping, setShowProvidersMapping] = useState(false);


  const TYPE_OPTIONS = [
    { label: t("customer"), value: "customer", color: "236, 117, 74" },
    { label: t("provider"), value: "provider", color: "236, 117, 74" },
  ];

  const getColumns = ({ t, onToggleActive, onToggleLocked, onEdit, onMapping }) => {
    return [
      {
        id: "groupName",
        label: t("name"),
      },
      { id: "code", label: t("code") },
      {
        id: "type",
        label: t("type"),
        renderCell: (value, obj) => {
          return (
            <span className="capitalize">
              {t(`${value}`)}
              {value === "provider" && (
                obj.providerData?.providerType ? ` (${t(obj.providerData?.providerType)})` : ""
              )}
            </span>
          );
        },
      },
      { id: "centerList", label: t("centerNum"), align: "center", renderCell: value => value.length },
      {
        id: "integrationEnabled",
        label: t("integrated"),
        align: "center",
        renderCell: value => (value ? "Sí" : "No"),
      },
      {
        id: "active",
        label: t("active"),
        align: "center",
        renderCell: (value, obj) => <SwitchChip value={value} onChange={v => onToggleActive(v, obj._id)} />,
      },
      {
        id: "lockedByNexus",
        label: t("blocked"),
        align: "center",
        renderCell: (value, obj) => <SwitchChip value={value} onChange={() => {}} />,
      },
      {
        width: 100,
        maxWidth: 100,
        minWidth: 100,
        align: "right",
        renderCell: (_, obj) => {
          return (
            (obj.type === "customer" && (
              <Button
                onClick={() => onMapping(obj._id)}
                variant="secondary"
                prefixIcon="providers-map"
                disabled={!obj.active}
              >
                {t("map")}
              </Button>
            )) || <div> </div>
          );
        },
      },
      {
        width: 100,
        maxWidth: 100,
        minWidth: 100,
        align: "right",
        renderCell: (_, obj) => {
          return (
            <Button onClick={() => onEdit(obj._id)} variant="secondary" prefixIcon="edit" disabled={!obj.active}>
              {t("edit")}
            </Button>
          );
        },
      },
    ];
  };

  const handleSearch = search => {
    currentSearchText = search || "";
    setSearchIsClearable(!!search);
    handleChangeSearch(search);
  };

  const handleSortColumn = column => {
    const prevColumn = Object.keys(prevOrderBy)[0];
    if (prevColumn === column) setOrderBy({ [column]: prevOrderBy[prevColumn] === "asc" ? "desc" : "asc" });
    else setOrderBy({ [column]: "asc" });
  };

  const onToggleActive = async (active, id) => {
    toggleActiveCompany(id, active);
  };

  const onToggleLocked = async (lockedByNexus, id) => {
    toggleLockedCompany(id, lockedByNexus);
  };

  const handleAddClick = () => {
    showPanel(() => <AddCompany />);
  };

  const handleProvidersMap = id => {
    const groupName = list.find(company => company._id === id).groupName;
    setCompanyMapped({ id, groupName });
    setShowProvidersMapping(true);
  };

  useEffect(() => {
    if (firstRender.current) return;
    const filter = {};
    const type = Object.keys(typeFilter).filter(item => typeFilter[item]);
    setTypeFilterCount(type.length);
    if (type.length !== TYPE_OPTIONS.length) {
      filter.type = type;
    }
    handleChangeFilters(filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeFilter]);

  useEffect(() => {
    if (firstRender.current) return;
    const orderArr = Object.keys(orderBy).map(key => (orderBy[key] === "asc" ? `${key}` : `-${key}`));
    handleChangeSort(orderArr.join(","));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderBy]);

  useEffect(() => {
    if (!firstRender.current) return;
    firstRender.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list]);

  useEffect(() => {
    fetchList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <>
      {list && (
        <>
          <List
            t={t}
            id="Companies"
            hide={!list || showProvidersMapping}
            totalDocs={totalDocs}
            title={t("companies-opt.listWithCount", { count: totalDocs || 0 })}
            headerFilters={
              <>
                <Button variant="secondary" onClick={() => setShowType(!showType)}>
                  <img src="/assets/icons/filter.svg" alt="" />
                  {t("type")}
                  {typeFilterCount > 0 && ` (${typeFilterCount})`}
                </Button>
                {showType && (
                  <div className="PageHeader__filters__dropdown" ref={typeRef}>
                    {TYPE_OPTIONS.map((item, index) => (
                      <Chip
                        color={item.color}
                        label={item.label}
                        width="80px"
                        isActive={typeFilter[item.value]}
                        key={index}
                        onChange={value => setTypeFilter({ ...typeFilter, [item.value]: value })}
                      />
                    ))}
                  </div>
                )}
              </>
            }
            headerActions={
              <>
                <div className="PageHeader__actions__search">
                  <SearchInput
                    onChange={handleSearch}
                    placeholder={t("searchByNameOrCode")}
                    defaultValue={currentSearchText}
                    tip={t("wholeWordSearch")}
                    clearable={searchIsClearable}
                  />
                </div>
                <div className="PageHeader__actions__lastButton flex end v-center">
                  <Button onClick={handleAddClick} prefixIcon="add-white">
                    {t("add")}
                  </Button>
                </div>
              </>
            }
            list={[...list]}
            columns={getColumns({ t, onToggleActive, onToggleLocked, onEdit: fetchCompanyDetail, onMapping: handleProvidersMap })}
            pageData={{ page, limit }}
            pageActions={{
              onChangePage: handleChangePage,
              onChangePageSize: handleChangePageSize,
              onDoubleClick: () => {},
            }}
            //params={{ orderBy, onSort: handleSortColumn }}
            params={{}}
          />
          {showProvidersMapping && (
            <ProvidersMapping
              company={companyMapped}
              onClose={() => {
                setShowProvidersMapping(false);
                setCompanyMapped(null);
              }}
            />
          )}
        </>
      )}
    </>
  );
};

export default Companies;
