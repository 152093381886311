import { useDispatch, useSelector } from 'react-redux'

import { setDocProfileDetail, setDocProfileList } from './actions'
import useFetch from '../../utils/hooks/useFetch'

const useDocProfile = () => {
  const endpoint = 'documentProfiles'
  const instance = useSelector(state => state.docProfile)
  const dispatch = useDispatch()
  const { get, post, put, patch } = useFetch()

  //LIST
  const fetchList = async params => {
    const data = await get({ endpoint, params })
    dispatch(setDocProfileList({ ...data, search: params?.search }))
  }

  const handleChangePage = page => {
    fetchList({ ...instance.params, page })
  }

  const handleChangePageSize = limit => {
    fetchList({ ...instance.params, page: 1, limit })
  }

  const handleChangeSearch = search => {
    fetchList({ ...instance.params, page: 1, search })
  }

  //DETAIL
  const updateDocProfile = async (id, body) => {
    const response = await put({ endpoint, id, body })
    const listIndex = instance.list.findIndex(item => item._id === response._id)
    if (listIndex !== -1) {
      const newList = [...instance.list]
      newList[listIndex] = response
      dispatch(setDocProfileList({ docs: newList, metadata: { totalDocs: instance.totalDocs } }))
    }
    return response
  }

  const toggleActiveDocProfile = async (id, active) => {
    const response = await patch({ endpoint, id, body: { active } })
    const listIndex = instance.list.findIndex(item => item._id === response._id)
    if (listIndex !== -1) {
      const newList = [...instance.list]
      newList[listIndex] = { ...newList[listIndex], active: response.active }
      dispatch(setDocProfileList({ docs: newList }))
    }
    return response
  }

  const fetchAllDocProfile = async active => {
    return await get({ endpoint: `documentProfiles/all${active ? '?active=true' : ''}` })
  }

  const addDocProfile = async body => {
    return await post({ endpoint, body })
  }

  const fetchDocProfileDetail = async id => {
    const response = await get({ endpoint: `${endpoint}/${id}` })
    dispatch(setDocProfileDetail(response))
  }

  const unsetDocProfileDetail = () => {
    dispatch(setDocProfileDetail(null))
  }

  const getDocProfileCategories = async () => {
    return await get({ endpoint: `${endpoint}/categories` })
  }

  return {
    methods: {
      fetchList,
      handleChangePage,
      handleChangePageSize,
      handleChangeSearch,
      toggleActiveDocProfile,
      fetchAllDocProfile,
      updateDocProfile,
      addDocProfile,
      fetchDocProfileDetail,
      setDocProfileDetail: data => dispatch(setDocProfileDetail(data)),
      unsetDocProfileDetail,
      getDocProfileCategories
    },
    data: instance
  }
}

export default useDocProfile
