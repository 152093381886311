import { useState, useEffect, useRef } from "react";

import { useTranslation } from "react-i18next";

import { useForm, FormProvider } from "react-hook-form";

import useMediumTypes from "../../../DataProviders/mediumTypes/useMediumTypes";
import useUi from "../../../DataProviders/ui/useUi";
import useDocProfile from "../../../DataProviders/docProfiles/useDocProfiles";

import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import { Button, Input, Select as FormSelect } from "ui-components";

import { NumberInput } from "ui-components";

import Documents from "../../../containers/Documents";

import { toast } from "react-toastify";

import "../../../styles/add-edit.scss";

const maxLoadTime = 60;

const EditMediumType = () => {
  const { t } = useTranslation();

  const loadTimeRef = useRef();

  const [isFetching, setIsFetching] = useState(false);
  const [categories, setCategories] = useState([]);
  const [profiles, setProfiles] = useState([]);

  const [activeTab, setActiveTab] = useState("summary");

  const [iconBudgetUrl, setIconBudgetUrl] = useState();
  const [isIconBudgetValid, setIsIconBudgetValid] = useState(false);
  const [iconMapUrl, setIconMapUrl] = useState();
  const [isIconMapValid, setIsIconMapValid] = useState(false);

  const {
    data: { detail },
    methods: { updateMediumType, getMediumTypeCategories, fetchList },
  } = useMediumTypes();

  const {
    methods: { closePanel },
  } = useUi();

  const {
    methods: { fetchAllDocProfile },
  } = useDocProfile();

  const methods = useForm({
    mode: "onChange",
    defaultValues: detail,
  });

  const { handleSubmit, errors, formState } = methods;
  const { isValid } = formState;

  const fetchCategories = async () => {
    const response = await getMediumTypeCategories();
    const _types = response.map(item => ({ value: item.category, label: item.name }));
    setCategories(_types);
  };

  const fetchProfiles = async () => {
    const response = await fetchAllDocProfile(true);
    const _types = response.map(item => ({ value: item._id, label: item.name }));
    setProfiles(_types);
  };

  const checkImage = (path, resolve, reject) => {
    let img = new Image();
    img.onload = resolve;
    img.onerror = reject;
    img.src = path;
  };

  const handleChangeIconBudgetUrl = evnt => {
    evnt.target.value = evnt.target.value.trim();
    const resolve = () => {
      setIsIconBudgetValid(true);
      setIconBudgetUrl(evnt.target.value);
    };
    const reject = () => {
      setIsIconBudgetValid(false);
      setIconBudgetUrl(null);
    };
    checkImage(evnt.target.value, resolve, reject);
  };

  const handleChangeIconMapUrl = evnt => {
    evnt.target.value = evnt.target.value.trim();
    const resolve = () => {
      setIsIconMapValid(true);
      setIconMapUrl(evnt.target.value);
    };
    const reject = () => {
      setIsIconMapValid(false);
      setIconMapUrl(null);
    };
    checkImage(evnt.target.value, resolve, reject);
  };

  const onSubmit = async data => {
    setIsFetching(true);
    try {
      await updateMediumType(detail._id, {
        ...detail,
        ...data,
      });
      toast.success(
        <>
          <div className="Toastify__toast__title">Tipo de medios actualizado</div>
          <div className="Toastify__toast__subtitle">Hemos actualizado la información del tipo de medios.</div>
        </>
      );
      await fetchList();
      closePanel();
    } catch (e) {
      toast.error(
        <>
          <div className="Toastify__toast__title">{t("anErrorHasOccurred")}</div>
          <div className="Toastify__toast__subtitle">No hemos podido actualizar la información del tipo de medios.</div>
        </>
      );
    }
    setIsFetching(false);
  };

  useEffect(() => {
    if (detail) {
      fetchCategories();
      fetchProfiles();
      setIsIconBudgetValid(true);
      setIconBudgetUrl(detail.imageBudgetUrl);
      setIsIconMapValid(true);
      setIconMapUrl(detail.imageMapUrl);
    }
  }, [detail]);

  return (
    <div className="Edit">
      <FormProvider {...methods}>
        <div className="Edit__header">
          <IconButton onClick={closePanel}>
            <CloseIcon />
          </IconButton>
          <div className="Edit__header__title">{detail.name}</div>
          <div className="Edit__header__content">
            <div className="Edit__header__content__description">{detail.active ? "Activo" : "No activo"}</div>
            <Button
              onClick={handleSubmit(onSubmit)}
              disabled={!isValid || isFetching || !isIconBudgetValid || !isIconMapValid}
            >
              {t("save")}
            </Button>
          </div>
        </div>
        <div className="Edit__body">
          <div className="Edit__main">
            <div className="Edit__tabs flex">
              <div
                className={`Edit__tabs__item ${activeTab === "summary" ? "active" : ""}`}
                onClick={() => setActiveTab("summary")}
              >
                RESUMEN
              </div>
              <div className={`Edit__tabs__item ${activeTab === "docs" ? "" : ""}`} onClick={() => { }}>
                DOCUMENTOS
              </div>
            </div>

            {activeTab === "summary" ? (
              <div className="Edit__body">
                <div className="Edit__item">
                  <div className="Edit__item__seccion-header">INFORMACIÓN GENERAL</div>

                  <div className="Edit__item__row">
                    <Input label={t("name")} name="name" required={true} rules={{ required: true }} />
                  </div>

                  <div className="Edit__item__row">
                    <Input
                      label={t("description")}
                      name="description"
                      required={true}
                      rules={{
                        required: true,
                      }}
                    />
                  </div>

                  <div className="Edit__item__row">
                    <FormSelect label={t("type")} name="category" rules={{ required: true }} options={categories} />
                    <FormSelect
                      label={t("documentProfile")}
                      name="documentProfile"
                      rules={{ required: true }}
                      options={profiles}
                    />
                  </div>

                  <div className="Edit__item__row">
                    <div className="NumberInputWrapper">
                      <NumberInput
                        ref={loadTimeRef}
                        className="NumberInput"
                        label={t("loadTimeInMinutes")}
                        name="loadTime"
                        defaultValue={0}
                        min={0}
                        max={maxLoadTime}
                        required={false}
                        rules={{
                          required: false,
                          validate: value =>
                            (value >= 0 && value <= maxLoadTime) || `Valor tiene que estar entre 0 y ${maxLoadTime}`,
                        }}
                        onChange={() => { }}
                      />
                    </div>
                    <Input
                      name="sharedCode"
                      label={t("sharedCode")}
                      inputProps={{ maxLength: 20 }}
                      required={true}
                      rules={{
                        required: true,
                      }}
                    />
                  </div>

                  <div className="Edit__divider"></div>

                  <div className="Edit__item__row">
                    <div className="Edit__icon">
                      <div className="Edit__icon url">
                        <div className="title">ICONO IDENTIFICATIVO</div>
                        <Input
                          className="inputUrl"
                          label={t("iconUrl")}
                          name="imageBudgetUrl"
                          inputProps={{
                            onChange: evnt => handleChangeIconBudgetUrl(evnt),
                          }}
                          required={true}
                          rules={{ required: true }}
                        />
                      </div>
                      <div className={`Edit__icon imageWrapper${isIconBudgetValid ? "" : " null"} small`}>
                        <img
                          className="image"
                          src={isIconBudgetValid ? iconBudgetUrl : "./assets/images/svgFile.svg"}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="Edit__divider"></div>

                  <div className="Edit__item__row">
                    <div className="Edit__icon">
                      <div className="Edit__icon url">
                        <div className="title">ICONO DEL MAPA</div>
                        <Input
                          className="inputUrl"
                          label={t("iconUrl")}
                          name="imageMapUrl"
                          inputProps={{
                            onChange: evnt => handleChangeIconMapUrl(evnt),
                          }}
                          required={true}
                          rules={{ required: true }}
                        />
                      </div>
                      <div className={`Edit__icon imageWrapper${isIconMapValid ? "" : " null"} large`}>
                        <img className="image" src={isIconMapValid ? iconMapUrl : "./assets/images/svgFile.svg"} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <Documents
                documentTypes={detail.documentation?.documentTypes || []}
                referenceId={detail._id}
                reference="deposits"
              />
            )}
          </div>
        </div>
      </FormProvider>
    </div>
  );
};

export default EditMediumType;
