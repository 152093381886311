import { useState, useRef, useEffect } from "react";

import { useTranslation } from "react-i18next";

import useClickOut from "../../../utils/hooks/useClickOut";
import useFetch from "../../../utils/hooks/useFetch";
import { lightFormat } from "date-fns";

import ReactTooltip from "react-tooltip";

import "./styles.scss";

const Notifications = () => {
  const { t } = useTranslation();

  const notificationsRef = useRef();
  const [showPanel, setShowPanel] = useState(false);
  const [notifications, setNotifications] = useState([]);
  useClickOut(notificationsRef, () => setShowPanel(false));
  const { get, patch, del } = useFetch();

  const fetchNotifications = async () => {
    const response = await get({ endpoint: "notifications" });
    setNotifications(response.docs);
  };

  const markAsRead = async (id, index) => {
    const response = await patch({ endpoint: "notifications/markReaded", id });
    notifications[index] = response;
    setNotifications([...notifications]);
  };

  const deleteNotification = async (e, id, index) => {
    e.stopPropagation();
    e.preventDefault();
    const response = await del({ endpoint: `notifications/${id}` });
    notifications.splice(index, 1);
    setNotifications([...notifications]);
  };

  useEffect(() => {
    showPanel && fetchNotifications();
  }, [showPanel]);

  return (
    <div className="Notifications" ref={notificationsRef}>
      <div
        className={`Notifications__trigger ${showPanel ? "Notifications__trigger--active" : ""} flex between v-center`}
        onClick={() => setShowPanel(!showPanel)}
      >
        <div data-tip data-for="tooltip-notifications" className="Notifications__title box-icon flex v-center">
          <div className="Notifications__title--default">
            <img src={`assets/icons/notifications-none.svg`} />
          </div>
          <div className="Notifications__title--active">
            <img src={`assets/icons/notifications-none-active.svg`} />
          </div>
          <div className="Notifications__tip">{t("notifications")}</div>
          <ReactTooltip
            id="tooltip-notifications"
            className="react-tooltip"
            place="right"
            border={false}
            arrowColor="#333333"
            type="dark"
            effect="solid"
          >
            <span>{t("notifications")}</span>
          </ReactTooltip>
        </div>

      </div>
      {showPanel && (
        <div className="Notifications__panel">
          <div className="Notifications__panel__title">{t("notifications").toUpperCase()}</div>
          {notifications.map((item, index) => (
            <div
              className={`Notifications__notification flex ${item.readed ? "Notifications__notification--read" : ""}`}
              key={item._id}
              onClick={() => !item.readed && markAsRead(item._id, index)}
            >
              <div className="Notifications__notification__icon">
                <img src={`assets/icons/notification-${item.type}${item.readed ? "" : "-active"}.svg`} />
              </div>
              <div className="Notifications__notification__info">
                <div className="Notifications__notification__header flex between">
                  <div className="Notifications__notification__title uppercase">{item.title}</div>
                </div>
                <div className="Notifications__notification__body">{item.body}</div>
                <div
                  className="Notifications__notification__delete"
                  onClick={e => deleteNotification(e, item._id, index)}
                >
                  <img src={`assets/icons/close.svg`} />
                </div>
                <div className="Notifications__notification__date flex v-center">
                  {lightFormat(new Date(item.createdAt), "dd/MM HH:mm")}
                  <img src={`assets/icons/notification-${item.readed ? "read" : "new"}.svg`} />
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Notifications;
