export const SET_DOCREVIEWS_LIST = 'SET_DOCREVIEWS_LIST'
export const setDocReviewsList = payload => ({
  type: SET_DOCREVIEWS_LIST,
  payload
})

export const SET_DOCREVIEWS_DETAIL = 'SET_DOCREVIEWS_DETAIL'
export const setDocReviewsDetail = payload => ({
  type: SET_DOCREVIEWS_DETAIL,
  payload
})
