import { useState, useRef, useEffect } from "react";

import { useTranslation } from "react-i18next";

import { useForm, FormProvider, useFieldArray } from "react-hook-form";
import { toast } from "react-toastify";

import { Button, Input, Select as FormSelect, SwitchChip } from "ui-components";
import useUi from "../../../DataProviders/ui/useUi";
import Documents from "../../../containers/Documents";
import useVehicleTypes from "../../../DataProviders/vehicleTypes/useVehicleTypes";
import DateInput from "../../../components/DateInput";

import "./styles.scss";

const EditVehicleType = () => {
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState("summary");
  const [isFetching, setIsFetching] = useState(false);

  const {
    methods: { closePanel },
  } = useUi();
  const {
    data: { detail },
    methods: { updateVehicleType },
  } = useVehicleTypes();

  const methods = useForm({
    mode: "onChange",
    defaultValues: detail,
  });
  const { handleSubmit, errors, formState, setValue, trigger, control, watch } = methods;
  const { isValid } = formState;
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
    control,
    name: "images",
  });

  const parseArrayError = (errors, index, fieldName) => {
    const name = `images[${index}].${fieldName}`;
    return (
      errors?.images?.[index]?.[fieldName] && {
        [name]: errors?.images?.[index]?.[fieldName],
      }
    );
  };

  const handleRemoveRow = index => {
    if (fields.length > 1) {
      remove(index);
    }
  };

  const onSubmit = async data => {
    setIsFetching(true);
    try {
      await updateVehicleType(detail._id, {
        ...detail,
        ...data,
      });
      toast.success(
        <>
          <div className="Toastify__toast__title">Tipo de vehículo actualizado</div>
          <div className="Toastify__toast__subtitle">Hemos actualizado la información del tipo de vehículo.</div>
        </>
      );
      closePanel();
    } catch (e) {
      toast.error(
        <>
          <div className="Toastify__toast__title">{t("anErrorHasOccurred")}</div>
          <div className="Toastify__toast__subtitle">
            No hemos podido actualizar la información del tipo de vehículo.
          </div>
        </>
      );
    }
    setIsFetching(false);
  };

  return (
    <div className="EditVehicleType">
      <FormProvider {...methods}>
        <div className="EditVehicleType__header flex">
          <div className="EditVehicleType__header__info">
            <div className="EditVehicleType__header__personal flex">
              <div className="EditVehicleType__header__name">{detail.title}</div>
            </div>
            <div className="EditVehicleType__header__misc flex v-center capitalize">
              {detail.active ? "activo" : "no activo"}
            </div>
            <div className="EditVehicleType__header__actions flex end">
              <Button onClick={handleSubmit(onSubmit)} disabled={!isValid || isFetching}>
                {t("save")}
              </Button>
            </div>
          </div>
        </div>
        <div className="EditVehicleType__body">
          <div className="EditVehicleType__main">
            <div className="EditVehicleType__tabs flex">
              <div
                className={`EditVehicleType__tabs__item ${activeTab === "summary" ? "active" : ""}`}
                onClick={() => setActiveTab("summary")}
              >
                RESUMEN
              </div>
              <div className={`EditVehicleType__tabs__item ${activeTab === "docs" ? "active" : ""}`} onClick={() => { }}>
                DOCUMENTOS
              </div>
            </div>
            {activeTab === "summary" ? (
              <div className="EditVehicleType__summary">
                <div className="EditVehicleType__summary__section">
                  <div className="EditVehicleType__summary__title">INFORMACIÓN GENERAL</div>
                  <div className="EditVehicleType__summary__form">
                    <div className="EditVehicleType__summary__form__row">
                      <Input label={t("title")} name="title" required={true} rules={{ required: true }} errorobj={errors} />
                    </div>
                  </div>
                </div>
                <div className="EditVehicleType__summary__section">
                  <div className="EditVehicleType__summary__form">
                    {fields.map((item, index) => (
                      <div key={item.id} className="EditVehicleType__item">
                        {fields.length > 1 && (
                          <div className="EditVehicleType__item__close" onClick={() => handleRemoveRow(index)}>
                            <img src="/assets/icons/close.svg" />
                          </div>
                        )}
                        <div className="flex">
                          <div className="EditVehicleType__item__inputs">
                            <div className="EditVehicleType__summary__form__row">
                              <Input
                                label={t("name")}
                                name={`images[${index}].name`}
                                required={true}
                                defaultValue={item.name}
                                rules={{ required: true }}
                                errorobj={parseArrayError(errors, index, "name")}
                              />
                            </div>
                            <div className="EditVehicleType__summary__form__row">
                              <Input
                                label={t("description")}
                                name={`images[${index}].text`}
                                required={true}
                                defaultValue={item.text}
                                rules={{ required: true }}
                                errorobj={parseArrayError(errors, index, "text")}
                              />
                            </div>
                            <div className="EditVehicleType__summary__form__row">
                              <Input
                                label={t("webAddress")}
                                name={`images[${index}].url`}
                                required={true}
                                defaultValue={item.url}
                                rules={{ required: true }}
                                errorobj={parseArrayError(errors, index, "url")}
                              />
                            </div>
                          </div>
                          <div className="EditVehicleType__item__image flex v-center h-center">
                            {watch(`images[${index}].url`, "") && (
                              <img
                                src={watch(`images[${index}].url`, "")}
                                onError={event => (event.target.style.display = "none")}
                                onLoad={event => (event.target.style.display = "inline-block")}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="EditVehicleType__add">
                    <Button
                      variant="secondary"
                      onClick={() => {
                        append({});
                      }}
                    >
                      <img src="/assets/icons/add.svg" />
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              <Documents
                documentTypes={detail.documentation?.documentTypes || []}
                referenceId={detail._id}
                reference="deposits"
              />
            )}
          </div>
        </div>
      </FormProvider>
    </div>
  );
};

export default EditVehicleType;
