import { useState, useEffect, useRef, useLayoutEffect } from "react";

import useUsers from "../../DataProviders/users/useUsers";
import useUi from "../../DataProviders/ui/useUi";
import useResizeObserver from "@react-hook/resize-observer";

import Menu from "../Menu";
import SidePanel from "../SidePanel";
import UserPanel from "../UserPanel";

import "./styles.scss";

const useSize = target => {
  const [size, setSize] = useState();
  useLayoutEffect(() => {
    setSize(target.current.getBoundingClientRect());
  }, [target]);
  useResizeObserver(target, entry => setSize(entry.contentRect));
  return size;
};

const Layout = ({ children }) => {
  
  const target = useRef(null);
  const size = useSize(target);
  const {
    methods: { showPanel, closePanel },
  } = useUi();
  const {
    data: { userDetail },
    methods: { unsetUserDetail },
  } = useUsers();

  const checkBodyMenuStatus = () => {
    const classes = document.body.classList;
    return [...classes].includes("menu-expanded") || classes.length === 0 ? "expanded" : "collapsed";
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [window.location]);

  useEffect(() => {
    if (userDetail) showPanel(() => <UserPanel />, unsetUserDetail);
    else closePanel();
  }, [userDetail]);

  return (
    <div ref={target} className="Layout flex">
      <Menu
        key={size?.width || 0}
        status={size?.width <= 1023 ? "collapsed" : checkBodyMenuStatus()}
        viewportWidth={size?.width || 0}
      />
      <SidePanel />
      <div
        className={`Layout__main-content ${window.location.pathname === "/" ? "Layout__main-content--dashboard" : ""}`}
      >
        {children}
      </div>
    </div>
  );
};

export default Layout;
