import { useDispatch, useSelector } from "react-redux";

import { resetProfilesList, setProfilesList, setProfileDetail } from "./actions";
import useFetch from "../../utils/hooks/useFetch";

const entity = "profiles";
const endpoint = "profiles";

const useProfiles = () => {
  const instance = useSelector(state => state[entity]);
  const dispatch = useDispatch();
  const { get, post, put, patch } = useFetch();

  // Create

  const addProfile = async body => {
    return await post({ endpoint, body });
  };

  // Read

  const initProfilesList = () => {
    dispatch(resetProfilesList());
  };

  const fetchList = async params => {
    const data = await get({ endpoint, params });
    dispatch(setProfilesList({ ...data, search: params?.search, filter: params?.filter, sort: params?.sort }));
  };

  const fetchAllProfiles = async () => {
    return await get({ endpoint: `${endpoint}/all` });
  };

  const fetchProfileDetail = async id => {
    const detail = await get({ endpoint: `${endpoint}/${id}` });
    dispatch(setProfileDetail(detail));
  };

  const unsetProfileDetail = () => {
    dispatch(setProfileDetail(null));
  };

  // Update

  const updateProfile = async (id, body) => {
    const response = await put({ endpoint, id, body });
    const itemIndex = instance.list.findIndex(item => item._id === response._id);
    if (itemIndex !== -1) {
      const newList = [...instance.list];
      newList[itemIndex] = response;
      dispatch(setProfilesList({ docs: newList, metadata: { totalDocs: instance.totalDocs } }));
    }
    return response;
  };

  const toggleActiveProfile = async (id, isActive) => {
    const response = await patch({ endpoint, id, body: { active: isActive } });
    const itemIndex = instance.list.findIndex(item => item._id === response._id);
    if (itemIndex !== -1) {
      const newList = [...instance.list];
      newList[itemIndex] = { ...newList[itemIndex], active: response.active };
      dispatch(setProfilesList({ docs: newList }));
    }
    return response;
  };

  // Pagination

  const handleChangePage = page => {
    fetchList({ ...instance.params, page });
  };

  const handleChangePageSize = limit => {
    fetchList({ ...instance.params, page: 1, limit });
  };

  const handleChangeSearch = search => {
    fetchList({ ...instance.params, page: 1, search });
  };

  const handleChangeSort = sort => {
    fetchList({ ...instance.params, page: 1, sort });
  };

  const handleChangeFilters = filter => {
    fetchList({ ...instance.params, page: 1, filter: JSON.stringify(filter) });
  };

  return {
    methods: {
      addProfile,
      initProfilesList,
      fetchList,
      fetchAllProfiles,
      fetchProfileDetail,
      setProfileDetail: data => dispatch(setProfileDetail(data)),
      unsetProfileDetail,
      updateProfile,
      toggleActiveProfile,
      handleChangePage,
      handleChangePageSize,
      handleChangeSearch,
      handleChangeFilters,
      handleChangeSort,
    },
    data: instance,
  };
};

export default useProfiles;
