import useUi from "../../DataProviders/ui/useUi";

import "./styles.scss";

const SidePanel = () => {
  const {
    data: { isPanelVisible, renderPanelContent },
    methods: { closePanel },
  } = useUi();

  return (
    <div className="SidePanel">
      <div className={`SidePanel__panel ${isPanelVisible ? "visible" : ""}`}>
        {isPanelVisible && renderPanelContent()}
      </div>
      {isPanelVisible && <div className="SidePanel__background" onClick={closePanel} />}
    </div>
  );
};

export default SidePanel;
