import { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";

import useAuth from "../../../DataProviders/auth/useAuth";
import useUi from "../../../DataProviders/ui/useUi";
import useTickets from "../../../DataProviders/tickets/useTickets";

import { Button } from "ui-components";
import { FileInput } from "ui-components";

import { PageWrapper } from "ui-components";
import { PageHeader } from "ui-components";
import { PageBody } from "ui-components";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";

import { ModalWindow } from "ui-components";

import { lightFormat } from "date-fns";
import { toast } from "react-toastify";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "@ckeditor/ckeditor5-build-classic/build/translations/es";

import { getTicketStatusAvatar } from "ui-components";

import "overlayscrollbars/css/OverlayScrollbars.css";
import "./styles.scss";

const TicketDetail = ({ onClose, isSupport }) => {
  const { t } = useTranslation();

  const [isFetching, setIsFetching] = useState(false);
  const [showModalCloseConfirmation, setShowModalCloseConfirmation] = useState(false);

  const [participants, setParticipants] = useState([]);
  const [teamMembers, setTeamMembers] = useState([]);

  const [textData, setTextData] = useState("");

  const [fileArr, setFileArr] = useState([]);
  const [imagePreview, setImagePreview] = useState(null);

  const {
    data: { user },
  } = useAuth();

  const {
    methods: { sendMenuWrapperBack, sendMenuWrapperFront },
  } = useUi();

  const {
    data: { detail: ticketDetail },
    methods: {
      fetchList,
      fetchTicketDetail,
      sendTicketMessage,
      closeTicket,
      getTeamMembers,
      inviteUser,
      assignTicketToMe,
    },
  } = useTickets();

  const imParticipating = () => {
    return participants.some(item => user._id === item._id);
  };

  const handleAttachFile = (e, code) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      // if (!isValidType(file.type, type)) return
      const url = URL.createObjectURL(file);
      setFileArr([...fileArr, { url, file }]);
    }
  };

  const sendMessage = async () => {
    const fd = new FormData();
    fd.append("text", textData);
    for (const item of fileArr) {
      fd.append("files", item.file, item.file.name);
    }
    setIsFetching(true);
    try {
      await sendTicketMessage(ticketDetail._id, fd);
      fetchTicketDetail(ticketDetail._id);
      setTextData("");
      setFileArr([]);
      toast.success(
        <>
          <div className="Toastify__toast__title">{t("ticketDetail.toastSendMessageSuccessTitle")}</div>
          <div className="Toastify__toast__subtitle">{t("ticketDetail.toastSendMessageSuccessText")}</div>
        </>
      );
    } catch (e) {
      toast.error(
        <>
          <div className="Toastify__toast__title">{t("ticketDetail.toastSendMessageErrorTitle")}</div>
          <div className="Toastify__toast__subtitle">{t("ticketDetail.toastSendMessageErrorText")}</div>
        </>
      );
    }
    setIsFetching(false);
  };

  const handleCloseTicket = async () => {
    try {
      await closeTicket(ticketDetail._id);
      // await fetchList();
      onClose();
      toast.success(
        <>
          <div className="Toastify__toast__title">{t("ticketDetail.toastCloseMessageSuccessTitle")}</div>
          <div className="Toastify__toast__subtitle">{t("ticketDetail.toastCloseMessageSuccessText")}</div>
        </>
      );
    } catch (e) {
      toast.error(
        <>
          <div className="Toastify__toast__title">{t("ticketDetail.toastCloseMessageErrorTitle")}</div>
          <div className="Toastify__toast__subtitle">{t("ticketDetail.toastCloseMessageErrorText")}</div>
        </>
      );
    }
  };

  const deleteFile = (e, index) => {
    e.stopPropagation();
    fileArr.splice(index, 1);
    setFileArr([...fileArr]);
  };

  const fetchTeamMembers = async () => {
    const response = await getTeamMembers(ticketDetail._id);
    setTeamMembers(response.filter(member => member._id !== user._id));
  };

  const inviteTeamMember = async (user, index) => {
    try {
      await inviteUser(ticketDetail._id, user._id);
      teamMembers.splice(index, 1);
      setTeamMembers([...teamMembers]);
      setParticipants([...participants, user]);
    } catch (e) { }
  };

  const assignTicket = async () => {
    try {
      await assignTicketToMe(ticketDetail._id);
      setParticipants([...participants, user]);
    } catch (e) { }
  };

  useEffect(() => {
    ticketDetail && setParticipants(ticketDetail.participants);
  }, [ticketDetail]);

  useEffect(() => {
    isSupport && fetchTeamMembers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSupport]);

  useEffect(() => {
    if (showModalCloseConfirmation) sendMenuWrapperBack();
    else sendMenuWrapperFront();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModalCloseConfirmation]);

  return (
    ticketDetail && (
      <PageWrapper id="TicketDetail">
        <PageHeader
          goBack={onClose}
          title={
            <>
              <div className={`TicketDetail__status flex h-center v-center ${ticketDetail.status}`}>
                {getTicketStatusAvatar(ticketDetail.status)}
              </div>
              <div className={`TicketDetail__title uppercase ${isSupport ? "SupportCenter" : ""}`}>
                {t("ticket").toUpperCase()} {ticketDetail.ticketNumber} - {ticketDetail.title}
              </div>
            </>
          }
          actions={
            <div className="PageHeader__actions flex end v-center">
              {((isSupport && imParticipating()) || !isSupport) &&
                ticketDetail.status !== "closed" &&
                !ticketDetail.readOnly && <Button onClick={() => setShowModalCloseConfirmation(true)}>{t("close")}</Button>}
              {isSupport && !imParticipating() && ticketDetail.status !== "closed" && !ticketDetail.readOnly && (
                <Button onClick={assignTicket} variant="secondary">
                  {t("assignItToMe")}
                </Button>
              )}
            </div>
          }
        />

        <OverlayScrollbarsComponent style={{ height: "100%", width: "100%" }}>
          <PageBody>
            <div className="TicketDetail__col-left">
              <div className="TicketDetail__info TicketDetail__box">
                <div className="TicketDetail__box__title TicketDetail__info__title">
                  {t("information").toUpperCase()}
                </div>
                <div className="TicketDetail__info__item flex">
                  <div className="TicketDetail__info__item__label">{t("from")}</div>
                  <div className="TicketDetail__info__item__value">{ticketDetail.source.name}</div>
                </div>
                <div className="TicketDetail__info__item flex">
                  <div className="TicketDetail__info__item__label">{t("sended")}</div>
                  <div className="TicketDetail__info__item__value">
                    {lightFormat(new Date(ticketDetail.createdAt), "dd/MM/yy HH:mm")}
                  </div>
                </div>
                <div className="TicketDetail__info__item flex">
                  <div className="TicketDetail__info__item__label">{t("lastAct")}</div>
                  <div className="TicketDetail__info__item__value">
                    {lightFormat(new Date(ticketDetail.lastActivity), "dd/MM/yy HH:mm")}
                  </div>
                </div>
                <div className="TicketDetail__info__item flex">
                  <div className="TicketDetail__info__item__label">{t("for")}</div>
                  <div className="TicketDetail__info__item__value">{ticketDetail.destination.name}</div>
                </div>
                <div className="TicketDetail__info__item flex">
                  <div className="TicketDetail__info__item__label">{t("supportTeamAbbrev")}</div>
                  <div className="TicketDetail__info__item__value">{ticketDetail.destination.teamName}</div>
                </div>
                <div className="TicketDetail__info__item flex">
                  <div className="TicketDetail__info__item__label">{t("status")}</div>
                  <div className="TicketDetail__info__item__value">{t(`${ticketDetail.status}`)}</div>
                </div>
                {ticketDetail.filecase && (
                  <div className="TicketDetail__info__item flex">
                    <div className="TicketDetail__info__item__label">{t("nFilecaseAbbrev")}</div>
                    <div className="TicketDetail__info__item__value">{ticketDetail.filecase}</div>
                  </div>
                )}
              </div>

              <div className="TicketDetail__info TicketDetail__box">
                <div className="TicketDetail__box__title TicketDetail__info__title">
                  {isSupport ? t("teamMembers").toUpperCase() : t("participants").toUpperCase()}
                </div>
                {participants.map((item, index) => (
                  <div className="TicketDetail__info__item flex between v-center" key={index}>
                    <div>{`${item.name} ${item.surname}`}</div>
                    {isSupport && <img src="assets/icons/check.svg" />}
                  </div>
                ))}
                {isSupport &&
                  teamMembers.map((item, index) => (
                    <div className="TicketDetail__info__item flex between v-center" key={index}>
                      <div>{`${item.name} ${item.surname}`}</div>
                      <Button variant="secondary" onClick={() => inviteTeamMember(item, index)}>
                        {t("invite")}
                      </Button>
                    </div>
                  ))}
              </div>
            </div>
            <div className="TicketDetail__col-right">
              {((isSupport && imParticipating()) || !isSupport) &&
                ticketDetail.status !== "closed" &&
                !ticketDetail.readOnly && (
                  <div className="TicketDetail__box">
                    <div className="TicketDetail__box__data flex column between">
                      <div className="flex row between">
                        <div className="flex row start avatarUserCenter">
                          <div className="TicketDetail__box__avatar flex h-center v-center">
                            {user.photo ? (
                              <img src={user.photo} />
                            ) : (
                              <div>{`${user.name?.[0]}${user.surname?.[0] || ""}`}</div>
                            )}
                          </div>
                          <div className="TicketDetail__box__title">
                            {user.name} {user.surname}
                            <br />
                            <span className="TicketDetail__box__title__company">{user.company.name}</span>
                          </div>
                        </div>
                        <FileInput
                          trigger={
                            <div className="TicketDetail__attach pointer">
                              <Button variant="secondary">
                                <div className="flex v-center">
                                  <img src="assets/icons/attachment-big.png" />
                                  {t("attachment")}
                                </div>
                              </Button>
                            </div>
                          }
                          onChange={e => handleAttachFile(e)}
                          accept={"application/pdf, image/*"}
                        />
                      </div>
                      <div className="flex column between">
                        <CKEditor
                          editor={ClassicEditor}
                          data={textData}
                          config={{
                            language: "es",
                            toolbar: {
                              items: [
                                "heading",
                                "|",
                                "alignment",
                                "|",
                                "bold",
                                "italic",
                                "|",
                                "numberedList",
                                "|",
                                "insertTable",
                                "|",
                                "blockQuote",
                                "|",
                                "undo",
                                "redo",
                              ],
                            },
                          }}
                          onReady={editor => {
                            // You can store the "editor" and use when it is needed.
                            // console.log("Editor is ready to use!", editor);
                          }}
                          onChange={(event, editor) => {
                            setTextData(editor.getData());
                          }}
                        />
                        <div className="TicketDetail__box__attachments flex">
                          {fileArr.map((item, index) => (
                            <div
                              key={index}
                              className="TicketDetail__box__attachments__item flex v-center"
                              onClick={
                                item.file.type !== "application/pdf"
                                  ? () => setImagePreview(item.url)
                                  : () => {
                                    window.open(item.url, "_blank").focus();
                                  }
                              }
                            >
                              <div className="TicketDetail__box__attachments__item__wrapper-image">
                                <img
                                  src={item.file.type !== "application/pdf" ? item.url : "assets/icons/pdf-icon.svg"}
                                />
                              </div>
                              <div className="flex v-center">
                                {item.file.name}
                                <div
                                  className="TicketDetail__box__attachments__item__delete"
                                  onClick={e => deleteFile(e, index)}
                                >
                                  <img src="assets/icons/close.svg" />
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                        <div className="TicketDetail__box__action flex end">
                          <Button variant="secondary" onClick={sendMessage} disabled={isFetching || !textData}>
                            {t("send")}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              {ticketDetail.messages.map((item, index) => (
                <div className="TicketDetail__box" key={index}>
                  <div className="TicketDetail__box__data flex column between">
                    <div className="flex row between">
                      <div className="flex row start avatarUserCenter">
                        <div className="TicketDetail__box__avatar flex h-center v-center">
                          {item.user.photo ? (
                            <img src={item.user.photo} />
                          ) : (
                            <div>{`${item.user.name?.[0]}${item.user.surname?.[0] || ""}`}</div>
                          )}
                        </div>
                        <div className="TicketDetail__box__title">
                          {item.user.name} {item.user.surname}
                          <br />
                          <span className="TicketDetail__box__title__company">{item.user.company.name}</span>
                        </div>
                      </div>
                      <div className="TicketDetail__box__date">
                        {lightFormat(new Date(item.createdAt), "dd/MM/yy HH:mm")}
                      </div>
                    </div>
                    <div className="flex column between">
                      <div className="TicketDetail__box__text" dangerouslySetInnerHTML={{ __html: item.text }}></div>
                      {item.metadata && <div className="TicketDetail__box__metadata">{item.metadata}</div>}
                      <div className="TicketDetail__box__attachments flex">
                        {item.attachments.map((attachment, index) => (
                          <div
                            key={index}
                            className="TicketDetail__box__attachments__item flex v-center"
                            onClick={
                              attachment.contentType !== "application/pdf"
                                ? () => setImagePreview(attachment.url)
                                : () => {
                                  window.open(attachment.url, "_blank").focus();
                                }
                            }
                          >
                            <div className="TicketDetail__box__attachments__item__wrapper-image">
                              <img
                                src={
                                  attachment.contentType !== "application/pdf"
                                    ? attachment.url
                                    : "assets/icons/pdf-icon.svg"
                                }
                              />
                            </div>
                            <div>{attachment.originalName}</div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </PageBody>
        </OverlayScrollbarsComponent>

        {imagePreview && (
          <div className="TicketDetail__preview flex v-center h-center" onClick={() => setImagePreview(null)}>
            <div className="TicketDetail__preview__container">
              <div className="TicketDetail__preview__close" onClick={() => setImagePreview(null)}>
                <img src="/assets/icons/close.svg" alt="" />
              </div>
              <div className="TicketDetail__preview__image">
                <img src={imagePreview} alt="" />
              </div>
            </div>
          </div>
        )}
        {showModalCloseConfirmation && (
          <ModalWindow
            title={t("modals.ticketDetail.closeTicket")}
            text={t("modals.ticketDetail.closeTicketQuestion")}
            cancelLabel={t("cancel")}
            handleCancel={() => setShowModalCloseConfirmation(false)}
            acceptLabel={t("accept")}
            handleAccept={() => {
              setShowModalCloseConfirmation(false);
              handleCloseTicket();
            }}
          />
        )}
      </PageWrapper>
    )
  );
};

export default TicketDetail;
