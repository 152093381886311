import { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";

import { useForm, FormProvider, useFieldArray } from "react-hook-form";

import useOperationTypes from "../../../DataProviders/operationTypes/useOperationTypes";
import useUi from "../../../DataProviders/ui/useUi";
import useDocProfile from "../../../DataProviders/docProfiles/useDocProfiles";
import useMediumTypes from "../../../DataProviders/mediumTypes/useMediumTypes";

import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import { Button, Input, Select as FormSelect } from "ui-components";

import Documents from "../../../containers/Documents";

import { toast } from "react-toastify";

import "../../../styles/add-edit.scss";

const EditOperationType = () => {
  const { t } = useTranslation();

  const [isFetching, setIsFetching] = useState(false);
  const [profiles, setProfiles] = useState([]);
  const [mediumTypes, setMediumTypes] = useState([]);

  const [activeTab, setActiveTab] = useState("summary");

  const [iconBudgetUrl, setIconBudgetUrl] = useState();
  const [isIconBudgetValid, setIsIconBudgetValid] = useState(false);

  const {
    data: { detail },
    methods: { fetchList, updateOperationType },
  } = useOperationTypes();

  const {
    methods: { closePanel },
  } = useUi();

  const {
    methods: { fetchAllDocProfile },
  } = useDocProfile();

  const {
    methods: { fetchAllMediumTypes },
  } = useMediumTypes();

  const newDetail = { ...detail, mediumTypes: detail.mediumTypes?.map(item => ({ value: item._id, label: item.name })) };

  const methods = useForm({
    mode: "onChange",
    defaultValues: newDetail,
  });

  const { handleSubmit, errors, formState, watch, trigger, control, setValue, getValues, setError } = methods;
  const { isValid } = formState;

  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
    control,
    name: "mediumTypes",
  });

  const fetchProfiles = async () => {
    const response = await fetchAllDocProfile(true);
    const _types = response.map(item => ({ value: item._id, name: item.name }));
    setProfiles(_types);
  };

  const fetchMediumTypes = async () => {
    const response = await fetchAllMediumTypes();
    const _types = response.map(item => ({ value: item._id, label: item.name }));
    setMediumTypes(_types);
  };

  const checkImage = (path, resolve, reject) => {
    let img = new Image();
    img.onload = resolve;
    img.onerror = reject;
    img.src = path;
  };

  const handleChangeIconBudgetUrl = evnt => {
    evnt.target.value = evnt.target.value.trim();
    const resolve = () => {
      setIsIconBudgetValid(true);
      setIconBudgetUrl(evnt.target.value);
    };
    const reject = () => {
      setIsIconBudgetValid(false);
      setIconBudgetUrl(null);
    };
    checkImage(evnt.target.value, resolve, reject);
  };

  const handleRemoveRow = index => {
    if (fields.length > 1) {
      remove(index);
    }
  };

  const onSubmit = async data => {
    data.mediumTypes = data.mediumTypes.map(item => item.name);
    data.active = true;

    setIsFetching(true);
    try {
      await updateOperationType(detail._id, {
        ...detail,
        ...data,
      });
      toast.success(
        <>
          <div className="Toastify__toast__title">Perfil de documentos actualizado</div>
          <div className="Toastify__toast__subtitle">Hemos actualizado la información del perfil de documentos.</div>
        </>
      );
      await fetchList();
      closePanel();
    } catch (e) {
      toast.error(
        <>
          <div className="Toastify__toast__title">{t("anErrorHasOccurred")}</div>
          <div className="Toastify__toast__subtitle">
            No hemos podido actualizar la información del perfil de documentos.
          </div>
        </>
      );
    }
    setIsFetching(false);
  };

  useEffect(() => {
    if (detail) {
      fetchMediumTypes();
      fetchProfiles();
      setIsIconBudgetValid(true);
      setIconBudgetUrl(detail.imageBudgetUrl);
    }
  }, [detail]);

  return (
    <div className="Edit">
      <FormProvider {...methods}>
        <div className="Edit__header">
          <IconButton onClick={closePanel}>
            <CloseIcon />
          </IconButton>
          <div className="Edit__header__title">{detail.name}</div>
          <div className="Edit__header__content">
            <div className="Edit__header__content__description">{detail.active ? "Activo" : "No activo"}</div>
            <Button
              onClick={handleSubmit(onSubmit)}
              disabled={!isValid || isFetching || !isIconBudgetValid || fields.length === 0}
            >
              {t("save")}
            </Button>
          </div>
        </div>
        <div className="Edit__body">
          <div className="Edit__main">
            <div className="Edit__tabs flex">
              <div
                className={`Edit__tabs__item ${activeTab === "summary" ? "active" : ""}`}
                onClick={() => setActiveTab("summary")}
              >
                RESUMEN
              </div>
              <div className={`Edit__tabs__item ${activeTab === "docs" ? "" : ""}`} onClick={() => { }}>
                DOCUMENTOS
              </div>
            </div>

            {activeTab === "summary" ? (
              <div className="Edit__body">
                <div className="Add__item">
                  <div className="Edit__item__seccion-header">INFORMACIÓN GENERAL</div>

                  <div className="Add__item__row">
                    <Input label={t("name")} name="name" required={true} rules={{ required: true }} />
                    < Input
                      name="sharedCode"
                      label={t("sharedCode")}
                      inputProps={{ maxLength: 20 }}
                      required={true}
                      rules={{
                        required: true,
                      }}
                    />
                  </div>

                  <div className="Add__item__row">
                    <Input
                      label={t("description")}
                      name="description"
                      required={true}
                      rules={{
                        required: true,
                      }}
                    />
                  </div>

                  <div className="Add__divider"></div>

                  <div className="Add__item__row">
                    <div className="Add__icon">
                      <div className="Add__icon url">
                        <div className="title">ICONO IDENTIFICATIVO</div>
                        <Input
                          className="inputUrl"
                          label={t("iconUrl")}
                          name="imageBudgetUrl"
                          inputProps={{
                            onChange: evnt => handleChangeIconBudgetUrl(evnt),
                          }}
                          required={true}
                          rules={{ required: true }}
                        />
                      </div>

                      <div className={`AddMediumType__icon imageWrapper${isIconBudgetValid ? "" : " null"} small`}>
                        <img
                          className="image"
                          src={isIconBudgetValid ? iconBudgetUrl : "./assets/images/svgFile.svg"}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="Add__divider"></div>

                  <div className="Add__item__seccion-header left-side">{t("types").toUpperCase()}</div>
                </div>

                {fields.map((item, index) => (
                  <div key={item.id} className="Edit__item plus">
                    {fields.length > 1 && (
                      <div className="Add__item__close" onClick={() => handleRemoveRow(index)}>
                        <img src="/assets/icons/close.svg" />
                      </div>
                    )}
                    <div className="Add__item__row">
                      <FormSelect
                        label={t("type")}
                        defaultValue={item.value}
                        name={`mediumTypes[${index}].name`}
                        options={mediumTypes}
                        required={true}
                        rules={{ required: true }}
                      />
                    </div>
                    <div className="Add__divider"></div>
                  </div>
                ))}

                <div className="Add__add-btn">
                  <Button variant="secondary" onClick={() => append({})}>
                    <img src="./assets/icons/add.svg" />
                  </Button>
                </div>
              </div>
            ) : (
              <Documents
                documentTypes={detail.documentation?.documentTypes || []}
                referenceId={detail._id}
                reference="deposits"
              />
            )}
          </div>
        </div>
      </FormProvider>
    </div>
  );
};

export default EditOperationType;
