import React, { useState, useEffect, useRef } from "react";

import { useTranslation } from "react-i18next";

import { useForm, FormProvider } from 'react-hook-form'

import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { lightFormat, format } from "date-fns";
import { Button, Table as StickyHeadTable } from "ui-components";
import TextField from "@material-ui/core/TextField";

import DateInput from "../../../components/DateInput";

import useAuth from "../../../DataProviders/auth/useAuth";
import useShipments from "../../../DataProviders/shipments/useShipments";

import { parseCurrency, parseStylishCurrency } from "ui-components";

import { toast } from "react-toastify";

import "overlayscrollbars/css/OverlayScrollbars.css";
import "react-datepicker/dist/react-datepicker.css";
import "./styles.scss";

const ShipmentDetail = ({ onClose }) => {
  const { t } = useTranslation();

  const SHIPMENT_STATUS_OPTIONS = [
    { label: t("interrupted"), value: "collecting", color: "250, 61, 61" },
    { label: t("generated"), value: "pending", color: "69, 162, 37" },
    { label: t("billed"), value: "billed", color: "220, 172,33" },
    { label: t("collected"), value: "collected", color: "53, 132, 218" },
    { label: t("paid"), value: "payed", color: "236, 117, 74" },
  ];

  const INVOICE_STATUS_OPTIONS = [
    { label: t("pending"), value: "pending", color: "250, 61, 61" },
    { label: t("billed"), value: "billed", color: "220, 172,33" },
    { label: t("collected"), value: "collected", color: "53, 132, 218" },
    { label: t("paid"), value: "payed", color: "236, 117, 74" },
  ];

  const collectInvoiceDateRef = useRef();
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [isUserAdmin, setIsUserAdmin] = useState(false);

  const [showCollectInvoiceModal, setShowCollectInvoiceModal] = useState(false);
  const [collectInvoiceDateValue, setCollectInvoiceDateValue] = useState(lightFormat(new Date(), "dd/MM/yy"));
  const [isFetching, setIsFetching] = useState(false);

  const [totalDocs, setTotalDocs] = useState(60);
  const [list, setList] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [orderBy, setOrderBy] = useState({ date: "desc" });

  const methods = useForm({
    mode: "onChange",
  });

  const { handleSubmit, errors, formState, watch, trigger, control, setValue, getValues, setError } = methods;
  const { isValid } = formState;


  const {
    data: { user },
  } = useAuth();

  const currency = user.company.localization.currency;

  const {
    data: { shipmentDetail },
    methods: { collectShipmentInvoice, fetchShipmentDetail, unsetShipmentDetail },
  } = useShipments();

  const getColumns = ({ t }) => {
    return [
      {
        id: "invoice",
        label: t("invoiceNumberAbbrev").toUpperCase(),
        renderCell: value => value,
      },
      {
        id: "centerName",
        label: t("provider").toUpperCase(),
        renderCell: value => value,
      },
      {
        id: "status",
        label: t("status").toUpperCase(),
        renderCell: value => <span className={`capitalize ${value}`}>{t(`invoice-status.${value}`)}</span>
      },
      {
        id: "date",
        label: t("date").toUpperCase(),
        renderCell: value => lightFormat(new Date(value), "dd/MM/yyyy"),
      },
      {
        id: "services",
        label: t("services").toUpperCase(),
        renderCell: value => value.toLocaleString('de-DE'),
      },
      {
        id: "base",
        label: t("base").toUpperCase(),
        renderCell: value => <span className="base-amount">{parseCurrency(value, currency)}</span>,
      },
      {
        id: "vatValue",
        label: t("IVA"),
        renderCell: value => <span className="base-amount">{parseCurrency(value, currency)}</span>,
      },
      {
        id: "irpfValue",
        label: t("IRPF"),
        renderCell: value => <span className="base-amount">{parseCurrency(value, currency)}</span>,
      },
      {
        id: "outlays",
        label: t("outlay").toUpperCase(),
        renderCell: value => <span className="base-amount">{parseCurrency(value, currency)}</span>,
      },
      {
        id: "total",
        label: t("total").toUpperCase(),
        renderCell: value => <span className="base-amount">{parseCurrency(value, currency)}</span>,
      },
      {
        id: "attachments",
        label: " ",
        renderCell: value => {
          return (
            <div className="ShipmentDetail__box__body__text">
              <div className="download-file-wrapper">
                <a href={`${value[0].url}`} target="_blank">
                  <img src="assets/icons/pdfFileIcon.svg" alt="factura" />
                  <span>{t("invoice")}</span>
                </a>
              </div>
            </div>
          )
        }
      },
    ];
  };

  const handleReturnToList = () => {
    unsetShipmentDetail();
    onClose();
  };

  const handleChangePage = newPage => {
    const start = (newPage - 1) * limit;
    const end = start + limit;
    setList(shipmentDetail.invoices.slice(start, end));
    setPage(newPage);
  };

  const handleChangePageSize = newLimit => {
    setList(shipmentDetail.invoices.slice(0, newLimit));
    setLimit(newLimit);
    setPage(1);
  };

  const handleCollectInvoice = () => {
    setShowCollectInvoiceModal(true);
  };

  const cancelCollectInvoice = () => {
    setShowCollectInvoiceModal(false);
  }

  const handleChangeCollectInvoiceDate = (newValue) => {
    try {
      setCollectInvoiceDateValue(lightFormat(new Date(newValue), "dd/MM/yyyy"));
    } catch {
      setCollectInvoiceDateValue(newValue);
    }
    setShowDatePicker(false);
  };

  const onSubmit = async values => {
    const shipmentId = shipmentDetail._id;
    const paymentDate = values.collectInvoiceDate || new Date();
    const requestBody = { shipmentId, paymentDate };
    setShowCollectInvoiceModal(false);
    setIsFetching(true);
    try {
      await collectShipmentInvoice(requestBody);
      await fetchShipmentDetail(shipmentDetail._id);
      toast.success(
        <>
          <div className="Toastify__toast__title">Cobro de Factura procesada de forma correcta</div>
          <div className="Toastify__toast__subtitle">La Remesa actual ha cambiado su estado a "Cobrada".</div>
        </>
      );
      setIsFetching(false);
    } catch (e) {
      await fetchShipmentDetail(shipmentDetail._id);
      let title = t(`shipments.collectInvoiceErrorTitle`);
      let text;
      if (e.statusCode === 400) {
        text = t(`servicesError.${e.code}`);
      } else {
        text = t(`shipments.collectInvoiceUnknownError`);
      }
      toast.error(
        <>
          <div className="Toastify__toast__title">{title}</div>
          <div className="Toastify__toast__subtitle">{text}</div>
        </>
      );
      setIsFetching(false);
    }
  };

  useEffect(() => {
    setTotalDocs(shipmentDetail.invoices.length);
    setList(shipmentDetail.invoices.slice(0, limit));
  }, []);


  useEffect(() => {
    if (!user) return;
    if (user.type === "admin") setIsUserAdmin(true);
  }, [user]);

  return (
    <>
      {(shipmentDetail && isUserAdmin) && (
        <div className="FullPagePanel flex column ShipmentDetail">
          <div className="FullPagePanel__header flex between v-center ShipmentDetail__header">
            <div className="FullPagePanel__header__title flex v-center">
              <img src="assets/icons/arrow-back.svg" onClick={handleReturnToList} className="pointer" />
              <div>
                REMESA DEL {lightFormat(new Date(shipmentDetail.date), "dd/MM/yyyy")}
                <span className={`shipment-status ${shipmentDetail.status} header`}>{t(`invoice-status.${shipmentDetail.status}`)}</span>
              </div>
              {shipmentDetail.status === "billed" && (
                <div className="CancelServiceButtonWrapper">
                  <Button variant="secondary" onClick={handleCollectInvoice}>
                    {t("collectInvoice")}
                  </Button>
                </div>
              )}
            </div>
            {shipmentDetail.resume?.shipment?.total && (
              <div className="FullPagePanel__header__action ShipmentDetail__price">
                {parseStylishCurrency(shipmentDetail.resume?.shipment?.total || 0, currency)}
              </div>
            )}
          </div>

          <OverlayScrollbarsComponent style={{ height: "100%", width: "100%" }}>
            <div className="FullPagePanel__body ShipmentDetail__body flex h-center">
              <div className="ShipmentDetail__wrapper flex">
                <div className="ShipmentDetail__col-unique">

                  <div className="ShipmentDetail__col-unique__row flex">

                    {/* INFO */}
                    <div className="ShipmentDetail__box ShipmentDetail__box--half">
                      <div className="flex between">
                        <div className="ShipmentDetail__box__title">{t("generalInformationAbbrev").toUpperCase()}</div>
                      </div>
                      <div className="ShipmentDetail__box__body flex v-center">
                        <div className="ShipmentDetail__box__body__icon">
                          <img src="assets/icons/info.svg" />
                        </div>
                        <div className="ShipmentDetail__box__body__info">
                          <div className="ShipmentDetail__box__body__title">
                            {shipmentDetail.centerName}
                          </div>
                          <div className="ShipmentDetail__box__body__text">
                            Fecha: {lightFormat(new Date(shipmentDetail.date), "dd/MM/yy") || " "}
                          </div>
                          <div className="ShipmentDetail__box__body__text">
                            Fecha limite: {lightFormat(new Date(shipmentDetail.limit), "dd/MM/yy") || " "}
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* PROCESS */}
                    <div className="ShipmentDetail__box ShipmentDetail__box--half">
                      <div className="flex between">
                        <div className="ShipmentDetail__box__title">PROCESOS</div>
                      </div>
                      <div className="ShipmentDetail__box__body flex v-center">
                        <div className="ShipmentDetail__box__body__icon">
                          <img src="assets/icons/process.svg" />
                        </div>
                        <div className="ShipmentDetail__box__body__financial__column process">
                          <div className="ShipmentDetail__box__body__data flex column v-center">
                            {shipmentDetail.process.map(p => {
                              return (
                                <div className="ShipmentDetail__box__body__text">
                                  Cambió a <span className={`process-status ${p.status}`}>{p.status}</span> el {lightFormat(new Date(p.date), "dd/MM/yyyy")} a las {lightFormat(new Date(p.date), "HH:mm")}
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>

                  {/* SHIPMENT */}
                  {shipmentDetail.resume?.shipment && (
                    <div className="ShipmentDetail__col-unique__row flex">
                      <div className="ShipmentDetail__box ShipmentDetail__box--financial">
                        <div className="ShipmentDetail__box__body">
                          <div className="ShipmentDetail__box__body__financial flex">
                            <div className="ShipmentDetail__box__body__financial__column">
                              <div className="ShipmentDetail__box__title">REMESA</div>
                              <div className="ShipmentDetail__box__body__data flex column h-center">
                                <div className="ShipmentDetail__box__body__icon">
                                  <img src="assets/icons/shipment.svg" />
                                </div>
                              </div>
                            </div>
                            <div className="ShipmentDetail__box__body__financial__column flex">
                              <div className="ShipmentDetail__box__body__title">{t("services").toUpperCase()}</div>
                              <div className="ShipmentDetail__box__body__data flex column h-center">
                                <div className="ShipmentDetail__box__body__text ShipmentDetail__box__body__text--amount">
                                  {shipmentDetail.resume?.shipment?.services || 0}
                                </div>
                              </div>
                            </div>
                            <div className="ShipmentDetail__box__body__financial__column">
                              <div className="ShipmentDetail__box__body__title">{t("base").toUpperCase()}</div>
                              <div className="ShipmentDetail__box__body__data  flex column h-center">
                                <div className="ShipmentDetail__box__body__text ShipmentDetail__box__body__text--amount">
                                  {parseStylishCurrency(shipmentDetail.resume?.shipment?.base || 0, currency)}
                                </div>
                              </div>
                            </div>
                            <div className="ShipmentDetail__box__body__financial__column">
                              <div className="ShipmentDetail__box__body__title">IVA {shipmentDetail.resume?.shipment?.vatType || 0}%</div>
                              <div className="ShipmentDetail__box__body__data  flex column h-center">
                                <div className="ShipmentDetail__box__body__text ShipmentDetail__box__body__text--amount">
                                  {parseStylishCurrency(shipmentDetail.resume?.shipment?.vatValue || 0, currency)}
                                </div>
                              </div>
                            </div>
                            <div className="ShipmentDetail__box__body__financial__column">
                              <div className="ShipmentDetail__box__body__title">SUPLIDOS</div>
                              <div className="ShipmentDetail__box__body__data  flex column h-center">
                                <div className="ShipmentDetail__box__body__text ShipmentDetail__box__body__text--amount">
                                  {parseStylishCurrency(shipmentDetail.resume?.shipment?.outlays || 0, currency)}
                                </div>
                              </div>
                            </div>
                            <div className="ShipmentDetail__box__body__financial__column">
                              <div className="ShipmentDetail__box__body__title">{t("total").toUpperCase()}</div>
                              <div className="ShipmentDetail__box__body__data  flex column h-center">
                                <div className="ShipmentDetail__box__body__text ShipmentDetail__box__body__text--amount">
                                  {parseStylishCurrency(shipmentDetail.resume?.shipment?.total || 0, currency)}
                                </div>
                              </div>
                            </div>
                            <div className="ShipmentDetail__box__body__financial__column">
                              <div className="ShipmentDetail__box__body__title"></div>
                              <div className="ShipmentDetail__box__body__data  flex column h-center">
                                <div className="ShipmentDetail__box__body__text">
                                  {shipmentDetail.attachments.filter(f => { return (f.documentType.indexOf("Shipment") !== -1) }).map(f => {
                                    const fileType = (f.contentType === "application/pdf") ? "pdf" : "xls";
                                    return (
                                      <div className="download-file-wrapper">
                                        <a href={f.url} target="_blank">
                                          <img src={`assets/icons/${fileType}FileIcon.svg`} alt={`${t("file")} ${fileType}`} />
                                          <span>{fileType === "pdf" ? t("invoice") : t("services")}</span>
                                        </a>
                                      </div>
                                    )
                                  })}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* INVOICE */}
                  {shipmentDetail.resume?.invoice && (
                    <div className="ShipmentDetail__col-unique__row flex">
                      <div className="ShipmentDetail__box ShipmentDetail__box--financial">
                        <div className="ShipmentDetail__box__body">
                          <div className="ShipmentDetail__box__body__financial flex">
                            <div className="ShipmentDetail__box__body__financial__column">
                              <div className="ShipmentDetail__box__title">{t("invoice").toUpperCase()}</div>
                              <div className="ShipmentDetail__box__title">{shipmentDetail.invoice}</div>
                              <div className="ShipmentDetail__box__body__data flex column h-center">
                                <div className="ShipmentDetail__box__body__icon">
                                  <img src="assets/icons/invoice.svg" />
                                </div>
                              </div>
                            </div>
                            <div className="ShipmentDetail__box__body__financial__column flex">
                              <div className="ShipmentDetail__box__body__title">{t("services").toUpperCase()}</div>
                              <div className="ShipmentDetail__box__body__data flex column h-center">
                                <div className="ShipmentDetail__box__body__text ShipmentDetail__box__body__text--amount">
                                  {shipmentDetail.resume?.invoice?.services || 0}
                                </div>
                              </div>
                            </div>
                            <div className="ShipmentDetail__box__body__financial__column">
                              <div className="ShipmentDetail__box__body__title">{t("base").toUpperCase()}</div>
                              <div className="ShipmentDetail__box__body__data  flex column h-center">
                                <div className="ShipmentDetail__box__body__text ShipmentDetail__box__body__text--amount">
                                  {parseStylishCurrency(shipmentDetail.resume?.invoice?.base || 0, currency)}
                                </div>
                              </div>
                            </div>
                            <div className="ShipmentDetail__box__body__financial__column">
                              <div className="ShipmentDetail__box__body__title">IVA {shipmentDetail.resume?.shipment?.vatType || 0}%</div>
                              <div className="ShipmentDetail__box__body__data  flex column h-center">
                                <div className="ShipmentDetail__box__body__text ShipmentDetail__box__body__text--amount">
                                  {parseStylishCurrency(shipmentDetail.resume?.invoice?.vatValue || 0, currency)}
                                </div>
                              </div>
                            </div>
                            <div className="ShipmentDetail__box__body__financial__column">
                              <div className="ShipmentDetail__box__body__title">SUPLIDOS</div>
                              <div className="ShipmentDetail__box__body__data  flex column h-center">
                                <div className="ShipmentDetail__box__body__text ShipmentDetail__box__body__text--amount">
                                  {parseStylishCurrency(shipmentDetail.resume?.invoice?.outlays || 0, currency)}
                                </div>
                              </div>
                            </div>
                            <div className="ShipmentDetail__box__body__financial__column">
                              <div className="ShipmentDetail__box__body__title">{t("total").toUpperCase()}</div>
                              <div className="ShipmentDetail__box__body__data  flex column h-center">
                                <div className="ShipmentDetail__box__body__text ShipmentDetail__box__body__text--amount">
                                  {parseStylishCurrency(shipmentDetail.resume?.invoice?.total || 0, currency)}
                                </div>
                              </div>
                            </div>
                            <div className="ShipmentDetail__box__body__financial__column">
                              <div className="ShipmentDetail__box__body__title"></div>
                              <div className="ShipmentDetail__box__body__data  flex column h-center">
                                <div className="ShipmentDetail__box__body__text">
                                  {shipmentDetail.attachments.filter(f => { return (f.documentType.indexOf("Customer") !== -1) }).map(f => {
                                    const fileType = (f.contentType === "application/pdf") ? "pdf" : "xls";
                                    return (
                                      <div className="download-file-wrapper">
                                        <a href={f.url} target="_blank">
                                          <img src={`assets/icons/${fileType}FileIcon.svg`} alt={`${t("file")} ${fileType}`} />
                                          <span>{fileType === "pdf" ? t("invoice") : t("services")}</span>
                                        </a>
                                      </div>
                                    )
                                  })}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {/* PROVIDERS INVOICES */}
              <div id="providers_invoices_list" className="ShipmentDetail__col-unique__row flex">
                <div className="ShipmentDetail__box ShipmentDetail__box--financial">
                  <div className="title">FACTURAS DE PROVEEDORES</div>
                  <StickyHeadTable
                    totalDocs={totalDocs}
                    rows={list || []}
                    columns={getColumns({ t })}
                    page={page}
                    pageSize={limit}
                    sortable={false}
                    orderBy={orderBy}
                    onChangePage={handleChangePage}
                    onChangePageSize={handleChangePageSize}
                  />
                </div>
              </div>

            </div>
          </OverlayScrollbarsComponent>
        </div>
      )}

      {showCollectInvoiceModal && (
        <div className="Modal">
          <div className="Modal__box CollectInvoiceModal">
            <div className="CollectInvoiceModal__title">{t("collectInvoice")}</div>
            <div className="CollectInvoiceModal__body">
              Al marcar como cobrada la factura, se pasarán las facturas de los proveedores a la siguiente remesa bancaria.
            </div>
            <div className="CollectInvoiceModal__body__Date">
              <div className="ShipmentDetail__date">
                <div className='CollectInvoiceDateInput'>
                  <FormProvider {...methods}>
                    <form className="" noValidate>
                      <div className="ShipmentDetail__item">
                        <div className="ShipmentDetail__item__row">
                          <DateInput label={t("collectDate")} name="collectInvoiceDate" />
                        </div>
                      </div>
                      <div className="CollectInvoiceModal__actions flex end v-center">
                        <Button variant="secondary" onClick={cancelCollectInvoice}>
                          {t("cancel")}
                        </Button>
                        <Button onClick={handleSubmit(onSubmit)} disabled={!isValid || isFetching}>
                          {t("accept")}
                        </Button>
                      </div>
                    </form>
                  </FormProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

    </>
  );
};

export default ShipmentDetail;
