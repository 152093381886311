import { useState } from "react";
import useFetch from "../../utils/hooks/useFetch";

const useDashboard = () => {
  const { get } = useFetch();
  const [data, setData] = useState({});

  const fetchDashboardData = async () => {
    const response = await get({ endpoint: "dashboard/companyStatus" });
    const resources = response.resources?.map(item => ({
      ...item,
      user: response.users.find(user => user._id === item.userId),
      medium: response.mediums.find(medium => medium._id === item.mediumId),
    })) || null;
    setData({ ...response, resources });
  };

  const fetchDashboardFilters = async () => {
    return await get({ endpoint: "dashboard/eventTrackerTypes" });
  };

  const fetchDashboardEvents = async (types = {}, user = null) => {
    const _types = [];
    for (const key in types) {
      if (types[key]) _types.push(key);
    }
    const filters = {};
    if (_types.length && _types.length !== Object.keys(types).length) filters["eventType"] = _types;
    if (user) filters["user.id"] = user;
    const endpoint =
      _types.length || user ? `dashboard/eventTracker?filter=${JSON.stringify(filters)}` : "dashboard/eventTracker";
    const response = await get({ endpoint });
    return response.docs;
  };

  const fetchDashboardService = async service => {
    return await get({ endpoint: `dashboard/serviceData/${service}` });
  };

  return {
    methods: {
      fetchDashboardData,
      fetchDashboardFilters,
      fetchDashboardEvents,

      fetchDashboardService,
    },
    data,
  };
};

export default useDashboard;
