import { useDispatch, useSelector } from "react-redux";

import { resetShipmentsList, setShipmentDetail, setShipmentsList } from "./actions";
import useFetch from "../../utils/hooks/useFetch";

const useShipments = () => {
  const shipments = useSelector(state => state.shipments);
  const dispatch = useDispatch();
  const { get, post, put, patch } = useFetch();

  //LIST
  const initShipmentsList = () => {
    dispatch(resetShipmentsList());
  };

  const fetchList = async params => {
    const data = await get({ endpoint: "shipments", params });
    dispatch(setShipmentsList({ ...data, search: params?.search, filter: params?.filter, sort: params?.sort }));
  };

  const handleChangePage = page => {
    fetchList({ ...shipments.params, page });
  };

  const handleChangePageSize = limit => {
    fetchList({ ...shipments.params, page: 1, limit });
  };

  const handleChangeSearch = search => {
    fetchList({ ...shipments.params, page: 1, search });
  };

  const handleChangeFilters = filter => {
    fetchList({ ...shipments.params, page: 1, filter: JSON.stringify(filter) });
  };

  const handleChangeSort = sort => {
    fetchList({ ...shipments.params, page: 1, sort });
  };

  //shipment

  const fetchAllShipments = async () => {
    return await get({ endpoint: "shipments" });
  };

  const fetchShipmentDetail = async id => {
    const shipment = await get({ endpoint: `shipments/${id}` });
    dispatch(setShipmentDetail(shipment));
  };

  const unsetShipmentDetail = () => {
    dispatch(setShipmentDetail(null));
  };

  const collectShipmentInvoice = async body => {
    return await post({ endpoint: "billing/shipmentCollected", body });
  };

  return {
    methods: {
      initShipmentsList,
      fetchList,
      handleChangePage,
      handleChangePageSize,
      handleChangeSearch,
      handleChangeFilters,
      handleChangeSort,
      fetchAllShipments,
      fetchShipmentDetail,
      setShipmentDetail: data => dispatch(setShipmentDetail(data)),
      unsetShipmentDetail,
      collectShipmentInvoice,
    },
    data: shipments,
  };
};

export default useShipments;
