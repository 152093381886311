import { useState, useEffect, useRef } from "react";

import { useTranslation } from "react-i18next";

import { lightFormat } from "date-fns";
import { useForm, FormProvider } from "react-hook-form";
import { toast } from "react-toastify";

import useUsers from "../../DataProviders/users/useUsers";
import useAuth from "../../DataProviders/auth/useAuth";
import useUi from "../../DataProviders/ui/useUi";
import ChangePassword from "./ChangePassword";
import ChangeAvatar from "./ChangeAvatar";

import { Button, Input, PhoneInput, Select as FormSelect } from "ui-components";

import Documents from "../Documents";

import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import "./styles.scss";

function readFile(file) {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}

const UserPanel = () => {
  const { t } = useTranslation();

  const fileRef = useRef(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [centers, setCenters] = useState([]);

  const [isAvatarVisible, setIsAvatarVisible] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [activeTab, setActiveTab] = useState("summary");
  const [isFetching, setIsFetching] = useState(false);

  const {
    data: { userDetail },
    methods: { updateUser, updateUserImage, setUserDetail },
  } = useUsers();

  const {
    data: { user: authUser },
    methods: { logout, setAuthUser, changeCurrentPassword, resendConfirmationCode, fetchCenters },
  } = useAuth();

  const {
    methods: { closePanel },
  } = useUi();

  const methods = useForm({
    mode: "onChange",
    defaultValues: userDetail,
  });
  const { handleSubmit, errors, formState } = methods;
  const { isValid } = formState;

  const itsMe = authUser._id === userDetail?._id;

  const initCenters = async () => {
    const response = await fetchCenters();
    const _centers = (response || []).map(item => ({ label: item.name, value: item.id }));
    setCenters(_centers);
  };

  const onSubmit = async data => {
    setIsFetching(true);
    try {
      data.company = { ...userDetail.company, ...data.company };
      const response = await updateUser(userDetail._id, { ...userDetail, ...data });
      if (itsMe) setAuthUser(response);
      toast.success(
        <>
          <div className="Toastify__toast__title">{t("updatedUser")}</div>
          <div className="Toastify__toast__subtitle">Hemos actualizado la información del usuario.</div>
        </>
      );
      closePanel();
    } catch (e) {
      toast.error(
        <>
          <div className="Toastify__toast__title">{t("anErrorHasOccurred")}</div>
          <div className="Toastify__toast__subtitle">No hemos podido actualizar la información del usuario.</div>
        </>
      );
    }
    setIsFetching(false);
  };

  const handleChangePassword = async data => {
    try {
      const result = await changeCurrentPassword(data);
      if (result === "error") {
        toast.error(
          <>
            <div className="Toastify__toast__title">{t("anErrorHasOccurred")}.</div>
            <div className="Toastify__toast__subtitle">Los datos introducidos no son correctos.</div>
          </>
        );
      } else {
        setIsPasswordVisible(false);
        toast.success(
          <>
            <div className="Toastify__toast__title">{t("resetPassword")}</div>
            <div className="Toastify__toast__subtitle">{t("Now you can use it when you login")}.</div>
          </>
        );
      }
    } catch (error) {
      toast.error(
        <>
          <div className="Toastify__toast__title">{t("anErrorHasOccurred")}.</div>
          <div className="Toastify__toast__subtitle">Los datos introducidos no son correctos.</div>
        </>
      );
    }
  };

  const handleSubmitAvatar = async photo => {
    var formdata = new FormData();
    formdata.append("photo", photo);
    const response = await updateUserImage(userDetail._id, formdata);
    if (itsMe) setAuthUser(response);
    setUserDetail(response);
    setIsAvatarVisible(false);
  };

  const onFileChange = async e => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      let imageDataUrl = await readFile(file);
      setImageSrc(imageDataUrl);
      setIsAvatarVisible(true);
    }
  };

  const handleClickAvatar = () => {
    if (userDetail.photo) {
      setImageSrc(userDetail.photo);
      setIsAvatarVisible(true);
    } else {
      fileRef.current.click();
    }
  };

  const handleResend = async username => {
    try {
      await resendConfirmationCode(username);
      toast.success(
        <>
          <div className="Toastify__toast__title">Notificación enviada</div>
          <div className="Toastify__toast__subtitle">
            Hemos enviado una notifiación al usuario con las instrucciones.
          </div>
        </>
      );
    } catch (e) {
      toast.error(
        <>
          <div className="Toastify__toast__title">{t("anErrorHasOccurred")}</div>
          <div className="Toastify__toast__subtitle">No hemos podido enviar la notificación al usuario.</div>
        </>
      );
    }
  };

  useEffect(() => {
    initCenters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return userDetail && (
    <div className="UserPanel">
      <FormProvider {...methods}>
        <div className="UserPanel__header flex">
          <IconButton onClick={closePanel}>
            <CloseIcon />
          </IconButton>
          <div className="UserPanel__header__avatar flex h-center v-center" onClick={handleClickAvatar}>
            {userDetail.photo ? <img src={userDetail.photo} alt="" /> : <div className={`${userDetail.type} flex h-center v-center`}>{userDetail.name?.[0]}{userDetail.surname?.[0] || ""}</div>}
          </div>
          <input type="file" onChange={onFileChange} accept="image/*" hidden ref={fileRef} />
          <div className="UserPanel__header__info">
            <div className="UserPanel__header__personal flex">
              <div className="UserPanel__header__name">{userDetail.username}</div>
              <div className="UserPanel__header__nick flex v-center h-center">{userDetail.nickname}</div>
            </div>
            <div className="UserPanel__header__misc flex v-center">
              {(!itsMe && userDetail.type !== "driver" && authUser.type === "admin" && (
                <div className="UserPanel__header__type UserPanel__header__type--select">
                  <FormSelect
                    label={t("type")}
                    name={`type`}
                    rules={{ required: true }}
                    defaultValue={userDetail.type}
                    errorobj={errors}
                    options={[
                      { value: "office", label: t("office") },
                      { value: "admin", label: t("admin") },
                    ]}
                    disableEmpty={true}
                  />
                </div>
              )) || <div className="UserPanel__header__type">{t(`${userDetail.type}`)}</div>}
              <div className="UserPanel__header__status">{t(`${userDetail.status}`)}</div>
              {userDetail.lastLogin && (
                <div className="UserPanel__header__date">
                  {lightFormat(new Date(userDetail.lastLogin), "dd/MM HH:mm")}
                </div>
              )}
            </div>
            <div
              className={`UserPanel__header__actions flex ${itsMe || userDetail.status === "pendingPassword" || userDetail.status === "changePassword"
                ? "between"
                : "end"
                }`}
            >
              {itsMe && (
                <>
                  <Button variant="secondary" onClick={logout}>
                    {t("disconnect")}
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={() => {
                      setIsPasswordVisible(true);
                    }}
                    prefixIcon="edit"
                  >
                    {t("password")}
                  </Button>
                </>
              )}
              {(userDetail.status === "pendingPassword" || userDetail.status === "changePassword") && (
                <Button onClick={() => handleResend(userDetail.username)} variant="secondary" prefixIcon="invite">
                  {t("invite")}
                </Button>
              )}
              <Button
                onClick={handleSubmit(onSubmit)}
                disabled={!isValid || isFetching || isAvatarVisible || isPasswordVisible}
              >
                {t("save")}
              </Button>
            </div>
          </div>
        </div>
        <div className="UserPanel__body">
          {isAvatarVisible ? (
            <ChangeAvatar
              imageSrc={imageSrc}
              onSubmit={handleSubmitAvatar}
              onCancel={() => setIsAvatarVisible(false)}
            />
          ) : !isPasswordVisible ? (
            <div className="UserPanel__main">
              <div className="UserPanel__tabs flex">
                <div
                  className={`UserPanel__tabs__item ${activeTab === "summary" ? "active" : ""}`}
                  onClick={() => setActiveTab("summary")}
                >
                  RESUMEN
                </div>
                <div
                  className={`UserPanel__tabs__item ${activeTab === "docs" ? "active" : ""}`}
                  onClick={() => setActiveTab("docs")}
                >
                  DOCUMENTOS
                </div>
              </div>
              {activeTab === "summary" ? (
                <div className="UserPanel__summary">
                  <div className="UserPanel__summary__section">
                    <div className="UserPanel__summary__title">INFORMACIÓN GENERAL</div>
                    <div className="UserPanel__summary__form">
                      <div className="UserPanel__summary__form__row">
                        <FormSelect
                          label={t("center")}
                          name={`company.center`}
                          rules={{ required: true }}
                          errorobj={errors}
                          options={centers}
                          disableEmpty={true}
                        />
                      </div>
                      <div className="UserPanel__summary__form__row">
                        <Input label={t("NIF")} name="legalId" rules={{ required: true }} errorobj={errors} />
                        <Input
                          label={t("nick")}
                          name="nickname"
                          rules={{
                            required: true,
                            validate: value => value.length <= 5 || "No mas de 5 caracteres",
                          }}
                          errorobj={errors}
                        />
                      </div>
                      <div className="UserPanel__summary__form__row">
                        <Input label={t("name")} name="name" rules={{ required: true }} errorobj={errors} />
                        < Input label={t("surname")} name="surname" rules={{ required: true }} errorobj={errors} />
                      </div>
                      <div className="UserPanel__summary__form__row">
                        <PhoneInput
                          defaultCountry={userDetail.company.region.toLowerCase()}
                          label={t("phone")}
                          name="mainContact.phone"
                          errorobj={errors?.mainContact?.phone && { "mainContact.phone": errors?.mainContact?.phone }}
                          rules={{
                            required: true,
                          }}
                        />
                        <Input
                          label={t("email")}
                          name="mainContact.email"
                          rules={{
                            required: true,
                            pattern: {
                              value: /^[A-Z0-9.!#$%&'*+-/=?^_`{|}~]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message: "Formato no válido",
                            },
                          }}
                          errorobj={errors?.mainContact?.email && { "mainContact.email": errors?.mainContact?.email }}
                        />
                      </div>
                    </div>
                  </div>
                  {userDetail.type === "driver" &&
                    !(userDetail.status === "pendingPassword" || userDetail.status === "changePassword") && (
                      <div className="UserPanel__summary__section">
                        <div className="UserPanel__summary__title">DATOS DEL CONDUCTOR</div>
                        <div className="UserPanel__summary__form">
                          <div className="UserPanel__summary__form__row">
                            <div className="UserPanel__summary__driver-data">
                              Último servicio: <span className="bold">25/01 18:57</span>
                            </div>
                          </div>
                          <div className="UserPanel__summary__form__row">
                            <div className="UserPanel__summary__driver-data">
                              Servicios completados: <span className="green bold">132</span>
                            </div>
                            <div className="UserPanel__summary__driver-data">
                              Servicios rechazados: <span className="red bold">9</span>
                            </div>
                          </div>
                          <div className="UserPanel__summary__form__row">
                            <div className="UserPanel__summary__driver-data">
                              Puntuación media: <span className="orange bold">3,7</span>
                            </div>
                            <div className="UserPanel__summary__driver-data">
                              Puntuación global: <span className="orange bold">4,3</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                </div>
              ) : (
                <div className="UserPanel__docs">
                  <Documents
                    documentTypes={userDetail.documentation?.documentTypes || []}
                    referenceId={userDetail._id}
                    reference="users"
                  />
                </div>
              )}
            </div>
          ) : (
            <ChangePassword onSubmit={handleChangePassword} onCancel={() => setIsPasswordVisible(false)} />
          )}
        </div>
      </FormProvider>
    </div>
  );
};

export default UserPanel;
