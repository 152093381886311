import { useDispatch, useSelector } from 'react-redux'

import { showPanel, hidePanel, showLoader, hideLoader, sendMenuWrapperBack, sendMenuWrapperFront } from './actions'

const useUi = () => {
  const ui = useSelector(state => state.ui)
  const dispatch = useDispatch()

  const closePanel = () => {
    dispatch(hidePanel())
    if (ui.closePanelCb) ui.closePanelCb()
  }

  return {
    methods: {
      showPanel: (render, closeCb) => dispatch(showPanel({ render, closeCb })),
      closePanel,
      showLoader: () => dispatch(showLoader()),
      hideLoader: () => dispatch(hideLoader()),
      sendMenuWrapperBack: () => dispatch(sendMenuWrapperBack()),
      sendMenuWrapperFront: () => dispatch(sendMenuWrapperFront()),
    },
    data: {
      ...ui,
      isLoaderVisible: ui.loaderCount > 0
    }
  }
}

export default useUi
