import { useRef, useState, useEffect } from "react";

import { useTranslation } from "react-i18next";

import { useForm, FormProvider, useFieldArray } from "react-hook-form";
import { toast } from "react-toastify";

import { Button, Input, Select as FormSelect, SwitchChip } from "ui-components";
import useUi from "../../../DataProviders/ui/useUi";
import useErrors from "../../../utils/hooks/useErrors";
import useVehicleTypes from "../../../DataProviders/vehicleTypes/useVehicleTypes";
import DateInput from "../../../components/DateInput";

import "./styles.scss";

const AddVehicleType = () => {
  const { t } = useTranslation();

  const [isFetching, setIsFetching] = useState(false);
  const {
    methods: { fetchList, addVehicleType },
  } = useVehicleTypes();
  const {
    methods: { closePanel },
  } = useUi();
  const methods = useForm({
    mode: "onChange",
  });
  const { handleSubmit, errors, formState, watch, trigger, control, setValue, getValues, setError } = methods;
  const { isValid } = formState;

  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
    control,
    name: "images",
  });

  const parseArrayError = (errors, index, fieldName) => {
    const name = `images[${index}].${fieldName}`;
    return (
      errors?.images?.[index]?.[fieldName] && {
        [name]: errors?.images?.[index]?.[fieldName],
      }
    );
  };

  const handleRemoveRow = index => {
    if (fields.length > 1) {
      remove(index);
    }
  };

  const onSubmit = async data => {
    setIsFetching(true);
    await addVehicleType(data);

    await fetchList();
    try {
      toast.success(
        <>
          <div className="Toastify__toast__title">Tipo de vehículo creado correctamente</div>
          <div className="Toastify__toast__subtitle">El tipo de vehículo ha sido creado y añadido a la lista.</div>
        </>
      );
      closePanel();
    } catch (e) {
      toast.error(
        <>
          <div className="Toastify__toast__title">Error al crear tipo de vehículo</div>
          <div className="Toastify__toast__subtitle">Ha ocurrido un error en la creación del tipo de vehículo.</div>
        </>
      );
    }
    setIsFetching(false);
  };

  useEffect(() => {
    append({});
  }, []);

  return (
    <div className="AddVehicleType">
      <div className="AddVehicleType__header">
        <div className="AddVehicleType__header__title">Añadir Tipo de vehículo</div>
        <div className="AddVehicleType__header__actions">
          <div className="AddVehicleType__header__description">
            Completa los datos de cada tipo de vehículo para añadirlo. <br />
            Los campos con (*) son obligatorios.
          </div>
          <div className="AddVehicleType__header__submit">
            <Button onClick={handleSubmit(onSubmit)} disabled={!isValid || isFetching}>
              {t("add")}
            </Button>
          </div>
        </div>
      </div>
      <div className="AddVehicleType__body">
        <FormProvider {...methods}>
          <form className="" noValidate>
            <div className="AddVehicleType__item">
              <div className="AddVehicleType__item__row">
                <Input label={t("title")} name="title" required={true} rules={{ required: true }} errorobj={errors} />
              </div>
            </div>
            {fields.map((item, index) => (
              <div key={item.id} className="AddVehicleType__item">
                {fields.length > 1 && (
                  <div className="AddVehicleType__item__close" onClick={() => handleRemoveRow(index)}>
                    <img src="/assets/icons/close.svg" />
                  </div>
                )}
                <div className="flex">
                  <div className="AddVehicleType__item__inputs">
                    <div className="AddVehicleType__item__row">
                      <Input
                        label={t("name")}
                        name={`images[${index}].name`}
                        required={true}
                        rules={{ required: true }}
                        errorobj={parseArrayError(errors, index, "name")}
                      />
                    </div>
                    <div className="AddVehicleType__item__row">
                      <Input
                        label={t("description")}
                        name={`images[${index}].text`}
                        required={true}
                        rules={{ required: true }}
                        errorobj={parseArrayError(errors, index, "text")}
                      />
                    </div>
                    <div className="AddVehicleType__item__row">
                      <Input
                        label={t("webAddress")}
                        name={`images[${index}].url`}
                        required={true}
                        rules={{ required: true }}
                        errorobj={parseArrayError(errors, index, "url")}
                      />
                    </div>
                  </div>
                  <div className="AddVehicleType__item__image flex v-center h-center">
                    {watch(`images[${index}].url`, "") && (
                      <img
                        src={watch(`images[${index}].url`, "")}
                        onError={event => (event.target.style.display = "none")}
                        onLoad={event => (event.target.style.display = "inline-block")}
                      />
                    )}
                  </div>
                </div>
              </div>
            ))}
          </form>
          <div className="AddVehicleType__add">
            <Button
              variant="secondary"
              onClick={() => {
                append({});
              }}
            >
              <img src="/assets/icons/add.svg" />
            </Button>
          </div>
        </FormProvider>
      </div>
    </div>
  );
};

export default AddVehicleType;
