import { useDispatch, useSelector } from "react-redux";

import { setMediumTypeDetail, setMediumTypesList } from "./actions";
import useFetch from "../../utils/hooks/useFetch";

const useMediumTypes = () => {
  const endpoint = "mediumtypes";
  const instance = useSelector(state => state.mediumTypes);
  const dispatch = useDispatch();
  const { get, post, put, patch } = useFetch();

  //LIST
  const fetchList = async params => {
    const data = await get({ endpoint, params });
    dispatch(setMediumTypesList({ ...data, search: params?.search }));
  };

  const handleChangePage = page => {
    fetchList({ ...instance.params, page });
  };

  const handleChangePageSize = limit => {
    fetchList({ ...instance.params, page: 1, limit });
  };

  const handleChangeSearch = search => {
    fetchList({ ...instance.params, page: 1, search });
  };

  //DETAIL
  const updateMediumType = async (id, body) => {
    const response = await put({ endpoint, id, body });
    const listIndex = instance.list.findIndex(item => item._id === response._id);
    if (listIndex !== -1) {
      const newList = [...instance.list];
      newList[listIndex] = response;
      dispatch(setMediumTypesList({ docs: newList, metadata: { totalDocs: instance.totalDocs } }));
    }
    return response;
  };

  const toggleActiveMediumType = async (id, active) => {
    const response = await patch({ endpoint, id, body: { active } });
    const listIndex = instance.list.findIndex(item => item._id === response._id);
    if (listIndex !== -1) {
      const newList = [...instance.list];
      newList[listIndex] = { ...newList[listIndex], active: response.active };
      dispatch(setMediumTypesList({ docs: newList }));
    }
    return response;
  };

  const fetchAllMediumTypes = async () => {
    return await get({ endpoint: "mediumtypes/all" });
  };

  const addMediumType = async body => {
    return await post({ endpoint, body });
  };

  const fetchMediumTypeDetail = async id => {
    const response = await get({ endpoint: `${endpoint}/${id}` });
    dispatch(setMediumTypeDetail(response));
  };

  const unsetMediumTypeDetail = () => {
    dispatch(setMediumTypeDetail(null));
  };

  const getMediumTypeCategories = async () => {
    return await get({ endpoint: `${endpoint}/categories` });
  };

  return {
    methods: {
      fetchList,
      handleChangePage,
      handleChangePageSize,
      handleChangeSearch,
      toggleActiveMediumType,
      fetchAllMediumTypes,
      updateMediumType,
      addMediumType,
      fetchMediumTypeDetail,
      setMediumTypeDetail: data => dispatch(setMediumTypeDetail(data)),
      unsetMediumTypeDetail,
      getMediumTypeCategories,
    },
    data: instance,
  };
};

export default useMediumTypes;
