import { useEffect } from "react";

import { useTranslation } from "react-i18next";

import useAuth from "../../DataProviders/auth/useAuth";
import useOperationTypes from "../../DataProviders/operationTypes/useOperationTypes";
import useUi from "../../DataProviders/ui/useUi";

import { List, Button, SwitchChip, Table as StickyHeadTable } from "ui-components";

import Add from "./Add";
import Edit from "./Edit";

const concatMediumTypes = mediumTypes => {
  const maxIndex = mediumTypes.length - 1;
  let mtStr = "";
  mediumTypes.forEach((obj, index) => {
    mtStr += obj.name;
    mtStr += index < maxIndex ? ", " : "";
  });
  return mtStr;
};

const OperationTypes = () => {
  const { t } = useTranslation();

  const getColumns = ({ t, onToggleActive, onEdit }) => {
    return [
      {
        id: "imageBudgetUrl",
        label: t("icon"),
        renderCell: (value, obj) => <img src={value} />,
      },
      {
        id: "name",
        label: t("name"),
      },
      { id: "sharedCode", label: t("sharedCode") },
      {
        id: "mediumTypes",
        label: t("types"),
        renderCell: (value, obj) => <>{concatMediumTypes(value)}</>,
      },
      {
        id: "active",
        label: t("active"),
        align: "center",
        renderCell: (value, obj) => <SwitchChip value={value} onChange={v => onToggleActive(v, obj._id)} />,
      },
      {
        align: "right",
        renderCell: (_, obj) => {
          return (
            <Button onClick={() => onEdit(obj._id)} variant="secondary" prefixIcon="edit" disabled={!obj.active}>
              {t("edit")}
            </Button>
          );
        },
      },
    ];
  };

  const {
    data: { user },
  } = useAuth();

  const {
    data: {
      list,
      totalDocs,
      params: { page, limit },
      detail,
    },
    methods: {
      fetchList,
      fetchOperationTypeDetail,
      unsetOperationTypeDetail,
      toggleActiveOperationType,
      handleChangePage,
      handleChangePageSize,
    },
  } = useOperationTypes();

  const {
    methods: { showPanel, closePanel },
  } = useUi();

  const onToggleActive = async (active, id) => {
    toggleActiveOperationType(id, active);
  };

  const handleAddClick = () => {
    showPanel(() => <Add />);
  };

  useEffect(() => {
    fetchList({ sort: "name" });
  }, [user.company?.region]);

  useEffect(() => {
    if (detail) showPanel(() => <Edit />, unsetOperationTypeDetail);
    else closePanel();
  }, [detail]);

  return (
    <List
      t={t}
      id="OperationTypes"
      hide={!list}
      totalDocs={totalDocs}
      title={t("operationTypes-opt.listWithCount", { count: totalDocs || 0 })}
      headerFilter={null}
      headerActions={
        <div className="PageHeader__actions__lastButton flex end v-center">
          <Button onClick={handleAddClick} prefixIcon="add-white">
            <span className="request">{t("add")}</span>
          </Button>
        </div>
      }
      list={[...list]}
      columns={getColumns({ t, onToggleActive, onEdit: fetchOperationTypeDetail })}
      pageData={{ page, limit }}
      pageActions={{
        onChangePage: handleChangePage,
        onChangePageSize: handleChangePageSize,
        onDoubleClick: () => { },
      }}
      params={{}}
    />
  );
};

export default OperationTypes;
