export const SET_OUTLAYS_LIST = 'SET_OUTLAYS_LIST'
export const setOutlaysList = payload => ({
  type: SET_OUTLAYS_LIST,
  payload
})

export const SET_OUTLAYS_DETAIL = 'SET_OUTLAYS_DETAIL'
export const setOutlaysDetail = payload => ({
  type: SET_OUTLAYS_DETAIL,
  payload
})
