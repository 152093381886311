import { useRef, useState, useEffect } from "react";

import { useTranslation } from "react-i18next";

import { useForm, FormProvider, useFieldArray } from "react-hook-form";
import { toast } from "react-toastify";

import { Button, Input, Select as FormSelect, SwitchChip } from "ui-components";
import useUi from "../../../DataProviders/ui/useUi";
import useDocProfile from "../../../DataProviders/docProfiles/useDocProfiles";
import useDocTypes from "../../../DataProviders/docTypes/useDocTypes";

import "./styles.scss";

const AddDocProfile = () => {
  const { t } = useTranslation();

  const [isFetching, setIsFetching] = useState(false);
  const [categories, setCategories] = useState([]);
  const {
    methods: { fetchAllDocTypes },
  } = useDocTypes();
  const {
    methods: { fetchList, addDocProfile },
  } = useDocProfile();
  const {
    methods: { closePanel },
  } = useUi();
  const methods = useForm({
    mode: "onChange",
  });
  const { handleSubmit, errors, formState, watch, trigger, control, setValue, getValues, setError } = methods;
  const { isValid } = formState;

  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
    control,
    name: "documentTypes",
  });

  const fetchCategories = async () => {
    const response = await fetchAllDocTypes();
    const _documentTypes = response.map(item => ({ value: item._id, label: item.name }));
    setCategories(_documentTypes);
  };

  const parseArrayError = (errors, index, fieldName) => {
    const name = `documentTypes[${index}].${fieldName}`;
    return (
      errors?.documentTypes?.[index]?.[fieldName] && {
        [name]: errors?.documentTypes?.[index]?.[fieldName],
      }
    );
  };

  const handleRemoveRow = index => {
    if (fields.length > 1) {
      remove(index);
    }
  };

  const onSubmit = async data => {
    data.documentTypes = data.documentTypes.map(item => item.name);
    setIsFetching(true);
    try {
      await addDocProfile(data);

      await fetchList();
      toast.success(
        <>
          <div className="Toastify__toast__title">Perfil de documento creado correctamente</div>
          <div className="Toastify__toast__subtitle">El perfil de documento ha sido creado y añadido a la lista.</div>
        </>
      );
      closePanel();
    } catch (e) {
      toast.error(
        <>
          <div className="Toastify__toast__title">Error al crear perfil de documento</div>
          <div className="Toastify__toast__subtitle">Ha ocurrido un error en la creación del perfil de documento.</div>
        </>
      );
    }
    setIsFetching(false);
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  return (
    <div className="AddDocProfile">
      <div className="AddDocProfile__header">
        <div className="AddDocProfile__header__title">Añadir perfil de documentos</div>
        <div className="AddDocProfile__header__actions">
          <div className="AddDocProfile__header__description">
            Completa los datos de cada perfil de documentos para añadirlos. <br />
            Los campos con (*) son obligatorios.
          </div>
          <div className="AddDocProfile__header__submit">
            <Button onClick={handleSubmit(onSubmit)} disabled={!isValid || isFetching}>
              {t("add")}
            </Button>
          </div>
        </div>
      </div>
      <div className="AddDocProfile__body">
        <FormProvider {...methods}>
          <form className="" noValidate>
            <div className="AddDocProfile__item">
              <div className="AddDocProfile__item__row">
                <Input label={t("name")} name="name" required={true} rules={{ required: true }} errorobj={errors} />
                < FormSelect
                  label={t("userType")}
                  name="userType"
                  rules={{ required: true }}
                  errorobj={errors}
                  options={[
                    { value: "office", label: t("office") },
                    { value: "admin", label: t("admin") },
                    { value: "driver", label: t("driver") },
                    { value: "none", label: t("typeless") },
                  ]}
                />
              </div>
            </div>
            <div className="AddDocProfile__documentTypes">TIPOS DE DOCUMENTO</div>
            {fields.map((item, index) => (
              <div key={item.id} className="AddDocProfile__item">
                {fields.length > 1 && (
                  <div className="AddDocProfile__item__close" onClick={() => handleRemoveRow(index)}>
                    <img src="/assets/icons/close.svg" />
                  </div>
                )}
                <div className="AddDocProfile__item__row">
                  <FormSelect
                    label={t("type")}
                    name={`documentTypes[${index}].name`}
                    required={true}
                    rules={{ required: true }}
                    errorobj={parseArrayError(errors, index, "name")}
                    options={categories}
                  />
                </div>
              </div>
            ))}
          </form>
          <div className="AddDocProfile__add">
            <Button
              variant="secondary"
              onClick={() => {
                append({});
              }}
            >
              <img src="/assets/icons/add.svg" />
            </Button>
          </div>
        </FormProvider>
      </div>
    </div>
  );
};

export default AddDocProfile;
