import { useDispatch, useSelector } from 'react-redux'

import { setTicketDetail, setTicketsList } from './actions'
import useFetch from '../../utils/hooks/useFetch'

const useTickets = isSupport => {
  const endpoint = 'tickets'
  const instance = useSelector(state => state.tickets)
  const dispatch = useDispatch()
  const { get, postFile, put, patch } = useFetch()

  //LIST
  const fetchList = async params => {
    const data = await get({ endpoint: `${endpoint}/${isSupport ? 'supportedTickets' : 'myTickets'}`, params })
    dispatch(setTicketsList({ ...data, search: params?.search, filter: params?.filter, sort: params?.sort }));
  }

  const handleChangePage = page => {
    fetchList({ ...instance.params, page })
  }

  const handleChangePageSize = limit => {
    fetchList({ ...instance.params, page: 1, limit })
  }

  const handleChangeSearch = search => {
    fetchList({ ...instance.params, page: 1, search })
  }

  const handleChangeSort = sort => {
    fetchList({ ...instance.params, page: 1, sort })
  }

  const handleChangeFilters = filter => {
    fetchList({ ...instance.params, page: 1, filter: JSON.stringify(filter) })
  }

  //DETAIL
  const updateTicket = async (id, body) => {
    const response = await put({ endpoint, id, body })
    const listIndex = instance.list.findIndex(item => item._id === response._id)
    if (listIndex !== -1) {
      const newList = [...instance.list]
      newList[listIndex] = response
      dispatch(setTicketsList({ docs: newList, metadata: { totalDocs: instance.totalDocs } }))
    }
    return response
  }

  const sendTicketMessage = async (id, body) => {
    return await postFile({ endpoint: `${endpoint}/updateTicket/${id}`, body })
  }

  const toggleActiveTicket = async (id, active) => {
    const response = await patch({ endpoint, id, body: { active } })
    const listIndex = instance.list.findIndex(item => item._id === response._id)
    if (listIndex !== -1) {
      const newList = [...instance.list]
      newList[listIndex] = { ...newList[listIndex], active: response.active }
      dispatch(setTicketsList({ docs: newList }))
    }
    return response
  }

  const addTicket = async body => {
    return await postFile({ endpoint: `${endpoint}/createTicket`, body })
  }

  const fetchTicketDetail = async id => {
    const response = await get({ endpoint: `${endpoint}/${id}` })
    dispatch(setTicketDetail(response))
  }

  const unsetTicketDetail = () => {
    dispatch(setTicketDetail(null))
  }

  const getSupportData = async service => {
    return await get({ endpoint: `${endpoint}/supportCenters${service ? '/' + service : ''}` })
  }

  const closeTicket = async id => {
    return await patch({ endpoint: `${endpoint}/close`, id })
  }

  const getServiceTickets = async service => {
    return await get({ endpoint: `${endpoint}/serviceTickets/${service}` })
  }

  const getTeamMembers = async ticket => {
    return await get({ endpoint: `${endpoint}/freeTeamMembers/${ticket}` })
  }

  const inviteUser = async (ticket, user) => {
    return await patch({ endpoint: `${endpoint}/invite/${ticket}`, id: user })
  }

  const assignTicketToMe = async ticket => {
    return await patch({ endpoint: `${endpoint}/assignMe`, id: ticket })
  }

  const getUserTeams = async ticket => {
    return await get({ endpoint: `${endpoint}/userTeams` })
  }

  return {
    methods: {
      fetchList,
      handleChangePage,
      handleChangePageSize,
      handleChangeSearch,
      handleChangeSort,
      handleChangeFilters,
      toggleActiveTicket,
      updateTicket,
      addTicket,
      fetchTicketDetail,
      setTicketDetail: data => dispatch(setTicketDetail(data)),
      unsetTicketDetail,
      getSupportData,
      sendTicketMessage,
      closeTicket,
      getServiceTickets,
      getTeamMembers,
      inviteUser,
      assignTicketToMe,
      getUserTeams
    },
    data: instance
  }
}

export default useTickets
