export const SET_REJECTIONS_LIST = 'SET_REJECTIONS_LIST'
export const setRejectionsList = payload => ({
  type: SET_REJECTIONS_LIST,
  payload
})

export const SET_REJECTION_DETAIL = 'SET_REJECTION_DETAIL'
export const setRejectionDetail = payload => ({
  type: SET_REJECTION_DETAIL,
  payload
})
