import { useRef, useState, useEffect } from "react";

import { useTranslation } from "react-i18next";

import { useForm, FormProvider } from "react-hook-form";
import { toast } from "react-toastify";

import useRouter from "../../../utils/hooks/useRouter";
import useAuth from "../../../DataProviders/auth/useAuth";
import useErrors from "../../../utils/hooks/useErrors";
import { Password, Input, Button } from "ui-components";

import "./styles.scss";

const PasswordReset = () => {
  const { t } = useTranslation();

  const methods = useForm({ mode: "onChange" });
  const { handleSubmit, errors, formState, watch, trigger } = methods;
  const { isValid } = formState;
  const { history, location } = useRouter();
  const { getError } = useErrors();

  const {
    methods: { resetPassword },
  } = useAuth();

  const password = useRef({});
  password.current = watch("password", "");
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState(null);
  const [isFetching, setIsFetching] = useState(false);

  const code = location.search.split("?code=")[1];

  const onSubmit = async ({ username, password }) => {
    setIsFetching(true);
    try {
      await resetPassword({
        username,
        newPassword: password,
        confirmationCode: code,
      });
      setShowError(false);
      toast.success(
        <>
          <div className="Toastify__toast__title">{t("resetPassword")}</div>
          <div className="Toastify__toast__subtitle">{t("Now you can use it when you login")}.</div>
        </>
      );
      setIsFetching(false);
      history.push("/login");
    } catch (e) {
      setError(getError(e.code));
      setShowError(true);
      setIsFetching(false);
    }
  };

  useEffect(() => {
    if (password.current) trigger("passwordRepeat"); // retriggers validation
  }, [password.current]);

  return (
    <div className="PasswordReset flex h-center v-center">
      <div className="PasswordReset__wrapper">
        <div className="PasswordReset__logo">
          <img src="/assets/images/logo-full.svg" />
        </div>
        <div className="PasswordReset__title">{t("resetYourPassword")}</div>
        <FormProvider {...methods}>
          <form className="">
            <Input label={t("user")} name="username" rules={{ required: true }} errorobj={errors} />
            <Password
              label={t("newPassword")}
              name="password"
              errorobj={errors}
              rules={{
                required: "Campo requerido",
                pattern: {
                  value: /^(?=\w*\d)(?=\w*[A-ZÑ])(?=\w*[a-zñ])\S{8,12}$/,
                  message:
                    "La constraseña debe tener entre 8 y 12 caracteres, al menos una mayúscula, una minúscula y un dígito numérico",
                },
              }}
            />
            <Password
              label={t("repeatPassword")}
              name="passwordRepeat"
              required={true}
              errorobj={errors}
              rules={{
                validate: value => value === password.current || t("passwordsNotMatch"),
              }}
            />
          </form>
        </FormProvider>
        {showError && (
          <div className="PasswordReset__error">
            <div className="PasswordReset__error__title">{error.title}</div>
            <div className="PasswordReset__error__subtitle">{error.text}</div>
          </div>
        )}
        <Button onClick={handleSubmit(onSubmit)} disabled={!isValid || isFetching}>
          {t("restore")}
        </Button>
        <Button onClick={() => history.push("/login")} variant="secondary">
          {t("cancel")}
        </Button>
      </div>
    </div>
  );
};

export default PasswordReset;
