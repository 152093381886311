import { useEffect } from "react";

import { useTranslation } from "react-i18next";

import useAuth from "../../DataProviders/auth/useAuth";
import useDocProfiles from "../../DataProviders/docProfiles/useDocProfiles";
import useUi from "../../DataProviders/ui/useUi";

import { Button, List, SwitchChip } from "ui-components";

import Add from "./Add";
import Edit from "./Edit";

const DocProfiles = () => {
  const { t } = useTranslation();

  const getColumns = ({ t, onToggleActive, onEdit }) => {
    return [
      {
        id: "name",
        label: t("name"),
      },
      {
        id: "userType",
        label: t("userType"),
        renderCell: value => t(`${value}`),
      },
      {
        id: "documentTypes",
        label: t("documentTypes"),
        align: "center",
        renderCell: value => value?.length || "",
      },
      {
        id: "active",
        label: t("active"),
        align: "center",
        renderCell: (value, obj) => <SwitchChip value={value} onChange={v => onToggleActive(v, obj._id)} />,
      },
      {
        align: "right",
        renderCell: (_, obj) => {
          return (
            <Button onClick={() => onEdit(obj._id)} variant="secondary" prefixIcon="edit" disabled={!obj.active}>
              {t("edit")}
            </Button>
          );
        },
      },
    ];
  };

  const {
    data: { user },
  } = useAuth();

  const {
    data: {
      list,
      totalDocs,
      params: { page, limit },
      detail,
    },
    methods: {
      fetchList,
      fetchDocProfileDetail,
      unsetDocProfileDetail,
      toggleActiveDocProfile,
      handleChangePage,
      handleChangePageSize,
    },
  } = useDocProfiles();

  const {
    methods: { showPanel, closePanel },
  } = useUi();

  const onToggleActive = async (active, id) => {
    toggleActiveDocProfile(id, active);
  };

  const handleAddClick = () => {
    showPanel(() => <Add />);
  };

  useEffect(() => {
    if (detail) showPanel(() => <Edit />, unsetDocProfileDetail);
    else closePanel();
  }, [detail]);

  useEffect(() => {
    fetchList({ sort: "name" });
  }, [user.company?.region]);

  return (
    <List
      t={t}
      id="DocProfiles"
      hide={!list}
      totalDocs={totalDocs}
      title={t("documentProfiles-opt.listWithCount", { count: totalDocs || 0 })}
      headerFilter={null}
      headerActions={
        <div className="PageHeader__actions__lastButton flex end v-center">
          <Button onClick={handleAddClick} prefixIcon="add-white">
            <span className="request">{t("add")}</span>
          </Button>
        </div>
      }
      list={[...list]}
      columns={getColumns({ t, onToggleActive, onEdit: fetchDocProfileDetail })}
      pageData={{ page, limit }}
      pageActions={{
        onChangePage: handleChangePage,
        onChangePageSize: handleChangePageSize,
        onDoubleClick: () => { },
      }}
      params={{}}
    />
  );
};

export default DocProfiles;
