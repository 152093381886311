const amount = amount => {
  let number = amount;
  if (typeof number === "string") {
    number = number.replace(/,/g, ".");
    number = parseFloat(number);
  }
  const arr = number.toFixed(2).split(".");
  return `${(arr[0]).toLocaleString("de-DE")},${arr[1]}`;
};

export default amount;