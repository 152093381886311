import { useEffect, useState, useRef } from "react";

import { useTranslation } from "react-i18next";

import { useForm, FormProvider, useFieldArray } from "react-hook-form";
import { toast } from "react-toastify";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import useTickets from "../../../DataProviders/tickets/useTickets";
import useUi from "../../../DataProviders/ui/useUi";
import { Button, Chip, FileInput, Input, Select as FormSelect } from "ui-components";

import "./styles.scss";

const AddTicket = ({ fetchList, service }) => {
  const { t } = useTranslation();

  const supportDataRef = useRef();
  const centersRef = useRef();
  const textRef = useRef();
  const [destinations, setDestinations] = useState([]);
  const [supportTeams, setSupportTeams] = useState([]);
  const [centers, setCenters] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [fileArr, setFileArr] = useState([]);
  const {
    methods: { getSupportData, addTicket },
  } = useTickets();
  const {
    methods: { closePanel },
  } = useUi();
  const methods = useForm({
    mode: "onChange",
  });
  const { handleSubmit, errors, formState, watch, trigger, control, setValue, getValues } = methods;
  const { isValid } = formState;

  const fetchDestinations = async () => {
    const response = await getSupportData(service);
    supportDataRef.current = response;
    const _destinations = response.map(item => ({ value: item._id, label: item.groupName }));
    await setDestinations(_destinations);
    if (_destinations.length === 1) {
      setValue("ticket.destination.companyId", _destinations[0].value);
      trigger("ticket.destination.companyId");
      handleChangeDestination(_destinations[0].value);
    } else if (service && _destinations.length > 1) {
      setValue("ticket.destination.companyId", _destinations[1].value);
      trigger("ticket.destination.companyId");
      handleChangeDestination(_destinations[1].value);
    } else {
      // setFocus('ticket.destination.companyId')
    }
  };

  const handleChangeDestination = value => {
    centersRef.current = supportDataRef.current.find(item => item._id == value)?.centerList || [];
    const _centers = centersRef.current.map(item => ({ value: item.id, label: item.name }));
    setCenters(_centers);
    if (_centers.length === 1) {
      setValue("ticket.destination.center", _centers[0].value);
      trigger("ticket.destination.center");
      handleChangeCenter(_centers[0].value);
    } else {
      setValue("ticket.destination.center", null);
      setValue("ticket.destination.teamId", null);
      trigger("ticket.destination.center");
      trigger("ticket.destination.teamId");
      // setFocus('ticket.destination.center')
    }
  };

  const handleChangeCenter = value => {
    const rawCenters = centersRef.current.find(item => item.id == value)?.teams || [];
    const _teams = rawCenters.map(item => ({ value: item.id, label: item.name }));
    setSupportTeams(_teams);
    if (_teams.length === 1) {
      setValue("ticket.destination.teamId", _teams[0].value);
      trigger("ticket.destination.teamId");
      // setFocus('ticket.title')
    } else {
      setValue("ticket.destination.teamId", null);
      trigger("ticket.destination.teamId");
      // setFocus('ticket.destination.teamId')
    }
  };

  const handleAttachFile = (e, code) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      // if (!isValidType(file.type, type)) return
      const url = URL.createObjectURL(file);
      setFileArr([...fileArr, { url, file }]);
    }
  };

  const deleteFile = (e, index) => {
    e.stopPropagation();
    fileArr.splice(index, 1);
    setFileArr([...fileArr]);
  };

  const onSubmit = async data => {
    const fd = new FormData();
    if (service) data["ticket"].serviceId = service;
    fd.append("ticket", JSON.stringify(data["ticket"]));
    fd.append("text", textRef.current);
    for (const item of fileArr) {
      fd.append("files", item.file, item.file.name);
    }
    setIsFetching(true);
    try {
      const response = await addTicket(fd);
      await fetchList();
      toast.success(
        <>
          <div className="Toastify__toast__title">Ticket creado correctamente</div>
          <div className="Toastify__toast__subtitle">El ticket ha sido creado y añadido a la lista.</div>
        </>
      );
      closePanel();
    } catch (e) {
      toast.error(
        <>
          <div className="Toastify__toast__title">Error al crear ticket</div>
          <div className="Toastify__toast__subtitle">Ha ocurrido un error en la creación del ticket.</div>
        </>
      );
    }
    setIsFetching(false);
  };

  useEffect(() => {
    fetchDestinations();
  }, []);

  return (
    <div className="AddTicket">
      <div className="AddTicket__header">
        <div className="AddTicket__header__title">Añadir Ticket</div>
        <div className="AddTicket__header__actions">
          <div className="AddTicket__header__description">
            Completa el formulario para enviar un ticket al equipo de soporte seleccionado.
            <br />
            Los campos con (*) son obligatorios.
          </div>
          <div className="AddTicket__header__submit">
            <Button onClick={handleSubmit(onSubmit)} disabled={!isValid || isFetching}>
              {t("add")}
            </Button>
          </div>
        </div>
      </div>
      <div className="AddTicket__body">
        <FormProvider {...methods}>
          <div className="AddTicket__item">
            <div className="AddTicket__item__row">
              <FormSelect
                label={t("for")}
                name="ticket.destination.companyId"
                rules={{ required: true }}
                errorobj={errors}
                options={destinations}
                onSelectChange={handleChangeDestination}
              />
              <FormSelect
                label={t("center")}
                name="ticket.destination.center"
                rules={{ required: true }}
                errorobj={errors}
                options={centers}
                onSelectChange={handleChangeCenter}
              />
            </div>
            <div className="AddTicket__item__row">
              <FormSelect
                label={t("supportTeam")}
                name="ticket.destination.teamId"
                rules={{ required: true }}
                errorobj={errors}
                options={supportTeams}
              />
            </div>
            <div className="AddTicket__item__row">
              <Input
                label={t("subject")}
                name="ticket.title"
                required={true}
                rules={{
                  required: true,
                }}
                errorobj={errors}
              />
            </div>
            <div className="AddTicket__item__row">
              <div>
                <CKEditor
                  editor={ClassicEditor}
                  config={{
                    language: "es",
                    toolbar: {
                      items: [
                        "heading",
                        "|",
                        "alignment",
                        "|",
                        "bold",
                        "italic",
                        "|",
                        "numberedList",
                        "|",
                        "insertTable",
                        "|",
                        "blockQuote",
                        "|",
                        "undo",
                        "redo",
                      ],
                    },
                  }}
                  onReady={editor => {
                    // You can store the "editor" and use when it is needed.
                    // console.log('Editor is ready to use!', editor)
                  }}
                  onChange={(event, editor) => {
                    textRef.current = editor.getData();
                  }}
                />
                <FileInput
                  trigger={
                    <div className="AddTicket__attach pointer">
                      <Button variant="secondary">
                        <div className="flex v-center">
                          <img src="assets/icons/attachment-big.png" />
                          {t("attachment")}
                        </div>
                      </Button>
                    </div>
                  }
                  onChange={e => handleAttachFile(e)}
                  accept={"application/pdf, image/*"}
                />
                <div className="AddTicket__files flex v-center">
                  {fileArr.map((item, index) => (
                    <div key={index} className="AddTicket__files__item flex v-center">
                      <div className="AddTicket__files__item__wrapper-image">
                        <img src={item.file.type !== "application/pdf" ? item.url : "assets/icons/pdf-icon.svg"} />
                      </div>
                      <div>{item.file.name}</div>
                      <div className="AddTicket__files__item__delete" onClick={e => deleteFile(e, index)}>
                        <img src="assets/icons/close.svg" />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </FormProvider>
      </div>
    </div>
  );
};

export default AddTicket;
