import { useDispatch, useSelector } from "react-redux";
import { setCompaniesList, setCompanyDetail } from "./actions";
import useFetch from "../../utils/hooks/useFetch";

const useCompanies = () => {
  const companies = useSelector(state => state.companies);
  const dispatch = useDispatch();
  const { get, post, put, patch, patchImage } = useFetch();

  //CREATE

  const addCompany = async body => {
    return await post({ endpoint: "companies", body });
  };
  //LIST
  const fetchList = async params => {
    const data = await get({ endpoint: "companies", params });
    dispatch(setCompaniesList({ ...data, search: params?.search, filter: params?.filter, sort: params?.sort }));
  };

  const handleChangePage = page => {
    fetchList({ ...companies.params, page });
  };

  const handleChangePageSize = limit => {
    fetchList({ ...companies.params, page: 1, limit });
  };

  const handleChangeSearch = search => {
    fetchList({ ...companies.params, page: 1, search });
  };

  const handleChangeFilters = filter => {
    fetchList({ ...companies.params, page: 1, filter: JSON.stringify(filter) });
  };

  const handleChangeSort = sort => {
    fetchList({ ...companies.params, page: 1, sort });
  };

  //company
  const updateCompany = async (id, body) => {
    const response = await put({ endpoint: "companies", id, body });
    const listCompanyIndex = companies.list.findIndex(item => item._id === response._id);
    if (listCompanyIndex !== -1) {
      const newList = [...companies.list];
      newList[listCompanyIndex] = response;
      dispatch(setCompaniesList({ docs: newList, metadata: { totalDocs: companies.totalDocs } }));
    }
    return response;
  };

  const toggleActiveCompany = async (id, isActive) => {
    const response = await patch({ endpoint: "companies/setactive", id, body: { isActive } });
    const listCompanyIndex = companies.list.findIndex(item => item._id === response._id);
    if (listCompanyIndex !== -1) {
      const newList = [...companies.list];
      newList[listCompanyIndex] = { ...newList[listCompanyIndex], active: response.active };
      dispatch(setCompaniesList({ docs: newList }));
      dispatch(
        setCompaniesList({
          docs: newList,
          metadata: {
            totalPages: companies.totalPages,
            totalDocs: companies.totalDocs,
            params: companies.params,
          },
        })
      );
    }
    return response;
  };

  const toggleLockedCompany = async (id, isLocked) => {
    return;
    const response = await patch({ endpoint: "companies/lockedByNexus", id, body: { isLocked } });
    const listCompanyIndex = companies.list.findIndex(item => item._id === response._id);
    if (listCompanyIndex !== -1) {
      const newList = [...companies.list];
      newList[listCompanyIndex] = { ...newList[listCompanyIndex], lockedByNexus: response.lockedByNexus };
      dispatch(setCompaniesList({ docs: newList }));
      dispatch(
        setCompaniesList({
          docs: newList,
          metadata: {
            totalPages: companies.totalPages,
            totalDocs: companies.totalDocs,
            params: companies.params,
          },
        })
      );
    }
    return response;
  };

  const fetchAllCompanies = async () => {
    return await get({ endpoint: "companies/all" });
  };

  const bulkInvite = async body => {
    return await post({ endpoint: "companies/bulkinvite", body });
  };

  const fetchCompanyDetail = async id => {
    const company = await get({ endpoint: `companies/${id}` });
    dispatch(setCompanyDetail(company));
  };

  const unsetCompanyDetail = () => {
    dispatch(setCompanyDetail(null));
  };

  const updateCompanyImage = async (id, photo) => {
    const response = await patchImage({ endpoint: "companies/setimage", id, body: photo });
    const listCompanyIndex = companies.list.findIndex(item => item._id === response._id);
    if (listCompanyIndex !== -1) {
      const newList = [...companies.list];
      newList[listCompanyIndex] = { ...newList[listCompanyIndex], photo: response.photo };
      dispatch(setCompaniesList({ docs: newList }));
    }
    return response;
  };

  const fetchCompanyProvidersMap = async (companyId) => {
    return await get({ endpoint: `companies/providers/${companyId}`});
  }

  const saveCompanyProvidersMap = async (id, body) => {
    return await patch({ endpoint: "companies/providers", id, body });
  };

  return {
    methods: {
      addCompany,
      fetchList,
      handleChangePage,
      handleChangePageSize,
      handleChangeSearch,
      handleChangeFilters,
      handleChangeSort,
      updateCompany,
      toggleActiveCompany,
      toggleLockedCompany,
      fetchAllCompanies,
      bulkInvite,
      fetchCompanyDetail,
      setCompanyDetail: company => dispatch(setCompanyDetail(company)),
      unsetCompanyDetail,
      updateCompanyImage,
      fetchCompanyProvidersMap,
      saveCompanyProvidersMap,
    },
    data: companies,
  };
};

export default useCompanies;
