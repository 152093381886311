export const SET_OPERATIONTYPES_LIST = 'SET_OPERATIONTYPES_LIST'
export const setOperationTypesList = payload => ({
  type: SET_OPERATIONTYPES_LIST,
  payload
});

export const SET_OPERATIONTYPE_DETAIL = 'SET_OPERATIONTYPE_DETAIL'
export const setOperationTypeDetail = payload => ({
  type: SET_OPERATIONTYPE_DETAIL,
  payload
});
