export const SET_MEDIUMTYPES_LIST = 'SET_MEDIUMTYPES_LIST'
export const setMediumTypesList = payload => ({
  type: SET_MEDIUMTYPES_LIST,
  payload
})

export const SET_MEDIUMTYPE_DETAIL = 'SET_MEDIUMTYPE_DETAIL'
export const setMediumTypeDetail = payload => ({
  type: SET_MEDIUMTYPE_DETAIL,
  payload
})
