import { useDispatch, useSelector } from 'react-redux'

import { setUserList, setUserDetail } from './actions'
import useFetch from '../../utils/hooks/useFetch'

const useUsers = () => {
  const users = useSelector(state => state.users)
  const dispatch = useDispatch()
  const { get, post, put, patch, patchImage } = useFetch()

  //LIST
  const fetchList = async params => {
    const data = await get({ endpoint: 'users', params })
    dispatch(setUserList({ ...data, search: params?.search }))
  }

  const handleChangePage = page => {
    fetchList({ ...users.params, page })
  }

  const handleChangePageSize = limit => {
    fetchList({ ...users.params, page: 1, limit })
  }

  const handleChangeSearch = search => {
    fetchList({ ...users.params, page: 1, search })
  }

  //USER
  const updateUser = async (id, body) => {
    const response = await put({ endpoint: 'users', id, body })
    const listUserIndex = users.list.findIndex(item => item._id === response._id)
    if (listUserIndex !== -1) {
      const newList = [...users.list]
      newList[listUserIndex] = response
      dispatch(setUserList({ docs: newList, metadata: { totalDocs: users.totalDocs } }))
    }
    return response
  }

  const toggleActiveUser = async (id, isActive) => {
    const response = await patch({ endpoint: 'users/setactive', id, body: { isActive } })
    const listUserIndex = users.list.findIndex(item => item._id === response._id)
    if (listUserIndex !== -1) {
      const newList = [...users.list]
      newList[listUserIndex] = { ...newList[listUserIndex], active: response.active }
      dispatch(
        setUserList({
          docs: newList,
          metadata: {
            totalPages: users.totalPages,
            totalDocs: users.totalDocs,
            params: users.params,
          },
        })
      );
    }
    return response
  }

  const fetchWebUsers = async () => {
    return await get({ endpoint: 'users/web' })
  }

  const fetchAllUsers = async () => {
    return await get({ endpoint: 'users/all' })
  }

  const bulkInvite = async body => {
    return await post({ endpoint: 'users/bulkinvite', body })
  }

  const fetchUserDetail = async id => {
    const user = await get({ endpoint: `users/${id}` })
    dispatch(setUserDetail(user))
  }

  const unsetUserDetail = () => {
    dispatch(setUserDetail(null))
  }

  const updateUserImage = async (id, photo) => {
    const response = await patchImage({ endpoint: 'users/setimage', id, body: photo })
    const listUserIndex = users.list.findIndex(item => item._id === response._id)
    if (listUserIndex !== -1) {
      const newList = [...users.list]
      newList[listUserIndex] = { ...newList[listUserIndex], photo: response.photo }
      dispatch(setUserList({ docs: newList }))
    }
    return response
  }

  return {
    methods: {
      fetchList,
      handleChangePage,
      handleChangePageSize,
      handleChangeSearch,
      updateUser,
      toggleActiveUser,
      fetchWebUsers,
      fetchAllUsers,
      bulkInvite,
      fetchUserDetail,
      setUserDetail: user => dispatch(setUserDetail(user)),
      unsetUserDetail,
      updateUserImage
    },
    data: users
  }
}

export default useUsers
