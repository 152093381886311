import { useEffect, useState, useRef } from "react";

import { useTranslation } from "react-i18next";

import YearCalendar from "rc-year-calendar";
import { lightFormat } from "date-fns";

import useAuth from "../../DataProviders/auth/useAuth";
import useCalendar from "../../DataProviders/calendar/useCalendar";

import "./styles.scss";

const NATIONAL_DISTRICT = "00";

const Calendar = () => {
  const { t } = useTranslation();
  
  const yearRef = useRef(new Date().getFullYear());
  const districtRef = useRef(NATIONAL_DISTRICT);
  const [currentDistrict, setCurrentDistrict] = useState(null);
  const [holidays, setHolidays] = useState([]);

  const {
    data: { user },
  } = useAuth();

  const {
    data: { districts, dates },
    methods: { fetchDistricts, fetchDates, addHoliday, removeHoliday },
  } = useCalendar();

  const handleDateClick = e => {
    if (e.events.length && districtRef.current === e.events[0].district) {
      removeHoliday(e.date, districtRef.current);
    } else if (!e.events.length) {
      addHoliday(e.date, districtRef.current);
    }
  };

  const handleYearChange = e => {
    fetchDates(e.currentYear);
    yearRef.current = e.currentYear;
  };

  const getDistrictHolidays = dCode => {
    let _holidays = [];
    const _district = dates.find(item => item.code === dCode);
    if (_district) {
      _holidays = _district.festives.map(item => ({
        startDate: new Date(item),
        endDate: new Date(item),
        color: NATIONAL_DISTRICT === dCode ? "#dcac21" : "#ec754a",
        district: dCode,
      }));
    }
    return _holidays;
  };

  useEffect(() => {
    districtRef.current = currentDistrict;
    if (!dates || !currentDistrict) return;
    let nationalHolidays = [];
    const districtHolidays = getDistrictHolidays(currentDistrict);
    if (currentDistrict !== NATIONAL_DISTRICT) {
      nationalHolidays = getDistrictHolidays(NATIONAL_DISTRICT);
    }
    setHolidays([...districtHolidays, ...nationalHolidays]);
  }, [currentDistrict, dates]);

  useEffect(() => {
    setCurrentDistrict(NATIONAL_DISTRICT);
    fetchDates(yearRef.current);
    fetchDistricts();
  }, [user.company?.region]);

  return (
    <div className="Calendar flex">
      <div className="Calendar__districts">
        {districts.map((item, index) => (
          <div
            className={`Calendar__districts__item ${currentDistrict === item.code ? "active" : ""}`}
            key={index}
            onClick={() => setCurrentDistrict(item.code)}
          >
            {item.name}
          </div>
        ))}
      </div>
      <div className="Calendar__wrapper">
        <YearCalendar
          dataSource={holidays}
          language="es"
          onDayClick={e => handleDateClick(e)}
          disabledWeekDays={[0, 6]}
          onYearChanged={handleYearChange}
          weekStart={1}
          alwaysHalfDay={true}
          allowOverlap={false}
        />
      </div>
    </div>
  );
};

export default Calendar;
