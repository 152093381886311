import { useEffect } from "react";

import { BrowserRouter, Switch, Route } from "react-router-dom";
import useRouter from "./utils/hooks/useRouter";

import useAuth from "./DataProviders/auth/useAuth";
import Login from "./pages/auth/Login";
import PasswordRecovery from "./pages/auth/PasswordRecovery";
import PasswordReset from "./pages/auth/PasswordReset";

import Layout from "./containers/Layout";

import Dashboard from "./pages/Dashboard";
import BI from "./pages/BI";
import Users from "./pages/Users";
import Profiles from "./pages/Profiles";
import Companies from "./pages/Companies";
import Calendar from "./pages/Calendar";

// Configuration
import MediumTypes from "./pages/MediumTypes";
import OperationTypes from "./pages/OperationTypes";
import AuxServiceTypes from "./pages/AuxServiceTypes";
import VehicleTypes from "./pages/VehicleTypes";
import Labeled from "./pages/Labeled";
import Rejections from "./pages/Rejections";
import Outlays from "./pages/Outlays";

// Financial
import Shipments from "./pages/Shipments";
import Payments from "./pages/Payments";

// Documentation
import DocTypes from "./pages/DocTypes";
import DocProfiles from "./pages/DocProfiles";
import DocReviews from "./pages/DocReviews";

import Support from "./pages/Support";

import Tickets from "./pages/Tickets";

import ToastWrapper from "./components/ToastWrapper";

import Loader from "./containers/Loader";

import { requestFirebaseNotificationPermission, onMessageListener } from "./services/FireBaseService";

import "./styles/bootstrap.scss";
import "./styles/base.scss";
import "./styles/app.scss";
import "./styles/responsive.scss";
import "ui-components/dist/index.css";

const MainApp = () => {
  const { history } = useRouter();
  const {
    methods: { me, registerFBtoken },
    data: { isAuthenticated },
  } = useAuth();

  useEffect(() => {
    if (!isAuthenticated) me(() => history.push("/login"));
    requestFirebaseNotificationPermission()
      .then(firebaseToken => {
        registerFBtoken(firebaseToken);
      })
      .catch(err => {
        // User denies permission for Firebase push notifications
        return err;
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  onMessageListener()
    .then(payload => {
      // console.log("onMessageListener - payload:");
      // console.log(payload);
    })
    .catch(err => {
      // console.log(JSON.stringify(err));
    });

  return (
    <Layout>
      <Route path="/" component={Dashboard} exact />

      <Route path="/bi" component={BI} exact />
      <Route path="/users" component={Users} exact />
      <Route path="/profiles" component={Profiles} exact />
      <Route path="/companies" component={Companies} exact />
      <Route path="/calendar" component={Calendar} exact />
      <Route path="/medium-types" component={MediumTypes} exact />
      <Route path="/operation-types" component={OperationTypes} exact />
      <Route path="/aux-service-types" component={AuxServiceTypes} exact />
      <Route path="/vehicle-types" component={VehicleTypes} exact />
      <Route path="/labeled" component={Labeled} exact />
      <Route path="/rejections" component={Rejections} exact />
      <Route path="/outlays" component={Outlays} exact />
      <Route path="/shipments" component={Shipments} exact />
      <Route path="/payments" component={Payments} exact />
      <Route path="/doc-types" component={DocTypes} exact />
      <Route path="/doc-profiles" component={DocProfiles} exact />
      <Route path="/doc-reviews" component={DocReviews} exact />
      <Route path="/support" component={Support} exact />

      <Route path="/tickets" component={Tickets} exact />
    </Layout>
  );
};

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route path="/login" component={Login} exact />
          <Route path="/recovery" component={PasswordRecovery} exact />
          <Route path="/reset-password" component={PasswordReset} exact />
          <Route path="*" component={MainApp} />
        </Switch>
      </BrowserRouter>
      <ToastWrapper />
      <Loader />
    </div>
  );
}

export default App;
