import { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";

import { toast } from "react-toastify";

import { Button } from "ui-components";
import useDocuments from "../../DataProviders/documents/useDocuments";
import AttachDocuments from "./Attach";

import "./styles.scss";

const Documents = ({ documentTypes, referenceId, reference }) => {
  const { t } = useTranslation();

  const [openRow, setOpenRow] = useState(-1);
  const [attachingDoc, setAttachingDoc] = useState(null);
  const [docs, setDocs] = useState([]);
  const [imagePreview, setImagePreview] = useState(null);

  const {
    methods: { fetchDocuments, uploadDocuments },
    data: documents,
  } = useDocuments();

  const toggleOpenRow = index => {
    if (openRow === index) {
      setOpenRow(-1);
    } else {
      setOpenRow(index);
    }
  };

  const handleAttach = index => {
    setAttachingDoc(documentTypes[index]);
  };

  const handleClickImage = (e, file) => {
    e.preventDefault();
    e.stopPropagation();
    setImagePreview(file);
  };

  const handleSubmitAttaching = async fileObj => {
    const fd = new FormData();
    fd.append("reference", reference);
    fd.append("referenceId", referenceId);
    fd.append("documentTypeId", attachingDoc._id);
    for (const key in fileObj) {
      const extension = fileObj[key].file.name.split(".").pop();
      fd.append("files", fileObj[key].file, `${key}.${extension}`);
    }
    try {
      // await uploadDocuments(fd)
      // ÑAPA DEL SIGLO DE ADOLFO
      await uploadDocuments(fd, reference, referenceId, attachingDoc._id);
      await fetchDocuments(referenceId);
      setAttachingDoc(null);
      toast.success(
        <>
          <div className="Toastify__toast__title">Documentos actualizados</div>
          <div className="Toastify__toast__subtitle">Hemos actualizado los documentos con éxito.</div>
        </>
      );
    } catch (e) {
      toast.error(
        <>
          <div className="Toastify__toast__title">{t("anErrorHasOccurred")}</div>
          <div className="Toastify__toast__subtitle">No hemos podido subir los documentos.</div>
        </>
      );
    }
  };

  const renderAttachment = attachment => {
    switch (attachment.contentType) {
      case "application/pdf":
        return (
          <a
            href={attachment.url}
            target="_blank"
            onClick={e => {
              e.stopPropagation();
            }}
            className="Documents__document__list__item__pdf flex h-center v-center"
          >
            <img src="/assets/icons/pdf-icon.svg" />
          </a>
        );
      default:
        return (
          <div className="Documents__document__list__item__image" onClick={e => handleClickImage(e, attachment)}>
            <img src={attachment.url} />
          </div>
        );
    }
  };

  useEffect(() => {
    const _docs = documentTypes.map(item => ({
      name: item.name,
      documents: documents
        .filter(doc => doc.documentTypeId === item._id)
        .map(docItem => ({ status: docItem.status, attachments: docItem.attachments })),
    }));
    setDocs(_docs);
  }, [documents]);

  useEffect(() => {
    fetchDocuments(referenceId);
  }, [referenceId]);

  return (
    <div className="Documents">
      {(attachingDoc && (
        <AttachDocuments
          attachingDoc={attachingDoc}
          onCancelAttaching={() => setAttachingDoc(null)}
          onSubmit={handleSubmitAttaching}
        />
      )) || (
          <div className="Documents__list">
            {docs.map((item, index) => (
              <div
                className={`Documents__document ${openRow === index ? "Documents__document--open" : ""}`}
                onClick={() => toggleOpenRow(index)}
                key={index}
              >
                <div className="Documents__document__header flex between v-center">
                  <div className="Documents__document__header__name">
                    <img
                      src={openRow !== index ? "/assets/icons/dropdown-open.svg" : "/assets/icons/dropdown-close.svg"}
                    />
                    {item.name}
                  </div>
                  <div className="flex v-center">
                    <div
                      className={`Documents__document__header__status Documents__document__header__status--${item.documents[0]?.status}`}
                    >
                      {item.documents.length > 0 ? t(`${item.documents[0].status}`) : "Sin archivos"}
                    </div>
                    <div className="Documents__document__header__button">
                      <Button variant="secondary" prefixIcon="attachment" onClick={() => handleAttach(index)}>
                        {t("attachment")}
                      </Button>
                    </div>
                  </div>
                </div>
                {item.documents.map((doc, docIndex) => (
                  <div className="Documents__document__row" key={docIndex}>
                    <div className={`Documents__document__status Documents__document__status--${doc.status}`}>
                      {t(`${doc.status}`)}
                    </div>
                    <div className="Documents__document__list flex">
                      {doc.attachments.map((attachment, attachmentIndex) => (
                        <div className="Documents__document__list__item" key={attachmentIndex}>
                          {renderAttachment(attachment)}
                          <div className="Documents__document__list__item__name">{attachment.code}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
                <div></div>
              </div>
            ))}
          </div>
        )}
      {imagePreview && (
        <div className="Documents__preview flex v-center h-center" onClick={() => setImagePreview(null)}>
          <div className="Documents__preview__container">
            <div className="Documents__preview__close" onClick={() => setImagePreview(null)}>
              <img src="/assets/icons/close.svg" />
            </div>
            <div className="Documents__preview__name">{imagePreview.code}</div>
            <div className="Documents__preview__image">
              <img src={imagePreview.url} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Documents;
