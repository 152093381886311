import { useState } from "react";

import { useTranslation } from "react-i18next";

import { useForm, FormProvider } from "react-hook-form";

import useAuxServiceTypes from "../../../DataProviders/auxServiceTypes/useAuxServiceTypes";
import useUi from "../../../DataProviders/ui/useUi";

import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import { Button, Input } from "ui-components";

import { toast } from "react-toastify";

import "../../../styles/add-edit.scss";

const AddAuxServiceType = () => {
  const { t } = useTranslation();

  const [isFetching, setIsFetching] = useState(false);

  const [iconUrl, setIconUrl] = useState();
  const [isIconValid, setIsIconValid] = useState(false);

  const [mapIconUrl, setMapIconUrl] = useState();
  const [isMapIconValid, setIsMapIconValid] = useState(false);

  const [infoIconUrl, setInfoIconUrl] = useState();
  const [isInfoIconValid, setIsInfoIconValid] = useState(false);

  const {
    methods: { fetchList, addAuxServiceType },
  } = useAuxServiceTypes();

  const {
    methods: { closePanel },
  } = useUi();

  const methods = useForm({
    mode: "onChange",
  });

  const { handleSubmit, formState } = methods;
  const { isValid } = formState;

  const checkImage = (path, resolve, reject) => {
    let img = new Image();
    img.onload = resolve;
    img.onerror = reject;
    img.src = path;
  };

  const handleChangeIconUrl = evnt => {
    evnt.target.value = evnt.target.value.trim();
    const resolve = () => {
      setIsIconValid(true);
      setIconUrl(evnt.target.value);
    };
    const reject = () => {
      setIsIconValid(false);
      setIconUrl(null);
    };
    checkImage(evnt.target.value, resolve, reject);
  };

  const handleChangeMapIconUrl = evnt => {
    evnt.target.value = evnt.target.value.trim();
    const resolve = () => {
      setIsMapIconValid(true);
      setMapIconUrl(evnt.target.value);
    };
    const reject = () => {
      setIsMapIconValid(false);
      setMapIconUrl(null);
    };
    checkImage(evnt.target.value, resolve, reject);
  };

  const handleChangeInfoIconUrl = evnt => {
    evnt.target.value = evnt.target.value.trim();
    const resolve = () => {
      setIsInfoIconValid(true);
      setInfoIconUrl(evnt.target.value);
    };
    const reject = () => {
      setIsInfoIconValid(false);
      setInfoIconUrl(null);
    };
    checkImage(evnt.target.value, resolve, reject);
  };

  const onSubmit = async data => {
    data.active = true;

    setIsFetching(true);
    try {
      await addAuxServiceType(data);
      await fetchList();
      toast.success(
        <>
          <div className="Toastify__toast__title">Servicio auxiliar creado correctamente</div>
          <div className="Toastify__toast__subtitle">El servicio auxiliar ha sido creado y añadido a la lista.</div>
        </>
      );
      closePanel();
    } catch (err) {
      console.log(err);
      toast.error(
        <>
          <div className="Toastify__toast__title">Error al crear servicio auxiliar</div>
          <div className="Toastify__toast__subtitle">Ha ocurrido un error en la creación del servicio auxiliar.</div>
        </>
      );
    }
    setIsFetching(false);
  };

  return (
    <div className="Add">
      <div className="Add__header">
        <IconButton onClick={closePanel}>
          <CloseIcon />
        </IconButton>
        <div className="Add__header__title">{t("addAuxService")}</div>
        <div className="Add__header__content">
          <div className="Add__header__content__description">
            {t("Complete the data of the auxiliar service to add it")}
            <br />
            {t("fields with an asterisk are required")}
          </div>
          <Button onClick={handleSubmit(onSubmit)} disabled={!isValid || isFetching || !isIconValid}>
            {t("add")}
          </Button>
        </div>
      </div>
      <div className="Add__body">
        <FormProvider {...methods}>
          <form className="" noValidate>
            <div className="Add__item">
              <div className="Add__item__row">
                <Input label={t("name")} name="name" required={true} rules={{ required: true }} />
                <Input
                  name="sharedCode"
                  label={t("sharedCode")}
                  inputProps={{ maxLength: 20 }}
                  required={true}
                  rules={{
                    required: true,
                  }}
                />
              </div>

              <div className="Add__divider"></div>

              <div className="Add__item__row">
                <div className="Add__icon">
                  <div className="Add__icon url">
                    <div className="title">{t("identificationIcon").toUpperCase()}</div>
                    <Input
                      className="inputUrl"
                      label={t("iconUrl")}
                      name="imageUrl"
                      inputProps={{
                        onChange: evnt => handleChangeIconUrl(evnt),
                      }}
                      required={true}
                      rules={{ required: true }}
                    />
                  </div>

                  <div className={`AddAuxServiceType__icon imageWrapper${isIconValid ? "" : " null"} small`} style={{"padding": "29px"}}>
                    <img className="image" src={isIconValid ? iconUrl : "./assets/images/svgFile.svg"} alt="" />
                  </div>
                </div>
              </div>

              <div className="Add__divider"></div>

              <div className="Add__item__row">
                <div className="Add__icon">
                  <div className="Add__icon url">
                    <div className="title">{t("mapIcon").toUpperCase()}</div>
                    <Input
                      className="inputUrl"
                      label={t("iconUrl")}
                      name="imageMapUrl"
                      inputProps={{
                        onChange: evnt => handleChangeMapIconUrl(evnt),
                      }}
                      required={true}
                      rules={{ required: true }}
                    />
                  </div>

                  <div className={`AddAuxServiceType__icon imageWrapper${isMapIconValid ? "" : " null"} small`} style={{"padding": "5px"}}>
                    <img className="image" src={isMapIconValid ? mapIconUrl : "./assets/images/svgFile.svg"} alt="" />
                  </div>
                </div>
              </div>

              <div className="Add__divider"></div>

              <div className="Add__item__row">
                <div className="Add__icon">
                  <div className="Add__icon url">
                    <div className="title">{t("infoWindowIcon").toUpperCase()}</div>
                    <Input
                      className="inputUrl"
                      label={t("iconUrl")}
                      name="imageInfoUrl"
                      inputProps={{
                        onChange: evnt => handleChangeInfoIconUrl(evnt),
                      }}
                      required={true}
                      rules={{ required: true }}
                    />
                  </div>

                  <div className={`AddAuxServiceType__icon imageWrapper${isInfoIconValid ? "" : " null"} small`} style={{"padding": "31px"}}>
                    <img className="image" src={isInfoIconValid ? infoIconUrl : "./assets/images/svgFile.svg"} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

export default AddAuxServiceType;
