import { SET_DOCTYPE_LIST, SET_DOCTYPE_DETAIL } from './actions'

const INITIAL_STATE = {
  detail: null,
  list: [],
  totalPages: 1,
  totalDocs: 0,
  params: {
    page: 1,
    limit: 10,
    search: '',
    sort: 'name'
  }
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_DOCTYPE_LIST:
      return {
        ...state,
        list: action.payload.docs,
        totalPages: action.payload.metadata?.totalPages || state.totalPages,
        totalDocs: action.payload.metadata?.totalDocs || state.totalDocs,
        params: {
          ...state.params,
          page: action.payload.metadata?.page || state.params.page,
          limit: action.payload.metadata?.limit || state.params.limit,
          search: action.payload.search
        }
      }
    case SET_DOCTYPE_DETAIL:
      return {
        ...state,
        detail: action.payload
      }

    default:
      return state
  }
}
