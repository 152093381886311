import { useState, useRef, useEffect } from "react";

import { useTranslation } from "react-i18next";

import { useForm, FormProvider, useFieldArray } from "react-hook-form";
import { toast } from "react-toastify";

import { Button, Input, Select as FormSelect, SwitchChip } from "ui-components";
import useUi from "../../../DataProviders/ui/useUi";
import Documents from "../../../containers/Documents";
import useDocTypes from "../../../DataProviders/docTypes/useDocTypes";
import DateInput from "../../../components/DateInput";

import "./styles.scss";

const EditDocType = () => {
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState("summary");
  const [isFetching, setIsFetching] = useState(false);
  const [categories, setCategories] = useState([]);
  const [isSelfValidated, setIsSelfValidated] = useState([]);
  const [isExpire, setIsExpire] = useState(false);
  const [isRequired, setIsRequired] = useState(false);

  const {
    methods: { closePanel },
  } = useUi();
  const {
    data: { detail },
    methods: { updateDocType, getDocTypeCategories },
  } = useDocTypes();

  const methods = useForm({
    mode: "onChange",
    defaultValues: detail,
  });
  const { handleSubmit, errors, formState, setValue, trigger, control } = methods;
  const { isValid } = formState;
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
    control,
    name: "attachments",
  });

  const fetchCategories = async () => {
    const response = await getDocTypeCategories();
    const _types = response.map(item => ({ value: item.contentType, label: item.name }));
    setCategories(_types);
  };

  const parseArrayError = (errors, index, fieldName) => {
    const name = `attachments[${index}].${fieldName}`;
    return (
      errors?.attachments?.[index]?.[fieldName] && {
        [name]: errors?.attachments?.[index]?.[fieldName],
      }
    );
  };

  const handleRemoveRow = index => {
    if (fields.length > 1) {
      remove(index);
    }
  };

  const onSubmit = async values => {
    const data = { ...values, required: isRequired, expires: isExpire, autoValidate: isSelfValidated };
    if (!values.validFrom) {
      data.validFrom = new Date();
    }
    setIsFetching(true);
    try {
      await updateDocType(detail._id, {
        ...detail,
        ...data,
      });
      toast.success(
        <>
          <div className="Toastify__toast__title">Tipo de documentos actualizado</div>
          <div className="Toastify__toast__subtitle">Hemos actualizado la información del tipo de documentos.</div>
        </>
      );
      closePanel();
    } catch (e) {
      toast.error(
        <>
          <div className="Toastify__toast__title">{t("anErrorHasOccurred")}</div>
          <div className="Toastify__toast__subtitle">
            No hemos podido actualizar la información del tipo de documentos.
          </div>
        </>
      );
    }
    setIsFetching(false);
  };

  useEffect(() => {
    if (detail) {
      fetchCategories();
      setIsSelfValidated(detail.selfValidated || false);
      setIsExpire(detail.expires || false);
      setIsRequired(detail.required || false);
    }
  }, [detail]);

  return (
    <div className="EditDocType">
      <FormProvider {...methods}>
        <div className="EditDocType__header flex">
          <div className="EditDocType__header__info">
            <div className="EditDocType__header__personal flex">
              <div className="EditDocType__header__name">{detail.name}</div>
            </div>
            <div className="EditDocType__header__misc flex v-center capitalize">
              {detail.active ? "activo" : "no activo"}
            </div>
            <div className="EditDocType__header__actions flex end">
              <Button onClick={handleSubmit(onSubmit)} disabled={!isValid || isFetching}>
                {t("save")}
              </Button>
            </div>
          </div>
        </div>
        <div className="EditDocType__body">
          <div className="EditDocType__main">
            <div className="EditDocType__tabs flex">
              <div
                className={`EditDocType__tabs__item ${activeTab === "summary" ? "active" : ""}`}
                onClick={() => setActiveTab("summary")}
              >
                RESUMEN
              </div>
              <div className={`EditDocType__tabs__item ${activeTab === "docs" ? "active" : ""}`} onClick={() => { }}>
                DOCUMENTOS
              </div>
            </div>
            {activeTab === "summary" ? (
              <div className="EditDocType__summary">
                <div className="EditDocType__summary__section">
                  <div className="EditDocType__summary__title">INFORMACIÓN GENERAL</div>
                  <div className="EditDocType__summary__form">
                    <div className="EditDocType__summary__form__row">
                      <Input label={t("name")} name="name" required={true} rules={{ required: true }} errorobj={errors} />
                      < DateInput label={t("validFrom")} name="validFrom" />
                    </div>
                    <div className="EditDocType__summary__form__row">
                      <div className="flex around">
                        <div>
                          <div className="EditDocType__SwitchLabel firstSwitchLabel">{t("selfValidated")}</div>
                          <SwitchChip value={isSelfValidated} onChange={v => setIsSelfValidated(v)} />
                        </div>
                        <div>
                          <div className="EditDocType__SwitchLabel secondSwitchLabel">{t("expires")}</div>
                          <SwitchChip value={isExpire} onChange={v => setIsExpire(v)} />
                        </div>
                        <div>
                          <div className="EditDocType__SwitchLabel thirdSwitchLabel">{t("required")}</div>
                          <SwitchChip value={isRequired} onChange={v => setIsRequired(v)} />
                        </div>
                      </div>
                      <Input
                        label={t("graceDays")}
                        name="graceDays"
                        required={true}
                        rules={{
                          required: true,
                        }}
                        errorobj={errors}
                      />
                    </div>
                  </div>
                </div>
                <div className="EditDocType__summary__section">
                  <div className="EditDocType__summary__title">{t("attachments").toUpperCase()}</div>
                  <div className="EditDocType__summary__form">
                    {fields.map((item, index) => (
                      <div key={item.id} className="EditDocType__item">
                        {fields.length > 1 && (
                          <div className="EditDocType__item__close" onClick={() => handleRemoveRow(index)}>
                            <img src="/assets/icons/close.svg" />
                          </div>
                        )}
                        <div className="EditDocType__summary__form__row">
                          <FormSelect
                            label={t("type")}
                            name={`attachments[${index}].type`}
                            defaultValue={item.type}
                            required={true}
                            rules={{ required: true }}
                            errorobj={parseArrayError(errors, index, "type")}
                            options={categories}
                          />
                          <Input
                            label={t("description")}
                            defaultValue={item.code}
                            name={`attachments[${index}].code`}
                            required={true}
                            rules={{ required: true }}
                            errorobj={parseArrayError(errors, index, "code")}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="EditDocType__add">
                    <Button
                      variant="secondary"
                      onClick={() => {
                        append({});
                      }}
                    >
                      <img src="/assets/icons/add.svg" alt="" />
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              <Documents
                documentTypes={detail.documentation?.documentTypes || []}
                referenceId={detail._id}
                reference="deposits"
              />
            )}
          </div>
        </div>
      </FormProvider>
    </div>
  );
};

export default EditDocType;
