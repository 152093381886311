import { RESET_SHIPMENTS_LIST, SET_SHIPMENTS_LIST, SET_SHIPMENT_DETAIL } from "./actions";

const INITIAL_STATE = {
  shipmentDetail: null,
  list: [],
  totalPages: 1,
  totalDocs: 0,
  params: {
    page: 1,
    limit: 10,
    search: "",
    sort: "",
    filter: null,
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RESET_SHIPMENTS_LIST:
      return {
        ...INITIAL_STATE,
      };
    case SET_SHIPMENTS_LIST:
      return {
        ...state,
        list: action.payload.docs,
        totalPages: action.payload.metadata?.totalPages,
        totalDocs: action.payload.metadata?.totalDocs,
        params: {
          ...state.params,
          page: action.payload.metadata?.page || state.params.page,
          limit: action.payload.metadata?.limit || state.params.limit,
          search: action.payload.search,
          filter: action.payload.filter,
          sort: action.payload.sort,
        },
      };
    case SET_SHIPMENT_DETAIL:
      return {
        ...state,
        shipmentDetail: action.payload,
      };

    default:
      return state;
  }
};
