import { useState, useEffect, useRef, useLayoutEffect } from "react";

import { useTranslation } from "react-i18next";

import useBI from "../../DataProviders/bi/useBI";
import useUi from "../../DataProviders/ui/useUi";
import useClickOut from "../../utils/hooks/useClickOut";

import useResizeObserver from "@react-hook/resize-observer";

import { PageWrapper } from "ui-components";
import { PageHeader } from "ui-components";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { PageBody } from "ui-components";

import { Button, Chip } from "ui-components";

import { DateFilter } from "ui-components";
import { format, startOfDay, endOfDay, sub, set } from "date-fns";
import es from "date-fns/locale/es";

import GridLayout from "react-grid-layout";

import { ChartMongoDB } from "ui-components";

import AddCharts from "./AddCharts";

import _ from "lodash";

import "react-resizable/css/styles.css";
import "react-datepicker/dist/react-datepicker.css";
import "overlayscrollbars/css/OverlayScrollbars.css";
import "react-grid-layout/css/styles.css";

import "./rgl.scss";
import "./styles.scss";

const useSize = target => {
  const [size, setSize] = useState();
  useLayoutEffect(() => {
    setSize(target.current.getBoundingClientRect());
  }, [target]);
  useResizeObserver(target, entry => setSize(entry.contentRect));
  return size;
};

let totalChartsToRender = 0;
let chartRenderedCounter = 0;

let initialHiddenCharts = [];

let chartInstances = [];
let fullScreenChartInstance = null;

let restoreViewBtnVisible;
let saveViewBtnVisible;
let btnControlDelegation;

const BI = () => {
  const { t } = useTranslation();

  const { fetchDefaultCharts, fetchUserProfile, saveUserProfile, removeUserProfile } = useBI();

  const target = useRef(null); // parent of div.PageWrapper
  let size = useSize(target);

  const overlayScrollbarsRef = useRef();

  const serviceTypeRef = useRef();
  const [serviceTypeFilter, setServiceTypeFilter] = useState({
    transfers: false,
    ris: false,
  }); // "execution.ris": [0|1] -> 0 transfers, 1 ris
  const [serviceTypeFilterCount, setServiceTypeFilterCount] = useState(0);
  const [showServiceType, setShowServiceType] = useState(false);
  useClickOut(serviceTypeRef, () => setShowServiceType(false));

  const dateRef = useRef();
  const [today, setToday] = useState(new Date());
  const [startDate, setStartDate] = useState(startOfDay(sub(today, { days: 29 })));
  const [endDate, setEndDate] = useState(endOfDay(today));
  const [dateFilterOption, setDateFilterOption] = useState("last30Days");
  const [showDateFilter, setShowDateFilter] = useState(false);
  useClickOut(dateRef, () => setShowDateFilter(false));

  const {
    methods: { showPanel, closePanel },
  } = useUi();


  const [renderGridCounter, setRenderGridCounter] = useState(1);

  const [gridColumns, setGridColumns] = useState(28);
  const [gridMargin, setGridMargin] = useState(5);

  const [allGridItemProtected, setAllGridItemProtected] = useState(false);

  const [biToken, setBiToken] = useState();

  const [defaultCharts, setDefaultCharts] = useState([]);
  const [defaultChartsLayout, setDefaultChartsLayout] = useState([]);

  const [hiddenCharts, setHiddenCharts] = useState([]);
  const [chartIdToShow, setChartIdToShow] = useState();
  const [makingChartVisible, setMakingChartVisible] = useState(false);

  const [workspaceChartsLayout, setWorkspaceChartsLayout] = useState([]);

  const [fullScreen, setFullScreen] = useState(false);
  const [fullScreenChart, setFullScreenChart] = useState(null);

  const [disabledFilters, setDisabledFilters] = useState(true);

  const handleAddChartsClick = () => {
    showPanel(() => <AddCharts hiddenCharts={[...hiddenCharts]} onChangeChartVisibility={handleShowChart} />);
  };

  /*** fetch default charts & user profile ***/

  const getDefaultCharts = async () => {
    restoreViewBtnVisible = false;
    saveViewBtnVisible = false;
    btnControlDelegation = false;
    try {
      const response = await fetchDefaultCharts();
      setBiToken(response.biToken);
      totalChartsToRender = response.biCharts.filter(chart => chart.default).length; // only visible charts (chat.default: true)

      setDefaultCharts([...response.biCharts]);

      initialHiddenCharts = response.biCharts.filter(chart => !chart.default);
      setHiddenCharts(initialHiddenCharts);


      setDefaultChartsLayout(
        response.biCharts.filter(chart => chart.default).map(chart => {
          const { column, row } = chart.location;
          const { width, height } = chart.size;
          return {
            i: chart._id,
            x: column,
            y: row,
            w: width,
            h: height,
          };
        })
      );

    } catch (error) {
      // console.log(error);
    }
  };

  const getUserProfileCharts = async () => {
    try {
      const userResponse = await fetchUserProfile();
      if (!userResponse) {
        restoreViewBtnVisible = false;
        saveViewBtnVisible = false;
        btnControlDelegation = false;
        setWorkspaceChartsLayout([...defaultChartsLayout]);
      } else {
        const chartIdsToShow = userResponse.map(chart => chart.id);
        setHiddenCharts(defaultCharts.filter(chart => {
          return !chartIdsToShow.includes(chart._id);
        }));
        totalChartsToRender = userResponse.length;
        const layout = userResponse.map(chart => {
          const { column, row } = chart.location;
          const { width, height } = chart.size;
          return {
            i: chart.id,
            x: column,
            y: row,
            w: width,
            h: height,
          };
        });
        restoreViewBtnVisible = true;
        saveViewBtnVisible = false;
        btnControlDelegation = false;
        setWorkspaceChartsLayout(layout);
      }
    } catch (error) {
      // console.log(error);
    }
  };

  /*** workspace layout ***/

  const handleChangeWorkspaceLayout = Layout => {
    setAllGridItemProtected(false);
    if (!btnControlDelegation) {
      const resizeHandlers = document.querySelectorAll(
        ".react-grid-item > .react-resizable-handle.react-resizable-handle-se"
      );
      Array.from(resizeHandlers).forEach(el => {
        el.addEventListener("mousedown", event => {
          event.preventDefault();
          setAllGridItemProtected(true);
        });
        el.addEventListener("mouseup", event => {
          event.preventDefault();
          setAllGridItemProtected(false);
        });
      });
      btnControlDelegation = true;
      return;
    }
    const newLayout = Layout.map(chart => {
      return {
        i: chart.i,
        x: chart.x,
        y: chart.y,
        w: chart.w,
        h: chart.h,
      };
    });
    restoreViewBtnVisible = true;
    saveViewBtnVisible = true;
    if (!makingChartVisible) {
      setWorkspaceChartsLayout([...newLayout]);
    } else {
      setMakingChartVisible(false);
    }
  };

  const restoreWorkspace = async () => {

    overlayScrollbarsRef.current.osInstance().scroll({ x: 0, y: 0 }, 500, "linear");
    restoreViewBtnVisible = false;
    saveViewBtnVisible = false;
    btnControlDelegation = false;
    setDisabledFilters(true);

    totalChartsToRender = defaultChartsLayout.length;
    chartRenderedCounter = 0;
    chartInstances = [];

    setHiddenCharts(initialHiddenCharts);
    setWorkspaceChartsLayout([...defaultChartsLayout]);
    setRenderGridCounter(renderGridCounter + 1);
    try {
      await removeUserProfile();
    } catch (error) {
      // console.log(error);
    }

  };

  const saveWorkspace = async () => {
    restoreViewBtnVisible = true;
    saveViewBtnVisible = false;
    const layoutToSave = workspaceChartsLayout.map(chart => {
      return {
        id: chart.i,
        size: {
          width: chart.w,
          height: chart.h,
        },
        location: {
          row: chart.y,
          column: chart.x,
        },
      };
    });
    try {
      await saveUserProfile(layoutToSave);
    } catch (error) {
      // console.log(error);
    }
  };

  /*** charts ***/

  const getChartSource = i => defaultCharts.find(item => item._id === i).source;

  const onChartRendered = chart => {
    ++chartRenderedCounter;
    chartInstances.push(chart);
    if (chartRenderedCounter === totalChartsToRender) {
      setDisabledFilters(false);
    }
  };

  const removeChartInstance = chartId => {
    const defaultChart = defaultCharts.find(chart => chart._id === chartId);
    const chartIndex = chartInstances.findIndex(chart => chart.options.chartId === defaultChart.source.chartId);
    chartInstances.splice(chartIndex, 1);
  };

  const handleHideChart = (event, chartId) => {
    event.stopPropagation();
    event.preventDefault();
    removeChartInstance(chartId);
    --chartRenderedCounter;
    --totalChartsToRender;
    setHiddenCharts([...hiddenCharts, defaultCharts.find(chart => chart._id === chartId)]);
    setWorkspaceChartsLayout(workspaceChartsLayout.filter(chart => chart.i !== chartId));
  };

  const handleShowChart = chartId => {
    overlayScrollbarsRef.current.osInstance().scroll({ x: 0, y: "100%" }, 500, "linear");
    setChartIdToShow(chartId);
  };

  const onFullScreenChartRendered = chart => (fullScreenChartInstance = chart);

  const handleChartFullScreen = (event, fullScreenStatus, chartId = null) => {
    event.stopPropagation();
    event.preventDefault();
    if (fullScreenStatus) {
      setFullScreenChart(defaultCharts[defaultCharts.findIndex(chart => chart._id === chartId)]);
    } else {
      fullScreenChartInstance = null;
      setFullScreenChart(null);
    }
    setFullScreen(fullScreenStatus);
  };

  /*** filters ***/

  const SERVICE_TYPE_OPTIONS = [
    {
      label: t("transfers"),
      value: "transfers",
      color: "236, 117, 74",
    },
    {
      label: t("ris"),
      value: "ris",
      color: "236, 117, 74",
    },
  ];

  const handleChangeServiceTypeFilter = (serviceType, value) => {
    setServiceTypeFilterCount(serviceTypeFilterCount + (value ? 1 : -1));
    setServiceTypeFilter({ ...serviceTypeFilter, [serviceType]: value });
  };

  const handleChangeDateFilter = (d1, d2, option) => {
    setShowDateFilter(false);
    setStartDate(startOfDay(d1));
    setEndDate(endOfDay(_.isNull(d2) ? d1 : d2));
    setDateFilterOption(option);
  };

  const resetDateFilter = event => {
    event.preventDefault();
    event.stopPropagation();
    setShowServiceType(false);
    setShowDateFilter(false);
    setStartDate(null);
    setEndDate(null);
    setDateFilterOption(null);
  };

  const getFilter = () => {
    let filter = {};
    if (startDate && endDate) {
      filter = {
        "dates.operation": {
          $gte: startDate,
          $lte: endDate,
        },
      };
    }
    if (serviceTypeFilterCount === 1) {
      filter = {
        ...filter,
        "execution.ris": serviceTypeFilter.ris ? 1 : 0,
      };
    }
    return filter;
  };

  const applyFilter = () => {
    const filter = getFilter();
    chartInstances.map(chart => chart.setFilter(filter));
    if (fullScreenChartInstance) fullScreenChartInstance.setFilter(filter);
  };

  /*** useEffect ***/

  useEffect(() => {
    if (_.isEmpty(defaultChartsLayout)) return;
    getUserProfileCharts();
  }, [defaultChartsLayout]);

  useEffect(() => {
    if (_.isEmpty(chartInstances)) return;
    applyFilter();
  }, [serviceTypeFilter, startDate, endDate]);

  useEffect(() => {
    if (!chartIdToShow) return;
    setHiddenCharts(hiddenCharts.filter(chart => chart._id !== chartIdToShow));
    let newRow;
    if (totalChartsToRender !== 0) {
      const lastRow = _.maxBy(workspaceChartsLayout, "y").y;
      newRow = lastRow + _.maxBy(workspaceChartsLayout.filter(chart => chart.y === lastRow), "h").h;
    } else {
      newRow = 0;
    }

    ++totalChartsToRender;

    let chartToShow = defaultCharts.find(chart => chart._id === chartIdToShow);
    chartToShow = { ...chartToShow, location: { row: newRow, column: 0 } };

    setWorkspaceChartsLayout([...workspaceChartsLayout, {
      i: chartIdToShow,
      x: 0,
      y: newRow,
      w: chartToShow.size.width,
      h: chartToShow.size.height
    }]);

    setMakingChartVisible(true);

    setChartIdToShow(null);
  }, [chartIdToShow]);

  useEffect(() => {
    totalChartsToRender = 0;
    chartRenderedCounter = 0;
    initialHiddenCharts = [];
    chartInstances = [];
    getDefaultCharts();
  }, []);

  /*** render ***/

  return (
    <div ref={target}>
      <PageWrapper id="BI">
        <PageHeader
          title={t("businessIntelligence").toUpperCase()}
          filters={
            <>
              {/* Service type filter */}
              <Button
                variant="secondary"
                onClick={() => {
                  setShowDateFilter(false);
                  setShowServiceType(true);
                }}
                disabled={disabledFilters}
              >
                <img src="/assets/icons/filter.svg" alt="" />
                <div>
                  {t("serviceType")}
                  {serviceTypeFilterCount > 0 && ` (${serviceTypeFilterCount})`}
                </div>
              </Button>
              {showServiceType && (
                <div className="PageHeader__filters__dropdown" ref={serviceTypeRef}>
                  {SERVICE_TYPE_OPTIONS.map(item => (
                    <Chip
                      key={item.value}
                      label={item.label}
                      color={item.color}
                      width="75px"
                      isActive={serviceTypeFilter[item.value]}
                      onChange={value => handleChangeServiceTypeFilter(item.value, value)}
                    />
                  ))}
                </div>
              )}
              {/* Date filter */}
              <div className="dateFilter">
                <Button
                  variant="secondary"
                  onClick={() => {
                    setShowServiceType(false);
                    setShowDateFilter(true);
                  }}
                  disabled={disabledFilters}
                >
                  <img src="/assets/icons/calendar-filter.svg" alt="" />
                  {startDate && endDate ? (
                    <>
                      {format(startDate, "dd/MM/yy", { locale: es })} {`- `}
                      {format(endDate, "dd/MM/yy", { locale: es })}
                      <img className="cleanDateFilter" src="/assets/icons/close.svg" alt="" onClick={resetDateFilter} />
                    </>
                  ) : (
                    t("fromUntil")
                  )}
                </Button>
                {showDateFilter && (
                  <div ref={dateRef}>
                    <DateFilter
                      t={t}
                      defaultStartDate={startDate}
                      defaultEndDate={endDate}
                      defaultOption={dateFilterOption}
                      onApply={handleChangeDateFilter}
                    />
                  </div>
                )}
              </div>
            </>
          }
          actions={
            <div className={fullScreen ? "FullScreenActions" : ""}>
              {!_.isEmpty(hiddenCharts) && (
                <Button variant="secondary" prefixIcon="add-chart" onClick={handleAddChartsClick}>
                  <span className="Button__label">{t("add")}</span>
                </Button>
              )}
              {restoreViewBtnVisible && (
                <Button variant="secondary" prefixIcon="restore-view" onClick={restoreWorkspace}>
                  <span className="Button__label">{t("restore")}</span>
                </Button>
              )}
              {saveViewBtnVisible && (
                <Button
                  className="PageHeader__actions__lastButton"
                  prefixIcon={`save-view${saveViewBtnVisible ? "-white" : ""}`}
                  onClick={saveWorkspace}
                >
                  <span className="Button__label">{t("save")}</span>
                </Button>
              )}
            </div>
          }
        />
        <div id="GridLayoutWrapper" key={renderGridCounter} >
          <OverlayScrollbarsComponent
            ref={overlayScrollbarsRef}
            options={{ scrollbars: { autoHide: "scroll" } }}
            style={{ height: "100%", width: "100%" }}
          >
            <PageBody id="PageBody__BI" className="PageBody__BI" ref={target} style={{ maxWidth: "1178px!important" }}>
              {(size && (
                <>

                  <GridLayout
                    className={`layout ${fullScreen ? "fullScreenActive" : ""} ${allGridItemProtected ? "allGridItemProtected" : ""
                      }`}
                    layout={workspaceChartsLayout}
                    draggable={true}
                    cols={gridColumns}
                    width={size.width + 80 <= 1200 ? size.width + 80 - 22 : 1178}
                    rowHeight={size.width + 80 <= 1200 ? (size.width + 80 - 22) / gridColumns - 5 : 37}
                    margin={[gridMargin, gridMargin]}
                    onLayoutChange={handleChangeWorkspaceLayout}
                  >
                    {workspaceChartsLayout.map(chart => {
                      const source = getChartSource(chart.i);
                      const filter = getFilter();
                      return (
                        <div id={`rgi-${chart.i}`} key={chart.i}>
                          <ChartMongoDB
                            token={biToken}
                            baseUrl={source.baseUrl}
                            chartId={source.chartId}
                            filter={filter}
                            onChartLoaded={() => { }}
                            onChartRendered={onChartRendered}
                          />
                          <div
                            className={`gridItemCovert ${allGridItemProtected ? "totalCovert" : ""}`}
                            onMouseDown={event => {
                              setAllGridItemProtected(true);
                            }}
                            onMouseUp={event => {
                              event.stopPropagation();
                              setAllGridItemProtected(false);
                            }}
                          >
                            <img src="/assets/icons/reposition.svg" alt="" />
                          </div>

                          <div
                            className="hideChart"
                            onMouseDown={event => {
                              event.stopPropagation();
                              event.preventDefault();
                            }}
                            onMouseUp={event => handleHideChart(event, chart.i)}
                          >
                            <img src="/assets/icons/hide-chart.svg" alt="" />
                          </div>

                          <div
                            className="fullScreenActivator"
                            onMouseDown={event => {
                              event.stopPropagation();
                              event.preventDefault();
                            }}
                            onMouseUp={event => handleChartFullScreen(event, true, chart.i)}
                          >
                            <img src="/assets/icons/fullscreen-in.svg" alt="" />
                          </div>
                        </div>
                      );
                    })}
                  </GridLayout>
                </>
              )) ||
                false}
            </PageBody>
          </OverlayScrollbarsComponent>
          <div id="ChartFullScreen" className={fullScreen ? "enabled" : ""}>
            {fullScreen && (
              <ChartMongoDB
                token={biToken}
                baseUrl={fullScreenChart.source.baseUrl}
                chartId={fullScreenChart.source.chartId}
                filter={getFilter()}
                onChartLoaded={() => { }}
                onChartRendered={onFullScreenChartRendered}
              />
            )}
            <img src="/assets/icons/fullscreen-out.svg" alt="" onClick={event => handleChartFullScreen(event, false)} />
          </div>
        </div>
      </PageWrapper>
    </div>
  );
};

export default BI;
