import { useDispatch, useSelector } from 'react-redux'

import { setOutlaysDetail, setOutlaysList } from './actions'
import useFetch from '../../utils/hooks/useFetch'

const useOutlays = () => {
  const endpoint = 'extraCharges'
  const instance = useSelector(state => state.outlays)
  const dispatch = useDispatch()
  const { get, post, put, patch } = useFetch()

  //LIST
  const fetchList = async params => {
    const data = await get({ endpoint, params })
    dispatch(setOutlaysList({ ...data, search: params?.search }))
  }

  const fetchAllOutlays = async () => {
    return await get({ endpoint: `${endpoint}/all` });
  };

  const handleChangePage = page => {
    fetchList({ ...instance.params, page })
  }

  const handleChangePageSize = limit => {
    fetchList({ ...instance.params, page: 1, limit })
  }

  const handleChangeSearch = search => {
    fetchList({ ...instance.params, page: 1, search })
  }

  //DETAIL
  const updateOutlays = async (id, body) => {
    const response = await put({ endpoint, id, body })
    const listIndex = instance.list.findIndex(item => item._id === response._id)
    if (listIndex !== -1) {
      const newList = [...instance.list]
      newList[listIndex] = response
      dispatch(setOutlaysList({ docs: newList, metadata: { totalDocs: instance.totalDocs } }))
    }
    return response
  }

  const toggleActiveOutlays = async (id, active) => {
    const response = await patch({ endpoint, id, body: { active } })
    const listIndex = instance.list.findIndex(item => item._id === response._id)
    if (listIndex !== -1) {
      const newList = [...instance.list]
      newList[listIndex] = { ...newList[listIndex], active: response.active }
      dispatch(setOutlaysList({ docs: newList }))
    }
    return response
  }

  const addOutlays = async body => {
    return await post({ endpoint, body })
  }

  const fetchOutlaysDetail = async id => {
    const response = await get({ endpoint: `${endpoint}/${id}` })
    dispatch(setOutlaysDetail(response))
  }

  const unsetOutlaysDetail = () => {
    dispatch(setOutlaysDetail(null))
  }

  const getOutlaysCategories = async () => {
    return await get({ endpoint: `${endpoint}/attachmentTypes` })
  }

  return {
    methods: {
      fetchList,
      fetchAllOutlays,
      handleChangePage,
      handleChangePageSize,
      handleChangeSearch,
      toggleActiveOutlays,
      updateOutlays,
      addOutlays,
      fetchOutlaysDetail,
      setOutlaysDetail: data => dispatch(setOutlaysDetail(data)),
      unsetOutlaysDetail,
      getOutlaysCategories
    },
    data: instance
  }
}

export default useOutlays
