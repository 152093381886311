export const RESET_PAYMENTS_LIST = 'RESET_PAYMENTS_LIST'
export const resetPaymentsList = payload => ({
  type: RESET_PAYMENTS_LIST,
  payload
})

export const SET_PAYMENTS_LIST = 'SET_PAYMENTS_LIST'
export const setPaymentsList = payload => ({
  type: SET_PAYMENTS_LIST,
  payload
})

export const SET_PAYMENT_DETAIL = 'SET_PAYMENT_DETAIL'
export const setPaymentDetail = payload => ({
  type: SET_PAYMENT_DETAIL,
  payload
})
