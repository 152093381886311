import { useDispatch, useSelector } from "react-redux";

import { setMediumDetail, setMediumsList } from "./actions";
import useFetch from "../../utils/hooks/useFetch";

const useMediums = () => {
  const mediums = useSelector(state => state.mediums);
  const dispatch = useDispatch();
  const { get, post, put, patch } = useFetch();

  //LIST
  const fetchList = async params => {
    const data = await get({ endpoint: "mediums", params });
    dispatch(setMediumsList({ ...data, search: params?.search }));
  };

  const handleChangePage = page => {
    fetchList({ ...mediums.params, page });
  };

  const handleChangePageSize = limit => {
    fetchList({ ...mediums.params, page: 1, limit });
  };

  const handleChangeSearch = search => {
    fetchList({ ...mediums.params, page: 1, search });
  };

  //MEDIUM
  const updateMedium = async (id, body, types) => {
    const response = await put({ endpoint: "mediums", id, body });
    const listMediumIndex = mediums.list.findIndex(item => item._id === response._id);
    if (listMediumIndex !== -1) {
      const _type = types.find(item => item.value === response.type);
      const newList = [...mediums.list];
      newList[listMediumIndex] = { ...response, type: { name: _type.label, _id: _type.value } };
      dispatch(setMediumsList({ docs: newList, metadata: { totalDocs: mediums.totalDocs } }));
    }
    return response;
  };

  const toggleActiveMedium = async (id, isActive) => {
    const response = await patch({ endpoint: "mediums/setactive", id, body: { isActive } });
    const listMediumIndex = mediums.list.findIndex(item => item._id === response._id);
    if (listMediumIndex !== -1) {
      const newList = [...mediums.list];
      newList[listMediumIndex] = { ...newList[listMediumIndex], active: response.active };
      dispatch(setMediumsList({ docs: newList }));
    }
    return response;
  };

  const toggleOos = async (id, outOfService) => {
    const medium = await patch({ endpoint: "mediums/setoutofservice", id, body: { outOfService } });
    dispatch(setMediumDetail(medium));
  };

  const fetchAllMediums = async () => {
    return await get({ endpoint: "mediums/all" });
  };

  const bulkAdd = async body => {
    return await post({ endpoint: "mediums/bulk", body });
  };

  const fetchMediumDetail = async id => {
    const medium = await get({ endpoint: `mediums/${id}` });
    dispatch(setMediumDetail(medium));
  };

  const unsetMediumDetail = () => {
    dispatch(setMediumDetail(null));
  };

  const getMediumBrands = async () => {
    return await get({ endpoint: "common/mediumBrands" });
  };

  const getMediumTypes = async () => {
    return await get({ endpoint: "common/mediumTypes" });
  };

  const getMediumResourceStatus = async mediumId => {
    return await get({ endpoint: `resources/mediumStatus/${mediumId}` });
  };

  const unbindMedium = async id => {
    return await patch({ endpoint: `resources/unbindMedium`, id, body: {} });
  };

  return {
    methods: {
      fetchList,
      handleChangePage,
      handleChangePageSize,
      handleChangeSearch,
      toggleActiveMedium,
      fetchAllMediums,
      updateMedium,
      bulkAdd,
      fetchMediumDetail,
      setMediumDetail: data => dispatch(setMediumDetail(data)),
      unsetMediumDetail,
      getMediumBrands,
      getMediumTypes,
      toggleOos,
      getMediumResourceStatus,
      unbindMedium,
    },
    data: mediums,
  };
};

export default useMediums;
